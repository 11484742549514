import React from 'react';

/**
 * Search bar component
 * 
 * rendered by the EntryPage
 */
export class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: ''
        }
    }
    
    /**
     * updates state based on input search term
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ text }));
    }

    /**
     * calls onSearch in EntryPage
     * updates state to clear text
     */
    onSearch = () => {
        this.props.onSearch(this.state.text)
        this.setState(() => ({ text: '' }))
    }

    render() {
        return (

            <div className="ml-5 mr-5 p-md-4 pt-0">
                <div className="ml-4 mr-4 pb-5">
                    
                    <div>
                        <h4 className="text-center display-4 ml-5 mb-md-4 pb-4 welcome-subtitle">What are you looking for?</h4>
                    </div>
                    
                    <div className="d-flex justify-content-center mb-md-4">
                        
                        <div className="col-12 col-sm-8">
                            <input
                                style={{height: '50px'}}
                                className="welcome-search form-control form-control-lg rounded-0 font-weight-light text-center"
                                type="text"
                                placeholder={`Enter any search term...`}
                                value={this.state.text}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        this.onSearch()
                                    }
                                }}
                                onChange={this.onTextChange}/>
                        </div>

                        <div className="input-group-append">
                            <div className="search-link" onClick={this.onSearch}>
                                <img src="/images/search.png" className="img img-fluid" style={{maxBlockSize: 45}}/>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        )
    }
}

export default Search;