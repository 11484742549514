import React from 'react';
import {connect} from 'react-redux';
import TopNav from '../TopNav';
import Profile from './Profile';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * page for displaying a logged in user's profile
 * 
 * www.itorho.com/profile
 * 
 */
export class ProfilePage extends React.Component {

    constructor(props) {
        super(props)
    }

    /**
     * initiates google analytics upon component mount
     */
    componentDidMount = () => {
        initGA();
        logPageView();
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true} type="buyer" props={this.props}/>
                <div>
                    <Profile 
                        profile={this.props.profile}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile[0]
});

export default connect(mapStateToProps)(ProfilePage);