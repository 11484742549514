
/* 
 * filtering services by search term (text), category and location 
 * if near is true, then returns an already created list of nearby services
 * 
 * NOTE: location filter (ie. services filtered by city of user)
 * is currently disabled
 * 
 */
export const selectServices = (services, { text, category, location, near, nearServices }) => {
    
    if (services) {

        services = services.filter((service) => service.disabled != true)
        services = services.filter((service) => service.name)
        services = services.filter((service) => service.serviceItems)

        if (near === false) {

            if (services.length != 0) {
                text = text.toLowerCase()
                return services.filter((service) => {
                    
                    // checks for a text match in service name, service address, service info, service item names, service item info and service item tags
                    let textMatch = true;
                    if (text.length != 0) {
                        textMatch = service.name.toLowerCase().includes(text) || service.address.toLowerCase().includes(text) || service.info.toLowerCase().includes(text)
                        if (service.serviceItems) {
                            Object.values(service.serviceItems).map((item) => {
                                if (item.title.toLowerCase().includes(text) ||  item.description.toLowerCase().includes(text)) { 
                                    textMatch = true 
                                }
                                if (item.tags) {
                                    item.tags.map((tag) => {
                                        if (tag.value.includes(text)) { 
                                            textMatch = true 
                                        }
                                    })
                                }
                            }) 
                        }
                    }

                    // checks for a text match in service tags
                    let tagMatch = false;
                    if (text.length != 0 && service) {
                        if(service.serviceItems) {
                            Object.values(service.serviceItems).map((item) => {
                                if (item.tags) {
                                    item.tags.map((tag) => {
                                        if (tag.value.includes(text.toLowerCase())) {
                                            tagMatch = true;
                                        }
                                    })
                                }
                            })
                        }
                    }

                    // checks for a category match 
                    let categoryMatch = false
                    if (service.categories) {
                        if (service.categories.length != 0) {
                            service.categories.map((c) => {
                                if (category.includes(c.value.toLowerCase())) { categoryMatch = true }
                            })
                        }
                    }

                    // DISABLED! checks for a location match (only services in the same city are shown)
                    let locationMatch = true
                    // let locationMatch = false
                    // if (service.city && location) {
                    //     if (location === service.city.toLowerCase().trim()) { locationMatch = true }
                    // } else {
                    //     locationMatch = true
                    // }
                    
                    // for category page
                    if (category.length > 1) { 
                        return ( (textMatch || tagMatch) && categoryMatch && locationMatch );
                    } 

                    // for general search page (not by category or location)
                    else { 
                        return (textMatch || tagMatch); 
                    }
                })
            }

        } else {

            let nearCategories = []
            let newNearServices = []
            nearServices.map((s) => {
                s.categories.map((c) => {
                    if (!nearCategories.includes(c.label)) {
                        nearCategories.push(c.label)
                        newNearServices.push(s)
                    }
                })
            })
            return newNearServices
        }
    }
}

/** returns filtered list of services */
export const selectActiveServices = (services) => {
    
    if (services) {
        services = services.filter((service) => service.disabled != true)
        services = services.filter((service) => service.name)
        services = services.filter((service) => service.serviceItems)
    }

    return services;
}