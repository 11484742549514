import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import Progress from '../Progress';

/**
 * form for editing and adding a service item
 * rendered by the edit service item or add service item page
 */
export default class ServiceItemForm extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            title: props.item ? props.item.title : '',
            description: props.item ? props.item.description : '',
            tags: props.item ? props.item.tags : [],
            cost: props.item ? props.item.cost : '',
            costType: props.item ? props.item.costType: 'flat',
            deposits: props.item ? props.item.percent ? true : false : false,
            percent: props.item ? props.item.percent ? props.item.percent : 0 : 0,
            duration: props.item ? props.item.duration ? props.item.duration : '' : '',
            customDuration: false,
            durations: ['Client must specify','N/A','15 minutes','30 minutes','45 minutes','60 minutes','90 minutes','120 minutes','360 minutes'],

            titleError: '',
            descriptionError: '',
            costError: '',
            formError: ''
        };
    }

    /**
     * updates state based on form inputs
     */
    onTitleChange = (e) => {
        const title = e.target.value;
        this.setState(() => ({ title }));
    }
    onDescriptionChange = (e) => {
        const description = e.target.value;
        this.setState(() => ({ description }));
    }
    onCostChange = (e) => {
        const cost = e.target.value;
        this.setState(() => ({ cost }));
    }
    onCostTypeChange = (e) => {
        const costType = e.target.value;
        this.setState(() => ({ costType }));
        e.persist();
    }
    onTagsChange = (selectedTags) => {
        this.setState(() => ({ tags: selectedTags }));
    }
    onDeposits = () => {
        this.setState(() => ({ deposits: !this.state.deposits }))
        if (!this.onDeposits) {
            this.setState(() => ({ percent: 0 }))
        }
    }
    onPercentChange = (e) => {
        const percent = e.target.value;
        this.setState(() => ({ percent }));
    }
    onDurationChange = (e) => {
        const duration = e.target.value;
        if (duration == "Custom") {
            this.setState(() => ({ 
                customDuration: true,
                duration 
            }));
        } else {
            this.setState(() => ({ 
                duration,
                customDuration: false 
            }));
        }
        this.setState(() => ({ durationError: '' }))
    }
    onCustomDurationChange = (e) => {
        const duration = e.target.value;
        this.setState(() => ({ 
            duration: duration + ' minutes',
            durationError: '' 
        }))
    }
    
    /**
     * calls onRemove method in EditServiceItemForm
     */
    onRemove = (e) => {
        e.preventDefault();
        this.props.onRemove();
    }
    
    /**
     * calls onCancel method in EditServiceItemPage/AddServiceItemPage
     */
    onCancel = (e) => {
        e.preventDefault();
        this.props.onCancel();
    }

    /**
     * performs error checks on form inpur data
     * if valid, submits to EditServiceItemPage/AddServiceItemPage
     */
    onSubmit = (e) => {
        e.preventDefault();

        let costRegex = /^[0-9]*$/;

        if (!this.state.title) { this.setState(() => ({ titleError: 'Please provide a title.'})) } 
        else { this.setState(() => ({ titleError: ''})) }
        if (!this.state.description) { this.setState(() => ({ descriptionError: 'Please provide a desciption.'})) } 
        else { this.setState(() => ({ descriptionError: ''})) }
        if (!this.state.cost || !costRegex.test(this.state.cost)) { this.setState(() => ({ costError: 'Please provide a valid cost.'})) } 
        else { this.setState(() => ({ costError: ''})) }
        if (!this.state.duration) { this.setState(() => ({ durationError: 'Please provide a valid duration.'})) }
        else { this.setState(() => ({ durationError: '' })) }
        if (this.state.customDuration) { if (!durationRegex.test(this.state.duration)) { this.setState(() => ({ durationError: 'Provide a valid duration in minutes.'})) }
        else { this.setState(() => ({ durationError: '' })) }}

        if(this.state.title && this.state.description && this.state.cost && costRegex.test(this.state.cost)) {
            if (this.state.deposits) {
                this.props.onSubmit({
                    title: this.state.title,
                    description: this.state.description,
                    tags: this.state.tags,
                    cost: this.state.cost,
                    costType: this.state.costType,
                    percent: this.state.percent,
                    duration: this.state.duration,
                });
            } else {
                this.props.onSubmit({
                    title: this.state.title,
                    description: this.state.description,
                    tags: this.state.tags,
                    cost: this.state.cost,
                    costType: this.state.costType,
                    duration: this.state.duration,
                });
            }
        } else { this.setState(() => ({ formError: 'Please fill in all required fields.'})) }
    }

    render() {

        const { tags } = this.state;
        
        return (

            <div>
                {!this.props.edit ? (
                    this.props.firstSignIn || !this.props.complete ? (
                        <div>
                            <Progress step={2}/>
                            <div className="d-flex justify-content-center pt-md-5 pt-4">
                                <div className="col-md-7 col-11 ml-lg-5 pl-lg-5">
                                <h1 className="m-md-5 mt-md-5 title welcome-subtitle" style={{fontSize: '50px', fontWeight: 'bold'}}>Step 2</h1>
                                <h1 className="font-weight-light m-md-5 mb-md-0 mb-4 title-2" style={{fontSize: '30px'}}>Add a Service</h1>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div onClick={this.onCancel} className="link float-left ml-5 mt-5 pt-5">
                                <img src="/images/back.png" className="img mx-auto" style={{maxWidth:20, maxHeight:20}}/>
                            </div>
                            <div className="d-flex justify-content-center pt-5">
                                <div className="col-7 ml-lg-5 pl-lg-5">
                                    <h1 className="font-weight-light m-sm-5 m-3 title-2" style={{fontSize: '30px'}}>Add a New Service</h1>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <div onClick={this.onCancel} className="link float-left ml-5">
                            <img src="/images/back.png" className="img mx-auto" style={{maxWidth:20, maxHeight:20}}/>
                        </div>
                        <h3 className="font-weight-light text-center m-5">Edit Service</h3>
                    </div>  
                )}

                <div className="d-flex justify-content-center text-left">
                    <form onSubmit={this.onSubmit} className="col-sm-6 col-12">

                        <div className="pb-4">
                            <input
                                className="form-control font-weight-light rounded-0 form-control-lg"
                                placeholder="Title"
                                type="text"
                                id="title"
                                value={this.state.title}
                                onChange={this.onTitleChange}/>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.titleError}</small>
                        </div>

                        <hr/>
                        <h5 className="font-weight-light text-center mt-5 mb-3">Service Details</h5>

                        <div className="pb-4">
                            <textarea
                                className="form-control font-weight-light rounded-0 form-control-lg"
                                style={{minHeight: 100}}
                                placeholder="Description"
                                type="text"
                                id="description"
                                value={this.state.description}
                                onChange={this.onDescriptionChange}/>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.descriptionError}</small>
                        </div>

                        <div className="pb-4">
                            <hr/>
                            <label htmlFor="dur">Duration</label>
                            <select 
                                id="dur" 
                                className="custom-select font-weight-light rounded-0 custom-select-lg"
                                value={this.state.duration}
                                onChange={this.onDurationChange}>
                                <option>{this.state.customDuration ? 'Custom' : this.state.duration}</option>
                                <option>Custom</option>
                                {this.state.durations.map((duration) => {
                                    return (<option key={duration}>{duration}</option>)
                                })}
                            </select>
                            
                            <div className="pt-2" hidden={!this.state.customDuration}>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Minutes"
                                    type="number"
                                    id="places"
                                    value={this.state.customDurationVal}
                                    onChange={this.onCustomDurationChange}/>
                                <small className="form-text text-muted">Please specify the number of minutes</small>     
                            </div>                           
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.durationError}</small>
                        </div>
                        
                        <fieldset className="form-group pb-4">
                            <hr/>
                            <label className="col-form-label">Cost</label>
                            <div className="row">
                                <div className="col-sm-10">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flat" onChange={this.onCostTypeChange}  checked={this.state.costType === 'flat'} value='flat'/>
                                        <label className="form-check-label ml-1">Flat Rate</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="hourly" onChange={this.onCostTypeChange} checked={this.state.costType === 'hourly'} value='hourly'/>
                                        <label className="form-check-label ml-1">Hourly Rate</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="daily" onChange={this.onCostTypeChange} checked={this.state.costType === 'daily'} value='daily'/>
                                        <label className="form-check-label ml-1">Daily Rate</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="negotiable" onChange={this.onCostTypeChange} checked={this.state.costType === 'negotiable'} value='negotiable'/>
                                        <label className="form-check-label ml-1">Negotiable</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="from" onChange={this.onCostTypeChange} checked={this.state.costType === 'from'} value='from'/>
                                        <label className="form-check-label ml-1">From</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div className="form-group pb-3">
                            <div className="input-group flex-nowrap">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">R</span>
                            </div>
                            <input
                                className="form-control font-weight-light rounded-0 form-control-lg"
                                placeholder="Cost"
                                type="text"
                                id="cost"
                                value={this.state.cost}
                                onChange={this.onCostChange}/>
                            </div>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.costError}</small>
                        </div>

                        <div className="form-group form-check pb-3">
                            <input type="checkbox" defaultChecked={this.state.deposits} onClick={this.onDeposits} className="form-check-input"/>
                            <label className="form-check-label pl-2">Allow deposits</label>
                        </div>

                        <div className="form-group pb-3" hidden={!this.state.deposits}>
                            <div className="input-group flex-nowrap">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping">Deposit %</span>
                            </div>
                            <input
                                className="form-control font-weight-light rounded-0 form-control-lg"
                                placeholder="Cost"
                                type="number"
                                id="percent"
                                value={this.state.percent}
                                onChange={this.onPercentChange}/>
                            </div>
                            <small className="text-muted">Input the percentage of total cost you'd like to charge as deposit.</small>
                        </div>

                        <div className="mb-5">
                            <CreatableSelect
                                placeholder="tags"
                                isMulti
                                onChange={this.onTagsChange}
                                value={tags}
                                options={this.props.categories} />
                            <small className="text-muted">To add a custom tag, type it in and press enter. This is for search optimisation.</small>
                        </div>

                        <h4 className="mb-3 mb-2 font-weight-light" style={{color: '#ec9422'}}>{this.state.formError}</h4>
                        <div className="d-flex justify-content-center mb-2 pt-2">
                            <button className="rounded-0 mb-5 btn btn-block" style={{height: 37}}>
                                <h4 className="font-weight-light">Save</h4>
                            </button>
                        </div>

                        {this.props.edit ? (
                            <div className="d-flex justify-content-center mb-5" onClick={this.onRemove}>
                                <h4 className="font-weight-light settings-link" style={{color: '#ec9422'}}>Remove</h4>
                            </div>
                        ) : (
                            <div></div>
                        )}

                    </form>
                </div>
            </div>
        )
    }
}

//export default ServiceItemForm;