import database from '../firebase/firebase';
import moment from 'moment';

// SET USERS

/** sets users in redux store */
export const setUsers = (users) => ({
    type: 'SET_USERS',
    users
});

  /** gets users from database */
export const startSetUsers = () => {
    return (dispatch) => {
        return database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                const users = [];
  
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val())
                    if (value[0].date) {
                        users.push({
                            id: childSnapshot.key,
                            ...value[0]
                        });
                    } else {
                        users.push({
                            id: childSnapshot.key,
                            date: (moment("2020-02-01")).toString(),
                            ...value[0]
                        });
                    }
                });
            dispatch(setUsers(users));
        });
    };
};

/** accesses user by id from firebase store */
export const getUserById = async (id) => {
    database.ref(`users/`)
        .once('value')
        .then((snapshot) => {
            snapshot.forEach((childSnapshot) => {
                const value = Object.values(childSnapshot.val())
                if(value[0].id == id){
                    console.log('found user: ',id);
                    return value[0];
                }
            });
    });
}

// REMOVE USER

/** removes user from redux store */
export const removeUser = (id) => ({
    type: 'REMOVE_USER',
    id
});
  
/** removes profile from database */
export const startRemoveUser = (id) => {
return (dispatch) => {
    return database.ref(`users/${id}`)
        .remove()
        .then(() => {
            dispatch(removeUser(id));
        });
    };
};

// EDIT USER

/** edits user in redux store */
export const editUser = (id, updates) => ({
    type: 'EDIT_USER',
    id,
    updates
});

// ADD USER

/** adds user to redux store */
export const addUser = (user) => ({
    type: 'ADD_USER',
    user
});
