import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import uuid from 'uuid';
import QuoteList from './QuoteList';
import Quote from './Quote';
import {findServiceName, findServiceID, findServiceItemID, sortQuotes} from '../../selectors/messages';
import {postData} from '../../utils/payment';


/**
 * component to render quotes and quote list in the chat popup
 * 
 * renderd by the Inbox
 */
export class Quotes extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            quotes: this.props.quotes ? this.props.quotes : [],

            cost: 100.00,
            reply: '',

            showQuotes: false,
            showConfirm: false,
            showCannot: false,

            booking: {}

        }
    }

    /**
     * method to update state upon receiving new props
     */
    componentWillReceiveProps = (newProps) => {
        if (newProps.quotes != this.state.quotes) { this.setState(() => ({ quotes: newProps.quotes })) }
    }

    /**
     * closes booking confirmation pop up
     * calls onClose in Inbox
     */
    onClose = () => {
        this.setState(() => ({ 
            showConfirm: false,
            booking: {},
            showCannot: false 
        }));
        this.props.onClose()
    }

    /*
     * updates state to hide quotes
     * calls onBack method in Inbox
     */
    onBack = () => {
        this.setState(() => ({ 
            showQuotes: false
        }))
        this.props.onBack()
    }

    /**
     * method to handle opening a quote
     * calls onOpenQuote method in Inbox
     */
    onOpenQuote = (buyerName, toUserID, quoteID) => {
        let quotes = this.props.allMessages.filter((q) => q.id === quoteID) 
        this.setState(() => ({ 
            cost: quotes[0].cost ? quotes[0].cost : 0,
            reply: quotes[0].reply ? quotes[0].reply : " ",
            quotes,
            showQuotes: true,
        }));

        this.props.onOpenQuote(buyerName, toUserID, quoteID, quotes)
    }

    /**
     * method to handle sending a quote
     * calls onQuoteSend method in Inbox
     */
    onQuoteSend = (reply, cost) => {

        let newQuote = {
            reply,
            cost,
        }
        let newQuoteBuyer = {
            reply,
            cost,
            status: 'unread'
        }
        this.setState(() => ({ showQuotes: false }))
        this.props.onQuoteSend(newQuote, newQuoteBuyer)
    }

    /**
     * updates state to show cannot accept quote popup
     */
    onQuoteCannotAccept = () => {
        this.setState(() => ({ showCannot: true }))
    }

    /**
     * method to handle booking a quote
     * calls onQuoteBook method in Inbox
     */
    onNewBooking = () => {
        this.props.onQuoteBook('new', this.state.quotes[0])
    }

    /**
     * method to handle accepting a quote
     * makes a new booking based on quote details
     * calls onQuoteAccept method in Inbox
     */
    onQuoteAccept = () => {
        
        // calculate deposit if relevant
        let deposit = undefined
        this.props.services.map((service) => {
            if (findServiceID(this.state.quotes[0].toUserID, this.props.services) === service.id) {
                Object.values(service.serviceItems).map((item) => {
                    if (item.title === this.state.quotes[0].i) {
                        if (item.percent && item.percent != 0) {
                            deposit = item.percent
                        }
                    }
                })
            }
        })

        let newBooking = {
            bookingID: uuid(),
            buyerID: this.state.quotes[0].userID,
            date: this.state.quotes[0].d.substring(0,16) + this.state.quotes[0].t.substring(0,6),
            time: this.state.quotes[0].t,
            duration: this.state.quotes[0].dur,
            title: this.state.quotes[0].buyerName,
            item: this.state.item,
            service: findServiceName(this.state.quotes[0].toUserID, this.props.services),
            serviceID: findServiceID(this.state.quotes[0].toUserID, this.props.services),
            item: this.state.quotes[0].i,
            itemID: findServiceItemID(this.state.quotes[0].toUserID, this.props.services, this.state.quotes[0].i),
            paid: false,
            rated: false,
            cost: this.state.quotes[0].cost
        }

        // remove empty elements
        Object.keys(newBooking).forEach((key) => newBooking[key] === undefined && delete newBooking[key])

        if (deposit != undefined) {
            newBooking = {
                ... newBooking,
                deposit
            }
        }
        this.props.onQuoteAccept(newBooking, this.state.quotes[0])

        this.setState(() => ({ 
            showConfirm: true,
            showQuotes: false,
            booking: newBooking
        }))

        // FOR ROGERS
        // email notification here for newly accepted quote and booking made
    }

    /**
     * method to handle paying for a booking
     */
    onPay = () => {
        postData({
            merchant_id: "12666206",
            merchant_key: "ny9qkip357kf3",
            return_url: "https://itorho.com/bookings",
            cancel_url: "https://itorho.com/bookings",
            notify_url: "api/response",
            name_first: ((this.state.booking.title).split(" "))[0] || null,
            name_last: ((this.state.booking.title).split(" "))[this.state.booking.title.split(" ").length-1]||null,
            email_address: "info@itorho.com" ||this.props.profile.email || null,
            m_payment_id: this.state.bookingID,
            amount : this.state.booking.cost,
            item_name : this.state.booking.item,
            email_confirmation: 1,
            payment_method: "cc",
            signature: null
        }, this.state.booking);
    }
    
    render() {
        return (

            <div>

                {/* booking confirmation popup */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showConfirm}
                        showCancel
                        confirmBtnText="Pay Now"
                        cancelBtnText="Pay Cash or Pay Later"
                        confirmBtnBsStyle="default"
                        title="Booking Comfirmed"
                        closeOnClickOutside={false}
                        focusConfirmBtn={false}
                        onConfirm={this.onPay}
                        onCancel={this.onClose}>
                        <div>
                            <h4 className="font-weight-light mt-4">Thank you for your booking.</h4>
                            <h5 className="font-weight-light mb-1">We look forward to seeing you.</h5>
                            <div className="d-flex justify-content-center pt-2">
                                <h5 className="font-weight-light mb-3 col-7" style={{color: '#ec9422'}}>{this.state.booking ? this.state.booking.info ? this.props.state.info : '' : ''}</h5>
                            </div>
                        </div>
                    </SweetAlert>
                </div>

                {/* booking unavailble pop up */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showCannot}
                        showCancel
                        confirmBtnText="Make a New Booking"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="default"
                        title="Booking Unavailable"
                        closeOnClickOutside={false}
                        focusConfirmBtn={false}
                        onConfirm={this.onNewBooking}
                        onCancel={this.onClose}>
                        <div>
                            <h4 className="font-weight-light mt-4">Unfortunately this date and time are no longer available.</h4>
                            <h5 className="font-weight-light mb-1">Would you like to select a new date and time?</h5>
                        </div>
                    </SweetAlert>
                </div>

                {/* view controls */}
                {!this.state.showQuotes ?
                    <QuoteList 
                        quotes={this.state.quotes}
                        seller={this.props.seller}
                        services={this.props.services}
                        onOpenQuote={this.onOpenQuote} />
                :
                    <Quote
                        allBookings={this.props.allBookings}
                        onBack={this.onBack}
                        onQuoteAccept={this.onQuoteAccept}
                        onQuoteCannotAccept={this.onQuoteCannotAccept}
                        onQuoteBook={this.props.onQuoteBook}
                        onQuoteSend={this.onQuoteSend}
                        quotes={this.state.quotes}
                        cost={this.state.cost}
                        services={this.props.services}
                        userID={this.props.userID} />
                }
            </div>
        )
    }
}

export default Quotes;