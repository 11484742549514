import React from 'react';
import moment from 'moment';
import {postData} from '../../utils/payment';

/**
 * booking component displays booking details
 * allows editing and removing of booking as well as paying
 * rendered in a pop up by BookingsPage
 */
export class Booking extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            edit: this.props.edit ? this.props.edit : false,
            past: this.props.booking ? moment(this.props.booking.date).isBefore(moment()) : '',
            paid: this.props.booking ? this.props.booking.paid : '',
            rated: this.props.booking ? this.props.booking.rated : '',
        }
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.booking !== this.props.booking) {
            this.setState(() => ({ 
                edit: newProps.edit,
                past: moment(newProps.booking.date).isBefore(moment()),
                paid: newProps.booking.paid,
                rated: newProps.booking.rated 
            }))
        }
    }

    /**
     * method calls onRemove in BookingsPage
     */
    onRemove = (e) => {
        e.preventDefault();
        this.props.onRemove();
    }

    /**
     * method calls onCancel in BookingsPage
     */
    onCancel = (e) => {
        e.preventDefault();
        this.props.onCancel();
    }

    /**
     * method calls onRate in BookingsPage
     */
    onRate = (e) => {
        e.preventDefault();
        this.props.onRate();
    }

    /**
     * method for paying for booking
     * redirects to payfast
     */
    onPay = () => {

        postData({
            merchant_id: "12666206",
            merchant_key: "ny9qkip357kf3",
            return_url: "https://itorho.com/bookings",
            cancel_url: "https://itorho.com/bookings",
            notify_url: "https://strawberry-tart-77589.herokuapp.com/response",
            name_first: ((this.props.booking.title).split(" "))[0] || null,
            name_last: ((this.props.booking.title).split(" "))[this.props.booking.title.split(" ").length-1]||null,
            email_address: "info@itorho.com" ||this.props.profile.email || null,
            m_payment_id: this.props.bookingID,
            amount : this.props.booking.deposit ? this.props.booking.deposit : this.props.booking.cost,
            item_name : this.props.booking.item,
            email_confirmation: 1,
            payment_method: "cc",
            signature: null},this.props
        );
    }

    render() {
        return (

            <div className="mt-5 mb-3">
                <div className="d-flex justify-content-center text-left">
                    <div className="col-8">
                        <h3 className="font-weight-light pb-2 mb-2 mt-3 display-5 text-center">{this.props.booking ? this.props.booking.date.substring(0,12) : ''}</h3>
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>                        
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>{this.props.booking ? this.props.booking.service : ''}</h5>                                                                                    
                            <h4 className="font-weight-light p-2 pl-3">{this.props.booking ? this.props.booking.item : ''}</h4>
                        </div>
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Time</h5>                                                        
                            <h4 className="font-weight-light p-2 pl-3">{this.props.booking ? this.props.booking.time : ''}</h4>
                        </div>
                        {this.props.booking ? (this.props.booking.duration && this.props.booking.duration != 'N/A') ?
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Duration</h5>                                                        
                            <h4 className="font-weight-light p-2 pl-3">{this.props.booking ? this.props.booking.duration : ''}</h4>
                        </div>
                        : '' : ''}
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>      
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Name</h5>                  
                            <h4 className="font-weight-light p-2 pl-3">{this.props.booking ? this.props.booking.title : ''}</h4>
                        </div>
                        {this.props.booking ? this.props.booking.info ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                                <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Additional Information</h5>                      
                                <h4 className="font-weight-light p-2 pl-3">{this.props.booking ? this.props.booking.info ? this.props.booking.info : '' : ''}</h4>
                            </div>
                        : '' : ''}          
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}> 
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}> {!this.state.paid ? this.props.booking.deposit ? 'Outstanding deposit' : 'Outstanding' : 'Paid'}</h5>                                                  
                            <h4 className="font-weight-light p-2 pl-3">{this.props.booking ? (this.props.booking.paidDeposit && this.props.booking.paid) ? "R" + this.props.booking.cost + ' + R' + this.props.booking.paidDeposit + ' (deposit)' : this.props.booking.paid ? "R" + this.props.booking.cost : this.props.booking.deposit ? "R" + this.props.booking.deposit : "R" + this.props.booking.cost : ''}</h4>
                        </div>
                        <div hidden={this.state.paid}>
                            <h5 className="font-weight-light pt-4 pb-2" style={{color: '#ec9422'}}>*Note that bookings are non-refundable.</h5>                            
                            <button className="rounded-0 mb-3 btn btn-main btn-block" onClick={this.onPay} style={{height: 37}}>
                                <h4 className="font-weight-light">Pay Now</h4>
                            </button>
                        </div> 
                        <div hidden={!this.state.past || this.state.rated}>
                            <button className="rounded-0 mb-3 btn btn-main btn-block" onClick={this.onRate} style={{height: 37}}>
                                <h4 className="font-weight-light">Leave a Rating</h4>
                            </button>
                        </div>
                        <div hidden={this.state.past || this.state.paid}>
                            <button className="rounded-0 mb-3 btn btn-main btn-block" onClick={this.onRemove} style={{height: 37}}>
                                <h4 className="font-weight-light">Cancel Booking</h4>
                            </button>
                        </div>       
                    </div>
                </div>

                {/* hidden payment form */}
                <form name="form1" action="https://www.payfast.co.za/eng/process" method="POST">
                    <input type="hidden" name="merchant_id" placeholder=""/>
                    <input type="hidden" name="merchant_key" placeholder=""/>
                    <input type="hidden" name="return_url" placeholder=""/>
                    <input type="hidden" name="cancel_url" placeholder=""/>
                    <input type="hidden" name="notify_url" placeholder="default"/>
                    <input type="hidden" name="name_first" placeholder="10000100"/>
                    <input type="hidden" name="name_last" placeholder="46f0cd694581a"/>
                    <input type="hidden" name="email_address" placeholder="default"/>
                    <input type="hidden" name="m_payment_id" placeholder="default"/>
                    <input type="hidden" name="amount" placeholder="default"/>
                    <input type="hidden" name="item_name" placeholder="item_name"/>
                    <input type="hidden" name="item_description" placeholder="default"/>
                    <input type="hidden" name="email_confirmation" placeholder="default"/>
                    <input type="hidden" name="payment_method" placeholder="default"/>
                    <input type="hidden" name="signature" placeholder="default"/>
                </form>
            </div>

        )
    }
}

export default Booking;