import React from 'react';

/**
 * message form component
 * rendered by Inbox
 */
export class Form extends React.Component {

    state = {
        message: ''
    }

    /**
     * updates state and calls onMessageSend method in Inbox
     */
    onMessageSend = (e) => {
        e.preventDefault()
        this.setState(() => ({ message: '' }))
        this.props.onMessageSend(this.state.message)
    }

    /**
     * updates state based on text input
     */
    onMessageChange = (e) => {
        const message = e.target.value;
        this.setState(() => ({ message }));
    };

    render() {
        return (

            <div>
                <form onSubmit={this.onMessageSend}>
                    <div className="input-group m-3 pt-4">
                        <input
                            placeholder="Type your message..."
                            className="form-control font-weight-light form-control-lg"
                            type="text"
                            id="message"
                            value={this.state.message}
                            onChange={this.onMessageChange}/>
                        <div className="input-group-append">
                            <button className="btn btn-chat">
                                <img src="/images/send.png" className="img img-fluid" style={{maxBlockSize: 20}}/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Form;