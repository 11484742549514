const profileReducerDefaultState = {};

export default (state = profileReducerDefaultState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return action.profile
        case 'SET_ERROR_MESSAGE':
            return action.error
        case 'ADD_PROFILE':
            return [
                ...state,
                action.profile
            ];
        case 'EDIT_PROFILE':
                return {
                    0: {
                        ...action.updates
                        },
                    };
        case 'LOGOUT':
            return {};
        default:
            return state;
    }
};