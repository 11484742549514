import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {animateScroll as scroll} from 'react-scroll';
import SweetAlert from 'react-bootstrap-sweetalert';
import {firebase} from '../../firebase/firebase';
import LoginForm from './LoginForm';
import {startLogin} from '../../actions/profile';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * Page for user login
 * 
 * www.itorho.com/:type/login
 * 
 */
export class LoginPage extends React.Component {

    state = {
        type: this.props.match.params.type,
        showForgot: false,
        showConfirm: false,
        email: '',
        emailError: ''
    }

    /*
     * scroll to top of page on mount 
     */
    componentDidMount = () => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        initGA();
        logPageView();
    }

    /**
     * updates state on email input
     */
    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ 
            email,
            emailError: '' 
        }))
    }

    /**
     * method calls action to log user in
     */
    onSubmit = (profile) => {
        this.props.startLogin({
            type: this.state.type,
            ...profile
        });
    }

    /**
     * method checks for valid email and updates state to show email sent pop up
     */
    onRecover = () => {

        // error checks
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(this.state.email)) {this.setState(() => ({ emailError: 'Please enter a valid email address.'})) }
        else { this.setState(() => ({ emailError: '' })) }

        if (regex.test(this.state.email) && this.state.email) {
            this.setState(() => ({ 
                showForgot: false,
                showConfirm: true 
            }))
            
            this.emailResetPassword();
        }
    }

    /**
     * sends an email reset request to change password
     */
    emailResetPassword() {
        var auth = firebase.auth() ;
        var emailAddress = this.state.email;

        auth.sendPasswordResetEmail(emailAddress).then(function() {
            // Email sent
            console.log("Email Sent.");
            alert("Email sent.")
        }).catch(function(error) {
            // An error occurred
            console.log("Email not sent.");
        });
    }

    /**
     * updates state to show forgot password pop up
     */
    onForgot = () => {
        this.setState(() => ({ showForgot: true }));
    }

    /**
     * method to close any pop ups
     */
    onCancel = () => {
        this.setState(() => ({ 
            showForgot: false,
            showConfirm: false 
        }))
    }

    render() {
        return (
            
            <div className="bg-blue-login">
                <div>
                    <div className="bg-white p-3"  style={{maxWidth: 350}}>
                        
                        <div className="row d-flex justify-content-center mt-5">
                            <img src="/images/itorho-logo-only.png" style={{maxWidth:60, maxHeight:60}}/>
                        </div>

                        {/* forgot password popup */}
                        <div className="mt-5">
                            <SweetAlert
                                show={this.state.showForgot}
                                confirmBtnText="Cancel"
                                confirmBtnBsStyle="link"
                                showCancelBtn={false}
                                focusConfirmBtn={false}
                                title="Password Recovery"
                                onCancel={this.onCancel}
                                onConfirm={this.onCancel}>
                                <div className="d-flex justify-content-center">
                                    <div className="col-7">
                                        <h4 className="font-weight-light mt-5">Please enter your email address, and a recovery email will be sent to you.</h4>
                                        <input
                                            className="form-control form-control-lg mt-4"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onEmailChange}
                                            placeholder="Email"/>
                                            <small className="font-weight-light text-left" style={{color: '#ec9422'}}>{this.state.emailError}</small>
                                        <button className="btn btn-block mt-4" onClick={this.onRecover}><h4 className="font-weight-light">Submit</h4></button>
                                    </div>
                                </div>
                            </SweetAlert>
                        </div>

                        {/* confirm email sent popup */}
                        <div className="mt-5">
                            <SweetAlert
                                show={this.state.showConfirm}
                                confirmBtnText="Exit"
                                confirmBtnBsStyle="link"
                                showCancelBtn={false}
                                focusConfirmBtn={false}
                                title="Check Your Inbox"
                                onCancel={this.onCancel}
                                onConfirm={this.onCancel}>
                                <div className="d-flex justify-content-center">
                                    <div className="col-6">
                                        <h4 className="font-weight-light mt-5">An email has been sent to you to reset your password.</h4>
                                        <h5 className="font-weight-light mt-3">If you cannot find it in your inbox, check your spam folder.</h5>
                                    </div>
                                </div>
                            </SweetAlert>
                        </div>

                        <div hidden={!(this.state.type == "seller")}>
                            <h3 className="text-center font-weight-light mt-3">Seller Login</h3>
                        </div>

                        <div hidden={(this.state.type == "seller")}>
                            <h3 className="text-center font-weight-light mt-3">Buyer Login</h3>
                        </div>

                        <div className="m-5">
                            <LoginForm
                                errorMessage={this.props.error}
                                onSubmit={this.onSubmit}/>
                        </div>

                    </div>

                    <div className="d-flex justify-content-center mt-4 p-0">
                        <div className="link" onClick={this.onForgot}>
                            <h5 className="text-center font-weight-light p-0 terms-link">Forgot Password?</h5>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mb-4 mt-2 p-0">
                        <Link className="link" to={`/${this.state.type}/register`}>
                            <h6 className="text-center font-weight-light p-0 terms-link">Don't have an account? Register now.</h6>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    startLogin: (profile) => dispatch(startLogin(profile))
});

const mapStateToProps = (state) => ({
    error: state.profile.error ? state.profile.error : ''
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);