import React from 'react';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import {isDayAvailable, getTimes, getEarliestDate} from '../../utils/bookings';

/**
 * form component for making a booking
 */
export class BookingForm extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {

            bookings: props.bookings ? props.bookings : [],
            availability: props.availability,
            serviceItems: props.service ? props.service.serviceItems : {},
            showCost: props.cost ? true : false,
            cost: props.cost ? props.cost : undefined,
            costType: '',
            percent: undefined,

            name: props.booking ? props.booking.name : '',
            notes: props.booking ? props.booking.notes : '',
            item: props.booking ? props.booking.item : '',
            date: props.booking ? moment(props.booking.date) : getEarliestDate(props.availability),
            dateSelected: false,
            time: props.booking ? props.booking.time : '',
            duration: props.booking ? props.booking.duration : undefined,
            
            showDuration: false,
            showDurationPicker: false,
            customDuration: false,
            durations: ['N/A','15 minutes','30 minutes','45 minutes','60 minutes','90 minutes','120 minutes','360 minutes'],

            enableDatePicker: false,
            enableTimePicker: false,

            itemError: '',
            nameError: '',
            formError: '',
            dateError: '',
            timeError: ''

        }
    }

    /**
     * method updates state upone receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.bookings != this.state.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }))
        }
        if (newProps.cost != this.state.cost) {
            this.setState(() => ({ 
                cost: newProps.cost,
                showCost: true 
            }))
        }
    }

    /**
     * methods to update state upon form inputs
     */
    onNameChange = (e) => {
        const name = e.target.value;
        this.setState(() => ({ 
            name,
            nameError: '' 
        }))
    }
    onNotesChange = (e) => {
        const notes = e.target.value;
        this.setState(() => ({ notes }));
    }
    onItemChange = (e) => {
        const item = e.target.value;

        // get and display item cost
        Object.values(this.state.serviceItems).map((i) => {
            if (i.title === item) {
                if (this.state.cost === undefined) {
                    this.setState(() => ({
                        cost: i.cost,
                        costType: i.costType,
                        showCost: true
                    }))
                }
                if (i.duration && i.duration != 'N/A' && i.duration != 'Client must specify') {
                    this.setState(() => ({ 
                        duration: i.duration,
                        showDuration: true,
                        dateError: '',
                        enableDatePicker: true
                    }))
                } else if (i.duration === 'Client must specify') {
                    this.setState(() => ({ showDurationPicker: true }))
                } else if (i.duration === 'N/A') {
                    this.setState(() => ({ 
                        duration: i.duration,
                        dateError: '',
                        enableDatePicker: true
                    }))
                }
                if (i.percent) {
                    this.setState(() => ({ percent: i.percent }))
                }
            }
        })

        // update state
        this.setState(() => ({ 
            item, 
            itemError: '' 
        }))
    }
    onDurationChange = (e) => {
        const duration = e.target.value;
        if (duration == "Custom") {
            this.setState(() => ({ 
                customDuration: true,
                duration 
            }));
        } else {
            this.setState(() => ({ 
                duration,
                customDuration: false,
                dateError: '',
                enableDatePicker: true
            }));
        }
        this.setState(() => ({ durationError: '' }))
    }
    onCustomDurationChange = (e) => {
        const duration = e.target.value;
        this.setState(() => ({ 
            duration: duration + ' minutes',
            durationError: '',
            dateError: '',
            enableDatePicker: true
        }))
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }));
    }
    onDateChange = (date) => {
        if (date) { 
            this.setState(() => ({ 
                date,
                dateSelected: true,
                enableTimePicker: true,
                dateError: '',
                timeError: ''
            })) 
        }
    }
    onTimeChange = (e) => {
        let times = getTimes(this.state.date, this.state.availability, this.state.duration)
        if (times.length === 0) {
            this.setState(() => ({ timeError: 'Sorry, there are no available times for this date.' }))
        } else {
            let time = e.target.value;
            this.setState(() => ({ 
                time,
                timeError: ''
            }))
        }
    }

    /**
     * method returns boolean for if day is blocked 
     */
    isDayBlocked = (day) => {
        return isDayAvailable(day, this.state.availability)
    }

    /**
     * submit form method
     * performs error checks and calls onSubmit method in AddBooking
     */
    onSubmit = (e) => {
        e.preventDefault();

        let durationRegex = /^$|^([0-9]* (minutes))|(infinite)$/;

        // error checks
        if (!this.state.name) { this.setState(() => ({ nameError: 'Please provide a name.'})) }
        else { this.setState(() => ({ nameError: '' })) }
        if (!this.state.item) { this.setState(() => ({ itemError: 'Please select a service.'})) }
        else { this.setState(() => ({ itemError: '' })) }
        if (!this.state.time) { this.setState(() => ({ timeError: 'Please select a time.'})) }
        else { this.setState(() => ({ timeError: '' })) }
        if (!this.state.duration) { this.setState(() => ({ durationError: 'Please provide a valid duration.'})) }
        else { this.setState(() => ({ durationError: '' })) }
        if (this.state.customDuration) { if (!durationRegex.test(this.state.duration)) { this.setState(() => ({ durationError: 'Provide a valid duration in minutes.'})) }
        else { this.setState(() => ({ durationError: '' })) }}
        if (!this.state.dateSelected) { this.setState(() => ({ dateError: 'Please select a date.'})) }
        else { this.setState(() => ({ dateError: '' })) }

        if (this.state.name && this.state.item && this.state.time && this.state.dateSelected && this.state.duration) {

            // calculate the total cost if costType is hourly
            let cost = this.state.cost
            let deposit
            if (this.state.costType === 'hourly' && this.state.duration != 'N/A') {
                let dur = parseInt(this.state.duration.substring(0, this.state.duration.indexOf(' ')))
                let i = 1
                while(dur > 60) {
                    i += 1
                    dur -= 60
                    if (dur <= 30) {
                        i -= 0.5
                    }
                }
                cost = (parseInt(this.state.cost)*i).toString()
            }

            // calculate deposit if relevant
            if (this.state.percent != undefined) {
                deposit = cost*parseInt(this.state.percent)/100
            }

            // submit
            let booking = {
                date: this.state.date.toString().substring(4,16) + this.state.time.substring(0,6),
                time: this.state.time,
                duration: this.state.duration,
                title: this.state.name,
                item: this.state.item,
                notes: this.state.notes,
                cost
            }
            if (this.state.percent != undefined) {
                booking = {
                    ...booking,
                    deposit
                }
            }
            this.props.onSubmit(booking);
        } else {
            this.setState(() => ({ formError: 'Please fill out all required fields.' }))
        }
    }

    render() {
        return (

            <div className="d-flex justify-content-center text-left mt-5">
                <div className="col-8">
                    <form onSubmit={this.onSubmit}>
                    
                    <div className="form-group pb-4">
                        <hr/>
                        <label htmlFor="name">Name and Surname</label>
                        <input
                            placeholder=""
                            className="form-control font-weight-light rounded-0 form-control-lg"
                            type="text"
                            id="name"
                            value={this.state.name}
                            onChange={this.onNameChange}/>
                        <small className="form-text" style={{color: '#ec9422'}}>{this.state.nameError}</small>                        
                    </div>

                    {this.props.service.serviceItems ? (
                        <div className="form-group pb-4">
                            <hr/>
                            <label htmlFor="item">Select Service</label>
                            <select 
                                id="item" 
                                style={{color: 'black'}}
                                className="custom-select font-weight-light rounded-0 custom-select-lg"
                                defaultValue={this.state.item}
                                onChange={this.onItemChange}>
                                <option></option>
                                {Object.values(this.props.service.serviceItems).map((item, index) => (
                                    <option key={index} value={item.title} label={item.title}>{item.title}</option>
                                ))}
                            </select>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.itemError}</small>                        
                        
                            <div className="mt-3" hidden={!this.state.showDuration} style={{border: '1px solid gainsboro'}}>
                                <h5 className="font-weight-light pl-3 pt-3 pb-1" style={{color: 'black'}}>{this.state.duration === '1 hours' ? '1 hour' : this.state.duration}</h5>
                            </div>
                            <div className="mt-3" hidden={!this.state.showCost} style={{border: '1px solid gainsboro'}}>
                                <h5 className="font-weight-light pl-3 pt-3 pb-1" style={{color: 'black'}}>{this.state.costType === 'negotiable' ? `R${this.state.cost} Negotiable` : this.state.costType === 'from' ? `From R${this.state.cost}` : `R${this.state.cost}`}{this.state.costType === 'hourly' ? ' / hr' : this.state.costType === 'daily' ? ' / day' : ''}</h5>
                            </div>
                            <div className="mt-3" hidden={!this.state.showCost || this.state.percent === undefined} style={{border: '1px solid gainsboro'}}>
                                <h5 className="font-weight-light pl-3 pt-3 pb-1" style={{color: 'black'}}>Deposit: {this.state.percent ? `R${parseInt(this.state.cost)*parseInt(this.state.percent)/100}` : ''}</h5>
                            </div>
                        
                        </div>
                    ) : (
                        <div>
                        </div>
                    )}

                    {this.state.showDurationPicker ? 
                        <div className="pb-4">
                            <hr/>
                            <label>Duration</label>
                            <select 
                                className="custom-select font-weight-light rounded-0 custom-select-lg"
                                value={this.state.duration}
                                onChange={this.onDurationChange}>
                                <option>{this.state.customDuration ? 'Custom' : this.state.duration}</option>
                                <option>Custom</option>
                                {this.state.durations.map((duration) => {
                                    return (<option key={duration}>{duration}</option>)
                                })}
                            </select>
                            
                            <div className="pt-2" hidden={!this.state.customDuration}>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Minutes"
                                    type="number"
                                    value={this.state.customDurationVal}
                                    onChange={this.onCustomDurationChange}/>
                                <small className="form-text text-muted">Please specify the number of minutes</small>     
                            </div>                           
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.durationError}</small>
                        </div>
                    :
                        <div></div>
                    }

                    <div className="pb-4">
                        <hr/>
                        <label htmlFor="date">Select Date</label>
                        <div style={{border: '1px solid gainsboro'}}>
                            <SingleDatePicker
                                id="date"
                                date={this.state.date}
                                focused={this.state.calendarFocused}
                                onDateChange={this.onDateChange}
                                onFocusChange={this.onFocusChange}
                                numberOfMonths={1}
                                isDayBlocked={this.isDayBlocked}
                                disabled={!this.state.enableDatePicker}
                            />
                        </div>
                        {this.state.dateError && <small className="form-text" style={{color: '#ec9422'}}>{this.state.dateError}</small>}
                    </div> 

                    <div>
                        <div className="pb-4">
                            <hr/>
                            <label htmlFor="time">Select Time</label>
                            <select 
                                id="time" 
                                style={{color: 'black'}}
                                className="custom-select font-weight-light rounded-0 custom-select-lg"
                                value={this.state.time}
                                disabled={!this.state.enableTimePicker}
                                onChange={this.onTimeChange}>
                                <option></option>
                                {getTimes(this.state.date, this.state.availability, this.state.duration, this.state.bookings).map((t, index) => (
                                            <option key={index}>{t}</option>
                                ))}
                            </select>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.timeError}</small>
                        </div>
                    </div>

                    <div className="pb-4">
                        <hr/>
                        <label htmlFor="notes">Extra Notes or Comments</label>
                        <textarea
                            className="form-control rounded-0 form-control-lg"
                            style={{height: 70}}
                            placeholder={''}
                            type="text"
                            id="notes"
                            value={this.state.notes}
                            onChange={this.onNotesChange}/>
                    </div>

                    <h4 className="mb-3 font-weight-light" style={{color: '#ec9422'}}>{this.state.formError}</h4>
                    <div className="d-flex justify-content-center">
                        <button className="rounded-0 btn btn-main btn-block" style={{height: 30}}>
                            <h4 className="font-weight-light">Book Now</h4>
                        </button>
                    </div>

                </form>

                </div>
            </div>
        );
    };

}

export default BookingForm;