import React from 'react';
import {connect} from 'react-redux';
import {setCategoryFilter, setTextFilter} from '../../actions/filters';


/**
 * service list filtering component rendered by the category page
 * this component contains a search bar that updates the redux store filters
 */
export class ServiceListFilters extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text ? this.props.text : ''
        }
    }

    /**
     * set the category filter to the selected category (passed down by the category page through the url)
     * in the redux store by calling the action upon mounting
     */
    componentDidMount = () => {
        this.props.setCategoryFilter(this.props.props);
    }

    /**
     * updates the state and redux store filter by calling the action
     * based on search text input
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ text }));
        this.props.setTextFilter(text);
    }

    /**
     * calls the onBack method in CategoryPage
     */
    onBack = () => {
        this.props.onBack();
    }

    render() {
        return (

            <div className="d-flex justify-content-center mt-5 pt-5 mb-5">
                <div className="col">
                    <div onClick={this.onBack} className="link float-left ml-3">
                        <img src="/images/back.png" className="img" style={{maxWidth:20, maxHeight:20}}/>
                    </div>
                    <h3 className="display-5 mb-5 mr-3 text-center">{this.props.title}</h3>
                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-sm-8 m-0">
                            <input
                                className="form-control form-control-lg rounded-0 font-weight-light text-center"
                                type="text"
                                placeholder={`Search ${this.props.title}`}
                                value={this.state.text}
                                onChange={this.onTextChange}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    text: state.filters.text,
    category: state.filters.category
});

const mapDispatchToProps = (dispatch) => ({
    setTextFilter: (text) => dispatch(setTextFilter(text)),
    setCategoryFilter: (category) => dispatch(setCategoryFilter(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceListFilters);