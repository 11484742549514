const eventsReducerDefaultState = [];

export default (state = eventsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_EVENTS':
            return action.events;
        case 'ADD_EVENT':
            return [
                ...state,
                action.event
            ];
        case 'EDIT_EVENT':   
            return state.map((event) => {
                if (event.id === action.eventID) {
                    return {
                        ...event,
                        ...action.updates
                    };
                } else {
                    return event;
                }
            });
        case 'REMOVE_EVENT':
            return state.filter(({ id }) => action.eventID !== id);
        default:
            return state;
    }
};