import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

/**
 * form for registration of new user
 * used by the register page
 */
export class RegisterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            number: '',
            password: '',
            terms: false,
            nameError: '',
            emailError: '',
            numberError: '',
            passwordError: 'Your password must be 8-20 characters long.',
            errorMessage: this.props.errorMessage ? this.props.errorMessage : '',
            type: this.props.type,
            uid: '',
            loading: false,
            showTerms: false,
            date: moment().toString()
        }
    }

    /**
     * method for updating state when receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.errorMessage != newProps.errorMessage) {
            this.setState(() => ({ errorMessage: newProps.errorMessage }))
        }
    }

    /**
     * method for updating state to google login type
     */
    onGoogle = () => {
        this.setState(() => ({ loginType: 'Google' }))
    }

    /**
     * methods for updating state based on form input
     */
    onNameChange = (e) => {
        const name = e.target.value;
        this.setState(() => ({ name }));
    }
    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
        this.props.setErrorMessage({ error: '' })
    }
    onNumberChange = (e) => {
        const number = e.target.value;
        this.setState(() => ({ number }));
    }
    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({ password }));
        if (password.length < 8) { 
            this.setState(() => ({ passwordError: 'Password must be more than 8 characters.' }))
        }
        else { this.setState(() => ({ passwordError: '' })) }
    }

    /**
     * methods for showing/closing terms and conditions pop up
     */
    onTerms = (e) => {
        this.setState(() => ({ showTerms: true }))
    }
    offTerms = (e) => {
        this.setState(() => ({ showTerms: false }))
    }
    onAgree = (e) => {
        this.setState(() => ({ 
            terms: true,
            showTerms: false 
        }))
    }

    /**
     * method calls onSubmit in register page
     * passes in loginType or login details
     */
    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.loginType == "Google") {
            this.props.onSubmit({
                loginType: this.state.loginType,
                type: this.state.type
            });
        } else {

            // error checks
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            if(!this.state.name) {this.setState(() => ({nameError: 'Please provide a name.'}))}
            else { this.setState(() => ({ nameError: '' })) }
            if(!regex.test(this.state.email)) {this.setState(() => ({ emailError: 'Please enter a valid email address.'})) }
            else { this.setState(() => ({ emailError: '' })) }
            if(!this.state.number) {this.setState(() => ({numberError: 'Please provide a number.'}))}
            else { this.setState(() => ({ numberError: '' })) }

            if(this.state.name && regex.test(this.state.email) && this.state.number && this.state.passwordError=='') {
                this.setState(() => ({ loading: true }))
                this.props.onSubmit({
                    name: this.state.name,
                    email: this.state.email,
                    number: this.state.number,
                    type: this.state.type,
                    password: this.state.password,
                    firstSignIn: true,
                    date: this.state.date
                });
            }


        }
    }

    render() {
        return (

            <div className="d-flex justify-content-center ml-5 mr-5">
                <div className="text-left">

                    {/* terms and conditions pop up */}
                    <div>
                        <SweetAlert
                            show={this.state.showTerms}
                            overflow="scroll"
                            confirmBtnBsStyle="link"
                            confirmBtnText="Agree"
                            title="Terms & Conditions"
                            onConfirm={this.onAgree}
                            onCancel={this.offTerms}>
                            <div>
                                <ul>
                                    <li><a href="/files/Payment Policy - iTorho ltd.pdf">Payment Policy</a></li>
                                    <li><a href="/files/Software_as_a_Service_Agreement - iTorho ltd.pdf"> Software as a Service Agreement</a></li>
                                    <li><a href="/files/Website_Terms_of_Use - iTorho ltd.pdf">Terms of Use</a></li>
                                </ul>
                            </div>
                        </SweetAlert>
                    </div>

                    <div>
                        <form onSubmit={this.onSubmit}>

                            <div className="form-group">
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.onNameChange}
                                    placeholder="Name"/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.nameError}</small>
                            </div>

                            <div className="form-group">
                                <input
                                    className="form-control form-control-lg"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.onEmailChange}
                                    placeholder="Email"/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.emailError}</small>
                            </div>

                            <div className="form-group">
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    value={this.state.number}
                                    onChange={this.onNumberChange}
                                    placeholder="Mobile Number"/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.numberError}</small>
                            </div>

                            <div className="form-group">
                                <input
                                    className="form-control form-control-lg"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.onPasswordChange}
                                    placeholder="Password"/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.passwordError}</small>
                            </div>

                            {this.state.errorMessage && <h5 className="font-weight-light mt-4" style={{color: '#ec9422'}}>{this.state.errorMessage}.</h5>}

                            <div hidden={!this.props.superuser} className="form-group pb-0" onClick={this.onSubmit}>
                                <button className="btn btn-lg mt-3" style={{width: 200}}>Create Profile</button>
                            </div>

                            <div hidden={this.props.superuser} className="form-group pb-0" onClick={this.onSubmit}>
                                <button className="btn btn-lg mt-3" style={{width: 200}}>Register</button>
                            </div>

                            <div hidden={this.props.superuser} className="ml-1 mb-2" style={{color: 'grey'}}>By registering you are agreeing to Itorho's <span className="terms-link" onClick={this.onTerms}>Terms and Conditions</span></div>

                            <div hidden={this.props.superuser}>
                                <button className="mt-4 social-btn" onClick={this.onGoogle}>
                                    <img src="/images/google.JPG" onMouseOver={e => (e.currentTarget.src = "/images/google2.JPG")} onMouseOut={e => (e.currentTarget.src = "/images/google.JPG")} className="link" style={{width: 200}}/>
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        );
    };
}

export default RegisterForm;

