import database from '../firebase/firebase';

// SET_EVENTS

/** set events in redux store */
export const setEvents = (events) => ({
    type: 'SET_EVENTS',
    events
  });

  /** set all events in redux store */
export const setAllEvents = (events) => ({
    type: 'SET_ALL_EVENTS',
    events
  });

/** get eventss relevant to logged in user from database */
export const startSetEvents = (uid) => {
    return (dispatch) => {
        return database.ref(`events/`)
            .once('value')
            .then((snapshot) => {
                const events = [];
  
                snapshot.forEach((childSnapshot) => {
                    if (childSnapshot.val().buyerID === uid) {
                        const value = childSnapshot.val();
                        events.push({
                            id: childSnapshot.key,
                            ...value
                        });
                    }
                });
            
            dispatch(setEvents(events));
        });
    };
};

/** get all events from database */
export const startSetAllEvents = () => {
    return (dispatch) => {
        return database.ref(`events/`)
            .once('value')
            .then((snapshot) => {
                const events = [];
                snapshot.forEach((childSnapshot) => {
                        const value = childSnapshot.val();
                        events.push({
                            id: childSnapshot.key,
                            ...value
                        });
                });
            
            dispatch(setAllEvents(events));
        });
    };
};


// ADD_EVENT

/** add event to redux store */
export const addEvent = (event) => ({
    type: 'ADD_EVENT',
    event
});

/** add new event to database */
export const startAddEvent = (event) => {
    return (dispatch) => {
        return database.ref(`events/${event.eventID}`)
            .set(event)
            .then(() => {
            dispatch(addEvent({
                id: event.eventID,
                ...event
            }));
        });
    };
};

// EDIT_EVENT

/** edit event in redux store */
export const editEvent = (eventID, updates) => ({
    type: 'EDIT_EVENT',
    eventID,
    updates
});

/** edit event in redux store */
export const editAllEvents = (eventID, updates) => ({
    type: 'EDIT_ALL_EVENTS',
    eventID,
    updates
});

/** edit event in database */
export const startEditEvent = (eventID, updates) => {
    return (dispatch) => {
        return database.ref(`events/${eventID}`)
            .update(updates)
            .then(() => {
                dispatch(editEvent(eventID, updates));
            });
    };
};

/** edit event in database */
export const startEditAllEvents = (eventID, updates) => {
    return (dispatch) => {
        return database.ref(`events/${eventID}`)
            .update(updates)
            .then(() => {
                dispatch(editAllEvents(eventID, updates));
            });
    };
};

// REMOVE_EVENT

/** remove event from redux store */
export const removeEvent = ({ eventID }) => ({
    type: 'REMOVE_EVENT',
    eventID
});

/** remove event from redux store */
export const removeAllEvents = ({ eventID }) => ({
    type: 'REMOVE_ALL_EVENTS',
    eventID
});

/** remove event from database */
export const startRemoveEvent = (eventID) => {
    return (dispatch) => {
        return database.ref(`events/${eventID}`)
            .remove()
            .then(() => {
                dispatch(removeEvent({ eventID }));
        })
    }
}

/** remove event from database */
export const startRemoveAllEvents = (eventID) => {
    return (dispatch) => {
        return database.ref(`events/${eventID}`)
            .remove()
            .then(() => {
                dispatch(removeAllEvents({ eventID }));
        })
    }
}
