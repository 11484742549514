import React from  'react';
import EventListItem from './EventListItem';
import {sortEvents} from '../../selectors/events';

/**
 * component to display the events list 
 * rendered by the EventsPage
 */
export class EventList extends React.Component {

    state = {
        events: this.props.events ? this.props.events : [],
        seller: this.props.seller,
        serviceID: this.props.serviceID
    }

    /**
     * updates state upon new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.events != this.state.events) {
            this.setState(() => ({ events: newProps.events }))
        }
        if (newProps.seller != this.state.seller) {
            this.setState(() => ({ seller: newProps.seller }))
        }
        if (newProps.serviceID != this.state.serviceID) {
            this.setState(() => ({ serviceID: newProps.serviceID }))
        }
    }

    /**
     * calls onView method in EventsPage
     */
    onView = (event) => {
        this.props.onView(event)
    }

    render() {
        return (

            <div className="d-flex justify-content-center mt-5 pb-5 mb-5">
                <div className="col-12">
                    {sortEvents(this.state.events).length != 0 ?
                        <div>
                            {sortEvents(this.state.events).map((event,index) => (
                                <div key={index}>
                                    <EventListItem 
                                        seller={this.state.seller}
                                        serviceID={this.state.serviceID}
                                        onView={this.onView}
                                        event={event} />
                                </div>
                            ))}
                        </div>
                    :
                        <div className="pb-5 mb-5 pt-5 mt-5">
                            <h3 className="font-weight-light text-center pt-5 pb-5 mb-5" style={{color: 'gray'}}>No Events to Display</h3>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default EventList;