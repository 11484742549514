import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import TopNav from './TopNav';
import ServiceListItem from './Services/ServiceListItem';
import Footer from './Footer';
import {setCategoryFilter,setLocationFilter, setTextFilter, setNearFilter} from '../actions/filters';
import {selectServices} from '../selectors/services';

/**
 * Page for universal service search
 * navigated to from the entry page when user enters a search term
 * 
 * www.itorho.com/search
 * 
 */
export class SearchPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.filters.text ? this.props.filters.text : '',
            services: this.props.services ? this.props.services : []
        }
    }

    /**
     * sets filters when component mounts
     */
    componentDidMount = () => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        this.props.setCategoryFilter('')
        this.props.setLocationFilter('')
        this.props.setNearFilter(false)
    }

    /**
     * method to set events for service upon click
     */
    onService = (id) => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
    }

    /**
     * updates state upon text input in search bar
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.props.setTextFilter(text)
        this.setState(() => ({ text }));
    }
    
    render() {
        return (

            <div>
                <TopNav loggedIn={false} type={'buyer'}/>
                
                {/* search heading */}
                <div className="mt-5 pt-5">
                    <h4 className="text-center font-weight-light display-4 ml-5 mb-4 pb-4">Find a Service</h4>
                </div>
                
                {/* search bar */}
                <div className="d-flex justify-content-center mb-4">
                    <div className="col-12 col-sm-8">
                        <input
                            style={{height: '50px'}}
                            className="form-control form-control-lg rounded-0 font-weight-light text-center"
                            type="text"
                            placeholder={this.state.text}
                            value={this.state.text}
                            onChange={this.onTextChange}/>
                    </div>
                    <div className="input-group-append">
                        <div className="search-link" onClick={this.onSearch}>
                            <img src="/images/search.png" className="img img-fluid" style={{maxBlockSize: 45}}/>
                        </div>
                    </div>
                </div>

                {/* search results */}
                <div className="row d-flex justify-content-center col-12 mb-5 pb-5">
                    <div className="" style={{maxWidth: 900}}>
                        <div hidden={!(this.props.services === undefined)} className="container h-100">
                            <div className="row h-100 justify-content-center align-items-center">
                                <img src='/images/loader.gif' />
                            </div>
                        </div>                          
                        <div hidden={(this.props.services === undefined) && !(this.props.services.length === 0)} className="d-flex justify-content-center mt-5">
                            <h3 className="text-center display-5 pb-sm-3 pb-1 pt-5 pt-sm-3 title font-weight-light col-8" style={{color: 'grey'}}>There are currently no services matching your search.</h3>
                        </div>
                        <div hidden={(this.props.services === undefined) || (this.props.services.length === 0)}>
                            {this.props.services.map((service, index) => (
                                <NavLink className="m-2 service-link pointer" key={index} to={`/service/${service.name.toLowerCase().replace(/ /g,'')}`} onClick={() => this.onService(service.id)}>
                                    <ServiceListItem key={service.id} {...service} near={this.props.near}/>
                                </NavLink>
                            ))}
                        </div>                   
                    </div>
                </div>

                <div className="footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    services: selectServices(state.services, state.filters),
    filters: state.filters
});

const mapDispatchToProps = (dispatch) => ({
    setTextFilter: (text) => dispatch(setTextFilter(text)),
    setNearFilter: (near) => dispatch(setNearFilter(near)),
    setCategoryFilter: (category) => dispatch(setCategoryFilter(category)),
    setLocationFilter: (location) => dispatch(setLocationFilter(location))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);