import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import {setErrorMessage} from '../actions/profile';

/**
 * Sign up page for both buyers and sellers
 * provides the option to register or login and to view the terms and conditions
 * 
 * NOTE:
 * terms and conditions are currently just filled with arbitrary text - this needs to be updated
 * 
 *  www.itorho.com/:type/signup
 * 
 */
export class SignUpPage extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            showTerms: false,
            info: this.props.match.params.type == "buyer" ? "Conveniently find, book and pay for services via iTorho, the premier online marketplace." : "Conveniently transact with digital customers through faster discoverability, simpler client access and efficient online booking management for a seamless payment experience."
        }
    }

    /* 
     * resets error message to null upon mounting
     */
    componentDidMount = () => {
        if (this.props.error) {
            this.props.setErrorMessage({ error: "" });
        }
    }

    /**
     * methods to handle showing terms pop up
     */
    onTerms = () => {
        this.setState(() => ({ showTerms: true }))
    }

    /**
     * methods to handle closing terms pop up
     */
    offTerms = () => {
        this.setState(() => ({ showTerms: false }))
    }

    render() {
        return (
  
            <div className="bg-blue-signup">
                <div className="container d-flex justify-content-center">

                    <div>
                        <SweetAlert
                            show={this.state.showTerms}
                            overflow="scroll"
                            confirmBtnBsStyle="link"
                            confirmBtnText="Close"
                            title="Terms & Conditions"
                            heightAuto={false}
                            onConfirm={this.offTerms}>
                            <div>
                                <ul>
                                    <li><a href="/files/Payment Policy - iTorho ltd.pdf">Payment Policy</a></li>
                                    <li><a href="/files/Software_as_a_Service_Agreement - iTorho ltd.pdf"> Software as a Service Agreement</a></li>
                                    <li><a href="/files/Website_Terms_of_Use - iTorho ltd.pdf">Terms of Use</a></li>
                                </ul>
                            </div>
                        </SweetAlert>
                    </div>

                    <div>
                        <div className="d-flex justify-content-center mb-4 p-0">
                            <img src="/images/itorho-logo-transparent.png" className="img" style={{maxWidth:120, maxHeight:150}}/>
                        </div>
                        <div className="d-flex justify-content-center mb-4 p-0">
                            <h5 className="text-center font-weight-light mt-4 mb-5 p-0" style={{maxWidth: 200, color: "white"}}>{this.state.info}</h5>
                        </div>
                        <NavLink className="d-flex justify-content-center mb-4 p-0 link" to={`/${this.props.match.params.type}/login`}>
                            <button className="btn btn-white btn-lg" style={{width: 200}}>Login</button>
                        </NavLink>
                        <NavLink className="d-flex justify-content-center mb-4 p-0 link" to={`/${this.props.match.params.type}/register`}>
                            <button className="btn btn-white btn-lg" style={{width: 200}}>Register</button>
                        </NavLink>
                        <div className="d-flex justify-content-center mb-4 p-0">
                            <div className="terms-link2" onClick={this.onTerms}>
                                <h6 className="text-center font-weight-light m-4 p-0">Terms & Conditions</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setErrorMessage: (error) => dispatch(setErrorMessage(error))
});

const mapStateToProps = (state) => ({
    error: state.profile.error ? true : false
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);