import React from 'react';
import {connect} from 'react-redux';
import uuid from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import {SingleDatePicker} from 'react-dates';
import {isDayAvailable, getTimes, getEarliestDate} from '../../utils/bookings';
import {selectMessages} from '../../selectors/messages';
import {startAddMessageBuyer, startAddMessageSeller, startEditMessage} from '../../actions/messages';
import {sendQuoteNotificationEmail} from '../../utils/emails';

/**
 * form component for sending new quotes for buyers
 * 
 * rendered by the ServiceProfile component
 */
export class QuoteForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            services: this.props.services ? this.props.services : [],
            onServiceClick: false,
            show: false,
            showSent: false,
            message: '',
            allMessages: this.props.allMessages ? this.props.allMessages : [],
            messages: [],
            buyerName: this.props.userName ? this.props.userName : '',
            seller: this.props.seller ? this.props.seller : undefined,

            bookings: this.props.bookings ? this.props.bookings : [],
            availability: this.props.availability,
            serviceItems: this.props.service ? this.props.service.serviceItems : {},
            item: '',
            date: getEarliestDate(this.props.availability),
            time: '',
            duration: '',
            showDuration: false,
            showDurationPicker: false,
            customDuration: false,
            durations: ['N/A','15 minutes','30 minutes','45 minutes','60 minutes','90 minutes','120 minutes','360 minutes'],

            enableDatePicker: false,
            enableTimePicker: false,

            times: []
        }
    }
    
    /**
     * updates state upon mounting
     */
    componentDidMount = () => {
        const messages = selectMessages(this.props.toUserID, this.props.userID, this.state.allMessages);
        this.setState(() => ({ messages }));
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (newProps.onServiceClick !== this.state.onServiceClick) {
            this.setState(() => ({ 
                    onServiceClick: newProps.onServiceClick,
                    toUserName: newProps.toUserName,
                    toUserID: newProps.toUserID,
                    services: newProps.services 
                }))
            if (newProps.onServiceClick == true) { 
                this.setState(() => ({ show: true }))
            }
        }

        if (newProps.seller != this.state.seller) {
            this.setState(() => ({ seller: newProps.seller }))
        }
    }

    /**
     * methods to update state upon form inputs
     */
    onItemChange = (e) => {
        const item = e.target.value;
        this.setState(() => ({ item }))

        // get and display item cost
        Object.values(this.state.serviceItems).map((i) => {
            if (i.title === item) {
                this.setState(() => ({
                    cost: i.cost,
                    costType: i.costType
                }))
                if (i.duration && i.duration != 'N/A' && i.duration != 'Client must specify') {
                    this.setState(() => ({ 
                        duration: i.duration,
                        showDuration: true,
                        dateError: '',
                        enableDatePicker: true
                    }))
                } else if (i.duration === 'Client must specify') {
                    this.setState(() => ({ showDurationPicker: true }))
                } else if (i.duration === 'N/A') {
                    this.setState(() => ({ 
                        duration: i.duration,
                        dateError: '',
                        enableDatePicker: true
                    }))
                }
                if (i.percent) {
                    this.setState(() => ({ percent: i.percent }))
                }
            }
        })
    }
    onDurationChange = (e) => {
        const duration = e.target.value;
        if (duration == "Custom") {
            this.setState(() => ({ 
                customDuration: true,
                duration 
            }));
        } else {
            this.setState(() => ({ 
                duration,
                customDuration: false,
                dateError: '',
                enableDatePicker: true
            }));
        }
        this.setState(() => ({ durationError: '' }))
    }
    onCustomDurationChange = (e) => {
        const duration = e.target.value;
        this.setState(() => ({ 
            duration: duration + ' minutes',
            durationError: '',
            dateError: '',
            enableDatePicker: true
        }))
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }));
    }
    onDateChange = (date) => {
        if (date) { 
            this.setState(() => ({ 
                date,
                enableTimePicker: true,
                times: getTimes(date, this.state.availability, this.state.duration, this.state.bookings)
            })) 
        }
    }
    onTimeChange = (e) => {
        let time = e.target.value;
        this.setState(() => ({ time }))
    }
    onMessageChange = (e) => {
        const message = e.target.value;
        this.setState(() => ({ message }));
    }

    /**
     * method to return boolean based on whether day is available for bookings
     */
    isDayBlocked = (day) => {
        return isDayAvailable(day, this.state.availability)
    }
    

    /**
     * method to close popup and reset state
     */
    onCancel = () => {
        this.setState(() => ({ 
                show: false,
                onServiceClick: false,
                showSent: false,
                item: '',
                date: moment(),
                time: '',
                calendarFocused: false,
                enableDatePicker: false,
                enableTimePicker: false
            }))
        this.props.onCancel();
    }

    /**
     * method to handle sending quotes
     * calls action to add quote (message)
     */
    onSend = () => {
        this.setState(() => ({ showSent: true }))
    
        let d = this.state.date._d.toString().substring(4,16)
        if (this.state.item === 'Other') { 
            d = "Not specified"
        }
        const time = moment().toString();
        let buyerMessage = { message: this.state.message, toUserID: this.state.toUserID, userID: this.props.userID, time, i: this.state.item, t: this.state.time, dur: this.state.duration, d, buyerName: this.state.buyerName };
        let sellerMessage = { message: this.state.message, toUserID: this.state.toUserID, userID: this.props.userID, status: 'unread', time, i: this.state.item, t: this.state.time, dur: this.state.duration, d, buyerName: this.state.buyerName };
        let allMessages = this.state.allMessages.concat(buyerMessage)
        let messages = this.state.messages.concat(buyerMessage)
        this.setState(() => ({ 
                allMessages,
                messages 
            })); 
        
        const mid = uuid();

        this.props.startAddMessageSeller(buyerMessage, sellerMessage, this.props.userID, this.state.toUserID, 'buyer', mid);
        this.props.startAddMessageBuyer(buyerMessage, sellerMessage, this.props.userID, this.state.toUserID, 'buyer', mid);

        if (!this.props.seller) {

            // FOR ROGERS
            // notification email of new quote to service provider from a buyer
            sendQuoteNotificationEmail(this.state.toUserID)
        }

        this.setState(() => ({ 
                item: '',
                message: '',
                time: '',
                date: moment(),
                calendarFocused: false,
                enableDatePicker: false,
                enableTimePicker: false,
                duration: '',
                showDuration: false,
                showDurationPicker: false,
                customDuration: false
            }))
    }

    render() {
        return (

            <div>
                <div>
                    <div className="chat-div">
                        <SweetAlert
                            style={{borderRadius: 15}}
                            show={this.state.show}
                            focusConfirmBtn={false}
                            confirmBtnText={this.state.showSent ? 'Exit' : 'Cancel'}
                            closeOnClickOutside
                            confirmBtnBsStyle="link"
                            title={'Request a Quote'}
                            heightAuto={false}
                            onCancel={this.onBack}
                            onConfirm={this.onCancel}>
                            
                            <div className="d-flex justify-content-center text-left mt-5">
                            <div className="col-8">
                            {!this.state.showSent ?
                                <div className="m-0 mt-2 p-0 bg-white">
                                    <form onSubmit={this.onSend}>
                                        
                                        {this.props.service.serviceItems ? (
                                            <div className="form-group pb-4">
                                                <hr/>
                                                <label htmlFor="item">Select Service</label>
                                                <select 
                                                    id="item" 
                                                    style={{color: 'black'}}
                                                    className="custom-select font-weight-light rounded-0 custom-select-lg"
                                                    defaultValue={this.state.item}
                                                    onChange={this.onItemChange}>
                                                    <option></option>
                                                    {Object.values(this.props.service.serviceItems).map((item, index) => (
                                                        <option key={index} value={item.title} label={item.title}>{item.title}</option>
                                                    ))}
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                        ) : (
                                            <div>
                                            </div>
                                        )}

                                        <div className="mt-3" hidden={!this.state.showDuration} style={{border: '1px solid gainsboro'}}>
                                            <h5 className="font-weight-light pl-3 pt-3 pb-1" style={{color: 'black'}}>{this.state.duration === '1 hours' ? '1 hour' : this.state.duration}</h5>
                                        </div>

                                        {this.state.showDurationPicker ? 
                                            <div className="pb-4">
                                                <hr/>
                                                <label>Duration</label>
                                                <select 
                                                    className="custom-select font-weight-light rounded-0 custom-select-lg"
                                                    value={this.state.duration}
                                                    onChange={this.onDurationChange}>
                                                    <option>{this.state.customDuration ? 'Custom' : this.state.duration}</option>
                                                    <option>Custom</option>
                                                    {this.state.durations.map((duration) => {
                                                        return (<option key={duration}>{duration}</option>)
                                                    })}
                                                </select>
                                                
                                                <div className="pt-2" hidden={!this.state.customDuration}>
                                                    <input
                                                        className="form-control font-weight-light rounded-0 form-control-lg"
                                                        placeholder="Minutes"
                                                        type="number"
                                                        value={this.state.customDurationVal}
                                                        onChange={this.onCustomDurationChange}/>
                                                    <small className="form-text text-muted">Please specify the number of minutes</small>     
                                                </div>                           
                                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.durationError}</small>
                                            </div>
                                        :
                                            <div></div>
                                        }

                                        <div className="pb-4">
                                            <hr/>
                                            <label htmlFor="date">Select Date</label>
                                            <div style={{border: '1px solid gainsboro'}}>
                                                <SingleDatePicker
                                                    id="date"
                                                    date={this.state.date}
                                                    focused={this.state.calendarFocused}
                                                    onDateChange={this.onDateChange}
                                                    onFocusChange={this.onFocusChange}
                                                    numberOfMonths={1}
                                                    isDayBlocked={this.isDayBlocked}
                                                    disabled={!this.state.enableDatePicker}
                                                />
                                            </div>
                                        </div> 

                                        <div>
                                            <div className="pb-4">
                                                <hr/>
                                                <label htmlFor="time">Select Time</label>
                                                <select 
                                                    id="time" 
                                                    style={{color: 'black'}}
                                                    className="custom-select font-weight-light rounded-0 custom-select-lg"
                                                    value={this.state.time}
                                                    disabled={!this.state.enableTimePicker}
                                                    onChange={this.onTimeChange}>
                                                    <option></option>
                                                    {this.state.times.map((t, index) => (
                                                                <option key={index}>{t}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="pb-4">
                                            <hr/>
                                            <label htmlFor="time">Optional Message</label>
                                            <textarea
                                                className="form-control font-weight-light rounded-0 form-control-lg m-0 p-0"
                                                placeholder=""
                                                type="text"
                                                id="review"
                                                value={this.state.message}
                                                onChange={this.onMessageChange}/>
                                        </div>
                                        <hr/>

                                        <button className="btn btn-block mt-3" onClick={this.onSend} disabled={!this.state.item}><h4 className="font-weight-light p-2 pt-3">Send</h4></button>
                                    </form>
                                </div>
                            :
                                <div>
                                    <hr/>
                                    <div className="d-flex justify-content-center mt-2">
                                        <h3 className="pt-4 display-5 col-10 font-weight-light" style={{color: 'black'}}>Your request for a quote has been sent.</h3>
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                        <h4 className="pt-4 display-5 col-10 font-weight-light text-muted">Responses will appear in your inbox.</h4>
                                    </div>
                                </div>
                            }
                            </div>
                            </div>
                        </SweetAlert>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allMessages: state.messages,
    seller: state.profile[0] ? state.profile[0].type === 'seller' ? true : false : undefined
})

const mapDispatchToProps = (dispatch) => ({
    startAddMessageBuyer: (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => dispatch(startAddMessageBuyer(buyerMessage, sellerMessage, buyerID, sellerID, type, mid)),
    startAddMessageSeller: (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => dispatch(startAddMessageSeller(buyerMessage, sellerMessage, buyerID, sellerID, type, mid)),
    startEditMessage: (id, messageID, updates) => dispatch(startEditMessage(id, messageID, updates))
});


export default connect(mapStateToProps, mapDispatchToProps)(QuoteForm);