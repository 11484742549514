import React from 'react';

/**
 * component for showing messages between two users
 * rendered by Inbox
 */
export const Message = (props) => (
            
    <div>
        <div onClick={props.onBack} className="link float-left mr-4">
            <img src="/images/back.png" className="img" style={{maxWidth:15, maxHeight:15}}/>
        </div>
        <h3 className="text-left font-weight-light">{props.toUserName != '' ? props.toUserName : 'Itorho Customer Service'}</h3>
        <hr/>
        {props.messages ? (
            props.messages.map((message, index) => {
                return (
                    message.userID === props.userID ?
                        <div key={index} className="message-user-div">
                            <h4 className="font-weight-light message-user">{message.message}</h4>
                        </div>
                    :
                        <div key={index} className="message-other-div">
                            <h4 className="font-weight-light message-other">{message.message}</h4>
                        </div>
                )})
        ) : (
            <div></div>
        )}
    </div>
)

export default Message;