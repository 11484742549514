import React from 'react';
import {connect} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import uuid from 'uuid';

import TopNav from '../TopNav';
import Footer from '../Footer';
import EventForm from './EventForm';
import EventList from './EventList';
import Event from './Event';
import ProposalForm from './ProposalForm';

import {startAddEvent, startSetAllEvents, startSetEvents, startEditEvent, startRemoveEvent, startRemoveAllEvents, startEditAllEvents} from '../../actions/events';
import {getEvents, getSellerEvents, proposalMatch, proposalStatus, selectProposal, getAcceptedProposals, getActiveProposals, remap} from '../../selectors/events';

/**
 * Page to render events for buyers and sellers (requests for proposals functionality)
 * 
 * www.itorho.com/events
 * 
 */
export class EventsPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            allEvents: this.props.allEvents ? this.props.allEvents : [],
            events: this.props.events ? this.props.events : [],
            event: {},
            proposal: {},
            service: this.props.service,

            edit: false,

            showAdd: false,
            showEvent: false,
            showProposal: false
        }
    }

    /**
     * updates state upone receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.allEvents != this.state.allEvents) {
            this.setState(() => ({ allEvents: newProps.allEvents }))
        }
        if (newProps.events != this.state.events) {
            this.setState(() => ({ events: newProps.events }))
        }
        if (newProps.service != this.state.service) {
            this.setState(() => ({ service: newProps.service }))
        }
        if (newProps.type === 'seller' && this.state.allEvents.length === 0) {
            this.props.startSetAllEvents()
        }
    }

    /**
     * updates state to show add event popup
     */
    onAdd = () => {
        this.setState(() => ({ showAdd: true }))
    }

    /**
     * updates state to show edit event popup form
     */
    onEdit = () => {

        this.setState(() => ({ 
            showAdd: true,
            showEvent: false,
            edit: true
        }))
    }

    /**
     * updates state to show edit proposal popup form
     */
    onEditProposal = () => {

        this.setState(() => ({ 
            proposal: selectProposal(this.state.event, this.state.service.id),
            showProposal: true,
            showEvent: false,
            edit: true
        }))
    }

    /**
     * updates state to close all popups and reset events and proposals
     */
    onCancel = () => {
        this.setState(() => ({ 
            showAdd: false,
            showEvent: false,
            showProposal: false,
            event: {},
            proposal: {},
            edit: false
        }));
    }

    /**
     * method to submit a new event or edit an existing event
     * calls action to add or edit event
     * 
     */
    onSubmit = (event, action) => {
        
        if (event != undefined && action != 'edit') {
            const eventID = uuid();
            const newEvent = {
                buyerID: this.props.id,
                eventID,
                ...event
            }
            
            this.props.startAddEvent(newEvent);

        } else if (event != undefined && action === 'edit') {
            this.props.startEditEvent(event.id, event);

        } else if (action === 'hide') {
            let hidden = true
            if (this.state.event.hidden != undefined) {
                hidden = !this.state.event.hidden
            }
            this.props.startEditEvent(this.state.event.id, {
                ...this.state.event,
                hidden
            });
        
        } else if (action === 'remove') {
            this.props.startRemoveEvent(this.state.event.id);
        }
        
        this.setState(() => ({ 
            showAdd: false,
            showEvent: false,
            event: {}
        }));
    }

    /**
     * method to show view event popup
     */
    onView = (event) => {
        this.setState(() => ({ 
            showEvent: true,
            event
        }));
    }

    /**
     * method to show add a proposal popup
     */
    onProposal = () => {
        this.setState(() => ({ 
            showProposal: true,
            showEvent: false
        }));
    }

    /**
     * method to submit a new proposal or edit an existing proposal
     * calls action to add or edit event
     */
    onSubmitPropsoal = (proposal, action) => {

        if (action === 'add') {
            let newProposal = {
                ...proposal,
                serviceID: this.state.service.id,
                service: this.state.service.name
            }
            let event = {
                ...this.state.event,
                proposals: {
                    ...this.state.event.proposals,
                    [this.state.event.proposals ? this.state.event.proposals.length : 0]: newProposal
                }
            }
            this.props.startEditAllEvents(event.id, event)

        } else if (action === 'edit') {
            let newProposal = {
                ...proposal,
                serviceID: this.state.service.id,
                service: this.state.service.name
            }
            let newProposals = Object.values(this.state.event.proposals).filter((p) => (p.serviceID != this.state.service.id))
            newProposals = remap(newProposals)
            let newerProposals = {
                ...newProposals,
                [Object.entries(newProposals).length]: newProposal
            }
            let event = {
                ...this.state.event,
                proposals: {
                    ...newerProposals
                }
            }
            this.props.startEditAllEvents(this.state.event.id, event)


        } else if (action === 'remove') {

            let proposals = Object.values(this.state.event.proposals).filter((p) => (p.serviceID != this.state.service.id))
            proposals = remap(proposals)
            let event = {
                ...this.state.event,
                proposals: {
                    ...proposals
                }
            }
            this.props.startEditAllEvents(this.state.event.id, event)
        }

        this.setState(() => ({ 
            showProposal: false,
            showEvent: false,
            showAdd: false,
            event: {},
            proposal: {}
        }));
    }

    /**
     * method to update a proposal when rejected
     * calls action to edit an event
     */
    onReject = (proposal) => {

        let newProposal = {
            ...proposal,
            status: 'rejected'
        }
        let proposals = this.state.event.proposals.filter((p) => p.serviceID != proposal.serviceID)
        proposals = remap(proposals, newProposal)
        let event = {
            ...this.state.event,
            proposals
        }
        this.props.startEditEvent(this.state.event.id, event)
        this.setState(() => ({ 
            event
        }));
    }

    /**
     * method to update a proposal when accepted
     * calls action to edit an event
     */
    onAccept = (proposal) => {

        let newProposal = {
            ...proposal,
            status: 'accepted'
        }
        let proposals = this.state.event.proposals.filter((p) => p.serviceID != proposal.serviceID)
        proposals = remap(proposals, newProposal)
        let event = {
            ...this.state.event,
            proposals
        }
        this.props.startEditEvent(this.state.event.id, event)
        this.setState(() => ({ 
            event
        }));
    }

    /**
     * method to navigate user to service profile page
     */
    onService = (service) => {
        let s = service.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
    }

    /**
     * method to update an event status to cleared
     * events can be cleared from a seller's event list when declined
     * calls action to edit event
     */
    onClear = (proposal) => {
        
        let newProposal = {
            ...proposal,
            clear: true
        }
        let proposals = this.state.event.proposals.filter((p) => p.serviceID != proposal.serviceID)
        proposals = remap(proposals, newProposal)
        let event = {
            ...this.state.event,
            proposals
        }
        this.props.startEditAllEvents(this.state.event.id, event)
        this.setState(() => ({ 
            event,
            showEvent: false
        }));
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true}  type={this.props.type} props={this.props}/>

                {/* pop up for adding new event */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showAdd}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Cancel"
                        confirmBtnBsStyle="link"
                        title={this.state.edit ? `Edit Event` : `Create a New Event`}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <EventForm
                            event={this.state.event}
                            edit={this.state.edit}
                            categories={this.props.categories}
                            onSubmit={this.onSubmit}/>
                        <div className="col-12 d-flex justify-content-center mb-2 pt-2">
                            <div className="col-9">
                                <hr/>
                            </div>
                        </div>
                    </SweetAlert>
                </div>

                {/* pop up for adding new proposal */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showProposal}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Cancel"
                        confirmBtnBsStyle="link"
                        title={this.state.edit ? `Edit Proposal` : `New Proposal for ${this.state.event ? this.state.event.title : ''}`}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <ProposalForm
                            proposal={this.state.proposal}
                            edit={this.state.edit}
                            event={this.state.event}
                            onSubmitProposal={this.onSubmitPropsoal}/>
                        <div className="col-12 d-flex justify-content-center mb-2 pt-2">
                            <div className="col-9">
                                <hr/>
                            </div>
                        </div>
                    </SweetAlert>
                </div>

                {/* pop up for viewing event */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showEvent}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Close"
                        confirmBtnBsStyle="link"
                        title={`${this.state.event ? this.state.event.title : ''}`}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <Event 
                            onClear={this.onClear}
                            onService={this.onService}
                            onReject={this.onReject}
                            onAccept={this.onAccept}
                            proposal={selectProposal(this.state.event, this.state.service.id)}
                            seller={this.props.type === 'seller' ? true : false}
                            event={this.state.event} />
                        <div>
                            <div className="col-12 d-flex justify-content-center mb-2 pt-2">
                                <div className="col-8 pb-2">
                                    {this.props.type === 'buyer' ?
                                        
                                        <div>
                                            {!this.state.event.proposals || getActiveProposals(this.state.event.proposals).length === 0 ?
                                                <div onClick={this.onEdit}>
                                                    <button className="rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                                        <h4 className="font-weight-light">Edit</h4>
                                                    </button>
                                                </div>
                                            :
                                                ''
                                            }
                                            
                                            <div className="row d-flex justify-content-center m-0 p-0 mt-3">
                                                <div className="col-12 d-flex justify-content-center m-0 p-0">
                                                    
                                                    {getAcceptedProposals(this.state.event.proposals).length === 0 ?
                                                        <div className="col-6 m-0 p-0">
                                                            <button onClick={(() => this.onSubmit(undefined,'remove'))} className="mr-2 rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                                                <h4 className="font-weight-light">Remove</h4>
                                                            </button>
                                                        </div>
                                                    :
                                                        ''
                                                    }
                                                    
                                                    {this.state.event.hidden && this.state.event.hidden === true ?
                                                        <div className="col-6 m-0 p-0">
                                                            <button onClick={(() => this.onSubmit(undefined,'hide'))} className="ml-2 rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                                                <h4 className="font-weight-light">Unhide</h4>
                                                            </button>
                                                        </div>
                                                    :
                                                        <div className="col-6 m-0 p-0">
                                                            <button onClick={(() => this.onSubmit(undefined,'hide'))} className="ml-2 rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                                                <h4 className="font-weight-light">Hide</h4>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    :
                                        this.props.type === 'seller' && proposalMatch(this.state.event, this.state.service.id) && proposalStatus(this.state.event, this.state.service.id) === undefined ?
                                            <div onClick={this.onEditProposal}>
                                                <button className="rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                                    <h4 className="font-weight-light">Edit Proposal</h4>
                                                </button>
                                            </div>
                                        :
                                            proposalStatus(this.state.event, this.state.service.id) === 'accepted' || proposalStatus(this.state.event, this.state.service.id) === 'rejected' ?
                                                ''
                                            :
                                                <div onClick={this.onProposal}>
                                                    <button className="rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                                        <h4 className="font-weight-light">Submit a Proposal</h4>
                                                    </button>
                                                </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mb-2 pt-2">
                                <div className="col-9">
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </SweetAlert>
                </div>

                <div className="mt-5">
                    {this.props.type === 'buyer' ?

                        // buyer content
                        <div className="mt-5 d-flex justify-content-center">
                            <div className="col-8">

                                <div className="mt-2 mb-5 pb-5">
                                    <h3 className="font-weight-light display-4 text-center">Your Events</h3>
                                    {this.state.events.length === 0 ?
                                        <div className="d-flex justify-content-center">
                                            <div className="col-6">
                                                <h3 className="font-weight-light text-center pt-5 pb-2">Planning an Event?</h3>
                                                <h4 className="font-weight-light text-center">Create an event and select the services you require so that sellers can contact you.</h4>
                                            </div>
                                        </div>
                                    :
                                        <div></div>
                                    }
                                </div>

                                <div className="mb-5 pb-5 d-flex justify-content-center" onClick={this.onAdd}>
                                    <div className="col-6">
                                        <button className="rounded-0 btn btn-main btn-block" style={{height: 35}}>
                                            <h4 className="font-weight-light">Create a New Event</h4>
                                        </button>
                                    </div>
                                </div>
                                <hr />

                                <div>
                                    <EventList 
                                        seller={false}
                                        serviceID={undefined}
                                        onView={this.onView}
                                        events={this.state.events} />
                                </div>

                            </div>
                        </div>

                    :

                        // seller content
                        <div className="mt-5 d-flex justify-content-center">
                            <div className="col-8">

                                <div className="mt-2 mb-5 pb-5">
                                    <h3 className="font-weight-light display-4 text-center">Available Events</h3>
                                </div>
                                <hr />

                                <div>
                                    <EventList 
                                        seller={true}
                                        serviceID={this.state.service.id}
                                        onView={this.onView}
                                        events={getSellerEvents(this.state.allEvents, this.state.service.categories, this.state.service.id)} />
                                </div>

                            </div>
                        </div>
                    }
                </div>
                <div className="mt-5 footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    type: state.profile[0] ? state.profile[0].type : '',
    id: state.profile[0] ? state.profile[0].id : '',
    categories: state.categories.map((category) => {
        return {
            label: category.category,
            value: category.id
        }
    }),
    events: getEvents(state.events, state.profile[0].id),
    allEvents: state.allEvents,
    service: state.services[0]
});

const mapDispatchToProps = (dispatch) => ({
    startAddEvent: (event) => dispatch(startAddEvent(event)),
    startSetAllEvents: () => dispatch(startSetAllEvents()),
    startSetEvents: () => dispatch(startSetEvents()),
    startEditEvent: (id, updates) => dispatch(startEditEvent(id, updates)),
    startEditAllEvents: (id, updates) => dispatch(startEditAllEvents(id, updates)),
    startRemoveEvent: (id) => dispatch(startRemoveEvent(id)),
    startRemoveAllEvents: (id) => dispatch(startRemoveAllEvents(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);