import React from 'react';
import {connect} from 'react-redux';
import firebase from '../../firebase/firebase';
import {startEditProfile} from '../../actions/profile';
import {startEditService} from '../../actions/services';

/**
 * component for adding an image of a service for buyers
 */
export class AddImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: this.props.image ? this.props.image : '',
            serviceName: this.props.serviceName ? this.props.serviceName : '',
            serviceID: this.props.serviceID ? this.props.serviceID : ''
        }
    }

    /**
     * method calls actions to add image to buyer profile and service profile galleries
     */
    onAddImage = () => {
        let id = this.props.profile.gallery ? Object.keys(this.props.profile.gallery).length : 0;
        let gallery = this.props.profile.gallery ? this.props.profile.gallery : ''
        if (this.state.image) {
            this.props.startEditProfile({
                ...this.props.profile,
                gallery: {
                    ...gallery,
                    [id]: {
                        ['i']: this.state.image,
                        ['s']: this.state.serviceName
                    }
                }
            })
            this.props.startEditService(this.state.serviceID, {
                gallery: {
                    ...this.props.service.gallery,
                    [this.props.service.gallery.length]: {
                        ['i']: this.state.image,
                        ['n']: this.props.profile.name
                    }
                }
            })
        }
        this.props.onAddImage();
    }

    /**
     * method for uploading an image to firebase storage
     */
    onImageChange(event) {
        const image = event.target.files[0]
        const storageRef = firebase.storage().ref();
        const mainImage = storageRef.child(image.name);
        mainImage.put(image)
            .then((snapshot) => {
                mainImage.getDownloadURL().then((url) => {
                this.setState({ image: url })
            })
        })
    }
    
    render() {
        return (

            <div className="form-group pb-4 d-flex justify-content-center">
                <div className="col-8">
                    <div className="mb-2 preview">
                        <img id="previewImg" src={this.state.image} className="rounded mx-auto d-block pb-4" style={{maxWidth:'50%'}}/>
                    </div>
                    <div className="custom-file rounded-0">
                        <input 
                            type="file" 
                            className="image-input"
                            id="picture"
                            onChange={() => this.onImageChange(event)}
                            name="picture"
                            accept="image/*"/>
                        <label
                            className="custom-file-label text-left" 
                            htmlFor="picture">Select Picture
                        </label>
                    </div>
                    <div className="d-flex justify-content-center mt-5 mb-5 pb-5" onClick={this.onAddImage}>
                        <button className="rounded-0 btn btn-block" style={{height: 37}}>
                            <h4 className="font-weight-light">Save</h4>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    profile: state.profile[0],
    service: state.services.find((service) => props.serviceName === service.name),
});

const mapDispatchToProps = (dispatch) => ({
    startEditProfile: (profile) => dispatch(startEditProfile(profile)),
    startEditService: (id, updates) => dispatch(startEditService(id, updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddImage);