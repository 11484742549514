import React from 'react';
import {findServiceName, getQuoteStatus, getQuoteStatusBuyer} from '../../selectors/messages';

/**
 * component to display list of quotes
 * rendered by the Quotes component
 */
export const QuoteList = (props) => (

    <div>
        <div>
            {props.quotes.length != 0 ? (
                props.quotes.map((other, index) => (
                        props.seller ?
                        <div key={index} className="p-3 link-chat" style={{border: '1px solid gainsboro'}} onClick={() => props.onOpenQuote(other.buyerName, other.toUserID, other.id)}>
                            <h3 className="font-weight-light ml-3 mt-1" style={{color: 'black'}}>{other.i}</h3>
                            {getQuoteStatus(other) === "New" ? <h4 className="ml-3 mb-1 text-o">{getQuoteStatus(other)}</h4> : <h5 className="font-weight-light ml-3 mb-1 text-o">{getQuoteStatus(other)}</h5>}
                        </div>
                        :
                        <div key={index} className="p-3 link-chat" style={{border: '1px solid gainsboro'}} onClick={() => props.onOpenQuote(other.buyerName, other.toUserID, other.id)}>
                            <h3 className="font-weight-light ml-3 mt-1" style={{color: 'black'}}>{other.i}</h3>
                            <h4 className="font-weight-light ml-3 mt-1" style={{color: 'black'}}>{findServiceName(other.toUserID, props.services)}</h4>
                            {getQuoteStatusBuyer(other) === "New" ? <h4 className="ml-3 mb-1 text-o">{getQuoteStatusBuyer(other)}</h4> : <h5 className="font-weight-light ml-3 mb-1 text-o">{getQuoteStatusBuyer(other)}</h5>}
                        </div>
                ))
            ) : (
                <h3>You have no quotes</h3>
            )}
        </div>
    </div>
)

export default QuoteList;