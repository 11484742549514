
/** returns the most booked service item and total number of bookings for that service */
export const getPopularItem = (events) => {
    var counterArr = [];
    var popItemMap = {};
    events.forEach(element => {
        counterArr.push(element.item);
    });

    counterArr.forEach(function (key) {
        if (popItemMap.hasOwnProperty(key)) {
            popItemMap[key]++;
        } else {
            popItemMap[key] = 1;
        }
    });

    const popItem = popItemMap
    const popItemArr = []
    const popItemKeys = []
    const popItemVals = []
    let totalBookings = 0
    Object.values(popItem).map((item) => {
        popItemVals.push(item)
    })
    Object.keys(popItem).map((key) => {
        popItemKeys.push(key)
    })
    for (var i = 0; i< popItemKeys.length; i++) {
        totalBookings += popItemVals[i]
        popItemArr.push({
            x: popItemVals[i],
            y: popItemKeys[i],
            label: popItemKeys[i]
        })
    }

    return {item: popItemArr, total: totalBookings};
}

/** returns total earnings for this month */
export const getMonthlyIncome = (bookings, sellerID) => {
    var total = 0;
    var currentMonth = new Date().getMonth()
    var currentYear = new Date().getFullYear()
    bookings.forEach(function(key){
        var keyDate = new Date(key.date)
        if (key.serviceID === sellerID.id) {
            if (keyDate.getUTCFullYear() === currentYear && keyDate.getMonth() === currentMonth) {
                total += parseInt(key.cost)
            }
        }
    })
    return {currentMonth: total}
}


/** returns number of times each day of the week has been booked (this year) */
export const popularDays = (bookings, sellerID) => {
    var currentYear = new Date().getFullYear()
    var days = {
    }
    for(var day=1; day<8; day++){
        bookings.forEach(function(key){
            var keyDate = new Date(key.date)
            if (key.serviceID === sellerID.id) {
                if (keyDate.getFullYear() === currentYear && keyDate.getDay() === day) {
                    if (days[day]) {
                        days[day]++
                    } else {
                        days[day] = 1
                    }
                }
            }
        })
    }

    const popDays = days
    const popDaysArr = []
    const popDaysKeys = []
    const popDaysVals = []
    Object.values(popDays).map((days) => {
        popDaysVals.push(days)
    })
    Object.keys(popDays).map((key) => {
        popDaysKeys.push(key)
    })
    popDaysKeys.map((key, index) => {
        popDaysArr.push({
            x: parseInt(key),
            y: popDaysVals[index],
        })
    })

    return popDaysArr
}

/** returns number of times each month has been booked (this year) */
export const popularMonths = (bookings, sellerID) => {
    var currentYear = new Date().getFullYear()
    var months = {}
    for (var month=1; month<13; month++) {
        bookings.forEach((key) => {
            var keyDate = new Date(key.date)
            if (key.serviceID === sellerID.id) {
                if (currentYear===keyDate.getFullYear() && keyDate.getMonth() === month) {
                    if (months[month]) {
                        months[month]++
                    } else {
                        months[month] = 1
                    }
                }
            }
        })
    }
    
    const popMonths = months
    const monthItemArr = []
    const monthItemKeys = []
    const monthItemVals = []
    Object.values(popMonths).map((month) => {
        monthItemVals.push(month)
    })
    Object.keys(popMonths).map((key) => {
        monthItemKeys.push(key)
    })
    for(var i = 0; i< monthItemKeys.length; i++) {
        monthItemArr.push({
            x: parseInt(monthItemKeys[i]),
            y: monthItemVals[i],
        })
    }

    return monthItemArr
}

/** returns the payment status of bookings (in the future) */
export const paidStatus = (bookings, sellerID) => {
    var statusList = []
    const date = new Date()
    bookings = bookings.sort((a, b) => {
        return new Date(a.date.substring(0,11)) <= new Date(b.date.substring(0,11)) ? -1 : 1;
    })

    bookings = bookings.filter((booking) => (new Date(booking.date) >= date))
    
    if (bookings.length != 0) {
        var current = new Date(bookings[0].date)
        if (current != date) { 
            statusList.push({date: true, d: bookings[0].date.substring(0,11)}) 
        } else if (current === date) { 
            statusList.push({date: true, d: 'Today' }) 
        }
        
        bookings.map((booking) => {
            var bookingDate = new Date(booking.date)
            if (booking.serviceID === sellerID && bookingDate >= date) {
                if (current.toString().substring(0,10) != bookingDate.toString().substring(0,10) && bookingDate != date) { 
                    statusList.push({date: true, d: booking.date.substring(0,11)}) 
                    current = bookingDate
                } else if (current.toString().substring(0,10) != bookingDate.toString().substring(0,10) && bookingDate === date) { 
                    statusList.push({date: true, d: 'Today' }) 
                    current = bookingDate
                }
                statusList.push(booking)
            }
        })
    }

    return statusList
}

/** returns list of past bookings */
export const getPast = (bookings, sellerID) => {
    var pastList = []
    const date = new Date()
    bookings = bookings.sort((a, b) => {
        return new Date(a.date.substring(0,11)) <= new Date(b.date.substring(0,11)) ? -1 : 1;
    })

    bookings = bookings.filter((booking) => (new Date(booking.date) <= date))
    
    if (bookings.length != 0) {
        var current = new Date(bookings[0].date)
        if (current != date) { pastList.push({date: true, d: bookings[0].date.substring(0,11)}) }
        else if (current === date) { pastList.push({date: true, d: 'Today' }) }
        
        bookings.map((booking) => {
            var bookingDate = new Date(booking.date)
            if (booking.serviceID === sellerID && bookingDate <= date) {
                if (current.toString().substring(0,10) != bookingDate.toString().substring(0,10) && bookingDate != date) { 
                    pastList.push({date: true, d: booking.date.substring(0,11)}) 
                    current = bookingDate
                }
                else if (current.toString().substring(0,10) != bookingDate.toString().substring(0,10) && bookingDate === date) { 
                    pastList.push({date: true, d: 'Today' }) 
                    current = bookingDate
                }
                pastList.push(booking)
            }
        })
    }

    return pastList
}

/** returns sorted and filtered list of bookings that match search text */
export const getAll = (bookings, text) => {
    var bookingsList = []
    var unpaid = 'outstanding'
    text = text.toLowerCase()
    bookings = bookings.sort((a, b) => {
        return new Date(a.date.substring(0,11)) <= new Date(b.date.substring(0,11)) ? -1 : 1;
    })
    bookings.map((booking) => {
        if (booking.cost === parseInt(text) || booking.item.toLowerCase().includes(text) || booking.title.toLowerCase().includes(text) || booking.start.includes(text) || booking.date.toLowerCase().includes(text)) { 
            bookingsList.push(booking) 
        } else if (unpaid.includes(text)) {
            if (!booking.paid) { 
                bookingsList.push(booking) 
            }
        }
    })

    return bookingsList;
}
