import React from 'react';
import {proposalMatch, proposalStatus, getProposals} from '../../selectors/events';

/**
 * component to display an event list item
 * rendered by the EventList
 */
export const EventListItem  = (props) => (

    <div>
        {props.event ? (
            <div className="mt-3 mb-2 p-3 pl-1 link-base" onClick={() => props.onView(props.event)} style={{border: '1px solid gainsboro', borderLeft: '3px solid #142948'}}>
                
                {!props.seller && props.event.hidden && (props.event.hidden === true) ? <h4 style={{color: '#ec9422'}} className="font-weight-light float-right">Hidden</h4> : ''}
                
                {props.event.hidden === false && !props.seller && props.event.proposals && getProposals(props.event.proposals).length != 0 ? <h4 style={{color: '#ec9422'}} className="font-weight-light float-right">New Proposals</h4> : '' }

                {props.seller ? 
                    proposalMatch(props.event, props.serviceID) ?
                        proposalStatus(props.event, props.serviceID) === undefined ? 
                            <h4 style={{color: '#ec9422'}} className="font-weight-light float-right">Proposal Pending</h4> 
                        : 
                            proposalStatus(props.event, props.serviceID) === 'accepted' ? 
                                <h4 style={{color: '#ec9422'}} className="font-weight-light float-right">Proposal Accepted</h4> 
                            :
                                <h4 style={{color: '#ec9422'}} className="font-weight-light float-right">Proposal Declined</h4> 
                    : ''
                : ''}
                
                <h3 className="font-weight-light pt-4 pb-1" style={{color: 'gray'}}>{props.event.date}</h3>
                <h3>{props.event.title}</h3>
                <h4 className="font-weight-light" style={{color: 'gray'}}>{props.event.people} people</h4>
                <h5 className="font-weight-light" style={{color: 'gray'}}>{props.event.duration}</h5>

            </div>
        ) : (
            <div></div>
        )}
    </div>
)

export default EventListItem;