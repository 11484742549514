import React from 'react';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryPie, VictoryTooltip} from 'victory';
import {getAll, getMonthlyIncome, getPopularItem, popularDays, popularMonths, paidStatus, getPast} from '../../utils/analytics';

/**
 * analytics view for sellers
 * rendered by dashboard page
 */
export class Analytics extends React.Component {

    state = {
        popMonthsNames: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        popDaysNames: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],
        bookings: this.props.bookings ? this.props.bookings : [],
        text: '',
        tab: 'upcoming',
        
        service: this.props.service
    }

    /**
     * updates state upon receiving new props from dashboard page
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.bookings != newProps.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }))
        }
        if (this.state.service != newProps.service) {
            this.setState(() => ({ service: newProps.service }))
        }
    }

    /**
     * updates state to reflect tab view change
     */
    onTabChange = (tab) => {
        this.setState(() => ({ tab }))
    }

    /**
     * updates state based on text input
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ 
            text,
        }))
    }

    /**
     * calls onCash method in dashboard page to update paid status
     */
    onCash = (id) => {
        this.props.onCash(id)
    }

    render() {
        return (

            <div>
                <div className="mt-md-5 mb-5 pt-md-5 pt-3">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-8 col-12">
                            <div className="mb-5 ml-5">
                                <h3 className="row font-weight-light text-left display-5 welcome-subtitle">Your Earnings This Month</h3>
                                <h3 className="row font-weight-bold text-left display-3 welcome-subtitle" style={{color:"black"}}>R{getMonthlyIncome(this.state.bookings, this.props.profile).currentMonth.toString()}.00</h3>
                            </div>

                            {/* tab view selectors */}
                            <div className="row mb-md-5 pb-5 mt-md-5 pt-md-5 pt-3 text-center pl-3 pr-3 pl-md-0 pr-md-0">
                                <div className={this.state.tab === 'upcoming' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('upcoming'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                    <h4 className="display-5 welcome-subtitle3">Upcoming</h4>
                                </div>
                                <div className={this.state.tab === 'past' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('past'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                    <h4 className="display-5 welcome-subtitle3">Past</h4>
                                </div>
                                <div className={this.state.tab === 'search' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('search'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                    <h4 className="display-5 welcome-subtitle3">Search</h4>
                                </div>
                                <div className={this.state.tab === 'days' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('days'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                    <h4 className="display-5 welcome-subtitle3">Days</h4>
                                </div>
                                <div className={this.state.tab === 'months' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('months'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                    <h4 className="display-5 welcome-subtitle3">Months</h4>
                                </div>
                                <div className={this.state.tab === 'services' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('services'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                    <h4 className="display-5 welcome-subtitle3">Services</h4>
                                </div>
                            </div>

                            {this.state.service != undefined ?
                                <div>

                                    {/* upcoming tab view */}
                                    <div hidden={!(this.state.tab === 'upcoming')}>
                                        <div className="mb-3 ml-5">
                                            <h3 className="row font-weight-light text-left display-5 mb-5 welcome-subtitle2">Upcoming Bookings</h3>
                                            {paidStatus(this.state.bookings, this.state.service.id).length != 0 ? 
                                                paidStatus(this.state.bookings, this.state.service.id).map((i, index) => (
                                                    i.date === true ? (
                                                        <div className="row mb-3 mt-5" key={index}>
                                                            <h4 className="text-muted">{i.d}</h4>
                                                        </div>
                                                    ) : (
                                                        <div className="row mb-3 hover-border pt-2 pr-2" key={index}>
                                                            <h5 className="col-2 font-weight-light">{i.title}</h5>
                                                            <h5 className="font-weight-light col-2">{i.item}</h5>
                                                            <h5 className="font-weight-light col-2">{i.start}</h5>
                                                            <h5 className="font-weight-light col-2">R{i.cost}</h5>
                                                            <h5 className="font-weight-light col-2">{i.paid ? 'PAID' : <span style={{color: '#ec9422'}} className="mobile-text">OUTSTANDING</span>}</h5>
                                                            {!i.paid? <h5 className="font-weight-light m-0 p-0 text-right hover-orange col-2" onClick={(() => this.onCash(i.bookingID))}>Paid Cash</h5> : ''}
                                                        </div>
                                                    ))
                                            ) : (
                                                <div><h4 className="text-center mb-5">You have no upcoming bookings</h4></div>
                                            )}
                                        </div>
                                        <hr/>
                                    </div>

                                    {/* past tab view */}
                                    <div hidden={!(this.state.tab === 'past')}>
                                        <div className="mb-3 ml-5">
                                            <h3 className="row font-weight-light text-left display-5 welcome-subtitle2">Past Bookings</h3>
                                            {getPast(this.state.bookings, this.state.service.id).length != 0 ? 
                                                getPast(this.state.bookings, this.state.service.id).map((i, index) => (
                                                    i.date === true ? (
                                                        <div className="row mb-3 mt-5" key={index}>
                                                            <h4 className="text-muted">{i.d}</h4>
                                                        </div>
                                                    ) : (
                                                        <div className="row mb-3 hover-border pt-2 pr-2" key={index}>
                                                            <h5 className="col-2 font-weight-light">{i.title}</h5>
                                                            <h5 className="font-weight-light col-2">{i.item}</h5>
                                                            <h5 className="font-weight-light col-2">{i.start}</h5>
                                                            <h5 className="font-weight-light col-2">R{i.cost}</h5>
                                                            <h5 className="font-weight-light col-2">{i.paid ? 'PAID' : <span style={{color: '#ec9422'}} className="mobile-text">OUTSTANDING</span>}</h5>
                                                            <div className="col-2 float-right hover-orange" onClick={(() => this.onCash(i.bookingID))}><h5 className="font-weight-light m-0 p-0 text-right">Paid Cash</h5></div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div><h4 className="text-center mb-5">You have no past bookings</h4></div>
                                            )}
                                        </div>
                                        <hr/>
                                    </div>

                                    {/* search tab view */}
                                    <div hidden={!(this.state.tab === 'search')}>
                                        <div className="mb-3 ml-5">
                                            <h3 className="row font-weight-light text-left display-5 mb-5 welcome-subtitle2">Search All Bookings</h3>
                                            {this.state.bookings.length != 0 ? 
                                                <div>
                                                    <div className="d-flex justify-content-center">
                                                    <div className="col-12 col-sm-8 m-0">
                                                        <input
                                                            className="form-control form-control-lg rounded-0 font-weight-light text-center"
                                                            type="text"
                                                            placeholder={`Search by name, date, time, service, cost or 'outstanding'`}
                                                            value={this.state.text}
                                                            onChange={this.onTextChange}/>
                                                    </div>
                                                    </div>
                                                    <div className="mt-5 mb-3">
                                                        {getAll(this.state.bookings, this.state.text).map((i, index) => (
                                                                <div className="row mb-3 hover-border pt-2 pr-2" key={index}>
                                                                    <h5 className="col-2 text-muted">{i.date.substring(0,11)}</h5>
                                                                    <h5 className="col-2 font-weight-light">{i.title}</h5>
                                                                    <h5 className="font-weight-light col-2">{i.item}</h5>
                                                                    <h5 className="font-weight-light col-2">R{i.cost}</h5>
                                                                    <h5 className="font-weight-light col-2">{i.paid ? 'PAID' : <span style={{color: '#ec9422'}} className="mobile-text">OUTSTANDING</span>}</h5>
                                                                    {!i.paid? <h5 className="font-weight-light m-0 p-0 text-right hover-orange col-2" onClick={(() => this.onCash(i.bookingID))}>Paid Cash</h5> : ''}
                                                                </div>
                                                        ))} 
                                                    </div>
                                                </div>
                                                : 
                                                    <div><h4 className="text-center mb-5">You have no bookings</h4></div>
                                                }
                                            </div>
                                        <hr/>
                                    </div>

                                    {/* days tab view */}
                                    <div hidden={!(this.state.tab === 'days')}>
                                        <div className="mb-3 ml-5">
                                            <h3 className="row font-weight-light text-left display-5 welcome-subtitle2">Popular Days</h3>
                                        </div>
                                        <div className="col-12">
                                            <VictoryChart
                                                animate={{duration: 1000}}
                                                domainPadding={20}>
                                                <VictoryAxis
                                                    tickFormat={this.state.popDaysNames}
                                                    tickValues={[1, 2, 3, 4, 5, 6, 7]}/>
                                                <VictoryAxis
                                                    dependentAxis/>
                                                <VictoryBar
                                                    labelComponent={<VictoryTooltip/>}
                                                    data={popularDays(this.state.bookings, this.props.profile)}
                                                    style={{ data: { fill: "orange" } }}
                                                    barWidth={30}
                                                    x="x"
                                                    y="y"/>
                                            </VictoryChart>
                                        </div>
                                        <hr/>
                                    </div>

                                    {/* services tab view */}
                                    <div hidden={!(this.state.tab === 'services')}>
                                        <div className="ml-5">
                                            <h3 className="row font-weight-light text-left display-5 welcome-subtitle2">Total Bookings</h3>
                                            <h3 className="row font-weight-bold text-left display-3 welcome-title" style={{color:"#142948"}}>{getPopularItem(this.state.bookings).total}</h3>
                                        </div>
                                        <div className="col-12">
                                            <VictoryPie
                                                data={getPopularItem(this.state.bookings).item}
                                                radius={150}
                                                labelRadius={170}
                                                width={500}
                                                colorScale={["tomato", "orange",  "#142948", "gold", "cyan" ]}
                                                containerComponent={<VictoryContainer responsive={true}/>}/>
                                        </div>
                                        <hr/>
                                    </div>

                                    {/* months tab view */}
                                    <div hidden={!(this.state.tab === 'months')}>
                                        <div className="mb-3 ml-5">
                                            <h3 className="row font-weight-light text-left display-5 welcome-subtitle2">Bookings This Year</h3>
                                        </div>
                                        <div className="col-12">
                                            <VictoryChart
                                                animate={{duration: 1000}}
                                                domainPadding={20}>
                                                <VictoryAxis
                                                    tickFormat={this.state.popMonthsNames}
                                                    tickValues={[0,1,2,3,4,5,6,7,8,9,10,11]}/>
                                                <VictoryAxis
                                                    dependentAxis/>
                                                <VictoryBar
                                                    data={popularMonths(this.state.bookings, this.props.profile)}
                                                    style={{ data: { fill: "#142948" } }}
                                                    barWidth={30}
                                                    x="x"
                                                    y="y"/>
                                            </VictoryChart>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            :
                                <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Analytics;