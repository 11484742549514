const servicesReducerDefaultState = [];

export default (state = servicesReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_SERVICES':
            return action.services;
        case 'ADD_SERVICE':
            return [
                action.service
            ];
        case 'EDIT_SERVICE':   
            return state.map((service) => {
                if (service.id === action.id) {
                    return {
                        ...service,
                        ...action.updates
                    };
                } else {
                    return service;
                }
            });
        case 'REMOVE_SERVICE':
            return state.filter(({ id }) => action.id !== id);
        default:
            return state;
    }
};