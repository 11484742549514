import React from 'react';
import ImageCarousel from '../Service/ImageCarousel';

/**
 * profile display component used by profile page
 */
export class Profile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showGallery: false,
            index: 0,
            gallery: this.props.profile.gallery ? this.props.profile.gallery : []
        }
    }

    /**
     * updates state to show gallery upon image click
     */
    onImage = (index) => {
        this.setState(() => ({ 
            showGallery: true,
            index 
        }));
    }

    /**
     * updates state to close gallery
     */
    closeGallery = () => {
        this.setState(() => ({ showGallery: false }));
    }

    render() {
        return (

            <div>

                <div className="d-flex justify-content-center m-5">
                    <div className="text-left mt-3 col-sm-8 col-12">
                        <div>
                            <img src={this.props.profile.image ? this.props.profile.image : ('/images/profile-placeholder.png')} className="img img-fluid mx-auto d-block col-6 col-md-4"/>
                        </div>
                        <h4 className="display-5 pt-5 ml-3">{this.props.profile.name}</h4>
                        <hr/>
                        <h4 className="font-weight-light pt-2 ml-3">{this.props.profile.email}</h4>
                        <hr/>
                        <h4 className="font-weight-light pt-2 ml-3">{this.props.profile.number}</h4>
                        <hr hidden={!this.props.profile.number}/>
                        <div hidden={!(Object.keys(this.state.gallery).length != 0)}>
                            <div className="row mt-4 pt-5 d-flex justify-content-center">
                                {Object.values(this.state.gallery).map((image, index) => (
                                    <div key={index} className="col-4 p-1">
                                        <div onClick={() => this.onImage(index)} className="mb-4">                                            
                                            <img src={image.i} className="rounded mx-auto d-block" style={{maxWidth:'100%'}}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <ImageCarousel 
                                gallery={Object.values(this.state.gallery).map((image) => (image.i))}
                                index={this.state.index}
                                closeGallery={this.closeGallery}
                                show={this.state.showGallery}/>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;