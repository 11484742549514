import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

export const PublicRoute = ({ 
    isAuthenticated, 
    component: Component,
    ...rest
}) => {

    return (
    <Route {...rest} component={(props) => {
        return (
        isAuthenticated ? (
            <Redirect to={`/dashboard`} />
        ) : (
            <div><Component {...props}/></div>
        )
    )}}/>
)};

export const mapStateToProps = (state) => ({
    isAuthenticated: !!state.profile[0]
});

export default connect(mapStateToProps)(PublicRoute);