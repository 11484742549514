import React from 'react';

/**
 * operating hours display
 * rendered by service profile component
 */
export const OperatingHours = (props) => (

    <div>
        <hr/>
        <div className="row justify-content-between pr-3">
            <h4 className="font-weight-light ml-3 col">Weekdays</h4>
            {props.operatingHrs.dFrom == 'closed' ? (<h4 className="font-weight-light text-right col">Closed</h4>) : (<h4 className="font-weight-light col text-right">{`${props.operatingHrs.dFrom} - ${props.operatingHrs.dTo}`}</h4>)}
        </div>
        <div className="row justify-content-between pr-3">
            <h4 className="font-weight-light ml-3 col">Weekends</h4>
            {props.operatingHrs.wFrom == 'closed' ? (<h4 className="font-weight-light text-right col">Closed</h4>) : (<h4 className="font-weight-light col text-right">{`${props.operatingHrs.wFrom} - ${props.operatingHrs.wTo}`}</h4>)}
        </div>
    </div>
);

export default OperatingHours;