import React from 'react';

/**
 * item component to display service list items
 * rendered by the item list component
 */
export class Item extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            onDescription: false
        }
    }

    /**
     * calls onEdit method in item list
     */
    onEdit = () => {
        this.props.onEdit(this.props.title);
    }

    /**
     * calls onRemove method in item list
     */
    onRemove = () => {
        this.props.onRemove(this.props.id);
    }

    /**
     * updates state to display or hide item description
     */
    onDescription = () => {
        const on = this.state.onDescription;
        this.setState(() => ({ onDescription: !on }))
    }

    render() {
        return (

            <div>
                <div className="col">
                    <div className="justify-content-center">
                        <div className="link-base" onClick={this.onDescription}>
                            {this.props.remove ? (
                                <button className="btn btn-main float-right mt-2 m-0 p-0 ml-2">
                                    <h4 className="font-weight-light pt-2 pl-4 pr-4" onClick={this.onRemove}>Remove</h4>                                     
                                </button>
                            ) : ('')}
                            {this.props.edit ? (
                                <button className="btn btn-main float-right mt-2 m-0 p-0">
                                    <h4 className="font-weight-light pt-2 pl-4 pr-4" onClick={this.onEdit}>Edit</h4>                                     
                                </button>
                            ) : ('')}
                            <h3 className="font-weight-light mb-3 pt-3 hover-orange">{this.props.title}</h3>
                        </div>
                        <div hidden={!this.state.onDescription}>
                            <hr/>
                            {this.props.edit ? (
                                <h4 className="font-weight-light mb-3 text-left" style={{color: 'gray'}}>{this.props.description}</h4>
                            ) : (
                                <h4 className="font-weight-light mb-3" style={{color: 'gray'}}>{this.props.description}</h4>
                            )}
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

export default Item;