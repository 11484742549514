import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timeGrid from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import BookingEvent from './BookingEvent';

/**
 * calendar component
 * 
 * rendered by the CalendarPage
 * uses the FullCalendar api
 */
export class CalendarPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            
            header: {   
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            },
            slotDuration: { minutes:15 },
            axisFormat: 'HH:mm',

            event: '',
            bookings: this.props.bookings ? this.props.bookings : [],
            showBooking: false,
        };
    }

    /**
     * updates the state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.bookings != newProps.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }))
        }
    }
    
    /**
     * calls the onPaid method in CalendarPage
     */
    onPaid = (id, updates) => {
        this.props.onPaid(id, updates)
    }

    /**
     * updates the state to hide the booking popup and clear event
     */
    onCancel = () => {
        this.setState(() => ({ 
            showBooking: false,
            event: ''
        }))
    }
    
    /**
     * updates the state to show the booking popup and set the event
     */
    onEventClick = (arg) => {
        this.setState(() => ({ 
            event: arg,
            showBooking: true 
        }))
    }

    render() {
        return (

            <div>
                <div>

                    {/* calendar component */}
                    <div className="mt-5 ml-2 mr-2 ml-sm-5 mr-sm-5" style={{height: '80vh'}}>
                        <FullCalendar
                            height="parent"
                            slotEventOverlap={true}
                            slotDuration={this.state.slotDuration}
                            defaultView="timeGridWeek" 
                            eventClick={this.onEventClick}
                            nowIndicator={true}
                            header={this.state.header}
                            selectable={true}
                            displayEventEnd={true}
                            eventTimeFormat={this.state.eventTimeFormat}
                            plugins={[ timeGrid, dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                            events={this.state.bookings} />
                    </div>
                </div>

                {/* view booking popup */}
                <div>
                    <SweetAlert
                        show={this.state.showBooking}
                        focusConfirmBtn={false}
                        confirmBtnText="Back"
                        overflow="scroll"
                        confirmBtnBsStyle="link"
                        closeOnClickOutside
                        title='Booking Details'
                        heightAuto={false}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <BookingEvent
                            onPaid={this.onPaid}
                            booking={this.state.event}/>
                    </SweetAlert>
                </div>
            </div>
        )
    }
}

export default CalendarPage;