import moment from 'moment';

/** returns sorted list of messages for the user to display in their inbox */
export const sortMessages = (userID, messages) => {
    let inbox = [];
    if (messages.length != 0) {
        messages.map((message) => {
            if (userID != 'admin') {
                if (message.userID != userID && message.i === undefined) {
                    if (inbox.length != 0) {
                        let add = true
                        inbox.map((inb) => {
                            if (inb.userID === message.userID) {
                                add = false;
                            }
                        })
                        if (add === true) {
                            inbox.push(message)
                        }
                    } else {
                        inbox.push(message)
                    }
                }
            } else {
                if (inbox.length != 0) {
                    let add = true
                    inbox.map((inb) => {
                        if (inb.toUserID === message.toUserID || inb.userID === message.toUserID) {
                            add = false;
                        }
                    })
                    if (add === true) {
                        inbox.push(message)
                    }
                } else {
                    inbox.push(message)
                }
            }
        })
    }
    return inbox;
} 

/** returns a string indicating status based on various quote parameters for a seller */
export const getQuoteStatus = (quote) => {
    if (quote.a != undefined) { return "Accepted" }
    else if (quote.cost != undefined) { return "Pending" } 
    else if (quote.status === "unread") { return "New" } 
    else { return "Reply now" }
}

/** returns a string indicating status based on various quote parameters for a buyer */
export const getQuoteStatusBuyer = (quote) => {
    if (quote.status === "unread") { return "New" } 
    else if (quote.a === undefined && quote.cost != undefined) { return "Reply Now" } 
    else if (quote.a === undefined) { return "Pending" } 
    else if (quote.a != undefined) { return "Accepted" } 
    else { return "" }
}

/** returns a sorted list of quotes for the id */
export const sortQuotes = (userID, messages, id) => {
    let quotes = [];
    if (messages.length != 0) {
        messages.map((message) => {
            if (message.i != undefined) {
                if (quotes.length != 0) {
                    let add = true
                    quotes.map((q) => {
                        if (q.id == message.id) {
                            add = false;
                        }
                    })
                    if (add == true) {
                        quotes.push(message)
                    }
                } else {
                    quotes.push(message)
                }
            }
        })
        if (id != undefined) {
            quotes.map((q, index) => {
                if (q.id === id) {
                    const newQ = {
                        ...q,
                        cost: 0
                    }
                    quotes.splice(index,1)
                    quotes.push(newQ)
                }
            })
        }
    }

    quotes.sort((a, b) => { return moment(a.time) > moment(b.time) ? 1 : -1 })
    quotes.sort((a, b) => { return a.status < b.status ? 1 : -1 })
    
    return quotes;
} 

/** returns selected messages sorted by date */
export const selectMessages = (toUserID, userID, allMessages) => {
    let messages = [];
    allMessages.map((message) => {
        if (userID != 'admin') {
            if ((message.toUserID === toUserID || message.userID === toUserID) && message.i === undefined) {
                messages.push(message)
            }
        } else {
            if ((message.toUserID === toUserID || message.userID === toUserID) && message.i === undefined) {
                messages.push(message)
            }
        }
    })
    messages.sort((a, b) => { return moment(a.time) > moment(b.time) ? 1 : -1 })
    return messages;
}

/** returns the service name associated with the id */
export const findServiceName = (id, services) => {

    let name = '';
    services.map((service) => {
        if (service.sellerID === id) {
            name = service.name
        }
    })
    return name;
}

/*
 * returns the service id associated with the id 
 * 
 * NOTE: redundant now - services now have the same id in database as the associated seller
 */
export const findServiceID = (id, services) => {

    let serviceID = '';
    services.map((service) => {
        if (service.sellerID === id) {
            serviceID = service.id
        }
    })
    return serviceID;
}

/** returns the service item id associated with the item name */
export const findServiceItemID = (id, services, item) => {

    let itemID = '';
    let index = 0
    services.map((service) => {
        if (service.sellerID === id) {
            Object.values(service.serviceItems).map((i, ind) => {
                if (i.title === item) {
                    index = ind
                }
            })
            Object.keys(service.serviceItems).map((i, ind) => {
                if (index === ind) {
                    itemID = i
                }
            })
        }
    })
    return itemID;
}

/** returns the number of unread messages from a specific user id */
export const getUnread = (toUserID, allMessages) => {
    let unreadCount = 0;
    if (allMessages != undefined) {
        allMessages.map((message) => {
            if (message.userID === toUserID && message.i === undefined) {
                if (message.status == 'unread') {
                    unreadCount = unreadCount+1;
                }
            }
        })
    }
    return unreadCount;
}

/** returns the total number of unread messages */
export const getAllUnread = (allMessages) => {

    let unreadCount = 0;
    if (allMessages.length != 0) {
        allMessages.map((message) => {
            if (message.status == 'unread') {
                unreadCount = unreadCount+1;
            }
        })
    }
    return unreadCount;
}

/** returns a list of updated messages where status is read */
export const changeStatus = (toUserID, messages) => {
    let newMessages = [];
    if (messages.length != 0) {
        messages.map((message) => {
            if (message.userID == toUserID) {
                newMessages.push({
                    ...message,
                    status: 'read'
                })
            } else {
                newMessages.push(message)
            }
        })
    }
    return newMessages;
}