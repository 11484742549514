import React from 'react';
import {connect} from 'react-redux';
import TopNav from '../TopNav';
import AvailabilityForm from './AvailabilityForm';
import Footer from '../Footer';
import {startEditService} from '../../actions/services';

/**
 * Page for sellers to set their service availability
 * 
 * www.itorho.com/availability
 * 
 */
export class AvailabilityPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            render: false,
            availability: this.props.service.availability ? this.props.service.availability : {},
            edit: false
        }
    }

    /**
     * updates state upon receiving new props
     */
    componentWillReceiveProps = (newProps) => {
        if (this.state.availability != newProps.service.availability) {
            this.setState(() => ({ availability: newProps.service.availability }))
        } 
    }

    /**
     * updates state to allow editing
     */
    onEdit = () => {
        this.setState(() => ({ edit: true }))
    }

    /**
     * updates state and calls edit service action
     */
    onSubmit = (availability) => {
        
        // getting rid of any empty time objects
        let newTimes = []
        availability.times.map((t) => {
            if (Object.keys(t).length != 0) { newTimes.push(t) }
        })

        this.setState(() => ({ edit: false }))
        let service = {
            ...this.props.service,
            availability: {
                ...availability,
                times: newTimes
            }
        }
        this.props.startEditService(this.props.service.id, service);
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true} type={'seller'} props={this.props}/>
                <div className="mt-5 d-flex justify-content-center">
                    <div className="col-8">
                        <h3 className="font-weight-light display-4 text-center mt-2 mb-5">Availability Schedule</h3>
                        {!this.state.edit ?
                            <div className="mb-5 d-flex justify-content-center" onClick={this.onEdit}>
                                <div className="col-6">
                                    <button className="rounded-0 btn btn-main btn-block" style={{height: 35}}>
                                        <h4 className="font-weight-light">Edit Availability</h4>
                                    </button>
                                </div>
                            </div>
                        :
                            <div></div>
                        }

                        <AvailabilityForm 
                            edit={this.state.edit}
                            availability={this.state.availability} 
                            onSubmit={this.onSubmit} />

                    </div>
                </div>
                <div className="footer mt-5 pt-5">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    service: state.services[0]
})

const mapDispatchToProps = (dispatch) => ({
    startEditService: (id, service) => dispatch(startEditService(id, service))
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityPage);