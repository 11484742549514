import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';

/**
 * form for editing bank details used by edit bank component
 */
export default class BankForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.profile ? props.profile.id : '',
            email: props.profile ? props.profile.email : '',
            name: props.profile ? props.profile.name : '',
            number: props.profile ? props.profile.number : '',
            type: props.profile ? props.profile.type : '',
            image: props.profile ? props.profile.image ? props.profile.image : '/images/profile-placeholder.png' : '/images/profile-placeholder.png',
            bank: props.profile ? props.profile.bank : '',
            accName: props.profile ? props.profile.accName : '',
            accNumber: props.profile ? props.profile.accNumber : '',
            branchCode: props.profile ? props.profile.branchCode : '',

            bankOptions: [
                { value: 'Standard Bank', label: 'Standard Bank'},
                { value: 'Absa Bank', label: 'Absa Bank'},
                { value: 'Bidvest Bank', label: 'Bidvest Bank'},
                { value: 'Capitec Bank Limited', label: 'Capitec Bank Limited'},
                { value: 'First National Bank', label: 'First National Bank'},
                { value: 'Nedbank Limited', label: 'Nedbank Limited'}
            ],
            
            enableSave: true
        };
    }

    /**
     * methods to update state based on form inputs
     */
    onBankChange = (bank) => {
        this.setState(() => ({ bank }));
    };
    onAccNameChange = (e) => {
        const accName = e.target.value;
        this.setState(() => ({ accName }));
    };
    onAccNumberChange = (e) => {
        const accNumber = e.target.value;
        this.setState(() => ({ accNumber }));
    };
    onBranchCodeChange = (e) => {
        const branchCode = e.target.value;
        this.setState(() => ({ branchCode }));
    };
    
    /**
     * method calls onSubmit in EditBank
     */
    onSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit({
            bank: this.state.bank,
            accName: this.state.accName,
            accNumber: this.state.accNumber,
            branchCode: this.state.branchCode,
            name: this.state.name,
            number: this.state.number,
            email: this.state.email,
            firstSignIn: false,
            type: this.state.type,
            image: this.state.image
        });
    };

    /**
     * method calls onCancel in EditBank
     */
    onCancel = (e) => {
        e.preventDefault();
        this.props.onCancel();
    };
    
    render() {
        return (

            <div>
                <div className="d-flex justify-content-center text-left mt-4">
                    <div className="col-8">

                        <form onSubmit={this.onSubmit}>
                            <div className="form-group pb-4 mt-5">
                                <hr/>
                                <label>Bank</label>
                                <CreatableSelect
                                    placeholder=""
                                    onChange={this.onBankChange}
                                    value={this.state.bank}
                                    options={this.state.bankOptions} />
                            </div>
                            <div className="form-group pb-4">
                                <hr/>
                                <label>Account Name</label>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="text"
                                    value={this.state.accName}
                                    onChange={this.onAccNameChange}/>
                            </div>
                            <div className="form-group pb-4">
                                <hr/>
                                <label>Account Number</label>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="text"
                                    value={this.state.accNumber}
                                    onChange={this.onAccNumberChange}/>
                            </div>
                            <div className="form-group pb-4">
                                <hr/>
                                <label>Branch Code</label>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="text"
                                    value={this.state.branchCode}
                                    onChange={this.onBranchCodeChange}/>
                            </div>
                            <hr/>
                            <div className="d-flex justify-content-center mb-4">
                                <button className="rounded-0 btn btn-main btn-block" disabled={!this.state.enableSave} style={{height: 35}}>
                                    <h4 className="font-weight-light">Save</h4>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    };
}



