import React from 'react';
import {getMonths, getMonthTotal, sortBookings} from '../../utils/transactions';

/**
 * transaction view for seller component of admin page
 */
export class Transactions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            bookings: this.props.bookings ? this.props.bookings : [],
            subscription: this.props.service.subscription != undefined ? this.props.service.subscription : false,
            payments: this.props.service.payments != undefined ? this.props.service.payments : false
        }
    }

    /**
     * updates state after timeout
     * gives time for transactions to load
     */
    componentDidMount = () => {
        setTimeout(() => {
            this.setState(() => ({ loading: false }))
          }, 1000);
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.bookings != newProps.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }))
        }
    }

    /**
     * onChange handler for updating charging subscription fee
     * updates service profile and calls onSubmitServiceProfile in AdminPage
     */
    onChange = () => {
        const subscription = !this.state.subscription
        const service = {
            ...this.props.service,
            subscription
        }
        this.setState(() => ({ subscription }))
        this.props.onServiceChange(service)
    }

    /**
     * updates paid status for a certain month
     * updates service profile and calls onSubmitServiceProfile in AdminPage
     */
    onPaid = (month, amount) => {

        const date = month.substring(2) + '20'
        const payments = {
            ...this.state.payments,
            [date]: amount
        }
        const service = {
            ...this.props.service,
            payments
        }

        this.setState(() => ({ payments }))
        this.props.onServiceChange(service)

    }

    render() {
        return (

            <div className="mt-5">
                {this.state.bookings.length != 0 ?
                    <div>
                        <div>
                        <hr/>

                            {/* charge subscription fee checkbox */}
                            <div className="row">
                                <div className="form-check mb-5 ml-4 float-left">
                                    <div>
                                        <input className="form-check-input" type="checkbox" onChange={this.onChange} value={this.state.subscription}/>
                                        <label className="form-check-label ml-3">Charge monthly subscription</label>
                                    </div>
                                    <small className="font-weight-light ml-1" style={{color: 'red'}}>{this.state.subscription ? '' : 'Not charged a monthly subscription'}</small>
                                </div>
                            </div>


                            {/* column headings */}
                            <div className="p-0 m-0">
                                <div className="row d-flex justify-content-center p-0 m-0">
                                    <div className="col-3 text-wrap p-0 m-0">
                                        <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Date</h5>
                                    </div>
                                    <div className="col-4 text-wrap p-0 m-0">
                                        <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Item</h5>
                                    </div>
                                    <div className="col-3 text-wrap p-0 m-0">
                                        <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Status</h5>
                                    </div>
                                    <div className="col-2 text-wrap p-0 m-0">
                                        <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Cost</h5>
                                    </div>
                                </div>
                            </div>

                            {getMonths(this.state.bookings).map((m, index) => (
                                <div key={index}>
                                    <hr/>
                                    <div className="p-0 m-0 mb-3">
                                        <h4 style={{color: 'gainsboro'}} className="text-left p-0 m-0">{m.substring(2)}</h4>
                                    </div>
                                    {sortBookings(this.state.bookings).map((booking, i) => {
                                        if (booking.date.substring(0,3) === m.substring(2)) {
                                            return (
                                                <div className="row d-flex justify-content-center p-0 m-0" key={i}>
                                                    <div className="col-3 text-wrap p-0 m-0">
                                                        <h4 className="text-left font-weight-light">{booking.date.substring(0,12)}</h4>
                                                    </div>
                                                    <div className="col-4 text-wrap p-0 m-0">
                                                        <h4 className="text-left font-weight-light">{booking.item}</h4>
                                                    </div>
                                                    <div className="col-3 text-wrap p-0 m-0">
                                                        <h4 className="text-left font-weight-light">{booking.paid ? 'PAID' : 'Awaiting Payment'}</h4>
                                                    </div>
                                                    <div className="col-2 text-wrap p-0 m-0">
                                                        <h4 className="text-left font-weight-light">R {booking.cost}</h4>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <hr/>
                                    <div className="row d-flex justify-content-center p-0 m-0 mt-4">
                                        <div className="col-7 text-wrap p-0 m-0">
                                            <h4 style={{color: 'gainsboro'}} className="text-left p-0 m-0">Total from bookings</h4>
                                        </div>
                                        <div className="col-3"></div>
                                        <div className="col-2 text-wrap p-0 m-0">
                                            <h4 className="text-left font-weight-light">R {getMonthTotal(m.substring(2), this.state.bookings)}</h4>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center p-0 m-0">
                                        <div className="col-7 text-wrap p-0 m-0">
                                            <h4 style={{color: 'gainsboro'}} className="text-left p-0 m-0">Itorho fee (5%)</h4>
                                        </div>
                                        <div className="col-3"></div>
                                        <div className="col-2 text-wrap p-0 m-0">
                                            <h4 className="text-left font-weight-light">- R {0.05 * getMonthTotal(m.substring(2), this.state.bookings)}</h4>
                                        </div>
                                    </div>
                                    {this.state.subscription ? (
                                    <div className="row d-flex justify-content-center p-0 m-0">
                                        <div className="col-7 text-wrap p-0 m-0">
                                            <h4 style={{color: 'gainsboro'}} className="text-left p-0 m-0">Monthly subscription</h4>
                                        </div>
                                        <div className="col-3"></div>
                                        <div className="col-2 text-wrap p-0 m-0">
                                            <h4 className="text-left font-weight-light">- R 120</h4>
                                        </div>
                                    </div>
                                    ) : ('')}
                                    <div className="row d-flex justify-content-center p-0 m-0">
                                        <div className="col-10"></div>
                                        <div className="col-2 text-wrap p-0 m-0">
                                            <hr/>
                                            <h4 style={this.state.payments ? Object.keys(this.state.payments).includes(m.substring(2) + '20') ? {} : {color: 'red'} : {color: 'red'}} className="text-left font-weight-light">R {this.state.subscription ? getMonthTotal(m.substring(2), this.state.bookings) - 0.05 * getMonthTotal(m.substring(2), this.state.bookings) - 120 : getMonthTotal(m.substring(2), this.state.bookings) - 0.05 * getMonthTotal(m.substring(2), this.state.bookings)} {this.state.payments ? Object.keys(this.state.payments).includes(m.substring(2) + '20') ? 'PAID' : '' : '' }</h4>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center p-0 m-0 pt-3">
                                        <div className="col-10 text-wrap p-0 m-0"></div>
                                        {this.state.payments ?
                                            Object.keys(this.state.payments).includes(m.substring(2) + '20') ?
                                                <div></div>
                                            :
                                                <div className="col-2 text-wrap p-0 m-0">
                                                    <button className="rounded-0 btn btn-main btn-block mb-4" onClick={(() => this.onPaid(m, this.state.subscription ? getMonthTotal(m.substring(2), this.state.bookings) - 0.05 * getMonthTotal(m.substring(2), this.state.bookings) - 120 : getMonthTotal(m.substring(2), this.state.bookings) - 0.05 * getMonthTotal(m.substring(2), this.state.bookings)))} style={{height: 30}}>
                                                        <h5 className="font-weight-light">Paid</h5>
                                                    </button>
                                                </div>
                                        :
                                            <div className="col-2 text-wrap p-0 m-0">
                                                <button className="rounded-0 btn btn-main btn-block mb-4" onClick={(() => this.onPaid(m, this.state.subscription ? getMonthTotal(m.substring(2), this.state.bookings) - 0.05 * getMonthTotal(m.substring(2), this.state.bookings) - 120 : getMonthTotal(m.substring(2), this.state.bookings) - 0.05 * getMonthTotal(m.substring(2), this.state.bookings)))} style={{height: 30}}>
                                                    <h5 className="font-weight-light">Paid</h5>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                :
                    <div hidden={this.state.loading} className="mt-5 pb-3">
                        <h3 className="font-weight-light">No transactions</h3>
                    </div>
                }

            <hr/>
            </div>

        )
    }
}

export default Transactions;