var md5 = require("blueimp-md5");
import qs from 'qs';

function up(d,mul,form)
{
    //console.log(document.forms[1]);
    if (document.forms[0] !== undefined && mul !== undefined && mul != null) {
        (document.forms[0])[d].value=mul;
    }
}

export function postData(payload, props, form) {
    //console.log(JSON.stringify(payload,null,1));
    console.log('In postData Booking');
    console.log(props);
    if(props.bookingID !== ""){
        console.log("ID not empty");
        console.log(document.forms.form1);
        if (document.forms[0] !== undefined) {
            console.log("Form is defined");
            var m_payment_id;
            m_payment_id = props.booking.bookingID;
            console.log(m_payment_id);
            payload.m_payment_id = m_payment_id;
            console.log('payload:');
            console.log(payload);
            console.log('props:');
            console.log(props);
            if (payload.signature === null) {
                console.log('Signature is null');
                var queryFields = "";
                console.log("Payload:\n"+JSON.stringify(payload,null,3));
                for (var key in payload) {
                    if (key !== 'signature') {
                        // If the object has the property (key), and is not the 'signature' field, then add to the query string
                        if (payload.hasOwnProperty(key) && payload.key !== null) {
                            var value = payload[key];
                            // if (typeof value === 'string') {
                            //     value = payload[key].replace(/\s/g, '+');
                            //     console.log(value);
                            // }
                            if  (typeof value === "number"){
                                queryFields += (key + "=" + encodeURIComponent(value) + "&");
                            }else {
                                queryFields += (key + "=" + encodeURIComponent(value) + "&");
                            }
                        }}
                    if (payload.hasOwnProperty(key)) {
                        up(key, payload[key], form);
                    }
                }
                // Strip the last '&" at the end of the query String and hash it.
                queryFields = queryFields.substring(0, queryFields.length - 1);
                queryFields = queryFields.replace(/%20/g, '+');
                console.log("Built QS:");
                console.log(queryFields);
                console.log("Using QS Module:");
                console.log(qs.stringify(payload));
                const md5Query = md5(queryFields);
                payload.signature = md5Query;
                (document.forms.form1)['signature'].value = md5Query;
                console.log(md5Query);
                console.log("Posting form.");
                (document.forms[0]).submit();

            }
        }} else {
        console.log("Too early mate.");
    }
}