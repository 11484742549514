import React from 'react';


/**
 * progress component for tracking progress of seller's service profile creation
 * 
 * a completed seller profile will have a completed service profile, at least one service item, 
 * and must have edited their service availability
 */
export class Progress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            step1: this.props.step === 1 ? true : false,
            step2: this.props.step === 2 ? true : false,
            step3: this.props.step === 3 ? true : false
        }
    }

    /**
     * method to navigate to add service page upon click
     */
    onStep2 = () => {
        if (this.state.step2) {
            this.props.props.history.push('/add')
        }
    }

    /**
     * method to navigate to calendar page upon click
     */
    onStep3 = () => {
        if (this.state.step3) {
            this.props.props.history.push('/availability')
        }
    }

    render() {
        return (

            <div>

                <div className="mt-md-5 pt-5 d-flex justify-content-center">
                    <div className="col-10 row d-flex justify-content-center">
                        <div className={this.state.step1 ? "col-1 progress progress-active" : "col-1 progress progress-complete"}></div>
                        <div  className={this.state.step2 ? "col-3 line-complete" : this.state.step3 ? "col-3 line-complete" : "col-3 line"}></div>
                        <div onClick={this.onStep2} className={this.state.step2 ? "pointer col-1 progress progress-active" : this.state.step3 ? "col-1 progress progress-complete" : "pointer col-1 progress progress-inactive"}></div>
                        <div className={this.state.step3 ? "col-3 line-complete" : "col-3 line"}></div>
                        <div onClick={this.onStep3} className={this.state.step3 ? "pointer col-1 progress progress-active" : "pointer col-1 progress progress-inactive"}></div>
                    </div>
                </div>

                <div className="mt-4 d-flex justify-content-center">
                    <div className="col-md-10 col-12 row d-flex justify-content-center text-center">
                        <div className="col-1"></div>
                        <div className={this.state.step1 ? "col-2 progress-text-active" : "col-2 progress-text-complete"}>
                            <h5 className="welcome-subtitle3">Step 1</h5>
                            <h5 className="font-weight-light welcome-text">Complete your Profile</h5>
                        </div> 
                        <div className="col-1"></div>
                        <div onClick={this.onStep2} className={this.state.step2 ? "pointer col-3 progress-text-active" : this.state.step3 ? "col-3 progress-text-complete" : "pointer col-3 progress-text-inactive"}>
                            <h5 className="welcome-subtitle3">Step 2</h5>
                            <h5 className="font-weight-light welcome-text">Add a Service Item</h5>
                        </div>
                        <div className="col-1"></div>
                        <div onClick={this.onStep3} className={this.state.step3 ? "pointer col-2 progress-text-active" : "pointer col-2 progress-text-inactive"}>
                            <h5 className="welcome-subtitle3">Step 3</h5>
                            <h5 className="font-weight-light welcome-text">Add your Availability</h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Progress;