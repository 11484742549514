import React from 'react';
import {GeoFire} from 'geofire';
import database from "../../firebase/firebase";


/**
 * class to order and sort nearby services upon dashboard mounting
 */
export class Nearby extends React.Component {

    constructor(props) {
        super(props)
    }

    /**
     * loads nearby services upon mounting
     * calls the setNearbyMethod in DashboardPage and passes it the ordered list of nearby services
     * 
     * set the rad variable to change the area of nearby
     */
    componentDidMount = () => {

        const rad = 50

        navigator.geolocation.getCurrentPosition((location) => {
            var firebaseRef = database.ref('/geofire');
            var geoFire = new GeoFire(firebaseRef);

            // Gets the geoQuery of closest locations
            var lat = location.coords.latitude;
            var long = location.coords.longitude;
            var geoQuery = geoFire.query({
                center: [lat, long],
                radius: rad
            });

            // Builds the list of closest locations
            var closetServices = [];
            var dist = [];
            geoQuery.on("key_entered", function(key, location, distance) {
                closetServices.push(key);
                dist.push(distance);
            });
            var returnServices = []
            if (closetServices.length === 0) {
                setTimeout(() => {
                    closetServices.forEach((key, index) => {
                        let tempService = this.props.services.find((service) => service.id === key);
                        if (tempService) {
                            tempService.distance = dist[index];
                            returnServices.push(tempService)
                        }
                    });
                    this.props.setNearby(this.orderNearServices(returnServices));
                }, 3000)
            }
        })
    }

    /**
     * helper method to order the nearby services by distance
     */
    orderNearServices  = (services) => {
        return services.sort((a, b) => {
            return a.distance > b.distance ? 1 : -1;
        });
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default Nearby;