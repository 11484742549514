import moment from 'moment';

/** returns list of upcoming bookings sorted by date */
export const sortBookings =  (bookings) => {
    return bookings.filter((booking) => {
        const startDate = moment(booking.date);
        const now = moment();
        const startDateMatch = startDate ? startDate.isSameOrAfter(now, 'day') : true;
        return startDateMatch;
    }).sort((a, b) => {
        return moment(a.startDate).isSameOrAfter(moment(b.startDate), 'day') ? -1 : 1;
    });
};

/** return sorted list of unique dates that are contained by upcoming bookings */
export const sortDates = (bookings) => {
    let dates = [];
    bookings.filter((booking) => {
        if (!dates.includes(booking.date.substring(0,12)) && moment(booking.date).isSameOrAfter(moment(), 'day')) {
            dates.push(booking.date.substring(0,12));
        }
    })
    return dates.sort((a, b) => {
        return moment(a).isSameOrAfter(moment(b), 'day') ? 1 : -1;
    });
} 

/** returns list of past bookings sorted by date */
export const sortHistoryBookings =  (bookings) => {
    return bookings.filter((booking) => {
        const startDate = moment(booking.date);
        const now = moment();
        const startDateMatch = startDate ? startDate.isBefore(now, 'day') : true;
        return startDateMatch;
    }).sort((a, b) => {
        return moment(a.startDate).isSameOrAfter(moment(b.startDate), 'day') ? -1 : 1;
    });
};

/** return sorted list of unique dates that are contained by past bookings */
export const sortHistoryDates = (bookings) => {
    let dates = [];
    bookings.filter((booking) => {
        if (!dates.includes(booking.date.substring(0,15)) && moment(booking.date.substring(0,15)).isBefore(moment(), 'day')) {
            dates.push(booking.date.substring(0,15));            
        }
    })
    return dates.sort((a, b) => {
        return moment(a).isBefore(moment(b), 'day') ? 1 : -1;
    });
} 

/** return sublist of bookings that are upcoming */
export const checkFutureBookings = (bookings) => {
    let futureBookings = []
    const now = moment();
    if (bookings) {
        bookings.map((booking) => {
            if (moment(booking.date).isSameOrAfter(moment(now))) {
                futureBookings.push(booking.bookingID)
            }
        })
    }

    return bookings;
}

/** returns only bookings with matching service id */
export const getServiceBookings = (bookings, id) => {

    return bookings.filter((b) => (
        b.serviceID === id
    ))
}