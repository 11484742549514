// ADD_ROUTE

/** add route to redux store */
export const addRoute = (route) => ({
    type: 'ADD_ROUTE',
    route
});

// REMOVE_ROUTE

/** remove route from redux store */
export const removeRoute = () => ({
    type: 'REMOVE_ROUTE'
});