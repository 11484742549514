const messagesReducerDefaultState = [];

export default (state = messagesReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_MESSAGES':
            return action.messages;
        case 'ADD_MESSAGE':
            return [
                ...state,
                action.message
            ];
        case 'EDIT_MESSAGE':   
            return state.map((message) => {
                if (action.id === message.id) {
                    return {
                        ...message,
                        ...action.updates
                    };
                } else {
                    return message;
                }
            });
        default:
            return state;
    }
};