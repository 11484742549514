import database from '../firebase/firebase';
import {GeoFire} from "geofire";

// SET_SERVICES

/** set services in redux store */
export const setServices = (services) => ({
    type: 'SET_SERVICES',
    services
});

/** get all services from database */
export const startSetAllServices = () => {
    return (dispatch) => {
        return database.ref(`services/`)
            .once('value')
            .then((snapshot) => {
                const services = [];

                snapshot.forEach((childSnapshot) => {
                    services.push({
                        id: childSnapshot.key,
                        ...childSnapshot.val()
                    });
                });
                dispatch(setServices(services));
            });
    };
};

/** get single service from database */
export const startSetService = (id) => {
    return (dispatch, getState) => {
        const type = getState().profile[0] ? getState().profile[0].type : 'buyer';
        if (type === 'buyer') {
            dispatch(startSetAllServices());
        } else {
            return database.ref(`services/${id}`)
            .once('value')
            .then((snapshot) => {
                const service = [];
                service.push({
                    id: snapshot.key,
                    ...snapshot.val()
                });
                dispatch(setServices(service));
            });
        }
    };
};

// ADD_SERVICE

/** add service to redux store */
export const addService = (service) => ({
    type: 'ADD_SERVICE',
    service
});

/** add service to database */
export const startAddService = (service) => {
    return (dispatch) => {
        return database.ref(`services/${service.sellerID}`)
            .update(service)
            .then(() => {
            dispatch(addService({
                id: service.sellerID,
                ...service
            }));
            updateGeoPoint(service.location, service.sellerID);
        });
    };
};

/** helper method for updating the geopoint */
export const updateGeoPoint = (location, id) => {
    var firebaseRef = database.ref('/geofire');

    // create a GeoFire index
    var geoFire = new GeoFire(firebaseRef);
    let lat = location.lat;
    let long = location.long;
    if (lat && long) {
        // update the geopoint location at every node/service.
        // let key = Math.floor(Math.random() * 99999999999) + 1;
        geoFire.set(id, [lat, long]).then(() => {
            console.log('GeoPoint added')
        }).catch((error) => {
            console.log(error);
        });
    } else {
        console.log("A proper address has not been used");
    }
}

// EDIT_SERVICE

/** edits service in redux store */
export const editService = (id, updates) => ({
    type: 'EDIT_SERVICE',
    id,
    updates
});

/** edits service in database */
export const startEditService = (id, updates) => {
    return (dispatch) => {
        return database.ref(`services/${id}`)
            .update(updates)
            .then(() => {
                dispatch(editService(id, updates));
                updateGeoPoint(updates.location, id);
            });
    };
};

// REMOVE_SERVICE

/** removes service from redux store */
export const removeService = (id) => ({
    type: 'REMOVE_SERVICE',
    id
});
  
/** removes service from database */
export const startRemoveService = (id) => {
return (dispatch) => {
    return database.ref(`services/${id}`)
        .remove()
        .then(() => {
            dispatch(removeService(id));
        });
    };
};
