import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import moment from 'moment';
import ItemList from './ItemList';
import Map from './Map';
import MessageForm from '../Chat/MessageForm';
import AddBooking from './AddBooking';
import Gallery from './Gallery';
import Reviews from './Reviews';
import OperatingHours from './OperatingHours';
import QuoteForm from '../Chat/QuoteForm';
import Image from './Image';
import {startEditService} from '../../actions/services';
import {addRoute} from '../../actions/route';
import {sortBookings, getServiceBookings} from '../../selectors/bookings';


/**
 * service profile display
 * rendered by the service page
 */
export class ServiceProfile extends React.Component {

    constructor(props) {
        super(props)

        this.onImageChange = this.onImageChange.bind(this);
        this.state = {
            showForm: this.props.showForm ? this.props.showForm : false,
            onMap: false,
            onContact: false,
            onHours: false,
            tab: 'services',
            onMessageForm: false,
            onMessage: false,
            onQuoteForm: false,
            onQuote: false,
            gallery: this.props.service ? this.props.service.gallery ? this.props.service.gallery : [] : [],
            showGallery: false,
            textToCopy: "www.itorho.com" + this.props.props.match.url,
            copyMessage: false,
            allBookings: this.props.allBookings ? this.props.allBookings : [],
            newCost: this.props.cost ? this.props.cost : undefined
        }
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.allBookings != this.state.allBookings) {
            this.setState(() => ({ allBookings: newProps.allBookings }))
        }
        if (newProps.showForm != this.state.showForm) {
            this.setState(() => ({ showForm: newProps.showForm }))
        }
        if (newProps.cost != this.state.newCost) {
            this.setState(() => ({ newCost: newProps.cost }))
        }
    }

    /**
     * updates state to reflect selected tab view
     */
    onTabChange = (tab) => {
        this.setState(() => ({ tab }))
    }

    /**
     * updates state to close open messaging pop ups
     */
    onCancel = () => {
        this.setState(() => ({ 
            onMessage: false,
            onQuote: false
        }));
    }

    /**
     * calls onBack method in service page
     */
    onBack = () => {
        this.props.onBack();
    }

    /**
     * updates state to toggle display of map
     */
    onMap = () => {
        const onMap = !this.state.onMap;
        this.setState(() => ({ onMap }))
    }

    /**
     * updates state to toggle display of contact
     * 
     * NOTE: not in use currently
     */
    onContact = () => {
        const onContact = !this.state.onContact;
        this.setState(() => ({ onContact }))
    }

    /**
     * updates state to toggle display of operating hours
     */
    onHours = () => {
        const onHours = !this.state.onHours;
        this.setState(() => ({ onHours }))
    }

    /**
     * updates state to toggle display of booking form
     */
    onBook = () => {
        const showForm = !this.state.showForm;
        this.setState(() => ({ 
            showForm,
            newCost: undefined
        }));
        this.props.onBook()
    }

    /**
     * updates state to display quote form
     */
    onQuote = () => {
        this.setState(() => ({ 
            onQuote: true,
            onQuoteForm: true 
        }));
    }

    /**
     * updates state to display messenger
     */
    onMessage = () => {
        this.setState(() => ({ 
            onMessageForm: true,
            onMessage: true 
        }));
    }

    /**
     * navigates user to register page
     * saves the surrent service in route
     * so that this can be navigated back to once registered
     */
    onRegister = () => {
        this.props.addRoute(this.props.props.match.params.service);
        this.props.props.history.push('/buyer/register');
    }

    /**
     * updates state to show gallery pop up with selected image
     */
    onImage = (index) => {
        this.setState(() => ({ 
            showGallery: true,
            index 
        }));
    }

    /**
     * updates state to hide gallery
     */
    closeGallery = () => {
        this.setState(() => ({ showGallery: false }));
    }

    /**
     * updates gallery state to remove selected component
     * calls action to edit service profile and remove image from gallery
     * 
     * NOTE: image is not removed from firebase storage
     */
    onImageRemove = (image) => {
        const gallery = this.state.gallery.filter((galleryImage) => galleryImage != image)
        this.setState(() => { gallery })
        const service = {
            ...this.props.service,
            gallery
        }
        this.props.startEditService(this.props.service.id, service)
    }

    /**
     * handles upload of image to firebase storage
     * calls action to add image to service profile in database
     */
    onImageChange(event) {
        const image = event.target.files[0];
        if(image.size < 2097152) {
            const storageRef = firebase.storage().ref();
            const mainImage = storageRef.child(image.name);
            mainImage.put(image).then((snapshot) => {
                mainImage.getDownloadURL().then((url) => {
                    const gallery = this.state.gallery.concat(url)
                    this.setState({ gallery })
                    const service = {
                        ...this.props.service,
                        gallery
                    }
                    this.props.startEditService(this.props.service.id, service)
                })
            })
        } else {
            alert("File is too large. Please reduce its size and reupload.");
        }
    }

    /**
     * method to copy the url for the service profile page to clipboard
     */
    onCopy = () => {
        this.setState(() => ({ copyMessage: true }))
        navigator.clipboard.writeText(this.state.textToCopy)
        setTimeout(() => {
            this.setState(() => ({ copyMessage: false }))
        }, 3000)
        
    }

    render() {
        return (

            <div>
                <div className="mt-5 d-flex justify-content-center">
                    <div className="col-sm-8 col-12">

                        {this.props.type === 'buyer' ? (
                            <div onClick={this.onBack} className="link float-left m-3 m-sm-5">
                                <img src="/images/back.png" className="img" style={{maxWidth:20, maxHeight:20}}/>
                            </div>
                        ) : ('')}

                        <h1 className="display-5 text-center mt-3 mt-sm-5 mb-5 pr-5 title">{this.props.service.name}</h1>
                        <div className="d-flex justify-content-center">
                            <Image image={this.props.service.image ? this.props.service.image : ''} show={true} size={1} crop={this.props.service.crop ? this.props.service.crop : undefined}/>
                        </div>

                        {/* tab view controls */}
                        <div className="row mb-4 mt-5 text-center ml-3 ml-md-0 mr-3 mr-md-0">
                            <div className={this.state.tab === 'services' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('services'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Services</h4>
                            </div>
                            <div className={this.state.tab === 'info' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('info'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Info</h4>
                            </div>
                            <div className={this.state.tab === 'gallery' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('gallery'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Gallery</h4>
                            </div>
                            <div className={this.state.tab === 'reviews' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('reviews'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Reviews</h4>
                            </div>
                        </div>

                        <AddBooking
                            availability={this.props.service.availability != undefined ? this.props.service.availability : {
                                fromDate: moment(),
                                toDate: moment().add(6,'months'),
                                notice: '2 hours',
                                buffer: '15 minutes',
                                increments: '15 minutes',
                                times: [{day: 'Monday', from: '09;00', to: '17:00'},{day: 'Tuesday', from: '09;00', to: '17:00'},{day: 'Wednesday', from: '09;00', to: '17:00'},{day: 'Thursday', from: '09;00', to: '17:00'},{day: 'Friday', from: '09;00', to: '17:00'}]
                            }}
                            cost={this.state.newCost}
                            service={this.props.service}
                            props={this.props}
                            history={this.props.history}
                            onSubmit={this.onBook}
                            showForm={this.state.showForm}/>

                        {/* info tab view */}
                        <div hidden={!(this.state.tab === 'info')}>
                            <h4 className="font-weight-light mt-4 mb-4 title-2">{this.props.service.info}</h4>
                            <hr/>

                            <div  style={{border: '1px solid gainsboro'}} className="mb-3">
                                <div className={this.props.service.location ? "row ml-3 hover-orange hover-orange-image" : "row ml-3"} onClick={this.onMap}>
                                    <img src="/images/pin.png" className="mt-4 img" style={{maxWidth:20, maxHeight:20}}/>
                                    {this.props.service.address ? (
                                        <h4 className={this.props.service.location ? "font-weight-light mt-3 mb-3 ml-3 link-base hover-orange pt-2 pb-2" : "font-weight-light mt-3 mb-3 ml-3 pt-2 pb-2"}>{this.props.service.address}{this.props.service.address2 ? ', ' + this.props.service.address2 : ''}{this.props.service.suburb ? ', ' + this.props.service.suburb : ''}<span className="address-mobile">, {this.props.service.city}</span></h4>
                                    ):(
                                        <h4 className={this.props.service.location ? "font-weight-light mt-3 mb-3 ml-3 link-base hover-orange pt-2 pb-2" : "font-weight-light mt-3 mb-3 ml-3 pt-2 pb-2"}>{this.props.service.city}</h4>
                                    )}
                                </div>
                            </div>

                            {this.state.onMap && this.props.service.location ? (
                                <div className="row pt-5 pb-3 d-flex justify-content-center">
                                    <Map
                                        id="myMap"
                                        options={{
                                            center: { lat: this.props.service.location ? this.props.service.location.lat : 0, lng: this.props.service.location ? this.props.service.location.long : 0 },
                                            zoom: 16
                                        }}
                                        onMapLoad={((map) => {
                                            var marker = new window.google.maps.Marker({
                                                position: { lat: this.props.service.location ? this.props.service.location.lat : 0, lng: this.props.service.location ? this.props.service.location.long : 0 },
                                                map: map,
                                                title: ''
                                            });
                                        })}
                                    />
                                </div>
                            ):(
                                <div></div>
                            )}

                            <div className="mb-3" style={{border: '1px solid gainsboro'}} onClick={this.onHours}>
                                <div className="row ml-3 hover-orange hover-orange-image">
                                    <img src="/images/timer.png" className="mt-4 img" style={{maxBlockSize: 23}}/>
                                    <h4 className="font-weight-light mt-2 mb-2 link-base p-3 pl-1">Operating Hours</h4>
                                </div>
                                <div hidden={!this.state.onHours} style={{color: 'gray'}}>
                                    <OperatingHours
                                        operatingHrs={this.props.service.operatingHours ? this.props.service.operatingHours : ''}/>
                                </div>
                            </div>

                            {/* contact details - removed by request */}
                            {/* <div className="mb-5" style={{border: '1px solid gainsboro'}} onClick={this.onContact}>
                                <div className="row ml-3 hover-orange hover-orange-image">
                                    <img src="/images/phone.png" className="mt-4 img" style={{maxBlockSize: 23}}/>
                                    <h4 className="font-weight-light mt-2 mb-2 link-base p-3 pl-1">Contact</h4>
                                </div>
                                <div hidden={!this.state.onContact} style={{color: 'gray'}}>
                                    <hr/>
                                    <div className="row ml-3">
                                        <img src="/images/phone.png" className="mt-3" style={{maxWidth:20, maxHeight:20}} />
                                        <h4 className="font-weight-light mt-2 mb-1 ml-1">{this.props.service.number}</h4>
                                    </div>
                                    <div className="row ml-3">
                                        <img src="/images/mail.png" className="mt-3" style={{maxWidth:20, maxHeight:20}} />
                                        <h4 className="font-weight-light mt-3 mb-3 ml-1">{this.props.service.email}</h4>
                                    </div>
                                </div>
                            </div> */}

                            {this.props.edit ? (

                                <NavLink className="no-underline mb-5 pb-5" to="/edit" onClick={() => scroll.scrollTo(0, { duration: 750, delay: 10 })}>
                                    <div className="d-flex justify-content-center mb-5 btn">
                                        <h4 className="font-weight-light p-2 pt-3">Edit Info</h4>
                                    </div>
                                </NavLink>

                            ) : (

                                <div>
                                    <div hidden={!this.props.loggedIn}>
                                        <div className="d-flex justify-content-center mb-5">
                                            <div className="col p-0">
                                                <div className="d-flex justify-content-center mb-3">
                                                    <button className="btn btn-block" onClick={this.onBook}><h4 className="font-weight-light p-2 pt-3">Make a Booking</h4></button>
                                                </div>
                                                <div className="d-flex justify-content-center mb-3">
                                                    <button className="btn btn-block" onClick={this.onQuote}><h4 className="font-weight-light p-2 pt-3">Request a Quote</h4></button>
                                                </div>
                                                <div className="d-flex justify-content-center mb-5 btn" onClick={this.onMessage}>
                                                    <h4 className="font-weight-light p-2 pt-3">Contact {this.props.service.name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div hidden={this.props.loggedIn}>
                                        <div className="d-flex justify-content-center mb-5">
                                            <div className="col p-0">
                                                <div className="d-flex justify-content-center mb-5 btn btn-main" onClick={this.onRegister}>
                                                    <h4 className="font-weight-light p-2 pt-3">Register to Book</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        {/* services tab view */}
                        <div hidden={!(this.state.tab === 'services')}>
                            <div>
                                <ItemList
                                    props={this.props.props}
                                    type={this.props.type}
                                    items={this.props.service ? this.props.service.serviceItems : {}}
                                    edit={this.props.edit}/>
                            </div>

                            {this.props.edit ? (

                                <div></div>

                            ) : (

                                <div>
                                    <div hidden={!this.props.loggedIn}>
                                        <div className="d-flex justify-content-center mb-5">
                                            <div className="col p-0">
                                                <div className="d-flex justify-content-center mb-3">
                                                    <button className="btn btn-block" onClick={this.onBook}><h4 className="font-weight-light p-2 pt-3">Make a Booking</h4></button>
                                                </div>
                                                <div className="d-flex justify-content-center mb-3">
                                                    <button className="btn btn-block" onClick={this.onQuote}><h4 className="font-weight-light p-2 pt-3">Request a Quote</h4></button>
                                                </div>
                                                <div className="d-flex justify-content-center mb-5 btn" onClick={this.onMessage}>
                                                    <h4 className="font-weight-light p-2 pt-3">Contact {this.props.service.name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div hidden={this.props.loggedIn}>
                                        <div className="d-flex justify-content-center mb-5">
                                            <div className="col p-0">
                                                <div className="d-flex justify-content-center mb-5 btn btn-main" onClick={this.onRegister}>
                                                    <h4 className="font-weight-light p-2 pt-3">Register to Book</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* gallery tab view */}
                        <Gallery
                            show={this.state.tab === 'gallery'}
                            gallery={this.state.gallery}
                            edit={this.props.edit}
                            onImageRemove={this.onImageRemove}
                            onImageChange={this.onImageChange}/>

                        {/* review tab view */}
                        <Reviews
                            reviews={this.props.service.reviews}
                            show={this.state.tab === 'reviews'}/>

                        {this.props.loggedIn ?

                            <div>
                                <div>
                                    <MessageForm
                                        seller={false}
                                        type={'message'}
                                        toUserID={this.props.service.sellerID}
                                        userName={this.props.profile.name}
                                        toUserName={this.props.service.name}
                                        userID={this.props.profile.id}
                                        onServiceClick={this.state.onMessageForm}
                                        onCancel={this.onCancel}/>
                                </div>
                                <div>
                                    <QuoteForm
                                        bookings={getServiceBookings(this.state.allBookings,this.props.service.id)}
                                        toUserID={this.props.service.sellerID}
                                        service={this.props.service}
                                        availability={this.props.service.availability != undefined ? this.props.service.availability : {
                                            fromDate: moment(),
                                            toDate: moment().add(6,'months'),
                                            notice: '2 hours',
                                            buffer: '15 minutes',
                                            increments: '15 minutes',
                                            times: [{day: 'Monday', from: '09;00', to: '17:00'},{day: 'Tuesday', from: '09;00', to: '17:00'},{day: 'Wednesday', from: '09;00', to: '17:00'},{day: 'Thursday', from: '09;00', to: '17:00'},{day: 'Friday', from: '09;00', to: '17:00'}]
                                        }}
                                        type={'quote'}
                                        userName={this.props.profile.name}
                                        toUserName={this.props.service.name}
                                        userID={this.props.profile.id}
                                        onServiceClick={this.state.onQuoteForm}
                                        onCancel={this.onCancel}/>
                                </div>
                            </div>
                        : ''}

                        {this.props.type === 'seller' ? (
                            <div className="mt-5">
                            <div onClick={this.onCopy} className="category-link-border share-link mt-5 p-3">
                                <img src="/images/share2.png" className="img float-left mr-3" style={{maxBlockSize: 20}}/>
                                {this.state.copyMessage ? <div><h3 className="text-left display-5 m-0" style={{color: '#ec9422'}}>Link Copied to Clipboard</h3></div> : <div><h3 className="text-left font-weight-light m-0">Share your Service Profile</h3></div> }
                            </div>
                            </div>
                        ) : ('')}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile[0] ? state.profile[0] : 'none',
    type: state.profile[0] ? state.profile[0].type : '',
    allBookings: sortBookings(state.allBookings)
})

const mapDispatchToProps = (dispatch) => ({
    startEditService: (id, service) => dispatch(startEditService(id, service)),
    addRoute: (route) => dispatch(addRoute(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProfile);