import React from 'react';
import {connect} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import uuid from 'uuid';
import {animateScroll as scroll} from 'react-scroll';
import BookingForm from './BookingForm';
import {startAddBooking, startSetAllBookings} from '../../actions/bookings';
import {sortBookings, getServiceBookings} from '../../selectors/bookings';
import {postData} from '../../utils/payment';

/**
 * component for adding a new booking for buyers
 * rendered by the ServiceProfile component
 */
export class AddBooking extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            showAlert: false,
            showAlert2: false,
            showForm: this.props.showForm ? this.props.showForm : false,
            booking: {},
            bookingID: '',
            service: this.props.service,
            allBookings: this.props.allBookings ? this.props.allBookings : [],
            newCost: this.props.cost ? this.props.cost : undefined
        }
    }

    /**
     * method to get all bookings upon mounting
     */
    componentDidMount = () => {
        this.props.startSetAllBookings()
    }

    /**
     * updates state upone receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.showForm != this.state.showForm) {
            this.setState(() => ({ showForm: newProps.showForm}))
        }
        if (newProps.allBookings != this.state.allBookings) {
            this.setState(() => ({ allBookings: newProps.allBookings }))
        }
        if (newProps.cost != this.state.newCost) {
            this.setState(() => ({ newCost: newProps.cost }))
        }
    }

    /**
     * method for submitting a new booking
     * updates state and calls action to add a new booking
     */
    onSubmit = (booking) => {
        
        this.props.onSubmit();
        this.setState(() => ({ showForm: false }));
        const bookingID = uuid();
        const newBooking = {
            buyerID: this.props.id,
            serviceID: this.props.service.id,
            service: this.props.service.name,
            bookingID,
            paid: false,
            rated: false,
            ...booking
        }

        this.setState(() => ({ 
            showAlert: true,
            bookingID,
            booking: newBooking
        }));
        
        this.props.startAddBooking(newBooking);

        // FOR ROGERS
        // invoice email here
        // all details are found in 'newBooking' object
    }

    /**
     * method to update state to close popup and calls onSubmit method in ServiceProfile
     */
    onCancel = () => {
        if (this.state.showForm) {
            this.props.onSubmit();
        }
        this.setState(() => ({ showAlert2: false }));
    }

    /**
     * method to update state to close popups
     * and navigate user to bookings page
     */
    onClose = () => {
        this.props.history.push('/bookings')
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        this.setState(() => ({ 
            showAlert: false,
            showAlert2: false 
        }));
    }

    /**
     * method for paying for a booking
     */
    onPay = () => {

        postData({
            merchant_id: "12666206",
            merchant_key: "ny9qkip357kf3",
            return_url: "https://itorho.com/bookings",
            cancel_url: "https://itorho.com/bookings",
            notify_url: "api/response",
            name_first: ((this.state.booking.title).split(" "))[0] || null,
            name_last: ((this.state.booking.title).split(" "))[this.state.booking.title.split(" ").length-1]||null,
            email_address: "info@itorho.com" ||this.props.profile.email || null,
            m_payment_id: this.state.bookingID,
            amount : this.state.booking.deposit ? this.state.booking.deposit : this.state.booking.cost,
            item_name : this.state.booking.item,
            email_confirmation: 1,
            payment_method: "cc",
            signature: null
        }, this.state.booking)
    }

    render() {
        return (

            <div>

                {/* pop up for viewing booking details */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showForm}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Back"
                        confirmBtnBsStyle="link"
                        title={`Make a Booking`}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <BookingForm
                            availability={this.props.availability}
                            cost={this.state.newCost}
                            bookings={getServiceBookings(this.state.allBookings,this.state.service.id)}
                            service={this.props.service}
                            edit={false}
                            onSubmit={this.onSubmit}/>
                    </SweetAlert>
                </div>

                {/* booking confirmation popup */}
                <div className="mt-5" hidden={!this.state.showAlert}>
                    <SweetAlert
                        showCancel
                        confirmBtnText="Pay Now"
                        cancelBtnText="Pay Cash or Pay Later"
                        confirmBtnBsStyle="default"
                        title="Booking Comfirmed"
                        closeOnClickOutside={false}
                        focusConfirmBtn={false}
                        onConfirm={this.onPay}
                        onCancel={this.onClose}>
                        <div>
                            <h4 className="font-weight-light mt-4">Thank you for your booking.</h4>
                            <h5 className="font-weight-light mb-1">We look forward to seeing you.</h5>
                            <div className="d-flex justify-content-center pt-2">
                                <h5 className="font-weight-light mb-3 col-7" style={{color: '#ec9422'}}>{this.state.booking ? this.state.booking.info ? this.state.booking.info : '' : ''}</h5>
                            </div>
                        </div>
                    </SweetAlert>
                </div>

                {/* hidden payment form */}
                <form name="form1" action="https://www.payfast.co.za/eng/process" method="POST">
                    <input type="hidden" name="merchant_id" placeholder=""/>
                    <input type="hidden" name="merchant_key" placeholder=""/>
                    <input type="hidden" name="return_url" placeholder=""/>
                    <input type="hidden" name="cancel_url" placeholder=""/>
                    <input type="hidden" name="notify_url" placeholder="default"/>
                    <input type="hidden" name="name_first" placeholder="10000100"/>
                    <input type="hidden" name="name_last" placeholder="46f0cd694581a"/>
                    <input type="hidden" name="email_address" placeholder="default"/>
                    <input type="hidden" name="m_payment_id" placeholder="default"/>
                    <input type="hidden" name="amount" placeholder="default"/>
                    <input type="hidden" name="item_name" placeholder="item_name"/>
                    <input type="hidden" name="item_description" placeholder="default"/>
                    <input type="hidden" name="email_confirmation" placeholder="default"/>
                    <input type="hidden" name="payment_method" placeholder="default"/>
                    <input type="hidden" name="signature" placeholder="default"/>
                </form>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allBookings: sortBookings(state.allBookings),
    id: state.profile[0] ? state.profile[0].id : ''
});

const mapDispatchToProps = (dispatch) => ({
    startAddBooking: (booking) => dispatch(startAddBooking(booking)),
    startSetAllBookings: () => dispatch(startSetAllBookings())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBooking);