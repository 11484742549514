import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import {animateScroll as scroll} from 'react-scroll';
import axios from 'axios';
import Progress from '../Progress';
import Crop from './Crop';

/**
 * service form is used to input service profile details
 * rendered by dashboard page (on first sign in), 
 * edit service page and admin/sellers page (superuser)
 */
export class ServiceForm extends React.Component {

    constructor(props) {
        super(props);

        this.onImageChange = this.onImageChange.bind(this);
        
        this.state = {

            id: props.service ? props.service.id : '',
            sellerID: props.service ? props.service.sellerID ? props.service.sellerID : this.props.id : this.props.id,
            name: props.service ? props.service.name : '',
            categories: props.service ? props.service.categories ? props.service.categories : {} : {},
            info: props.service ? props.service.info : '',
            number: props.service ? props.service.number : '',
            email: props.service ? props.service.email : '',
            address: props.service ? props.service.address : '',
            address2: props.service ? props.service.address2 : '',
            city: props.service ? props.service.city : '',
            suburb: props.service ? props.service.suburb : '',
            postal: props.service ? props.service.postal : '',
            province: props.service ? props.service.province : '',
            provinceSelect: false,
            operatingHours: props.service ? props.service.operatingHours : {},
            serviceItems: props.service ? props.service.serviceItems : {},
            image: props.service ? props.service.image ? props.service.image : '' : '',
            location: props.service ? props.service.location : {},
            rating: props.service ? props.service.rating : 0,
            gallery: props.service ? props.service.gallery ? props.service.gallery.length != 0 ? props.service.gallery : '' : '' : '',
            crop: {},
            subscription: props.service ? props.service.subscription : true,

            categoryOptions: this.props.categories ? this.props.categories : [],
            companyNames: this.props.companyNames ? this.props.companyNames : [],
            
            nameError: '',
            emailError: '',
            infoError: '',
            categoriesError: '',
            numberError: '',
            cityError: '',
            imageError: '',
            formError: '',
            
            submitting: false,
            locationChange: false,
            imageLoading: false,

            selectedCategories: null,
            times: ['closed','1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00']
        }
    }

    /**
     * receives crop parameters from Crop component and updates to state
     */
    onCrop = (crop) => {
        this.setState(() => ({ crop }))
    }

    /**
     * handles uploading of image to firebase storage
     */
    onImageChange(event) {
        const image = event.target.files[0]
        if (image.size < 2097152) {
            this.setState(() => ({ imageLoading: true }));
            this.setState(() => ({ imageError: ''}))
            const storageRef = firebase.storage().ref();
            const mainImage = storageRef.child(image.name);
            mainImage.put(image).then((snapshot) => {
                mainImage.getDownloadURL().then((url) => {
                    this.setState(() => ({ image: url}))
                    this.setState(() => ({ imageLoading: false }))
                })
            })
        } else {
            this.setState(() => ({ 
                imageLoading: false,
                imageError: 'Please choose a smaller image.'
            }))
        }
    }

    /**
     * calls onCancel method in EditServicePage
     */
    onCancel = (e) => {
        e.preventDefault();
        this.props.onCancel();
    }

    /**
     * methods to update state based on form inputs
     */
    onNameChange = (e) => {
        const name = e.target.value;
        if (this.state.companyNames.includes(name)) { this.setState(() => ({ nameError: 'This name is already in use.' })) }
        else { this.setState(() => ({ nameError: '' })) }
        this.setState(() => ({ name }));
    }
    onInfoChange = (e) => {
      const info = e.target.value;
      this.setState(() => ({ info }));
    }
    onNumberChange = (e) => {
        const number = e.target.value;
        this.setState(() => ({ number }));
    }
    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
    }
    onAddressChange = (e) => {
        const address = e.target.value;
        this.setState(() => ({ address }));
        this.setState(() => ({ locationChange: true }));
    }
    onAddress2Change = (e) => {
        const address2 = e.target.value;
        this.setState(() => ({ address2 }));
        this.setState(() => ({ locationChange: true }));
    }
    onCityChange = (e) => {
        const city = e.target.value;
        this.setState(() => ({ city }));
        this.setState(() => ({ locationChange: true }));
    }
    onSuburbChange = (e) => {
        const suburb = e.target.value;
        this.setState(() => ({ suburb }));
        this.setState(() => ({ locationChange: true }));
    }
    onPostalChange = (e) => {
        const postal = e.target.value;
        this.setState(() => ({ postal }));
        this.setState(() => ({ locationChange: true }));
    }
    onProvinceChange = (e) => {
        const province = e.target.value;
        this.setState(() => ({ province }));
        this.setState(() => ({ locationChange: true }));
        this.setState(() => ({ provinceSelect: true }));
    }
    onCategoryChange = (selectedCategories) => {
        this.setState(() => ({ categories: selectedCategories }));
    }

    /**
     * performs an error check on form input data
     * if valid, calls onSubmit method in EditServicePage
     */
    onSubmit = async (e) => {
        e.preventDefault();

        // prevent submit unless all details filled in
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!this.state.name) { this.setState(() => ({ nameError: 'Please enter a name.'})) }
        else { this.setState(() => ({ nameError: '' })) }
        if (!this.state.info) { this.setState(() => ({ infoError: 'Please provide information on your service.'})) }
        else { this.setState(() => ({ infoError: '' })) }
        if (!this.state.number) { this.setState(() => ({ numberError: 'Please provide a number.'})) }
        else { this.setState(() => ({ numberError: '' })) }
        if(!regex.test(this.state.email)) { this.setState(() => ({ emailError: 'Please enter a valid email address.'})) }
        else { this.setState(() => ({ emailError: '' })) }
        if (Object.keys(this.state.categories).length == 0) { this.setState(() => ({ categoriesError: 'Please select a category.'})) }
        else { this.setState(() => ({ categoriesError: '' })) }
        if (!this.state.city) { this.setState(() => ({ cityError: 'Please list your city.'})) }
        else { this.setState(() => ({ cityError: '' })) }
        if (!this.state.image) { this.setState(() => ({ imageError: 'Please add an image.'})) }
        else { this.setState(() => ({ imageError: '' })) }

        if (this.state.name && this.state.number && this.state.info && (Object.keys(this.state.categories).length != 0) && this.state.email && this.state.city && this.state.image) {

            this.setState(() => ({ formError: '' }))
            this.setState(() => ({ submitting: true }))
            scroll.scrollTo(0, { duration: 750, delay: 10 });

            // getting gps location
            if ((this.state.locationChange || !this.props.edit) && !this.props.admin) {
                var add1 = this.state.address;
                var add2 = this.state.address2;
                var add3 = this.state.suburb;
                var add4 = this.state.postal;
                var city = this.state.city;
                var location = [];

                axios('https://strawberry-tart-77589.herokuapp.com/sellLocation',{
                    method: 'post',
                    data: [add1, add2, add3, add4, city],
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json"
                    }
                }).then(response => {
                    location = response.data;
                    this.props.onSubmit(this.state.id,{
                        sellerID: this.state.sellerID,
                        name: this.state.name,
                        info: this.state.info,
                        number: this.state.number,
                        email: this.state.email,
                        address: this.state.address,
                        address2: this.state.address2,
                        city: this.state.city,
                        suburb: this.state.suburb,
                        postal: this.state.postal,
                        province: this.state.province,
                        location: location,
                        categories: this.state.categories,
                        rating: this.state.rating,
                        image: this.state.image,
                        gallery: this.state.gallery,
                        operatingHours: {
                            dFrom: '9',
                            dTo: '5',
                            wFrom: 'closed',
                            wTo: 'closed' 
                        },
                        disabled: false,
                        crop: this.state.crop ? this.state.crop : {},
                        subscription: this.state.subscription
                    });
                }).catch(error => {
                    console.log(error);
                })
            } else {
                this.props.onSubmit(this.state.id,{
                    sellerID: this.state.sellerID,
                    name: this.state.name,
                    info: this.state.info,
                    number: this.state.number,
                    email: this.state.email,
                    address: this.state.address,
                    address2: this.state.address2,
                    city: this.state.city,
                    suburb: this.state.suburb,
                    postal: this.state.postal,
                    province: this.state.province,
                    location: this.state.location,
                    categories: this.state.categories,
                    rating: this.state.rating,
                    image: this.state.image,
                    gallery: this.state.gallery,
                    operatingHours: {
                        dFrom: '9',
                        dTo: '5',
                        wFrom: 'closed',
                        wTo: 'closed' 
                    },
                    disabled: false,
                    crop: this.state.crop ? this.state.crop : {},
                    subscription: this.state.subscription
                });
            }
        } else {
            this.setState(() => ({ formError: 'Please fill out all required fields.' }))
        }
    }

    render() {
       
        let { selectedCategories } = this.props.categories;

        return (

            <div>
                <div>
                    {!this.props.edit  && !this.props.admin ?
                        !this.state.submitting ? 
                            <div>
                            <Progress step={1}/>

                            <div className="d-flex justify-content-center pt-md-5 pt-4">
                                <div className="col-md-7 col-11 ml-md-5 pl-md-5">
                                <h1 className="m-md-5 m-md-0 mt-md-5 title welcome-subtitle" style={{fontSize: '50px', fontWeight: 'bold'}}>Step 1</h1>
                                <h1 className="font-weight-light m-md-5 m-md-3 title-2" style={{fontSize: '30px'}}>Complete your Service Profile to get started</h1>
                                </div>
                            </div>

                            </div>
                        : 
                            <div className="m-5 pt-5">
                                <h3 className="font-weight-light text-center m-sm-5 m-3 title-2" style={{fontSize: '30px'}}>Just a moment...</h3>
                            </div>
                        
                    :
                        !this.props.admin ?
                            <div>
                                <div onClick={this.onCancel} className="link float-left ml-5">
                                    <img src="/images/back.png" className="img mx-auto" style={{maxWidth:20, maxHeight:20}}/>
                                </div>
                                <h3 className="font-weight-light text-center m-5">Edit Service Profile</h3>
                            </div>
                        :
                            <div></div>
                    }
                </div>

                {this.state.submitting && !this.props.admin ?
                    <div className="container h-100">
                        <div className="row h-100 justify-content-center align-items-center">
                            <img src='/images/loader.gif' />
                        </div>
                    </div>
                 :
                    <div className="d-flex justify-content-center text-left">
                        <form onSubmit={this.onSubmit} className="col-sm-6 col-12">

                            <hr/>
                            <h5 className="font-weight-light text-center mt-5 mb-3">General Information</h5>

                            <div className="pb-4">
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Company Name"
                                    type="text"
                                    id="name"
                                    value={this.state.name}
                                    onChange={this.onNameChange}/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.nameError}</small>
                            </div>

                            <div className="pb-4">
                                <textarea
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    style={{minHeight: 100}}
                                    placeholder="Info"
                                    type="text"
                                    id="info"
                                    value={this.state.info}
                                    onChange={this.onInfoChange}/>
                                {this.state.infoError ? (
                                    <small className="form-text" style={{color: '#ec9422'}}>{this.state.infoError}</small>
                                ) : (
                                    <small>Provide an general overview of what you offer.</small>
                                )}
                            </div>

                            <div className="mb-4" style={{position: 'relative', zIndex: 100}}>
                                <CreatableSelect
                                    placeholder="Category"
                                    className="rounded-0"
                                    isMulti
                                    defaultValue={ Object.entries(this.state.categories).length === 0 ? null : this.state.categories}
                                    onChange={this.onCategoryChange}
                                    value={selectedCategories}
                                    options={this.state.categoryOptions} />
                                {this.state.categoriesError ? (
                                    <small className="form-text" style={{color: '#ec9422'}}>{this.state.categoriesError}</small>
                                ) : (
                                    <small>Select one or more categories.</small>
                                )}
                            </div>
                            {this.state.imageLoading ? (
                                <div style={{maxBlockSize: 200}} className="mb-5 pb-5">
                                    <h4 className="font-weight-light mt-5">Loading Image...</h4>
                                    <div className="row h-100 justify-content-center align-items-center">
                                        <img src='/images/loader.gif' />
                                    </div>
                                </div>
                            ) : (
                                <div style={{zIndex: 0}}>
                                    {this.state.image ?
                                        <div className="mt-5 mb-2">
                                            <Crop 
                                                image={this.state.image} 
                                                onCrop={this.onCrop}
                                            />
                                        </div>
                                    :
                                        <div></div>
                                    }
                                    <div className="pb-5">
                                        <div className="input-group">
                                            <div className="custom-file rounded-0">
                                                <input 
                                                    type="file" 
                                                    className="image-input"
                                                    id="picture"
                                                    onChange={this.onImageChange}
                                                    name="picture"
                                                    accept="image/*"/>
                                                <label 
                                                    className="custom-file-label" 
                                                    htmlFor="picture">Select Image
                                                </label>
                                            </div>
                                        </div>
                                        <small className="font-weight-light text-muted mb-1">Upload may take up to 30 seconds to display...</small>
                                        <small className="form-text" style={{color: '#ec9422'}}>{this.state.imageError}</small>
                                        </div>
                                    </div>
                            )}

                            <hr/>
                            <h5 className="font-weight-light text-center mt-5 mb-3">Contact Details</h5>

                            <div className="pb-4">
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Contact Number"
                                    type="text"
                                    id="number"
                                    value={this.state.number}
                                    onChange={this.onNumberChange}/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.numberError}</small>
                            </div>
                            <div className="pb-4">
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Email"
                                    type="email"
                                    id="email"
                                    value={this.state.email}
                                    onChange={this.onEmailChange}/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.emailError}</small>
                            </div>

                            <hr/>
                            <h5 className="font-weight-light text-center mt-5 mb-3">Location Details</h5>

                            <div className="pb-4">
                                <input
                                    type="text"
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Address"
                                    id="inputAddress"
                                    placeholder="Street Address"
                                    value={this.state.address}
                                    onChange={this.onAddressChange}/>
                            </div>
                            <div className="pb-4">
                                <input
                                    type="text"
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    id="inputAddress2"
                                    placeholder="Apartment, studio, or floor"
                                    value={this.state.address2}
                                    onChange={this.onAddress2Change}/>
                            </div>

                            <div className="pb-4">
                                <input
                                    type="text"
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Suburb"
                                    id="inputSuburb"
                                    value={this.state.suburb}
                                    onChange={this.onSuburbChange}/>
                            </div>
                            <div className="pb-4">
                                <input
                                    type="text"
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="Postal Code"
                                    id="inputPostal"
                                    value={this.state.postal}
                                    onChange={this.onPostalChange}/>
                            </div>
                            <div className="pb-4">
                                <input
                                    type="text"
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder="City"
                                    id="inputCity"
                                    value={this.state.city}
                                    onChange={this.onCityChange}/>
                                <small className="form-text" style={{color: '#ec9422'}}>{this.state.cityError}</small>
                            </div>
                            <div className="pb-4">
                                <select
                                    id="province"
                                    className="custom-select font-weight-light rounded-0 custom-select-lg"
                                    value={this.state.province}
                                    onChange={this.onProvinceChange}>
                                    <option disabled={this.state.provinceSelect}>Select Province</option>
                                    <option>Western Cape</option>
                                    <option>Gauteng</option>
                                    <option>KwaZulu Natal</option>
                                    <option>Eastern Cape</option>
                                    <option>Mpumalanga</option>
                                    <option>North West Province</option>
                                    <option>Limpopo</option>
                                    <option>Free State</option>
                                    <option>Northern Cape</option>
                                </select>
                            </div>
                            <hr/>
                            <h4 className="mb-3 mb-2 font-weight-light" style={{color: '#ec9422'}}>{this.state.formError}</h4>
                            <div className="d-flex justify-content-center mt-5 mb-5 pb-5">
                                <button className="rounded-0 btn btn-block" style={{height: 37}}>
                                    <h4 className="font-weight-light">Save</h4>
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        );
    };
}

export default ServiceForm;