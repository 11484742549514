import React from 'react';
import {getTimes} from '../../utils/availability';

/**
 * component to display available hours selector
 * 
 * rendered by AvailabilityForm
 */
export class Hours extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
            show: this.props.show,
            index: this.props.index,

            day: this.props.times != undefined ? this.props.times.day : 'Monday',
            from: this.props.times != undefined ? this.props.times.from : '',
            to: this.props.times != undefined ? this.props.times.to : '',

            days: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
            times: ['','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00']
        }
    }

    /**
     * updates state upon receiving new props
     */
    componentWillReceiveProps = (newProps) => {
        if (this.state.show != newProps.show) {
            this.setState(() => ({ show: newProps.show }))
        }
        if (newProps.times != undefined && (this.state.day != newProps.times.day || this.state.from != newProps.times.from || this.state.to != newProps.times.to)) {
            this.setState(() => ({
                day: newProps.times.day,
                from: newProps.times.from,
                to: newProps.times.to
            }))
        }
    }

    /**
     * updates state when day is changed
     */
    onDayChange = (e) => {
        let day = e.target.value
        this.setState(() => ({ day }))
        this.props.onSaveTimes(this.state.index, day, this.state.from, this.state.to)
    }

    /**
     * updates state when from is changed
     */
    onFromChange = (e) => {
        let from = e.target.value
        this.setState(() => ({ from }))
        if (this.state.to === "") {
            let to = (parseInt(from) + 1).toString()
            this.setState(() => ({ to }))
            this.props.onSaveTimes(this.state.index, this.state.day, from, to)
        } else {
            this.props.onSaveTimes(this.state.index, this.state.day, from, this.state.to)
        }
    }

    /**
     * updates state when to is changed
     */
    onToChange = (e) => {
        let to = e.target.value
        this.setState(() => ({ to }))
        this.props.onSaveTimes(this.state.index, this.state.day,this.state.from, to)
    }

    /**
     * calls onAdd method in AvailabilityForm
     */
    onAdd = () => {
        this.props.onAdd(this.state.index)
    }

    /**
     * calls onRemove method in AvailabilityForm
     */
    onRemove = () => {
        this.props.onRemove(this.state.index)
    }

    render() {
        return (

            <div>
                {this.state.show ?
                    <div className="form-row">
                        <div className="form-group col">
                            <label>Day</label>
                            <select
                                disabled={!this.props.edit}
                                className="custom-select font-weight-light rounded-0 custom-select-md"
                                value={this.state.day}
                                onChange={this.onDayChange}>
                                {this.state.days.map((d, index) => {
                                    return (<option key={index}>{d}</option>)
                                })}
                            </select>
                        </div>
                        <div className="form-group col">
                            <label>From</label>
                            <select
                                disabled={!this.props.edit}
                                className="custom-select font-weight-light rounded-0 custom-select-md"
                                value={this.state.from}
                                onChange={this.onFromChange}>
                                {this.state.times.map((t,index) => {
                                    return (<option key={index}>{t}</option>)
                                })}
                            </select>
                        </div>
                        <div className="form-group col" hidden={this.state.from == 'closed'}>
                        <label>To</label>
                            <select
                                disabled={!this.props.edit}
                                className="custom-select font-weight-light rounded-0 custom-select-md"
                                value={this.state.to}
                                onChange={this.onToChange}>
                                {getTimes(this.state.times, this.state.from).map((t,index) => {
                                    return (<option key={index}>{t}</option>)
                                })}
                            </select>
                        </div>
                        <div hidden={!this.props.edit} onClick={this.onRemove} className="col link pt-4">
                            <img src="/images/minus.png" className="img" style={{maxWidth:30, maxHeight:30}}/>
                        </div>
                    </div>
                :
                    this.state.show != undefined ?
                        <div hidden={!this.props.edit} onClick={this.onAdd} className="row ml-2 mt-3 mb-5">
                            <div className="link">
                                <img src="/images/plus.png" className="img" style={{maxWidth:30, maxHeight:30}}/>
                            </div>
                            <div className="mt-2 ml-2">
                                <h4 className="font-weight-light text-left">Add Hours</h4>
                            </div>
                        </div>
                    :
                    <div></div>
                }
            </div>
        )
    }
}

export default Hours;