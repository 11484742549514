import React from 'react';
import ItemList from '../Service/ItemList';
import Map from '../Service/Map';
import Gallery from '../Service/Gallery';
import Reviews from '../Service/Reviews';
import OperatingHours from '../Service/OperatingHours';
import Image from '../Service/Image';

/**
 * service profile view for admin page
 */
export class ServiceProfile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showForm: false,
            onMap: false,
            onHours: false,
            onTab: 'services',
            gallery: this.props.service ? this.props.service.gallery ? this.props.service.gallery : [] : [],
            showGallery: false
        }
    }

    /**
     * updates state to selected tab view
     */
    onTab = (tab) => {
        this.setState(() => ({ onTab: tab }))
    }

    /**
     * updates state to show gallery and to selected image index
     */
    onImage = (index) => {
        this.setState(() => ({ 
            showGallery: true,
            index 
        }));
    }

    /**
     * updates state to hide gallery
     */
    closeGallery = () => {
        this.setState(() => ({ showGallery: false }));
    }

    /**
     * updates state to toggle map display
     */
    onMap = () => {
        const onMap = !this.state.onMap;
        this.setState(() => ({ onMap }))
    }

    /**
     * updates state to toggle hours display
     */
    onHours = () => {
        const onHours = !this.state.onHours;
        this.setState(() => ({ onHours }))
    }

    render() {
        return (

            <div>
                <div className="d-flex justify-content-center p-0">
                    <div className="col-10 p-0">

                        {/* service image */}
                        <div className="d-flex justify-content-center mt-5 pl-3 pr-3">
                            <Image image={this.props.service.image ? this.props.service.image : ''} show={true} size={1} crop={this.props.service.crop ? this.props.service.crop : undefined}/>
                        </div>

                        {/* tab view options */}
                        <div className="row text-center mt-5">
                            <div className={this.state.onTab === 'services' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTab('services'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Services</h4>
                            </div>
                            <div className={this.state.onTab === 'info' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTab('info'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Info</h4>
                            </div>
                            <div className={this.state.onTab === 'gallery' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTab('gallery'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Gallery</h4>
                            </div>
                            <div className={this.state.onTab === 'reviews' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTab('reviews'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5 welcome-subtitle2">Reviews</h4>
                            </div>
                        </div>

                        {/* info tab view */}
                        <div className="p-0" hidden={!(this.state.onTab === 'info')}>
                            <h4 className="font-weight-light mt-4 mb-4 title-2">{this.props.service.info}</h4>
                            <hr/>
                            
                            <div  style={{border: '1px solid gainsboro'}} className="mb-3">
                                <div className="row ml-3 hover-orange hover-orange-image" onClick={this.onMap}>
                                    {this.props.service.address ?
                                        <h4 className="text-left font-weight-light mt-3 mb-3 ml-3 link-base hover-orange pt-2 pb-2">{this.props.service.address}{this.props.service.address2 ? ', ' + this.props.service.address2 : ''}{this.props.service.suburb ? ', ' + this.props.service.suburb : ''}<span className="address-mobile">, {this.props.service.city}</span></h4>
                                    :
                                        <h4 className="text-left font-weight-light mt-3 mb-3 ml-3 link-base hover-orange pt-2 pb-2">{this.props.service.city}</h4>
                                    }
                                </div>
                            </div>

                            {this.state.onMap && this.props.service.location ?
                                <div className="row pt-5 pb-3 d-flex justify-content-center">
                                    <Map
                                        id="myMap"
                                        options={{
                                            center: { lat: this.props.service.location ? this.props.service.location.lat : 0, lng: this.props.service.location ? this.props.service.location.long : 0 },
                                            zoom: 16
                                        }}
                                        onMapLoad={((map) => {
                                            var marker = new window.google.maps.Marker({
                                                position: { lat: this.props.service.location ? this.props.service.location.lat : 0, lng: this.props.service.location ? this.props.service.location.long : 0 },
                                                map: map,
                                                title: ''
                                            });
                                        })}
                                    />
                                </div>
                            :
                                <div></div>
                            }

                            <div className="mb-3" style={{border: '1px solid gainsboro'}} onClick={this.onHours}>
                                <div className="row ml-3 hover-orange hover-orange-image">
                                    <h4 className="font-weight-light mt-2 mb-2 link-base p-3 pl-1">Operating Hours</h4>
                                </div>
                                <div hidden={!this.state.onHours} style={{color: 'gray'}}>
                                    <OperatingHours
                                        operatingHrs={this.props.service.operatingHours ? this.props.service.operatingHours : ''}/>
                                </div>
                            </div>

                        </div>
                        
                        {/* services tab view */}
                        <div hidden={!(this.state.onTab === 'services')}>
                            <div>
                                <ItemList
                                    props={this.props.props}
                                    type={'seller'}
                                    items={this.props.service ? this.props.service.serviceItems : {}}
                                    edit={false}
                                />
                            </div>
                        </div>

                        {/* gallery tab view */}
                        <Gallery
                            show={this.state.onTab === 'gallery'}
                            gallery={this.state.gallery}
                            edit={false}
                        />

                        {/* reviews tab view */}
                        <Reviews
                            reviews={this.props.service.reviews}
                            show={this.state.onTab === 'reviews'}
                        />

                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceProfile;