import React from 'react';
import {connect} from 'react-redux';
import {setCategoryFilter, setNearFilter, setTextFilter} from '../../actions/filters';

/**
 * component to control the filtering of services based on the selected tab view (info or nearby)
 */
export class CategoryListFilters extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text ? this.props.text : '',
            onInfo: true,
            onNear: false
        }
    }

    /**
     * updates state upon mounting
     */
    componentDidMount = () => {
        this.setState(() => ({ 
            onInfo: true,
            onNear: false 
        }))
    }

    /**
     * updates state on text input
     * calls action to set text filter
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ text }));
        this.props.setTextFilter(text);
    }

    /**
     * updates state to reflect tab view click to info
     * calls action to turn off near filter
     */
    onInfoChange = () => {
        this.setState(() => ({ 
            onInfo: true,
            onNear: false 
        }))
        this.props.setNearFilter(false);
    }

    /**
     * updates state to reflect tab view click to nearby
     * calls action to turn on near filter and turn off category filter
     */
    onNearChange = () => {
        navigator.geolocation.getCurrentPosition((location) => {
        });
        this.setState(() => ({ 
            onInfo: false,
            onNear: true 
        }));
        this.props.setNearFilter(true);
        this.props.setCategoryFilter('');
    }

    render() {
        return (

            <div className="d-flex justify-content-center mt-5 mb-5 text-center">
                <div className={this.state.onInfo ? "col category-nav-active" : "col category-nav"} onClick={this.onInfoChange} style={{borderBottom: '0.2px solid gainsboro'}}>
                    <h4 className="display-5">Info</h4>
                </div>
                <div className={this.state.onNear ? "col category-nav-active" : "col category-nav"} onClick={this.onNearChange} style={{borderBottom: '0.2px solid gainsboro'}}>
                    <h4 className="display-5">Near You</h4>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    filters: state.filters,
    text: state.filters.text
});

const mapDispatchToProps = (dispatch) => ({
    setTextFilter: (text) => dispatch(setTextFilter(text)),
    setCategoryFilter: (category) => dispatch(setCategoryFilter(category)),
    setNearFilter: (near) => dispatch(setNearFilter(near))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListFilters);