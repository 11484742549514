import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import ProfileForm from './ProfileForm';

/**
 * edit profile pop up component used by settings page
 * uses profile form to edit profile
 */
export class EditProfile extends React.Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            showForm: this.props.showForm ? this.props.showForm : false
        }
    }

    /**
     * updates state upon receiving new props from SettingsPage
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.showForm != newProps.showForm) {
            this.setState(() => ({ showForm: newProps.showForm }));
        }
    }

    /**
     * calls onSubmit method in SettingsPage
     */
    onSubmit = (profile) => {
        this.props.onSubmit('profile', profile);
    }

    /**
     * calls onCancel method in SettingsPage
     */
    onCancel = () => {
        this.props.onSubmit('cancel');
    }

    render() {
        return (

            <div>

                {/* pop up for editing profile */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showForm}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Cancel"
                        confirmBtnBsStyle="link"
                        title={`Edit Profile`}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <ProfileForm
                            edit={true}
                            profile={this.props.profile}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}/>
                    </SweetAlert>
                </div>

            </div>
        );
    }
};

export default EditProfile;