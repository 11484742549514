import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import Inbox from './Chat/Inbox';
import {startLogout} from '../actions/profile';
import {startSetService, startSetAllServices} from '../actions/services';

/**
 * navigation bar component
 * 
 * renders navigation options depending on the user type and whether they are logged in or not
 */
export class TopNav extends React.Component {

    constructor(props) {
        super(props)
    }

    state = {
        type: this.props.type ? this.props.type : '',
        loggedIn: this.props.loggedIn,
        showMenu: false,
        inbox: false,

        unreadMessages: 0,
        messages: []
    }

    /**
     * method to update state upon mount
     * updates the number of unread messages in inbox
     */
    componentDidMount = () => {

        let unreadMessages = 0;
        let messages = this.props.messages;

        if (this.props.messages.length != 0) {
            this.props.messages.map((message) => {
                if ( (message.toUserID === this.props.profile.id && message.status === 'unread') || (message.userID === this.props.profile.id && message.status === "unread" && message.cost != undefined && this.props.profile.type === "buyer")) { unreadMessages += 1 }
            })
        }

        this.setState(() => ({ 
            unreadMessages,
            messages
        }))

        if (this.state.loggedIn &&  this.props.type == "seller") {
            this.props.startSetService(this.props.profile.id)
        }
    }

    /**
     * method to update state upon receiving new props
     * updates the number of unread messages in inbox
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        
        if (newProps.loggedIn !== this.state.loggedIn || newProps.messages != this.state.messages) {
            this.setState(() => ({ 
                loggedIn: newProps.loggedIn,
                messages: newProps.messages,
            }))

            let unreadMessages = 0;
            if (newProps.messages.length != 0) {
                newProps.messages.map((message) => {
                    if ( (message.toUserID === this.props.profile.id && message.status === 'unread') || (message.userID === this.props.profile.id && message.status === "unread" && message.cost != undefined && this.props.profile.type === "buyer")) { unreadMessages += 1 }
                })
            }

            this.setState(() => ({ unreadMessages }))
        }
    }

    /**
     * method to call logout action
     */
    onLogout = () => {
        if (this.props.type === 'seller') {
            this.props.startSetAllServices()
        }
        this.props.startLogout();
        this.props.props.history.push('/')
    }

    /**
     * method to toggle menu for mobile view
     */
    onMenu = () => {
        const showMenu = !this.state.showMenu
        this.setState(() => ({ showMenu }))
    }

    /**
     * method to update state to open inbox
     */
    onInbox = () => {
        this.setState(() => ({ inbox: true }))
    }

    /**
     * method to update state to close inbox
     */
    onCancel = () => {
        this.setState(() => ({ inbox: false }))
    }

    render() {
        return (
            
            <div style={{ width: '100%' }}>
                <div className="top-nav">
                    <div className="wrapper">

                        {/* itorho logo */}
                        <div className="m-4 left">
                            <NavLink className="ml-md-5 ml-2" to={"/dashboard"}>
                                <img src="/images/itorho-logo-only-3.png" className="img mr-2" style={{maxWidth:50, maxHeight:50}}/>
                                <img src="/images/itorho-beta.png" className="img" style={{maxBlockSize:30}}/>
                            </NavLink>
                        </div>

                        <div>
                            <Inbox
                                seller={this.props.type == 'seller' ? true : false}
                                userName={this.props.profile ? this.props.profile.name : ''}
                                userID={this.props.profile ? this.props.profile.id : ''}
                                onServiceClick={false}
                                props={this.props}
                                onCancel={this.onCancel}
                                open={this.state.inbox}/>
                        </div>

                            <div hidden={!this.state.loggedIn}>

                                <div hidden={!(this.props.type == "seller")}>

                                    {/* seller nav bar */}
                                    <div className="row mt-5 mr-5 pr-2 topnav">
                                        <NavLink className="nav mr-5" to="/dashboard">
                                            <h4 className="font-weight-light">Dashboard</h4>
                                        </NavLink>
                                        <NavLink className="nav mr-5" to={this.props.service ? `/service/${this.props.service.name.toLowerCase().replace(/ /g,'')}` : ''} onClick={this.onClickServices}>
                                            <h4 className="font-weight-light">Services</h4>
                                        </NavLink>
                                        <NavLink className="nav mr-5" to="/availability">
                                            <h4 className="font-weight-light">Availability</h4>
                                        </NavLink>
                                        <NavLink className="nav mr-5" to="/calendar">
                                            <h4 className="font-weight-light">Calendar</h4>
                                        </NavLink>
                                        <div className="nav mr-5" onClick={this.onInbox}>
                                            <h4 className="font-weight-light">Inbox</h4>
                                            {this.state.unreadMessages ? (
                                                <div className="unread d-flex justify-content-center align-items-center no-hover"><h6 className="font-weight-light no-hover pt-2" style={{color: 'white'}}>{this.state.unreadMessages}</h6></div>
                                            ) : ('')}
                                        </div>
                                        <NavLink className="nav mr-5" to="/events">
                                            <h4 className="font-weight-light">Events</h4>
                                        </NavLink>
                                        <NavLink className="nav mr-5 pr-5" to="/settings">
                                            <h4 className="font-weight-light">Settings</h4>
                                        </NavLink>
                                        <div className="nav ml-5" onClick={this.onLogout} style={{maxHeight: 50}}>
                                            <h4 className="font-weight-light">Logout</h4>
                                        </div>
                                    </div>

                                    {/* seller mobile nav bar */}
                                    <div className="topnav-icon" onClick={this.onMenu} style={{position: 'static'}}>
                                        <img src="/images/menu.png" className="img pr-5 pt-5" style={{maxBlockSize: 55}}/>
                                    </div>
                                    <div hidden={!this.state.showMenu} style={{zIndex: 1, border: '1px solid gainsboro', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)'}} className="bg-white pl-3 pb-2 topnav-drop">
                                        <NavLink className="mr-5 topnav-menu" to="/dashboard">
                                            <h4 className="font-weight-light">Dashboard</h4>
                                        </NavLink>
                                        <NavLink className="mr-5 topnav-menu" to={this.props.service ? `/service/${this.props.service.name.toLowerCase().replace(/ /g,'')}` : ''}>
                                            <h4 className="font-weight-light">Services</h4>
                                        </NavLink>
                                        <NavLink className="mr-5 topnav-menu" to="/availability">
                                            <h4 className="font-weight-light">Availability</h4>
                                        </NavLink>
                                        <NavLink className="mr-5 topnav-menu" to="/calendar">
                                            <h4 className="font-weight-light">Calendar</h4>
                                        </NavLink>
                                        <div className="pr-5 topnav-menu pb-4" onClick={this.onInbox}>
                                            <h4 className="font-weight-light">Inbox</h4>
                                        </div>
                                        <NavLink className="mr-5 topnav-menu" to="/events">
                                            <h4 className="font-weight-light">Events</h4>
                                        </NavLink>
                                        <NavLink className="mr-5 topnav-menu" to="/settings">
                                            <h4 className="font-weight-light">Settings</h4>
                                        </NavLink>
                                        <div className="topnav-menu topnav-logout" onClick={this.onLogout}>
                                            <h4 className="font-weight-light">Logout</h4>
                                        </div>
                                    </div>
                                </div>

                                <div hidden={(this.props.type == "seller")}>
                                    
                                    {/* buyer nav bar */}
                                    <div className="row mt-5 mr-5 pr-2 topnav" style={{maxHeight: 50}}>
                                        <NavLink className="nav mr-5" to="/dashboard">
                                            <h4 className="font-weight-light">Home</h4>
                                        </NavLink>
                                        <NavLink className="nav mr-5" to="/bookings">
                                            <h4 className="font-weight-light">My Bookings</h4>
                                            {this.state.unreadRequests ? (
                                                <div className="unread d-flex justify-content-center align-items-center no-hover"><h6 className="font-weight-light no-hover pt-2" style={{color: 'white'}}>{this.state.unreadRequests}</h6></div>
                                            ) : ('')}
                                        </NavLink>
                                        <NavLink className="nav mr-5" to="/profile">
                                            <h4 className="font-weight-light">Profile</h4>
                                        </NavLink>
                                        <div className="nav mr-5" onClick={this.onInbox}>
                                            <h4 className="font-weight-light">Inbox</h4>
                                            {this.state.unreadMessages ? (
                                                <div className="unread d-flex justify-content-center align-items-center no-hover"><h6 className="font-weight-light no-hover pt-2" style={{color: 'white'}}>{this.state.unreadMessages}</h6></div>
                                            ) : ('')}
                                        </div>
                                        <NavLink className="nav mr-5" to="/events">
                                            <h4 className="font-weight-light">Events</h4>
                                        </NavLink>
                                        <NavLink className="nav mr-5" to="/settings">
                                            <h4 className="font-weight-light">Settings</h4>
                                        </NavLink>
                                        <div className="nav" onClick={this.onLogout}>
                                            <h4 className="font-weight-light">Logout</h4>
                                        </div>
                                    </div>

                                    {/* buyer mobile nav bar */}
                                    <img src="/images/menu.png" onClick={this.onMenu} className="topnav-icon mr-5 mt-5 img img-fluid p-0 m-0 float-right" style={{maxBlockSize: 20}}/>
                                    <div hidden={!this.state.showMenu} style={{zIndex: 10, border: '1px solid gainsboro', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)', background: 'white'}} className="mt-5 pl-3 pb-2 topnav-drop">
                                        <NavLink className="pr-5 topnav-menu" to="/dashboard">
                                            <h4 className="font-weight-light">Home</h4>
                                        </NavLink>
                                        <NavLink className="pr-5 topnav-menu" to="/bookings">
                                            <h4 className="font-weight-light">My Bookings</h4>
                                        </NavLink>
                                        <NavLink className="pr-5 topnav-menu" to="/profile">
                                            <h4 className="font-weight-light">Profile</h4>
                                        </NavLink>
                                        <div className="pr-5 topnav-menu" onClick={this.onInbox}>
                                            <h4 className="font-weight-light">Inbox</h4>
                                        </div>
                                        <NavLink className="pr-5 topnav-menu" to="/events">
                                            <h4 className="font-weight-light">Events</h4>
                                        </NavLink>
                                        <NavLink className="pr-5 topnav-menu" to="/settings">
                                            <h4 className="font-weight-light">Settings</h4>
                                        </NavLink>
                                        <hr/>
                                        <div className="topnav-menu topnav-logout" onClick={this.onLogout}>
                                            <h4 className="font-weight-light">Logout</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* navigation for users who are not logged in */}
                            <div hidden={this.state.loggedIn} className="row mt-4 mr-0 pr-0 col-md-6 col-5" style={{maxHeight: 35}}>
                                <NavLink className="ml-3 mr-3 p-0 btn-entry mt-3 col-4 col-sm-5" to="/buyer/signup">
                                    <h4 className="font-weight-light text-center mt-1">Buyer<span className="mobile-login"> Login</span></h4>
                                </NavLink>
                                <NavLink className="btn-entry mt-3 col-4 col-sm-5 p-0 mr-0" to="/seller/signup">
                                    <h4 className="font-weight-light text-center mt-1">Seller<span className="mobile-login"> Login</span></h4>
                                </NavLink>
                            </div> 

                    </div>
                </div>
            </div> 
        );
    };
}

const mapDispatchToProps = (dispatch) => ({
    startLogout: () => dispatch(startLogout()),
    startSetService: (id) => dispatch(startSetService(id)),
    startSetAllServices: () => dispatch(startSetAllServices())
});

const mapStateToProps = (state, props) => ({
    type: state.profile[0] ? state.profile[0].type : props.type,
    loggedIn: state.profile[0] ? true : false,
    profile: state.profile[0] ? state.profile[0] : {},
    service: state.profile[0] ? state.services.find((service) => service.sellerID === state.profile[0].id) : '',
    messages: state.messages ? state.messages : []
})

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);