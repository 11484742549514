import React from 'react';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import Hours from './Hours';

/**
 * form component for seller to fill out their service availability
 */
export class AvailabilityForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
            edit: this.props.edit,

            fromDate: this.props.availability ? this.props.availability.fromDate ? moment(this.props.availability.fromDate) : moment() : moment(),
            toDate: this.props.availability ? this.props.availability.toDate ? moment(this.props.availability.toDate) : moment(). add(1,'days') : moment(). add(1,'days'),
            calendar1Focused: false,
            calendar2Focused: false,

            notice: this.props.availability ? this.props.availability.notice ? this.props.availability.notice : '15 minutes' : '15 minutes',
            buffer: this.props.availability ? this.props.availability.buffer ? this.props.availability.buffer : '15 minutes': '15 minutes',
            increments: this.props.availability ? this.props.availability.increments ? this.props.availability.increments : '15 minutes': '15 minutes',

            show0: true,
            show1: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 1 ? true : this.props.availability.times.length > 0 ? false : false : false : false,
            show2: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 2 ? true : this.props.availability.times.length > 1 ? false : undefined : undefined : undefined,
            show3: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 3 ? true : this.props.availability.times.length > 2 ? false : undefined : undefined : undefined,
            show4: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 4 ? true : this.props.availability.times.length > 3 ? false : undefined : undefined : undefined,
            show5: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 5 ? true : this.props.availability.times.length > 4 ? false : undefined : undefined : undefined,
            show6: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 6 ? true : this.props.availability.times.length > 5 ? false : undefined : undefined : undefined,
            show7: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 7 ? true : this.props.availability.times.length > 6 ? false : undefined : undefined : undefined,
            show8: this.props.availability ? this.props.availability.times ?  this.props.availability.times.length > 8 ? true : this.props.availability.times.length > 7 ? false : undefined : undefined : undefined,

            times: this.props.availability ? this.props.availability.times ?  this.props.availability.times : [] : [],

            noticeArray: ['15 minutes', '30 minutes', '45 minutes', '1 hours', '2 hours', '3 hours', '4 hours', '5 hours', '6 hours', '24 hours', '48 hours', '72 hours'],
            bufferArray: ['15 minutes', '30 minutes', '45 minutes', '1 hours', '2 hours', '3 hours', '4 hours', '5 hours', '6 hours', '24 hours', '48 hours', '72 hours'],
            incrementsArray: ['15 minutes', '30 minutes', '45 minutes', '1 hours', '2 hours', '3 hours', '4 hours', '5 hours', '6 hours', '24 hours', '48 hours', '72 hours']
        }
    }

    /**
     * method updates state upon receiving props
     */
    componentWillReceiveProps = (newProps) => {
        if (this.state.edit != newProps.edit) {
            this.setState(() => ({ edit: newProps.edit }))
        }
        if (newProps.availability && this.state.fromDate != newProps.availability.fromDate) {
            this.setState(() => ({ 
                fromDate: moment(newProps.availability.fromDate),
                toDate: moment(newProps.availability.toDate),
                notice: newProps.availability.notice,
                buffer: newProps.availability.buffer,
                increments: newProps.availability.increments,
                times: newProps.availability.times,
                show1: newProps.availability.times.length > 1 ? true : newProps.availability.times.length > 0 ? false : false,
                show2: newProps.availability.times.length > 2 ? true : newProps.availability.times.length > 1 ? false : undefined,
                show3: newProps.availability.times.length > 3 ? true : newProps.availability.times.length > 2 ? false : undefined,
                show4: newProps.availability.times.length > 4 ? true : newProps.availability.times.length > 3 ? false : undefined,
                show5: newProps.availability.times.length > 5 ? true : newProps.availability.times.length > 4 ? false : undefined,
                show6: newProps.availability.times.length > 6 ? true : newProps.availability.times.length > 5 ? false : undefined,
                show7: newProps.availability.times.length > 7 ? true : newProps.availability.times.length > 6 ? false : undefined,
                show8: newProps.availability.times.length > 8 ? true : newProps.availability.times.length > 7 ? false : undefined
            }))
        }
    }
    
    /**
     * method to update state to add an hours input
     */
    onAdd = (index) => {
        let key1 = 'show' + index
        let key2 = 'show' + (index+1)
        this.setState(() => ({ 
            [key1]: true,
            [key2]: false
        }))
    }

    /**
     * method to update state to reflect new hours input
     */
    onSaveTimes = (index, day, from, to) => {
        let times = []
        if (this.state.times === undefined || this.state.times.length === 0 || this.state.times.length < index) {
            times.push({
                day,
                from,
                to
            })
        } else {
            times = this.state.times
            times[index] = {
                day,
                from,
                to
            }
        }
        this.setState(() => ({ times }))
    }

    /**
     * method to update state to remove an hours input
     */
    onRemove = (index) => {
        let key = 'show' + index
        let times = this.state.times
        times[index] = {}
        this.setState(() => ({ 
            [key]: undefined,
            times
        }))
    }

    /**
     * methods to update state when user inputs form information
     */
    onFromDateChange = (date) => {
        const fromDate = date;
        this.setState(() => ({ fromDate }));
    }
    onToDateChange = (date) => {
        const toDate = date;
        this.setState(() => ({ toDate }));
    }
    onFocus1Change = ({ focused }) => {
        this.setState(() => ({ calendar1Focused: focused }));
    }
    onFocus2Change = ({ focused }) => {
        this.setState(() => ({ calendar2Focused: focused }));
    }
    onNoticeChange = (e) => {
        let notice = e.target.value
        this.setState(() => ({ notice }))
    }
    onBufferChange = (e) => {
        let buffer = e.target.value
        this.setState(() => ({ buffer }))
    }
    onIncrementsChange = (e) => {
        let increments = e.target.value
        this.setState(() => ({ increments }))
    }

    /**
     * method calls onSubmit method in AvailabilityPage
     */
    onSubmit = (e) => {
        e.preventDefault()

        this.props.onSubmit({
            fromDate: this.state.fromDate._d.toString().substring(4,15),
            toDate: this.state.toDate._d.toString().substring(4,15),
            notice: this.state.notice,
            buffer: this.state.buffer,
            increments: this.state.increments,
            times: this.state.times
        })
    }

    render() {
        return (

            <div>
                <div className="d-flex justify-content-center text-left mt-4">
                    <div className="col-8">

                        <form onSubmit={this.onSubmit}>

                            {/* available hours per day */}
                            <hr/>
                            <h3 className="font-weight-light text-left mt-2 mb-3">Available Times</h3>
                            <h5 className="font-weight-light text-left mt-2 mb-5">The hours during which clients can make bookings</h5>
                            
                            <Hours times={this.state.times.length > 0 ? this.state.times[0] : undefined} edit={this.state.edit} index={0} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show0}/>
                            <Hours times={this.state.times.length > 1 ? this.state.times[1] : undefined} edit={this.state.edit} index={1} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show1} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 2 ? this.state.times[2] : undefined} edit={this.state.edit} index={2} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show2} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 3 ? this.state.times[3] : undefined} edit={this.state.edit} index={3} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show3} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 4 ? this.state.times[4] : undefined} edit={this.state.edit} index={4} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show4} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 5 ? this.state.times[5] : undefined} edit={this.state.edit} index={5} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show5} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 6 ? this.state.times[6] : undefined} edit={this.state.edit} index={6} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show6} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 7 ? this.state.times[7] : undefined} edit={this.state.edit} index={7} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show7} onAdd={this.onAdd} />
                            <Hours times={this.state.times.length > 8 ? this.state.times[8] : undefined} edit={this.state.edit} index={8} onSaveTimes={this.onSaveTimes} onRemove={this.onRemove} show={this.state.show8} onAdd={this.onAdd} />

                            {/* date range for bookings */}
                            <hr/>
                            <h3 className="font-weight-light text-left mt-2 mb-3">When Bookings Can Be Made</h3>
                            <h5 className="font-weight-light text-left mt-2 mb-5">Custom date range during which bookings can be made</h5>
                            <div className="row">
                                <div className="pb-4 col-6">
                                    <label>Start Date</label>
                                    <div>
                                        <SingleDatePicker
                                            disabled={!this.state.edit}
                                            id="date"
                                            date={this.state.fromDate}
                                            focused={this.state.calendar1Focused}
                                            onDateChange={this.onFromDateChange}
                                            onFocusChange={this.onFocus1Change}
                                            numberOfMonths={1}
                                        />
                                    </div>
                                </div> 
                                <div className="pb-4 col-6">
                                    <label>End Date</label>
                                    <div>
                                        <SingleDatePicker
                                            disabled={!this.state.edit}
                                            id="date"
                                            date={this.state.toDate}
                                            focused={this.state.calendar2Focused}
                                            onDateChange={this.onToDateChange}
                                            onFocusChange={this.onFocus2Change}
                                            numberOfMonths={1}
                                        />
                                    </div>
                                </div> 
                            </div>

                            {/* minimum notice time */}
                            <hr/>
                            <h3 className="font-weight-light text-left mt-2 mb-3">Minimum Notice Time</h3>
                            <h5 className="font-weight-light text-left mt-2 mb-5">The minimum amount of notice time you need before a booking</h5>

                            <div className="form-row">
                                <div className="form-group col">
                                    <select
                                        disabled={!this.state.edit}
                                        className="custom-select font-weight-light rounded-0 custom-select-md"
                                        value={this.state.notice}
                                        onChange={this.onNoticeChange}>
                                        {this.state.noticeArray.map((t,index) => {
                                            return (<option key={index}>{t}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            {/* buffer time */}
                            <hr/>
                            <h3 className="font-weight-light text-left mt-2 mb-3">Buffer Time</h3>
                            <h5 className="font-weight-light text-left mt-2 mb-5">The minimum amount of buffer time you need between bookings</h5>

                            <div className="form-row">
                                <div className="form-group col">
                                    <select
                                        disabled={!this.state.edit}
                                        className="custom-select font-weight-light rounded-0 custom-select-md"
                                        value={this.state.buffer}
                                        onChange={this.onBufferChange}>
                                        {this.state.bufferArray.map((t,index) => {
                                            return (<option key={index}>{t}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            {/* start time increments */}
                            <hr/>
                            <h3 className="font-weight-light text-left mt-2 mb-3">Start Time Increments</h3>
                            <h5 className="font-weight-light text-left mt-2 mb-5">The start times you would like available for your bookings</h5>

                            <div className="form-row">
                                <div className="form-group col">
                                    <select
                                        disabled={!this.state.edit}
                                        className="custom-select font-weight-light rounded-0 custom-select-md"
                                        value={this.state.increments}
                                        onChange={this.onIncrementsChange}>
                                        {this.state.incrementsArray.map((t,index) => {
                                            return (<option key={index}>{t}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <hr/>

                            {this.state.edit ?
                                <div className="d-flex justify-content-center mb-4 mt-5" hidden={!this.state.edit}>
                                    <button className="rounded-0 btn btn-main btn-block" style={{height: 35}}>
                                        <h4 className="font-weight-light">Save</h4>
                                    </button>
                                </div>
                            :
                                <div></div>
                            }
                        </form>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default AvailabilityForm;