import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import AppRouter, {history} from './routers/AppRouter';
import configureStore from './store/configureStore';
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {firebase} from './firebase/firebase';
import {logout, startSetProfile} from './actions/profile';
import {startSetService} from './actions/services';
import {startSetCategories} from './actions/categories';
import {startSetBookings} from './actions/bookings';
import {startSetAllBookings} from './actions/bookings';
import {startSetMessages} from './actions/messages';
import {startSetEvents} from './actions/events';
import {startSetAllServices} from './actions/services';

const store = configureStore();
store.dispatch(startSetCategories());
store.dispatch(startSetAllServices());



const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);

let hasRendered = false;
const renderApp = () => {
    if (!hasRendered) {
        ReactDOM.render(jsx, document.getElementById('app'));
        hasRendered = true;
    }
};

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        store.dispatch(startSetProfile(user.uid))
            .then(() => {
            //store.dispatch(startSetService(user.uid))
            store.dispatch(startSetMessages(user.uid))
            // store.dispatch(startSetEvents(user.uid, true))
            store.dispatch(startSetBookings(user.uid))
            store.dispatch(startSetAllBookings(user.uid))
            store.dispatch(startSetEvents(user.uid))
            .then(() => { renderApp() });
        })
    } else {
        store.dispatch(logout());
        renderApp();
        //history.push('/');
    }
});
