import React from 'react';

/**
 * displays category list item components
 * gets props from category list
 */
const CategoryListItem = (props) => (

    <div>      
        <div className="mb-3 welcome-image" style={{backgroundImage: props.image ? `url(${props.image})` : `url(/images/categories/${props.id.replace(/\s/g, '')}.jpg)`, backgroundSize: 'cover', height: `200px`}}></div>            
        <div className="category-link-border">
            <h1 className="text-center font-weight-light display-6 m-3 welcome-subtitle category-text">{props.category}</h1>
        </div>
    </div>
    
);

export default CategoryListItem;