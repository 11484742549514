import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import CreatableSelect from 'react-select/lib/Creatable';
import {getSellers, getServiceCategories} from '../../utils/admin';
import Profile from '../Profile/Profile';
import Bank from './Bank';
import ProfileForm from '../Settings/ProfileForm';
import RegisterForm from '../Register/RegisterForm';
import ServiceProfile from './ServiceProfile';
import ServiceForm from '../Edit/ServiceForm';
import Transactions from './Transactions';

/**
 * sellers view for admin page
 */
export class Sellers extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            sellers: this.props.sellers ? this.props.sellers : [],
            text: '',
            showDisable: false,
            seller: {},
            sortby: this.props.sortby ? this.props.sortby : 'name',
            selectedCategories: null,
            categoryOptions: this.props.categories ? this.props.categories : [],
            categories: {},
            bookings: [],
            
            showProfile: false,
            showEditProfile: false,
            showConfirmDeleteProfile: false,
            showCreateProfile: false,
            showMessageForm: false,
            showServiceProfile: false,
            showEditServiceProfile: false,
            editServiceProfile: false,
            showTransactionProfile: false
        }
    }

    /**
     * updates state when new props received from AdminPage
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.sellers != newProps.sellers) {
            this.setState(() => ({ sellers: newProps.sellers }))
        } if (this.state.sortby != newProps.sortby) {
            this.setState(() => ({ sortby: newProps.sortby }))
        } if (this.state.categories != newProps.categories) {
            this.setState(() => ({ categoryOptions: newProps.categories }))
        } if (this.state.bookings != newProps.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }))
        }
    }

    /**
     * updates state based on text input
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ text }))
    }

    /**
     * updates state based on sortby selection
     */
    onSortby = (value) => {
        if (value === 'name') {
            this.setState(() => ({ sortby: 'name' }))
        } if (value === 'date') {
            this.setState(() => ({ sortby: 'date' }))
        } if (value === 'service') {
            this.setState(() => ({ sortby: 'service' }))
        }
    }

    /**
     * updates state based on category filter input
     */
    onCategoryChange = (selectedCategories) => {
        this.setState(() => ({ 
            categories: selectedCategories
        }));
    };

    /**
     * updates state to close all pop ups upon clicking cancel
     */
    onCancel = () => {
        this.setState(() => ({ 
            showDisable: false,
            seller: {},
            showProfile: false,
            showEditProfile: false,
            showConfirmDeleteProfile: false,
            showCreateProfile: false,
            showServiceProfile: false,
            showEditServiceProfile: false,
            showMessageForm: false,
            editServiceProfile: false,
            showTransactionProfile: false,
            bookings: []
        }))
    }

    /**
     * updates state to show confirm disable pop up and to selected seller
     */
    onDisableSeller = (seller) => {
        this.setState(() => ({ 
            showDisable: true,
            seller
         }))
    }

    /**
     * calls disableProfile and disableServiceProfile methods in AdminPage
     * updates state to close confirm disable pop up and clear seller
     */
    onConfirmDisable = () => {
        this.props.disableProfile(this.state.seller)
        this.props.disableServiceProfile(this.state.seller)
        this.setState(() => ({ 
            showDisable: false,
            seller: {}
        }))
    }

    /**
     * calls enableProfile and enableServiceProfile methods in AdminPage
     */
    onEnableSeller = (seller) => {
        this.props.enableProfile(seller)
        this.props.enableServiceProfile(seller)
    }

    /**
     * updates state to show profile pop up and to selected seller
     */
    onViewProfile = (seller) => {
        this.setState(() => ({
            seller,
            showProfile: true
        }))
    }

    /**
     * updates state to show service profile pop up and to selected seller
     */
    onViewServiceProfile = (seller) => {
        this.setState(() => ({
            seller,
            showServiceProfile: true
        }))
    }

    /**
     * updates state to show confirm delete profile pop up and close show profile pop up
     */
    onDeleteProfile = () => {
        this.setState(() => ({
            showProfile: false,
            showConfirmDeleteProfile: true
        }))
    }

    /**
     * calls onDeleteProfile method in AdminPage
     * if seller profile has a service listed, calls onDeleteServiceProfile method in AdminPage
     * updates state to close confirm delete profile pop up
     */
    onConfirmDeleteProfile = () => {
        this.props.onDeleteProfile(this.state.seller.id)
        if (this.state.seller.service.name != "No Service Listed") {
            this.props.onDeleteServiceProfile(this.state.seller.id)
        }
        this.setState(() => ({
            showConfirmDeleteProfile: false,
            seller: {}
        }))
    }

    /**
     * updates state to show edit profile pop up and close profile pop up
     */
    onEditProfile = () => {
        this.setState(() => ({
            showProfile: false,
            showEditProfile: true
        }))
    }

    /**
     * updates state to show add/edit service profile pop up and close service profile pop up
     */
    onEditServiceProfile = () => {
        this.setState(() => ({
            showServiceProfile: false,
            showEditServiceProfile: true,
            editServiceProfile: true
        }))
    }

    /**
     * calls onSubmitProfile method in AdminPage
     * updates state to close edit profile pop up and clear seller
     */
    onSubmitProfile = (profile) => {
        this.props.onSubmitProfile(profile, this.state.seller.id)
        this.setState(() => ({
            showEditProfile: false,
            seller: {}
        }))
    }

    /**
     * calls onSubmitServiceProfile method in AdminPage
     * 'add' indicates whether this is an update to exisiting service profile or new service profile
     * updates state to close add/edit service profile pop up and clear seller
     */
    onSubmitServiceProfile = (id, service) => {
        const add = this.state.seller.service.name === "No Service Listed" ? true : false
        this.props.onSubmitServiceProfile(id, service, add)
        this.setState(() => ({
            showEditServiceProfile: false,
            editServiceProfile: false,
            seller: {}
        }))
    }

    /**
     * called by transactions component
     * receives updates service profile
     * calls onSubmitServiceProfile method in AdminPage
     */
    onServiceChange = (service) => {
        this.props.onSubmitServiceProfile(service.id, service)
    }

    /**
     * updates state to show create profile pop up
     */
    onCreateProfile = () => {
        this.setState(() => ({
            showCreateProfile: true
        }))
    }

    /**
     * calls onSubmitNewProfile method in AdminPage
     * updates state to close create profile pop up
     */
    onSubmitNewProfile = (profile) => {
        this.props.onSubmitNewProfile(profile)
        this.setState(() => ({
            showCreateProfile: false
        }))
    }

    /**
     * updates state to show create service profile pop up
     */
    onCreateServiceProfile = (seller) => {
        this.setState(() => ({
            seller,
            showEditServiceProfile: true
        }))
    }

    /**
     * calls getBookings method in AdminPage to get selected sellers transactions
     * updates state to show transaction view pop up and to selected seller
     */
    onTransactionView = (seller) => {
        this.props.getBookings(seller.id)
        this.setState(() => ({ 
            seller,
            showTransactionProfile: true
         }))
    }

    // TO DO
    onMessageSeller = (seller) => {
        this.props.onMessage(seller)
    }

    /**
     * not used but had to be included as radio buttons require an onchange handler or give an error
     * (radio button changes are handled by the onClick => onSortby method)
     */
    doNothing = () => {
        // do nothing
    }

    render() {

        let { selectedCategories } = this.state.categories;

        return (

            <div>
                <div className="mb-3 ml-5">

                    {/* pop up for disabling seller */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showDisable}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="default"
                            showCancel
                            cancelBsBtnText="Cancel"
                            cancelBsBtnBsStyle="link"
                            title={`Disable ${this.state.seller.name}`}
                            onConfirm={this.onConfirmDisable}
                            onCancel={this.onCancel}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-sm-10 mt-5">
                                    <h3 className="row font-weight-light text-left display-5 mb-5">Are you sure you want to disable this account?</h3>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* pop up for confirming deleting of seller */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showConfirmDeleteProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="default"
                            showCancel
                            cancelBsBtnText="Cancel"
                            cancelBsBtnBsStyle="link"
                            title={`Delete ${this.state.seller.name}`}
                            onConfirm={this.onConfirmDeleteProfile}
                            onCancel={this.onCancel}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-sm-10 mt-5">
                                    <h3 className="row font-weight-light text-left display-5 mb-5">Are you sure you want to permanently delete this account?</h3>
                                    <h4 className="row font-weight-light text-left display-5 mb-5">Their service profile will also be permanently deleted.</h4>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* pop up for viewing profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.seller.name ? this.state.seller.name : ''}
                            onConfirm={this.onCancel}>
                            <Profile profile={this.state.seller} />
                            {this.state.seller.accName && this.state.seller.accNumber && this.state.seller.bank && this.state.seller.branchCode ?
                                <Bank profile={this.state.seller} />
                            :
                                <div></div>
                            }
                            <div className="d-flex justify-content-center">
                                <div className="col-7">
                                    <button className="rounded-0 btn btn-main btn-block mb-4" onClick={this.onEditProfile} style={{height: 30}}>
                                        <h4 className="font-weight-light">Edit</h4>
                                    </button>
                                    <button className="rounded-0 btn btn-main btn-block" onClick={this.onDeleteProfile} style={{height: 30}}>
                                        <h4 className="font-weight-light">Delete</h4>
                                    </button>
                                </div>
                            </div>

                        </SweetAlert>
                    </div>

                    {/* pop up for editing profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showEditProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.seller.name ? this.state.seller.name : ''}
                            onConfirm={this.onCancel}>
                            <ProfileForm
                                edit={true}
                                profile={this.state.seller}
                                onSubmit={this.onSubmitProfile}
                                onCancel={this.onCancel}
                            />
                        </SweetAlert>
                    </div>

                    {/* pop up for creating profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showCreateProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Cancel"
                            confirmBtnBsStyle="link"
                            title={'Create a New Seller Profile'}
                            onConfirm={this.onCancel}>
                            <div className="m-3">
                                <hr/>
                                <div className="mt-1 d-flex justify-content-center">
                                    <div className="m-5 mb-0 pb-0 col-6">
                                        <RegisterForm
                                            superuser={true}
                                            onSubmit={this.onSubmitNewProfile}
                                            type={'seller'}
                                            errorMessage={''} />
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* pop up for viewing service profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showServiceProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.seller ? this.state.seller.service ? this.state.seller.service.name : '' : ''}
                            onConfirm={this.onCancel}>
                            <div className="mb-5">
                                <ServiceProfile 
                                    service={this.state.seller.service}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="col-7">
                                    <button className="rounded-0 btn btn-main btn-block mb-4" onClick={this.onEditServiceProfile} style={{height: 30}}>
                                        <h4 className="font-weight-light">Edit</h4>
                                    </button>
                                    <button className="rounded-0 btn btn-main btn-block" onClick={this.onDeleteServiceProfile} style={{height: 30}}>
                                        <h4 className="font-weight-light">Delete</h4>
                                    </button>
                                </div>
                            </div>

                        </SweetAlert>
                    </div>

                    {/* pop up for creating and editing service profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showEditServiceProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.seller.service ? this.state.seller.service.name != "No Service Listed" ? this.state.seller.service.name : `Create Service Profile for ${this.state.seller.name}` : `Create Service Profile for ${this.state.seller.name}`}
                            onConfirm={this.onCancel}>
                            <ServiceForm
                                edit={this.state.editServiceProfile}
                                admin={true}
                                id={this.state.seller ? this.state.seller.id : ''}
                                companyNames={[]}
                                categories={this.state.categoryOptions}
                                service={this.state.seller ? this.state.seller.service ? this.state.seller.service.name != "No Service Listed" ? this.state.seller.service : {} : {} : {}}
                                onSubmit={this.onSubmitServiceProfile}
                                onCancel={this.onCancel} 
                                submitting={false}
                            />
                        </SweetAlert>
                    </div>

                    {/* pop up for transaction view */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showTransactionProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.seller ? `${this.state.seller.name}'s Transactions` : ''}
                            onConfirm={this.onCancel}>
                            <Transactions
                                onServiceChange={this.onServiceChange}
                                bookings={this.state.bookings}
                                service={this.state.seller ? this.state.seller.service ? this.state.seller.service.name != "No Service Listed" ? this.state.seller.service : {} : {} : {}}
                            />
                        </SweetAlert>
                    </div>

                    {/* create profile button */}
                    <div className="d-flex justify-content-center mt-3 mb-5" style={{height: 30}}>
                        <div className="col-2"></div>
                        <button className="btn btn-block col-12 col-sm-8" onClick={this.onCreateProfile}><h4 className="font-weight-light">Create New Profile</h4></button>
                        <div className="col-2"></div>
                    </div>
                    <hr/>

                    <div className="d-flex justify-content-center">
                        <div className="col-2"></div>

                        {/* category search bar */}
                        <div className="mb-5 mt-5 col-6 pl-0">
                            <div className="col-12 mb-4 p-0 pl-1" style={{position: 'relative', zIndex: 100}}>
                                <CreatableSelect
                                    placeholder="View Category"
                                    className="rounded-0"
                                    isMulti
                                    onChange={this.onCategoryChange}
                                    value={selectedCategories}
                                    options={this.state.categoryOptions} />
                            </div>
                        </div>

                        {/* sort by radio buttons */}
                        <div className="mt-3 mr-3 col-2">
                            <div className="float-right">
                                <h5 className="font-weight-light">Sort by</h5>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.doNothing} type="radio" onClick={(() => this.onSortby('name'))} checked={this.state.sortby === 'name' ? true : false}/>
                                    <label className="form-check-label font-weight-light">Name</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" onChange={this.doNothing} onClick={(() => this.onSortby('service'))} checked={this.state.sortby === 'service' ? true : false}/>
                                    <label className="form-check-label font-weight-light">Service</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.doNothing} type="radio"  onClick={(() => this.onSortby('date'))} checked={this.state.sortby === 'date' ? true : false}/>
                                    <label className="form-check-label font-weight-light">Created</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-2"></div>
                    </div>

                    {/* search bar input */}
                    <div className="d-flex justify-content-center">
                        <div className="col-2"></div>
                        <div className="d-flex justify-content-center col-8 p-0 m-0 mb-5">
                            <div className="col-12 m-0 ml-2 p-0">
                                <input
                                    className="form-control form-control-lg rounded-0 font-weight-light text-center"
                                    type="text"
                                    placeholder={`Search Sellers`}
                                    value={this.state.text}
                                    onChange={this.onTextChange}/>
                            </div>
                        </div>
                        <div className="col-2"></div>
                    </div>
                    <hr/>

                    {/* results display */}
                    <div className="d-flex justify-content-center mb-5 mt-5">
                        <h4 className="text-center display-6">{getSellers(this.state.sellers, this.state.text, this.state.sortby, this.props.services, this.state.categories).length} results</h4>
                    </div>

                    {/* headings display */}
                    <div className="row p-0 m-0">
                        <div className="row col-9">
                            <div className="col-2 text-wrap p-0 pr-2 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Created</h5>
                            </div>
                            <div className="col-4 text-wrap p-0 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Name</h5>
                            </div>
                            <div className="col-3 text-wrap p-0 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Service</h5>
                            </div>
                            <div className="col-3 text-wrap p-0 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Categories</h5>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    {getSellers(this.state.sellers, this.state.text, this.state.sortby, this.props.services, this.state.categories).map((seller, index) => (
                        <div key={index}>
                            <div className="row mb-3 pt-2 hover-border">
                                <div className="row col-9" onClick={(() => this.onViewProfile(seller))}>
                                    <h5 className="col-2 font-weight-light">{seller.date.substring(4,15)}</h5>
                                    <h4 className="col-4 font-weight-light text-muted display-5">{seller.name}</h4>
                                    <h4 className="col-3 font-weight-light">{seller.service.name}</h4>
                                    <h5 className="col-3 font-weight-light">{this.props.services ? getServiceCategories(seller.id, this.props.services) : ''}</h5>
                                </div>
                                <div className="col-3">
                                    <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onViewProfile(seller))}>View Profile</h5>
                                    {seller.service.name != "No Service Listed" ? <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onViewServiceProfile(seller))}>View Service Profile</h5> : <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onCreateServiceProfile(seller))}>Create Service Profile</h5> }
                                    {seller.service.name != "No Service Listed" ? <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onTransactionView(seller))}>View Transactions</h5> : <h5 className="text-muted font-weight-light m-0 p-0 text-right">View Transactions</h5> }
                                    <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onMessageSeller(seller))}>Message</h5>
                                    {(seller.disabled != undefined && seller.disabled === true) ? <h5 style={{color: 'red'}} className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onEnableSeller(seller))}>Enable</h5> : <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onDisableSeller(seller))}>Disable</h5> }
                                </div>
                            </div>
                            <hr/>
                        </div>
                    ))}
                </div>

            </div>

        )
    }
}

export default Sellers;