import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BankForm from './BankForm';


/**
 * edit bank pop up component used by settings page
 * uses bank form to edit bank details
 */
export class EditBank extends React.Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            showForm: this.props.showForm ? this.props.showForm : false
        }
    }

    /**
     * method to update state upon receiving new props from SettingsPage
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.showForm != newProps.showForm) {
            this.setState(() => ({ showForm: newProps.showForm }));
        }
    } 

    /**
     * calls onSubmit method in SettingsPage
     */
    onSubmit = (profile) => {
        this.props.onSubmit('profile', profile);
    }

    /**
     * method calls onCancel method in SettingsPage
     */
    onCancel = () => {
        this.props.onSubmit();
    }

    render() {
        return (

            <div>
            
                {/* pop up for editing banking details */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showForm}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Cancel"
                        confirmBtnBsStyle="link"
                        title={`Edit Profile`}
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <BankForm
                            edit={true}
                            profile={this.props.profile}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}/>
                    </SweetAlert>
                </div>

            </div>
        );
    }
};

export default EditBank;