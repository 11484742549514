import moment from 'moment';

/** returns a sorted list of categories matching the search text */
export const getCategories = (categories, text) => {
    let categoryList = []
    categories.map((category) => {
        if (category.id.includes(text.toLowerCase())) { categoryList.push(category) }
    })

    categoryList = categoryList.sort((a, b) => {
        return a.id <= b.id ? -1 : 1;
    })

    return categoryList;
}

/** returns filtered list of buyers that match search term and are sorted according to sortby */
export const getBuyers = (users, text, sortby) => {
    let buyerList = []

    text = text.toLowerCase()
    users.map((user) => {
        if (user.type === 'buyer') {
            if (user.name.toLowerCase().includes(text) || user.email.toLowerCase().includes(text) || user.number.toLowerCase().includes(text)) {
                buyerList.push(user)
            }
        }
    })

    if (!sortby || sortby === 'name') {
        buyerList = buyerList.sort((a, b) => {
            return a.name <= b.name ? -1 : 1;
        })
    } if (sortby === 'date') {
        buyerList = buyerList.sort((a, b) => {
            return moment(a.date).isSameOrAfter(moment(b.date), 'day') ? -1 : 1;
        })
    }

    return buyerList;
}

/** returns filtered list of sellers that match search term, categories and are sorted according to sortby */
export const getSellers = (users, text, sortby, services, categories) => {
    
    let sellerList = []
    let newSellerList = []

    text = text.toLowerCase()

    users.map((user) => {
        if (user.type === 'seller') {
            
            let seller = {}

            if (services) {
                services.filter((s) => {
                    if (s.id === user.id) {
                        seller = {
                            ...user,
                            service: s,
                            categories: s.categories
                        }
                    } if (!seller.service || !seller.service.name) {
                        seller = {
                            ...user,
                            service: {
                                name: 'No Service Listed'
                            }
                        }
                    }
                })
                if (user.name.toLowerCase().includes(text) || seller.service.name.toLowerCase().includes(text) || user.email.toLowerCase().includes(text) || user.number.toLowerCase().includes(text)) {
                    sellerList.push(seller)
                }
            }
        }
    })

    if (categories && Object.entries(categories).length != 0 && categories.length != 0) {
        sellerList.map((seller) => {
            categories.map((c) => {
                if (seller.categories) {
                    seller.categories.map((cat) => {
                        if (cat.value === c.value && !newSellerList.includes(seller)) { newSellerList.push(seller) }
                    })
                }
            })
        })
    } else {
        newSellerList = sellerList
    }

    if (!sortby || sortby === 'name') {
        newSellerList = newSellerList.sort((a, b) => {
            return a.name <= b.name ? -1 : 1;
        })
    } else if (sortby === 'date') {
        newSellerList = newSellerList.sort((a, b) => {
            return moment(a.date).isSameOrAfter(moment(b.date), 'day') ? -1 : 1;
        })
    } else if (sortby === 'service') {
        newSellerList = newSellerList.filter((s) => s.service.name != 'No Service Listed')
        newSellerList = newSellerList.filter((s) => s.service.name != ' ')
        newSellerList = newSellerList.sort((a, b) => {
            return a.service.name <= b.service.name ? -1 : 1;
        })
    }

    return newSellerList;
}

/** returns total number of users */
export const getTotalUsers = (users) => {
    return users.length;
}

/** returns total number of sellers */
export const getTotalSellers = (users) => {
    let count = 0
    users.map((user) => {
        if (user.type === 'seller') {
            count += 1
        }
    })
    return count;
}

/** returns total number of buyers */
export const getTotalBuyers = (users) => {
    let count = 0
    users.map((user) => {
        if (user.type === 'buyer') {
            count += 1
        }
    })
    return count;
}

/** returns total number of services */
export const getTotalServices = (services) => {
    return services.length;
}

/** returns users who registered less than 7 days ago */
export const getNewUsers = (users) => {
    let newUsers = []
    users.map((u) => {
        if (u.date && moment(u.date) > moment().subtract(7,'d')) {
            newUsers.push(u)
        }
    })
    return newUsers;
}

/** returns buyers who registered less than 7 days ago */
export const getNewBuyers = (users) => {
    let newBuyers = []
    users.map((u) => {
        if (u.type === 'buyer' && u.date && moment(u.date) > moment().subtract(7,'d')) {
            newBuyers.push(u)
        }
    })
    return newBuyers;
}

/** returns sellers who registered less than 7 days ago */
export const getNewSellers = (users) => {
    let newSellers = []
    users.map((u) => {
        if (u.type === 'seller' && u.date && moment(u.date) > moment().subtract(7,'d')) {
            newSellers.push(u)
        }
    })
    return newSellers;
}

/** returns a comma separated string list of the categories for the service that matches id */
export const getServiceCategories = (id, services) => {
    let categories = ''
    services.map((s) => {
        if (s.id === id && s.categories) {
            s.categories.map((c, index) => {
                if (index != 0) { categories += ", " }
                categories += c.label
            })
        }
    })
    if (categories === '') { categories = "No Categories Listed"}
    return categories
}