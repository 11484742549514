import React from 'react';

/**
 * class to display the cropped image on service profile
 * rendered by the service profile component
 */
export default class Image extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            image: '',
            show: false,
            size: 2
        }
    }

    /**
     * updates state upon mounting
     */
    componentDidMount = () => {

        this.setState(() => ({ 
            image: this.props.image,
            show: this.props.show,
            size: this.props.size
        }))
        const image = document.getElementById('source');
        image.onload = () => {
            const canvas = document.getElementById('canvas');
            const ctx = canvas.getContext('2d');
            var f = image.height / image.width;
            var newHeight = canvas.width * f;
            
            if (this.props.crop != undefined) {
                ctx.drawImage(image, this.props.crop.x, this.props.crop.y, this.props.crop.width, this.props.crop.height, 0, 0, canvas.width, newHeight);
            } else {
                ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, newHeight);
            }
        }
    }

    /**
     * updates state upon receiving new props
     */
    componentWillReceiveProps = (newProps) => {
        this.setState(() => ({ 
            image: newProps.image,
            show: newProps.show,
            size: newProps.size
        }))
        const image = document.getElementById('source');
        image.onload = () => {
            const canvas = document.getElementById('canvas');
            const ctx = canvas.getContext('2d');
            var f = image.height / image.width;
            var newHeight = canvas.width * f;
            
            if (newProps.crop != undefined) {
                ctx.drawImage(image, newProps.crop.x, newProps.crop.y, newProps.crop.width, newProps.crop.height, 0, 0, canvas.width, newHeight);
            } else {
                ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, newHeight);
            }
        }
    }

    render() {
        return (

            <div className="d-flex justify-content-center mb-5">
                <canvas hidden={!this.state.show || this.state.size != 1} width="600" height="400" id="canvas"></canvas>
                <canvas hidden={!this.state.show || this.state.size != 0} width="100" height="50" id="canvas"></canvas>
                <div className="row">
                    <img 
                        style={{display: 'none'}}
                        id="source"
                        className="img"
                        src={this.state.image}
                    />
                </div>
            </div>
        )
    }
}