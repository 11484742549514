import React from 'react'

/**
 * footer component
 * for bottom of all pages
 * contains social media links and short write up
 */
const Footer = () => (

    <div style={{backgroundColor: '#142948'}} className="pb-5 pt-3 d-flex justify-content-center">
        <div className="pt-md-5 pt-3 pb-md-5 col-8">
            
            <div className="d-flex justify-content-center">
                <img src="/images/itorho-logo-only-3.png" className="img footer-image" style={{maxWidth:70, maxHeight:70}}/>
            </div>
            <div className="d-flex justify-content-center">
                <img src="/images/itorho-light.png" className="img footer-image" style={{maxWidth:70, maxHeight:70}}/>
            </div>
            <div className="mt-md-5 mt-3 mb-md-5 d-flex justify-content-center">
                <h4 className="text-center font-weight-light welcome-text" style={{color: 'white'}}>iTorho is a South African technology company offering a market place platform for small businesses, freelancers and professionals to access more clients seamlessly.</h4>
            </div>

            <div className="d-flex justify-content-center mt-2">
                <a href="https://web.facebook.com/ITorho-1046333855529216/">
                    <div className="mr-5 pr-3 social-links">
                        <img src="/images/facebook.png" className="img welcome-search-link" style={{maxWidth:35, maxHeight:35}}/>
                    </div>
                </a>
                <a href="https://twitter.com/ITorho_za?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                    <div className="social-links">
                        <img src="/images/twitter.png" className="img welcome-search-link" style={{maxWidth:35, maxHeight:35}}/>
                    </div>
                </a>
                <a href="https://www.instagram.com/itorho_za/">
                    <div className="ml-5 pl-3 social-links">
                        <img src="/images/instagram.png" className="img welcome-search-link" style={{maxWidth:35, maxHeight:35}}/>
                    </div>
                </a>
            </div>

        </div>
    </div>
)

export default Footer;