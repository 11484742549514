import React from 'react'

/**
 * tutorial feature for entry page
 */
export class Tutorial extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * for navigation to register page
     */
    onSignup = () => {
        this.props.props.history.push('/seller/register')
    }

    render() {
        return (

            <div className="p-md-5">

                <div>
                    <h4 className="text-center display-4 ml-md-5 mb-5 pb-md-5 welcome-subtitle">Looking to Sell a Service?</h4>
                </div>

                {/* main content */}
                <div className="d-flex justify-content-center">
                    <div className="pl-md-5 pr-md-5 col-10 d-flex justify-content-center">

                        <div className="col-3 p-0 mb-md-5 d-flex justify-content-center">
                            <div className="text-center">
                                <img src="/images/profile.png" className="img welcome-image2" style={{maxWidth:200}}/>
                                <h3 className="mt-4 mb-2 display-4 text-o welcome-subtitle2">CREATE</h3>
                                <h4 className="font-weight-light welcome-text">A profile to start taking orders and bookings online</h4>
                            </div>
                        </div>

                        <div className="col-3 p-0 mb-md-5 d-flex justify-content-center">
                            <div className="text-center">
                                <img src="/images/add.png" className="img welcome-image2" style={{maxWidth:200}}/>
                                <h3 className="mt-4 mb-2 display-4 text-o welcome-subtitle2">ADD</h3>
                                <h4 className="font-weight-light welcome-text">Your services and availability</h4>
                            </div>
                        </div>

                        <div className="col-3 p-0 mb-md-5 d-flex justify-content-center">
                            <div className="text-center">
                                <img src="/images/manage.png" className="img welcome-image2" style={{maxWidth:200}}/>
                                <h3 className="mt-4 mb-2 display-4 text-o welcome-subtitle2">MANAGE</h3>
                                <h4 className="font-weight-light welcome-text">Client bookings and messages from your dashboard</h4>
                            </div>
                        </div>

                        <div className="col-3 p-0 mb-md-5 d-flex justify-content-center">
                            <div className="text-center">
                                <img src="/images/share.png" className="img welcome-image2" style={{maxWidth:200}}/>
                                <h3 className="mt-4 mb-2 display-4 text-o welcome-subtitle2">SHARE</h3>
                                <h4 className="font-weight-light welcome-text">Your iTorho link to your social media pages</h4>
                            </div>
                        </div>

                    </div>
                </div>

                {/* sign up button */}
                <div className="d-flex justify-content-center mb-md-2 pt-md-5 pt-4 mt-md-3" onClick={this.onSignup}>
                    <button className="col-md-3 col-4 rounded-0 mb-0 btn btn-block welcome-btn" style={{height: 40}}>
                        <h4 className="welcome-text">Sign Up Now</h4>
                    </button>
                </div>

            </div>
        )
    }
}

export default Tutorial;
