import React from 'react';
import ImageCarousel from './ImageCarousel';
import {sortGallery1, sortGallery2} from '../../selectors/service';

/**
 * gallery component for service profile
 * rendered by service profile
 */
export class Gallery extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showGallery: false,
            show: false,
            gallery: this.props.gallery ? this.props.gallery : [],
            gallery1: this.props.gallery ? sortGallery1(this.props.gallery) : [], // images uploaded by seller
            gallery2: this.props.gallery ? sortGallery2(this.props.gallery) : [], // images uploaded by buyers 
            loading: false
        }
    }

    /**
     * updates state upon receiving new props from service profile
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.show != newProps.show || this.state.gallery != newProps.gallery) { 
            this.setState(() => ({ 
                loading: false,
                show: newProps.show,
                gallery1: sortGallery1(newProps.gallery)
            }))
        }
    }

    /**
     * calls onImageRemove method in service profile
     */
    onImageRemove = (image) => {
        this.props.onImageRemove(image)
    }

    /**
     * updates state to selected image and to show gallery
     */
    onImage = (index, gallery) => {
        this.setState(() => ({ 
            showGallery: true,
            gallery,
            index 
        }));
    }

    /**
     * updates state to loading
     * calls onImageChange method in service profile component
     */
    onImageChange = (event) => {
        this.setState(() => ({ loading: true }))
        this.props.onImageChange(event);
    }

    /**
     * updates state to close gallery
     */
    closeGallery = () => {
        this.setState(() => ({ showGallery: false }));
    }

    render() {
        return (

            <div hidden={!this.state.show}>
                <div className="mb-5">
                    {this.state.gallery1.length != 0 ? (
                        <div className="row mt-4 d-flex justify-content-center">
                            {this.state.gallery1.map((image, index) => (
                                <div key={index} className="col-4 p-1">
                                    <div onClick={() => this.onImage(index, this.state.gallery1)}>
                                        <img src={image} className="rounded mx-auto d-block pb-4" style={{maxWidth:'100%'}}/>
                                    </div>
                                    {this.props.edit ? ( 
                                        <button className="btn btn-main" onClick={(() => (this.onImageRemove(image)))}>
                                            <h5 className="text-center font-weight-light mt-2">Remove</h5>                                                            
                                        </button>
                                    ) : ( 
                                        <div></div>
                                    )}
                                </div>
                            ))}
                        </div> ) : ( <div><h4 className="font-weight-light text-center mt-5 pt-5 pb-5 mb-5">No images yet</h4></div> )}

                        {this.state.loading ? (
                            <div className="container h-80 mt-5 pt-5">
                                <div className="row h-80 justify-content-center align-items-center mt-5 pt-5">
                                    <img src='/images/loader.gif' />
                                </div>
                            </div>
                        ) : ('')}

                        {!this.props.edit ? (
                            <div></div>
                        ) : (
                            <div className="p-5">
                                <div className="input-group">
                                    <div className="custom-file rounded-0 col-12">
                                        <input 
                                            type="file" 
                                            className="image-input"
                                            id="picture"
                                            onChange={this.onImageChange}
                                            name="picture"
                                            accept="image/*"/>
                                        <label 
                                            className="custom-file-label" 
                                            htmlFor="picture">Add Image...
                                        </label>
                                    </div>
                                </div>
                                <small className="font-weight-light text-muted">Upload may take up to 30 seconds to display...</small>
                            </div>
                        )}

                        {this.state.gallery2.length != 0 ? (
                            <div>
                                <div className="mt-5">
                                    <h3 className="font-weight-light text-left">Photos from Clients</h3>
                                </div>
                                <div className="row mt-4 d-flex justify-content-center">
                                    {this.state.gallery2.map((image, index) => (
                                        <div key={index} className="col-4 p-1">
                                            <div onClick={() => this.onImage(index, this.state.gallery2)}>
                                                <img src={image} className="rounded mx-auto d-block pb-4" style={{maxWidth:'100%'}}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                </div>

                <ImageCarousel 
                    gallery={this.state.gallery}
                    index={this.state.index}
                    closeGallery={this.closeGallery}
                    show={this.state.showGallery}/>
            </div>
        )
    }
}

export default Gallery;