import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import uuid from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';
import Quotes from './Quotes';
import Form from './Form';
import MessageList from './MessageList';
import Message from './Message';
import {findServiceName, getAllUnread, selectMessages, sortMessages, sortQuotes} from '../../selectors/messages';
import {startAddMessageAdmin, startAddMessageBuyer, startAddMessageSeller, startEditMessage, startEditMessageSeller, startEditMessageBuyer} from '../../actions/messages';
import {startAddBooking} from '../../actions/bookings';
import {addRoute} from '../../actions/route';
import {sendNotificationEmail, sendQuoteAcceptedEmail, sendInvoiceEmail} from '../../utils/emails';

/**
 * Inbox component renders chat and quote components
 * for communication between buyers, sellers and admin
 * 
 * rendered by the TopNav component
 */
export class Inbox extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            
            services: this.props.services ? this.props.services : [],
            allMessages: this.props.allMessages ? this.props.allMessages : [],
            messages: [],
            inbox: [],
            quotes: [],
            unreadCount: 0,
            
            buyerName: this.props.userName ? this.props.userName : '',
            quoteID : '',
            userID: this.props.userID ? this.props.userID : '', 
            toUserName: this.props.toUserName ? this.props.toUserName : '',
            toUserID: this.props.toUserID ? this.props.toUserID : '',
            
            onTab: 'inbox',
            open: this.props.open,
            showChat: false,
            showInbox: true,
            showMessages: false,
            showIcon: false,
            quotesVisible: true,
            admin: false
        }
    }
    
    /**
     * updates state upon component mounting
     */
    componentDidMount = () => {
        if (this.props.admin) {
            this.setState(() => ({ 
                inbox: sortMessages(this.props.toUserID, this.props.allMessages),
                showIcon: true
            }))
        } else {
            this.setState(() => ({ 
                inbox: sortMessages(this.props.userID, this.state.allMessages),
                quotes: sortQuotes(this.props.userID, this.state.allMessages),
            }))
        }
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (this.state.quotes != sortQuotes(newProps.userID, newProps.allMessages)) {
            this.setState(() => ({ quotes: sortQuotes(newProps.userID, newProps.allMessages) }))
        }
        if (this.state.allMessages != newProps.allMessages) {
            this.setState(() => ({ 
                allMessages: newProps.allMessages,
                inbox: sortMessages(newProps.userID, newProps.allMessages),
            }))
        }
        if (newProps.toUserID != undefined && (this.state.toUserID != newProps.toUserID || this.state.toUserName != newProps.toUserName)) {
            this.setState(() => ({ 
                toUserID: newProps.toUserID,
                toUserName: newProps.toUserName 
            }));
        }
        if (newProps.open != this.state.open || newProps.allMessages != this.state.allMessages) {
            this.setState(() => ({ 
                allMessages: newProps.allMessages,
                toUserID: newProps.toUserID,
                services: newProps.services 
            }))
            
            if (newProps.open != this.state.open && newProps.open === true) { 
                
                const unreadCount = getAllUnread(this.state.allMessages);
                this.setState(() => ({ 
                        open: newProps.open,
                        unreadCount,
                        showChat: true,
                        showMessages: false,
                        showInbox: true 
                    }))
            }
        }
        
        if (newProps.admin && newProps.admin === true && newProps.open) {

            this.setState(() => ({ 
                inbox: sortMessages('admin', this.state.allMessages),
                messages:  selectMessages(newProps.toUserID, 'admin', this.state.allMessages),
                admin: true,
                quotesVisible: false,
                showChat: true,
                showMessages: true,
                toUserName: newProps.userName,
                toUserID: newProps.toUserID,
                userID: 'admin',
                buyerName: newProps.userName
            }))
        }
    }

    /**
     * updates state to show inbox when icon is pressed
     * icon only visible for admin
     */
    onIcon = () => {
        this.setState(() => ({ 
            inbox: sortMessages('admin', this.state.allMessages),
            admin: true,
            quotesVisible: false,
            showChat: true,
            userID: 'admin'
        }))
    }

    /**
     * updates state to reflect active tab 
     */
    onTabChange = () => {
        if (this.state.onTab === 'inbox') {
            this.setState(() => ({ 
                    onTab: 'quotes',
                 }))
        } else {
            this.setState(() => ({ 
                    onTab: 'inbox',
                }))
        }
    }

    /**
     * navigates to the bookings page
     */
    onClose = () => {
        this.setState(() => ({ showChat: false }))
        this.props.props.props.history.push('/bookings')
    }

    /**
     * updates state and calls onCancel in TopNav
     */
    onCancel = () => {
        this.props.onCancel();
        this.setState(() => ({
            showChat: false,
            open: false,
            showInbox: true,
            onTab: 'inbox',
            quotes: sortQuotes(this.props.userID, this.state.allMessages),
            admin: false
        }))
    }

    /*
     * updates state to hide messages and resort quotes
     */
    onBack = () => {
        this.setState(() => ({ 
            showMessages: false,
            showInbox: true,
            quotes: sortQuotes(this.props.userID, this.state.allMessages),
        }))
        if (this.state.admin) {
            this.setState(() => ({ inbox: sortMessages('admin', this.state.allMessages) }))
        }
    }

    /**
     * method for handling opening a message
     * updates status to read and calls action to edit message 
     */
    onOpen = (buyerName, toUserID, userID) => {
        let messages
        if (this.state.admin) {
            messages = selectMessages(toUserID === 'admin' ? userID : toUserID, 'admin', this.state.allMessages);
            this.setState(() => ({ toUserID: toUserID === 'admin' ? userID : toUserID }));
        } else {
            messages = selectMessages(toUserID, this.state.userID, this.state.allMessages);
            this.setState(() => ({ toUserID: toUserID }));
        }

        this.setState(() => ({ 
            buyerName,
            messages,
            allMessages: this.state.allMessages,
            toUserName: this.props.admin ? buyerName : this.props.seller ? buyerName : findServiceName(toUserID, this.state.services),
            showInbox: false,
            showMessages: true
        }));

        // update message status in database
        let id = this.props.userID;
        messages.map((message) => {
            if (message.status && message.status === 'unread') {
                let newMessage = {
                    status: 'read',
                }
                this.props.startEditMessage(id, message.id, newMessage);
                this.setState(() => ({ unreadCount: getAllUnread(this.state.allMessages) }))
            }
        })
    }
    
    /**
     * method for handling opening a quote
     * updates status to read and calls action to edit quote
     */
    onOpenQuote = (buyerName, toUserID, quoteID, quotes) => {
        this.setState(() => ({ 
            buyerName,
            quoteID,
            quotes,
            toUserName: this.props.seller ? buyerName : findServiceName(toUserID, this.state.services),
            toUserID,
            showInbox: false,
            unreadCount: getAllUnread(this.state.quotes) 
        }));

        // update message status in database
        let id = this.props.userID;
        quotes.map((q) => {
            if (q.status && q.status === 'unread') {
                let newQuote = {
                    status: 'read',
                }
                this.props.startEditMessage(id, quoteID, newQuote);
            }
        })
    }

    /*
     * method for handling sending a message
     */
    onMessageSend = async (message) => {
        const mid = uuid();
        let newMessage = {};
        let newOtherMessage = {};
        let toUserID = this.state.toUserID ? this.state.toUserID : 'admin'
        const time = moment().toString();
        if (message) {
            newMessage = { message: message, toUserID: toUserID, userID: this.props.userID, time: time, buyerName: this.state.buyerName };
            newOtherMessage = { message: message, toUserID: toUserID, userID: this.props.userID, status: 'unread', time: time, buyerName: this.state.buyerName };
            let allMessages = this.state.allMessages.concat(newMessage)
            let messages = this.state.messages.concat(newMessage)
            this.setState(() => ({ 
                allMessages,
                messages
            }));

        }

        // add new message to database
        if (this.state.admin) {

            if (this.props.seller) {
                this.props.startAddMessageSeller('', newOtherMessage, '', toUserID, 'admin', mid);
            } else {
                this.props.startAddMessageBuyer(newOtherMessage, '', toUserID, '', 'admin', mid);
            }
            this.props.startAddMessageAdmin(newMessage, 'admin', mid);

        } else {

            let type = this.props.seller ? 'seller' : 'buyer';
            let buyerMessage = this.props.seller ? newOtherMessage : newMessage;
            let sellerMessage = this.props.seller ? newMessage : newOtherMessage;
            let buyerID = this.props.seller ? toUserID : this.props.userID;
            let sellerID = this.props.seller ? this.props.userID : toUserID;

            if (sellerID === undefined) { sellerID = 'admin' }
            if (buyerID === undefined) { buyerID = 'admin' }

            this.props.startAddMessageSeller(buyerMessage, sellerMessage, buyerID, sellerID, type, mid);
            this.props.startAddMessageBuyer(buyerMessage, sellerMessage, buyerID, sellerID, type, mid);
            
            if (this.props.seller) {
                // email notification for new email to service provider from  buyer
                sendNotificationEmail(buyerID);                
            } else{
                sendNotificationEmail(sellerID);
            }
        }
    }

    /**
     * method for handling sending a quote
     */
    onQuoteSend = (newQuote, newQuoteBuyer) => {
        let type = this.props.seller ? 'seller' : 'buyer';
        this.props.startEditMessageSeller(this.state.quotes[0].toUserID, this.state.quotes[0].id, newQuote, type);
        this.props.startEditMessageBuyer(this.state.quotes[0].userID, this.state.quotes[0].id, newQuoteBuyer, type);
        this.setState(() => ({ 
            quotes: sortQuotes(this.props.userID, this.props.allMessages, this.state.quotes[0].id),
            showInbox: true,
        }))
    }

    /**
     * method for handling booking a quote
     */
    onQuoteBook = (action, quote) => {
        if (action != undefined) {
            this.props.addRoute(this.state.quotes[0].cost);
            this.props.props.props.history.push(`/service/${findServiceName(this.state.toUserID, this.state.services).toLowerCase().replace(/ /g,'')}`)
        } else {
            this.props.addRoute('book');
            this.props.props.props.history.push(`/service/${findServiceName(this.state.toUserID, this.state.services).toLowerCase().replace(/ /g,'')}`)
        }

        let quoteUpdate = {
            a: true
        }
        this.props.startEditMessageSeller(quote.toUserID, quote.id, quoteUpdate, "buyer");
        this.props.startEditMessageBuyer(quote.userID, quote.id, quoteUpdate, "buyer");
    }
    
    /**
     * method for handling accepting a quote
     */
    onQuoteAccept = (booking, quote) => {

        this.props.startAddBooking(booking);

        let quoteUpdate = {
            a: true
        }
        this.props.startEditMessageSeller(quote.toUserID, quote.id, quoteUpdate, "buyer");
        this.props.startEditMessageBuyer(quote.userID, quote.id, quoteUpdate, "buyer");

        // email notification here for newly accepted quote and booking made
        sendQuoteAcceptedEmail(quote.toUserID, booking.service);

        // invoice email here
        // all details are found in 'booking' object
        sendInvoiceEmail(quote.userID, booking);
    }

    render() {
        return (

            <div>
                <div>

                    {/* inbox/chat pop up */}
                    <div className="chat-div">
                        <SweetAlert
                            style={{borderRadius: 15}}
                            show={this.state.showChat}
                            focusConfirmBtn={false}
                            confirmBtnText="Exit"
                            closeOnClickOutside
                            confirmBtnBsStyle="link"
                            title={'Inbox'}
                            heightAuto={false}
                            onCancel={this.onCancel}
                            onConfirm={this.onCancel}>
                            <div className="m-0 mt-2 p-0 bg-white">

                                {/* tab view controls */}
                                <div hidden={!this.state.quotesVisible || !this.state.showInbox} className="mt-5 mb-5">
                                <div className="d-flex justify-content-center text-center">
                                    <div className={this.state.onTab === 'inbox' ? "col category-nav-active pointer" : "col category-nav pointer"} onClick={this.onTabChange} style={{borderBottom: '0.2px solid gainsboro'}}>
                                        <h3 className="display-6 font-weight-light welcome-tab">Inbox</h3>
                                    </div>
                                    <div className={this.state.onTab === 'quotes' ? "col category-nav-active pointer" : "col category-nav pointer"} onClick={this.onTabChange} style={{borderBottom: '0.2px solid gainsboro'}}>
                                        <h3 className="font-weight-light welcome-tab">Quotes</h3>
                                    </div>
                                </div>
                                </div>
 
                                {/* quotes tab view */}
                                <div hidden={!(this.state.onTab === 'quotes')}>
                                    <Quotes
                                        allMessages={this.state.allMessages}
                                        allBookings={this.props.allBookings}
                                        onCancel={this.onCancel}
                                        onClose={this.onClose}
                                        onBack={this.onBack}
                                        onQuoteAccept={this.onQuoteAccept}
                                        onQuoteBook={this.onQuoteBook}
                                        onQuoteSend={this.onQuoteSend}
                                        userID={this.props.userID}
                                        quotes={this.state.quotes}
                                        seller={this.props.seller}
                                        services={this.state.services}
                                        onOpenQuote={this.onOpenQuote} />
                                </div>

                                {/* messages tab view */}
                                <div hidden={(this.state.onTab === 'quotes')}>
                                    {!this.state.showMessages ?
                                
                                        <MessageList
                                            inbox={this.state.inbox}
                                            seller={this.props.seller}
                                            services={this.state.services}
                                            allMessages={this.state.allMessages}
                                            services={this.state.services}
                                            admin={this.state.admin}
                                            onOpen={this.onOpen} />
                                    :
                                        <div>
                                            <Message
                                                userID={this.state.userID}
                                                onBack={this.onBack}
                                                toUserName={this.state.admin ? findServiceName(this.state.toUserID, this.state.services) ? `${this.state.toUserName} - ${findServiceName(this.state.toUserID, this.state.services)}` : this.state.toUserName : this.state.toUserName}
                                                messages={this.state.messages} />
                                            <Form 
                                                onMessageSend={this.onMessageSend} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* chat icon for admin page */}
                    <div hidden={!this.state.showIcon} className="fixed-bottom mb-5 mr-5">
                        <div className="float-right d-flex justify-content-center chat-icon" style={{width: 40, height: 40, background: '#142948', borderRadius: 100, boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)'}} onClick={this.onIcon}>
                            {getAllUnread(this.state.allMessages) === 0 ? (
                                    <img src='/images/chat.png' className="img img-fluid mt-3" style={{maxBlockSize: 20}}/>
                                ) : (
                                    <img src='/images/chat-notification.png' className="img img-fluid mt-2" style={{maxBlockSize: 26}}/>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    services: state.services,
    allMessages: state.messages,
    allBookings: state.allBookings
})

const mapDispatchToProps = (dispatch) => ({
    startAddMessageBuyer: (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => dispatch(startAddMessageBuyer(buyerMessage, sellerMessage, buyerID, sellerID, type, mid)),
    startAddMessageSeller: (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => dispatch(startAddMessageSeller(buyerMessage, sellerMessage, buyerID, sellerID, type, mid)),
    startAddMessageAdmin: (message, type, mid) => dispatch(startAddMessageAdmin(message, type, mid)),
    startEditMessage: (id, messageID, updates) => dispatch(startEditMessage(id, messageID, updates)),
    startEditMessageBuyer: (id, messageID, updates, type) => dispatch(startEditMessageBuyer(id, messageID, updates, type)),
    startEditMessageSeller: (id, messageID, updates, type) => dispatch(startEditMessageSeller(id, messageID, updates, type)),
    startEditMessageAdmin: (id, messageID, updates, type) => dispatch(startEditMessageAdmin(id, messageID, updates, type)),
    startAddBooking: (booking) => dispatch(startAddBooking(booking)),
    addRoute: (route) => dispatch(addRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);