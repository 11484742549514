import React from 'react';
import moment from 'moment';
import Rating from 'react-rating';

/**
 * reviews component to display reviews
 * rendered by the service profile component
 */
export class Reviews extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: this.props.show ? this.props.show : false
        }
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.show != this.state.show) { this.setState(() => ({ show: newProps.show }))}
    }

    render() {
        return (

            <div hidden={!this.state.show}>
                {this.props.reviews ? (Object.values(this.props.reviews).map((review, index) => (
                    <div key={index} className="ml-3">
                        <h5 className="text-muted mb-2">{moment(parseInt(review.substring(0,13))).format("DD MMM YYYY")}</h5>
                        <Rating
                            id="rating"
                            readonly
                            {...this.props}
                            initialRating={parseInt(review.substring(review.length -1))}
                            emptySymbol={<img src="/images/star-empty.jpg" className="icon" style={{maxWidth: 20}} />}
                            fullSymbol={<img src="/images/star-full.jpg" className="icon" style={{maxWidth: 20}} />} />
                        <h4 className="font-weight-light mt-4">{review.substring(14,review.length-1)}</h4>
                        <hr/>
                    </div>
                ))) : ( 
                    <div><h4 className="font-weight-light text-center mt-5 pt-5 pb-5 mb-5">No reviews yet</h4></div> )}
            </div>
        )
    }
}

export default Reviews;