import database from '../firebase/firebase';

// SET_UP_CATEGORIES

/** set categories in redux store */
export const setCategories = (categories) => ({
    type: 'SET_CATEGORIES',
    categories
});

/** get categories from database */
export const startSetCategories = () => {
    return (dispatch) => {
        return database.ref('categories')
            .once('value')
            .then((snapshot) => {
                const categories = [];

                snapshot.forEach((childSnapshot) => {
                    if (childSnapshot.val().image) {
                        const category = childSnapshot.val().val;
                        const image = childSnapshot.val().image
                        categories.push({
                            id: childSnapshot.key,
                            category,
                            image
                        });
                    } else {
                        const category = childSnapshot.val();
                        categories.push({
                            id: childSnapshot.key,
                            category
                        });
                    }
            });
            dispatch(setCategories(categories));
        })
    }
};


// ADD_CATEGORY

/** adds new category to reedux store */
export const addCategory = (category) => ({
    type: 'ADD_CATEGORY',
    category
});

/** adds new category to database */
export const startAddCategory = (category) => {
    return (dispatch) => {
        return database.ref(`categories/${category.id}`)
            .set({
                val: category.category,
                image: category.image
            })
            .then(() => {
                dispatch(addCategory({
                    id: category.id,
                    ...category
                }));
        });
    };
};

// REMOVE_CATEGORY

/** removes category from redux store */
export const removeCategory = (category) => ({
    type: 'REMOVE_CATEGORY',
    category
});

/** removes category from database */
export const startRemoveCategory = (category) => {
    return (dispatch) => {
        return database.ref(`categories/${category}`)
            .remove()
            .then(() => {
                dispatch(removeCategory(category));
        });
    };
};
