import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {getBuyers} from '../../utils/admin';
import Profile from '../Profile/Profile';
import ProfileForm from '../Settings/ProfileForm';
import RegisterForm from '../Register/RegisterForm';

/**
 * buyers view for admin page
 */
export class Buyers extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            buyers: this.props.buyers ? this.props.buyers : [],
            text: '',
            showDisable: false,
            buyer: {},
            sortby: this.props.sortby ? this.props.sortby : 'name',
            showProfile: false,
            showEditProfile: false,
            showConfirmDeleteProfile: false,
            showCreateProfile: false,
            showMessageForm: false
        }
    }

    /**
     * updates the state when new props are received from AdminPage
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.buyers != newProps.buyers) {
            this.setState(() => ({ buyers: newProps.buyers }))
        } if (this.state.sortby != newProps.sortby) {
            this.setState(() => ({ sortby: newProps.sortby }))
        }
    }

    /**
     * updates state based on search input
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ text }))
    }

    /**
     * updates the state for sortby selection
     */
    onSortby = (value) => {
        if (value === 'name') {
            this.setState(() => ({ sortby: 'name' }))
        } if (value === 'date') {
            this.setState(() => ({ sortby: 'date' }))
        }
    }

    /**
     * implemented for all cancel button clicks on pop ups
     * updates the state to not show all pop ups
     */
    onCancel = () => {
        this.setState(() => ({ 
            showDisable: false,
            buyer: {},
            showProfile: false,
            showEditProfile: false,
            showConfirmDeleteProfile: false,
            showCreateProfile: false,
            showMessageForm: false
        }))
    }

    /**
     * updates state to show confirm disable pop up and to selected buyer
     */
    onDisableBuyer = (buyer) => {
        this.setState(() => ({ 
            showDisable: true,
            buyer
         }))
    }

    /**
     * calls AdminPage method to disable selected buyer
     * updates state to close confirm disable pop up and clear buyer
     */
    onConfirmDisable = () => {
        this.props.disableProfile(this.state.buyer)
        this.setState(() => ({ 
            showDisable: false,
            buyer: {}
        }))
    }

    /**
     * calls AdminPage method to enable selected buyer
     */
    onEnableBuyer = (buyer) => {
        this.props.enableProfile(buyer)
    }

    /**
     * updates state to show buyer profile pop up and to selected buyer
     */
    onViewProfile = (buyer) => {
        this.setState(() => ({
            buyer,
            showProfile: true
        }))
    }

    /**
     * updates state to show confirm delete pop up and close profile pop up
     */
    onDeleteProfile = () => {
        this.setState(() => ({
            showProfile: false,
            showConfirmDeleteProfile: true
        }))
    }

    /**
     * calls AdminPage method to delete selected buyer
     * updates state to close confirm delete pop up and clear buyer
     */
    onConfirmDeleteProfile = () => {
        this.props.onDeleteProfile(this.state.buyer.id)
        this.setState(() => ({
            showConfirmDeleteProfile: false,
            buyer: {}
        }))
    }

    /**
     * updates state to show pop up for editing a profile
     * and close pop up for viewing a profile
     */
    onEditProfile = () => {
        this.setState(() => ({
            showProfile: false,
            showEditProfile: true
        }))
    }

    /**
     * submits edited profile to AdminPage method
     * updates state to close edit profile pop up, clear buyer and update buyers
     */
    onSubmitProfile = (profile) => {
        this.props.onSubmitProfile(profile, this.state.buyer.id)
        this.setState(() => ({
            showEditProfile: false,
            buyer: {}
        }))
    }

    /**
     * updates state to show pop up for creating a new profile
     */
    onCreateProfile = () => {
        this.setState(() => ({
            showCreateProfile: true
        }))
    }

    /**
     * submits new profile to AdminPage method
     * updates state to close create profile pop up
     */
    onSubmitNewProfile = (profile) => {
        this.props.onSubmitNewProfile(profile)
        this.setState(() => ({
            showCreateProfile: false
        }))
    } 

    /**
     * calls onMessage method in AdminPage
     */
    onMessageBuyer = (buyer) => {
        this.props.onMessage(buyer)
    }

    /**
     * not used but had to be included as radio buttons require an onchange handler or give an error
     * (radio button changes are handled by the onClick => onSortby method)
     */
    doNothing = () => {
        // do nothing
    }

    render() {
        return (

            <div>
                <div className="mb-3 ml-5">

                    {/* pop up for disabling buyer */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showDisable}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="default"
                            showCancel
                            cancelBsBtnText="Cancel"
                            cancelBsBtnBsStyle="link"
                            title={`Disable ${this.state.buyer.name}`}
                            onConfirm={this.onConfirmDisable}
                            onCancel={this.onCancel}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-sm-10 mt-5">
                                    <h3 className="row font-weight-light text-left display-5 mb-5">Are you sure you want to disable this account?</h3>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* pop up for confirming deleting of buyer */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showConfirmDeleteProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="default"
                            showCancel
                            cancelBsBtnText="Cancel"
                            cancelBsBtnBsStyle="link"
                            title={`Delete ${this.state.buyer.name}`}
                            onConfirm={this.onConfirmDeleteProfile}
                            onCancel={this.onCancel}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-sm-10 mt-5">
                                    <h3 className="row font-weight-light text-left display-5 mb-5">Are you sure you want to permanently delete this account?</h3>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* pop up for viewing profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.buyer.name ? this.state.buyer.name : ''}
                            onConfirm={this.onCancel}>
                            <Profile profile={this.state.buyer} />
                            <div className="d-flex justify-content-center">
                                <div className="col-7">
                                    <button className="rounded-0 btn btn-main btn-block mb-4" onClick={this.onEditProfile} style={{height: 30}}>
                                        <h4 className="font-weight-light">Edit</h4>
                                    </button>
                                    <button className="rounded-0 btn btn-main btn-block" onClick={this.onDeleteProfile} style={{height: 30}}>
                                        <h4 className="font-weight-light">Delete</h4>
                                    </button>
                                </div>
                            </div>

                        </SweetAlert>
                    </div>

                    {/* pop up for editing profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showEditProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Close"
                            confirmBtnBsStyle="link"
                            title={this.state.buyer.name ? this.state.buyer.name : ''}
                            onConfirm={this.onCancel}>
                            <ProfileForm
                                edit={true}
                                profile={this.state.buyer}
                                onSubmit={this.onSubmitProfile}
                                onCancel={this.onCancel}
                            />
                        </SweetAlert>
                    </div>

                    {/* pop up for creating profile */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showCreateProfile}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            onCancel={this.onCancel}
                            heightAuto={false}
                            confirmBtnText="Cancel"
                            confirmBtnBsStyle="link"
                            title={'Create a New Buyer Profile'}
                            onConfirm={this.onCancel}>

                            <div className="m-3">
                                <hr/>
                                <div className="mt-1 d-flex justify-content-center">
                                    <div className="m-5 mb-0 pb-0 col-6">
                                        <RegisterForm
                                            superuser={true}
                                            onSubmit={this.onSubmitNewProfile}
                                            type={'buyer'}
                                            errorMessage={''} />
                                    </div>
                                </div>
                                <hr/>
                            </div>

                        </SweetAlert>
                    </div>

                    {/* create profile button */}
                    <div className="d-flex justify-content-center mt-3 mb-5" style={{height: 30}}>
                        <div className="col-2"></div>
                        <button className="btn btn-block col-12 col-sm-8" onClick={this.onCreateProfile}><h4 className="font-weight-light">Create New Profile</h4></button>
                        <div className="col-2"></div>
                    </div>
                    <hr/>

                    <div className="d-flex justify-content-center">
                        <div className="col-2"></div>

                        {/* search bar input */}
                        <div className="d-flex justify-content-center mb-5 mt-5 col-6 pl-0">
                            <div className="col-12 m-0 ml-2 p-0">
                                <input
                                    className="form-control form-control-lg rounded-0 font-weight-light text-center"
                                    type="text"
                                    placeholder={`Search Buyers`}
                                    value={this.state.text}
                                    onChange={this.onTextChange}/>
                            </div>
                        </div>

                        {/* sort by radio buttons */}
                        <div className="mt-3 mr-3 col-2">
                            <div className="float-right">
                                <h5 className="font-weight-light">Sort by</h5>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.doNothing} type="radio" onClick={(() => this.onSortby('name'))} checked={this.state.sortby === 'name' ? true : false}/>
                                    <label className="form-check-label font-weight-light">Name</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.doNothing} type="radio"  onClick={(() => this.onSortby('date'))} checked={this.state.sortby === 'date' ? true : false}/>
                                    <label className="form-check-label font-weight-light">Created</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-2"></div>
                    </div>
                    <hr/>

                    {/* results display */}
                    <div className="mb-5 mt-5">
                        <h4 className="text-center display-6">{getBuyers(this.state.buyers, this.state.text, this.state.sortby).length} results</h4>
                    </div>

                    {/* headings display */}
                    <div className="row p-0 m-0">
                        <div className="row col-10">
                            <div className="col-3 text-wrap p-0 pr-2 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Created</h5>
                            </div>
                            <div className="col-4 text-wrap p-0 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Name</h5>
                            </div>
                            <div className="col-5 text-wrap p-0 m-0">
                                <h5 style={{color: 'gainsboro'}} className="text-left font-weight-light p-0 m-0">Email</h5>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    
                    {/* buyer list display */}
                    {getBuyers(this.state.buyers, this.state.text, this.state.sortby).map((buyer, index) => (
                        <div key={index}>
                            <div className="row pb-3 pt-2 hover-border">
                                <div className="row col-10" onClick={(() => this.onViewProfile(buyer))}>
                                    <h5 className="col-3 font-weight-light">{buyer.date.substring(4,15)}</h5>
                                    <h4 className="col-4 font-weight-light text-muted display-5">{buyer.name}</h4>
                                    <h5 className="col-5 font-weight-light">{buyer.email}</h5>
                                </div>
                                <div className="col-2">
                                    <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onViewProfile(buyer))}>View Profile</h5>
                                    <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onMessageBuyer(buyer))}>Message</h5>
                                    {(buyer.disabled != undefined && buyer.disabled === true) ? <h5 className="font-weight-light m-0 p-0 text-right hover-orange" style={{color: 'red'}} onClick={(() => this.onEnableBuyer(buyer))}>Enable</h5> : <h5 className="font-weight-light m-0 p-0 text-right hover-orange" onClick={(() => this.onDisableBuyer(buyer))}>Disable</h5>}
                                </div>
                            </div>
                            <hr/>
                        </div>
                    ))}

                </div>
            </div>
        )
    }
}

export default Buyers;