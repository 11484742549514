import React from 'react';
import {connect} from 'react-redux';
import TopNav from '../TopNav';
import Footer from '../Footer';
import ServiceForm from './ServiceForm';
import {startAddService, startEditService} from '../../actions/services';
import {addRoute} from '../../actions/route';

/**
 * Page for sellers to edit service profiles
 *  
 * www.itorho.com/edit
 * 
 */
export class EditServicePage extends React.Component {

    state = {
        service: this.props.service ? this.props.service : '',
        submitting: false
    }

    /**
     * updates state to submitting - shows loading gif in service form
     * calls action to edit service in database
     * navigates user back to service profile view page
     */
    onSubmit = (id, service) => {
        this.setState(() => ({ submitting: true }))
        let s = this.props.service.name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
        this.props.startEditService(id, service);
    }

    /**
     * navigates user back to view service page
     */
    onCancel = () => {
        let s = this.props.service.name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true} type={'seller'}/>
                <div>
                    <ServiceForm
                        edit={true}
                        id={this.props.id}
                        companyNames={this.props.companyNames}
                        categories={this.props.categories}
                        service={this.state.service}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel} 
                        onRemove={this.onRemove}
                        admin={false}
                        submitting={this.state.submitting}/>
                </div>
                <div className="mt-5 footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    service: state.services[0],
    id: state.profile[0].id,
    companyNames: state.services.map((service) => ( service.name )),
    categories: state.categories.map((category) => {
        return {
            label: category.category,
            value: category.id
        }
    })
})

const mapDispatchToProps = (dispatch) => ({
    startEditService: (id, service) => dispatch(startEditService(id, service)),
    startAddService: (service) => dispatch(startAddService(service)),
    addRoute: (route) => dispatch(addRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditServicePage);