import React from 'react';
import moment from 'moment';
import {findServiceName, getQuoteStatus} from '../../selectors/messages';
import {isDayAvailable, getTimes} from '../../utils/bookings';
import {getServiceBookings} from '../../selectors/bookings';

/**
 * component for displaying a quote to sellers and buyers
 * allows sellers to respond with cost and message
 * 
 * rendered by the Quotes component
 */
export class Quote extends React.Component {

    state = {
        cost: this.props.cost ? this.props.cost : 0,
        reply: this.props.reply ? this.props.reply : '',
        formError: ''
    }

    /**
     * methods for updating state based on form input
     */
    onCostChange = (e) => {
        const cost = e.target.value;
        this.setState(() => ({ 
            cost,
            formError: '' 
        }));
    }
    onReplyChange = (e) => {
        const reply = e.target.value;
        this.setState(() => ({ reply }));
    }

    /**
     * method to handle sending a quote
     * calls onQuoteSend method in Quotes
     */
    onQuoteSend = () => {
        if (parseInt(this.state.cost) === 0 || this.state.cost === '') {
            this.setState(() => ({ formError: 'Please provide a valid cost' }))
        } else {
            this.setState(() => ({ formError: '' }))
            this.props.onQuoteSend(this.state.reply, this.state.cost)
        }
    }

    /**
     * method to handle accepting a quote
     * calls onQuoteAccept method in Quotes
     */
    onQuoteAccept = () => {

        // recheck time and date
        let bookings = getServiceBookings(this.props.allBookings, this.props.quotes[0].toUserID)
        let service = (this.props.services.filter((s) => s.id === this.props.quotes[0].toUserID))[0]
        let dayAvailable = !isDayAvailable(moment(this.props.quotes[0].d), service.availability)
        let timeAvailable = getTimes(moment(this.props.quotes[0].d), service.availability, this.props.quotes[0].dur, bookings).includes(this.props.quotes[0].t)
        
        if (dayAvailable && timeAvailable) {
            this.props.onQuoteAccept()
        } else {
            this.props.onQuoteCannotAccept()
        }
    }

    /**
     * method to return boolean based on whether day is available for bookings
     */
    isDayBlocked = (day) => {
        return isDayAvailable(day, this.state.availability)
    }

    render() {
        return (

            <div>
                <div onClick={this.props.onBack} className="link float-left mr-4">
                    <img src="/images/back.png" className="img" style={{maxWidth:15, maxHeight:15}}/>
                </div>

                {this.props.quotes.length != 0 ? (
                    this.props.quotes[0].userID == this.props.userID ?
                        <div>
                            <h3 className="text-left font-weight-light mt-5">Quote for <span className="font-weight-bold">{this.props.quotes[0].i}</span> at <span className="font-weight-bold">{findServiceName(this.props.quotes[0].toUserID, this.props.services)}</span></h3>
                            <hr/>
                            {this.props.quotes[0].d ? (
                                <div className="ml-5 mr-5 mt-5 text-left">
                                    <label>Date</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].d.substring(0,16)}</h4>
                                </div>
                            ) : (<div></div>)}
                            {this.props.quotes[0].t ? (
                                <div className="ml-5 mr-5 mt-4 text-left">
                                    <label>Time</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].t}</h4>
                                </div>
                            ) : (<div></div>)}
                            
                            {this.props.quotes[0].dur ? (
                                <div className="ml-5 mr-5 mt-4 text-left">
                                    <label>Duration</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].dur}</h4>
                                </div>
                            ) : (<div></div>)}

                            {this.props.quotes[0].message ? (
                                <div className="ml-5 mr-5 mt-4 text-left">
                                    <label>Message</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].message}</h4>
                                </div>
                            ) : (<div></div>)}
                            {this.props.quotes[0].reply ? (
                                <div className="ml-5 mr-5 mt-4 text-left">
                                    <label>Reply</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].reply}</h4>
                                </div>
                            ) : (<div></div>)}

                            {this.props.quotes[0].cost ? (
                                <div className="ml-5 mr-5 mt-4 mb-5 text-left">
                                    <label>Cost</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">R{this.props.quotes[0].cost}</h4>
                                </div>
                            ) : (<div></div>)}
                            {this.props.quotes[0].a === undefined && this.props.quotes[0].cost ?
                                <div className="ml-5 mr-5 mb-4 mt-5">
                                {this.props.quotes[0].d && this.props.quotes[0].t ?
                                    <button onClick={this.onQuoteAccept} className="rounded-0 btn btn-main btn-block" style={{height: 35}}>
                                        <h4 className="font-weight-light">Accept & Add to Bookings</h4>
                                    </button>
                                    :
                                    <button onClick={this.props.onQuoteBook} className="rounded-0 btn btn-main btn-block" style={{height: 35}}>
                                        <h4 className="font-weight-light">Make a Booking</h4>
                                    </button>
                                }
                                </div> 
                            : 
                                <div>
                                    {this.props.quotes[0].cost ?
                                        <div><h3>Booking Made</h3></div>
                                    :
                                        <div className="mt-5"><h3>Awaiting Reply</h3></div>
                                    }
                                </div>
                            }
                        </div>
                    :
                        <div>
                            {getQuoteStatus(this.props.quotes[0]) != "Pending" ?
                                <h3 className="text-left font-weight-light mt-5"><span className="font-weight-bold">{this.props.quotes[0].buyerName}</span> has requested a quote for <span className="font-weight-bold">{this.props.quotes[0].i}</span></h3>
                            :
                                <h3 className="text-left font-weight-light mt-5">Pending response from <span className="font-weight-bold">{this.props.quotes[0].buyerName}</span> for <span className="font-weight-bold">{this.props.quotes[0].i}</span></h3>
                            }
                            <hr/>

                            <div className="ml-5 mr-5 mt-5 text-left">
                                <label>Date</label>
                                <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].d.substring(0,16)}</h4>
                            </div>
                            {this.props.quotes[0].t ? (
                                <div className="ml-5 mr-5 mt-4 text-left">
                                <label>Time</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].t}</h4>
                                </div>
                            ) : (<div></div>)}
                            {this.props.quotes[0].message ? (
                                <div className="ml-5 mr-5 mt-4 mb-5 text-left">
                                    <label>Message</label>
                                    <h4 style={{border: '1px solid gainsboro'}} className="text-left p-3 font-weight-light">{this.props.quotes[0].message}</h4>
                                </div>
                            ) : (<div></div>)}
                            
                            <div>
                            <hr/>
                            <div className="mt-3 mb-4 ml-5 mr-5 text-left">
                                <label>Once you reply with a cost, this service item will be bookable at the cost specified below.</label>
                            </div>
                            <div className="ml-5 mr-5 mt-4 text-left">
                            <label>Cost</label>
                            <div className="input-group pb-4">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">R</span>
                                </div>
                                <input
                                    disabled={this.props.quotes[0].a != undefined || getQuoteStatus(this.props.quotes[0]) === "Pending"}
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="number"
                                    id="name"
                                    value={this.state.cost}
                                    onChange={this.onCostChange}/>
                                <div className="input-group-append">
                                    <span className="input-group-text">.00</span>
                                </div>
                            </div>
                            <small className="form-text mb-5" style={{color: '#ec9422'}}>{this.state.formError}</small>

                            <div className="pb-4">
                                <label className="text-left" htmlFor="time">Optional Message</label>
                                <textarea
                                    disabled={this.props.quotes[0].a != undefined || getQuoteStatus(this.props.quotes[0]) === "Pending"}
                                    className="form-control font-weight-light rounded-0 form-control-lg m-0 p-0"
                                    placeholder=""
                                    type="text"
                                    id="review"
                                    value={this.state.reply}
                                    onChange={this.onReplyChange}/>
                            </div>
                            </div>
                            </div>

                            <hr/>
                            <div className="d-flex justify-content-center mb-4 mt-5 ml-5 mr-5">
                                {getQuoteStatus(this.props.quotes[0]) === "Pending" ?
                                    <div><h3>Quote Pending</h3></div>
                                    :
                                        getQuoteStatus(this.props.quotes[0]) === "Accepted" ?
                                            <div><h3>Quote Accepted</h3></div>
                                        :
                                            <button disabled={this.state.cost === 0} onClick={this.onQuoteSend} className="rounded-0 btn btn-main btn-block" style={{height: 35}}>
                                                <h4 className="font-weight-light">Send Quote</h4>
                                            </button>
                                }
                            </div>

                        </div>
                ) : (
                    <div></div>
                )}
            </div>
        )
    }
}

export default Quote;