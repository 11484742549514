const usersReducerDefaultState = [];

export default (state = usersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_USERS':
            return action.users;
        case 'ADD_USER':
                return [
                    ...state,
                    action.user
                ];
        case 'EDIT_USER':
            return state.map((u) => {
                if (u.id === action.id) {
                    return {
                        ...u,
                        ...action.updates
                    };
                } else {
                    return u;
                }
            });
        case 'REMOVE_USER':
            return state.filter(({ id }) => action.id !== id);
        default:
            return state;
    }
};