import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import Item from './Item';
import {startEditService} from '../../actions/services';

/**
 * item list component for rendering the list of service items
 * rendered by the service profile component
 */
export class ItemList extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * navigates to the edit service item page
     */
    onEdit = (title) => {
        this.props.props.history.push(`/edit/${title}`);
        scroll.scrollTo(0, { duration: 750, delay: 10 });
    }

    /**
     * navigates to the add service item page
     */
    onAdd = () => {
        this.props.props.history.push(`/add`);
        scroll.scrollTo(0, { duration: 750, delay: 10 });
    }

    /**
     * method calls remove action to remove item from service profile on database
     */
    onRemove = (id) => {
        
        let serviceItems = {}
        Object.keys(this.props.service.serviceItems).filter((item, index) => {
            if (item != id) {
                serviceItems = {
                    ...serviceItems,
                    [Object.keys(this.props.service.serviceItems)[index]]: Object.values(this.props.service.serviceItems)[index]
                }
            }
        })
        this.props.startEditService(this.props.service.id, {
            ...this.props.service,
            serviceItems: serviceItems
        })
        scroll.scrollTo(0, { duration: 750, delay: 10 });
    }

    render() {
        return (

            <div className="text-center font-weight-light mb-5">
                <div>
                    {!this.props.items || this.props.items.length === 0 ? (
                        <h4 className="mt-5 pt-3 pb-3 font-weight-light text-center" style={{border: '1px solid gainsboro'}}>No Services Listed</h4>
                    ) : (
                        <div>
                            {Object.values(this.props.items).map((item, index) => {
                                return (
                                    <div key={index} className="mb-3 mt-3">
                                        <div style={{border: '1px solid gainsboro'}}>
                                            <Item {...item} id={Object.keys(this.props.items)[index]} edit={this.props.edit} onEdit={this.onEdit} onRemove={this.onRemove}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    {this.props.edit ? (
                        <div>
                            <NavLink className="no-underline mb-5 pb-5" to="/add" onClick={() => scroll.scrollTo(0, { duration: 750, delay: 10 })}>
                                <div className="d-flex justify-content-center mb-5 btn">
                                    <h4 className="font-weight-light p-2 pt-3">Add Service</h4>
                                </div>
                            </NavLink>
                        </div>
                    ) : (
                        <div>
                        </div>
                    )}
                    <hr/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    service: state.services[0]
})

const mapDispatchToProps = (dispatch) => ({
    startEditService: (id, updates) => dispatch(startEditService(id, updates))
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);