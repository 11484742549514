const routeReducerDefaultState = [];

export default (state = routeReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_ROUTE':
            return [
                action.route
            ];
        case 'REMOVE_ROUTE':
            return [];
        default:
            return state;
    }
};