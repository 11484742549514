const allEventsReducerDefaultState = [];

export default (state = allEventsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_ALL_EVENTS':
            return action.events;
        case 'EDIT_ALL_EVENTS':   
            return state.map((event) => {
                if (event.id === action.eventID) {
                    return {
                        ...event,
                        ...action.updates
                    };
                } else {
                    return event;
                }
            });
        case 'REMOVE_ALL_EVENTS':
            return state.filter(({ id }) => action.eventID !== id);
        default:
            return state;
    }
};