import moment from 'moment';

/** returns a sorted and numbered list of months */
export const getMonths = (bookings) => {

    let months = []
    bookings.map((b) => {
        let month;

        if (b.date.substring(0,3) === 'Jan') { month = '1 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Feb') { month = '2 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Mar') { month = '3 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Apr') { month = '4 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'May') { month = '5 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Jun') { month = '6 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Jul') { month = '7 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Aug') { month = '8 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Sep') { month = '9 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Oct') { month = '10 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Nov') { month = '11 ' + b.date.substring(0,3) }
        if (b.date.substring(0,3) === 'Dec') { month = '12 ' + b.date.substring(0,3) }

        if (months.length === 0) { months.push(month) }
        else if (!months.includes(month)) { months.push(month) }

        months = months.sort((a, b) => {
            return a <= b ? 1 : -1;
        })
    })

    return months;
}

/** 
 * returns the total income from bookings during a certain month
 * 
 * NOTE for future: this does not take year into account
 * 
 */
export const getMonthTotal = (month, bookings) => {

    let total = 0
    bookings.map((b) => {
        if (b.date.includes(month)) {
            total += parseInt(b.cost)
        }
    })
    return total;
}

/** sorts bookings by date from furthest away to most recent */
export const sortBookings = (bookings) => {
    return bookings.sort((a, b) => {
        return moment(a.date).isSameOrAfter(moment(b.date), 'day') ? 1 : -1;
    });
}