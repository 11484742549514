import React from 'react';
import {findServiceName, getUnread} from '../../selectors/messages';

/**
 * component for rendering list of messages in inbox
 * rendered by the Inbox
 */
export const MessageList = (props) => (
    
    <div>
        <div>
            {props.inbox.length != 0 ? (
                props.inbox.map((other, index) => (
                        <div key={index} className="p-3 link-chat" style={{border: '1px solid gainsboro'}} onClick={() => props.onOpen(other.buyerName, other.userID, other.toUserID)}>
                            <h3 className="font-weight-light ml-3 mt-1" style={{color: 'black'}}>{props.admin ? (findServiceName(other.toUserID, props.services) ? `${other.buyerName} - ${findServiceName(other.toUserID, props.services)}` : other.buyerName) : other.userID === 'admin' ? 'Itorho Customer Service' : props.seller ? other.buyerName : findServiceName(other.userID, props.services)}</h3>
                            <h5 className="font-weight-light ml-3 mb-1">{getUnread(props.admin ? other.toUserID : other.userID, props.allMessages)} unread</h5>
                        </div>
                ))
            ) : (
                <h3>You have no messages</h3>
            )}
        </div>
    </div>
)

export default MessageList;