import React, { Component } from 'react';

/**
 * component displays google map view with pinned location
 * rendered by service profile component
 */
class Map extends Component {
  
    constructor(props) {
        super(props);
        this.onScriptLoad = this.onScriptLoad.bind(this)
    }

    componentDidMount() {
        
        if (!window.google) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?key=AIzaSyAX2iUx4TfeUcNfkmVl5juOSP0VUN3f_yU`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);

            s.addEventListener('load', e => {
                this.onScriptLoad()
            })
        } else {
            this.onScriptLoad()
        }
    }
    
    onScriptLoad() {
        const map = new window.google.maps.Map(
        document.getElementById(this.props.id),
        this.props.options);
        this.props.onMapLoad(map)
    }

    render() {
        return (
            <div style={{ width: 500, height: 500 }} id={this.props.id} />
        )
    }
}

export default Map;
  