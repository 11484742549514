import React from 'react';


/**
 * form for logging in
 * used by the login page
 */
export default class LoginForm extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loginType: '',
            email: '',
            password: '',
            error: '',
            errorMessage: this.props.errorMessage ? this.props.errorMessage : ''
        }
    }

    /**
     * method for updating state when receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.errorMessage != newProps.errorMessage) {
            this.setState(() => ({ errorMessage: newProps.errorMessage }))
        }
    }

    /**
     * method for updating state to google login type
     */
    onGoogle = () => {
        this.setState(() => ({ loginType: 'Google' }))
    }

    /**
     * methods for updating state based on form input
     */
    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
    }
    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({ password }));
    }
    onEmail = () => {
        this.setState(() => ({ loginType: 'Email' }))
    }

    /**
     * method calls onSubmit in login page
     * passes in loginType or login details
     */
    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.loginType == "Email") {
            if (!this.state.email || !this.state.password) {
                this.setState(() => ({ error: 'Please fill in all required fields.'}))
            } else {
                this.setState(() => ({ error: ''}))
                this.props.onSubmit({
                    loginType: this.state.loginType,
                    email: this.state.email,
                    password: this.state.password
                });
            }
        } else {
            this.props.onSubmit({
                loginType: this.state.loginType
            });
        }
    };

    render() {
        return (

            <div className="d-flex justify-content-center ml-5 mr-5">
                <div className="text-left">
                    <form onSubmit={this.onSubmit}>

                        <div className="form-group pb-1">
                            <input
                                className="form-control form-control-lg"
                                type="email"
                                value={this.state.email}
                                onChange={this.onEmailChange}
                                placeholder="Email"
                                />
                        </div>

                        <div className="form-group pb-1">
                            <input
                                className="form-control form-control-lg"
                                type="password"
                                value={this.state.password}
                                onChange={this.onPasswordChange}
                                placeholder="Password"
                                />
                        </div>

                        <div className="form-group pb-1">
                            <type
                                className="form-control form-control-lg"
                                type="checkbox"
                                value={this.state.password}
                                onChange={this.onPasswordChange}
                                placeholder="Password"
                                />
                        </div>

                        <div className="form-group">
                            <button className="btn btn-lg" style={{width: 210}} onClick={this.onEmail}>Login</button>
                        </div>

                        {this.state.error && <h5 className="font-weight-light" style={{color: '#ec9422'}}>{this.state.error}</h5>}
                        {this.state.errorMessage && <h5 className="font-weight-light" style={{color: '#ec9422'}}>{this.state.errorMessage}.</h5>}

                        <button className="mt-5 social-btn" onClick={this.onGoogle}>
                            <img src="/images/google.JPG" onMouseOver={e => (e.currentTarget.src = "/images/google2.JPG")} onMouseOut={e => (e.currentTarget.src = "/images/google.JPG")} className="link" style={{width: 210}}/>
                        </button>

                    </form>
                </div>
            </div>
        )
    }

}