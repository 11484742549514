import React from 'react';
import {connect} from 'react-redux';
import TopNav from '../TopNav';
import Analytics from './Analytics';
import Categories from './Categories';
import Buyers from './Buyers';
import Sellers from './Sellers';
import Footer from '../Footer';
import Inbox from '../Chat/Inbox';
import {startSetUsers} from '../../actions/users';
import {startSetMessages} from '../../actions/messages';
import {getBuyers, getSellers} from '../../utils/admin';
import {startEditProfile, startAddProfile} from '../../actions/profile';
import {startRemoveUser} from '../../actions/users';
import {startAddService, startEditService, startRemoveService} from '../../actions/services';
import {startSetBookings} from '../../actions/bookings';
import {startAddCategory, startRemoveCategory} from '../../actions/categories';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * Page providing admin (superuser) functionality and analytics overview
 * 
 * www.itorho.com/admin
 */
export class AdminPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTab: 'analytics',
            sortby: 'name',
            buyers: this.props.users ? getBuyers(this.props.users, '') : [],
            sellers: this.props.users ? getSellers(this.props.users, '', 'name', this.props.services) : [],
            users: this.props.users ? this.props.users : [],
            services: this.props.services ? this.props.services : [],
            bookings: [],
            categoryList: this.props.categoryList ? this.props.categoryList : [],
            showInbox: false,
            user: {}
        }
    }

    /**
     * calls action method to set all users and admin messages in the redux store when component mounts
     */
    componentDidMount = () => {
        this.props.startSetUsers();
        this.props.startSetMessages('admin');
        initGA();
        logPageView();
    }

    /**
     * updates the state when new props are received from the redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.users != this.state.users) {
            this.setState(() => ({ 
                users: newProps.users,
                buyers: getBuyers(newProps.users, ''),
                sellers: getSellers(newProps.users, '', 'name', newProps.services)
            }))
        } if (newProps.services != this.state.services) {
            this.setState(() => ({ 
                sellers: getSellers(newProps.users, '', 'name', newProps.services),
                services: newProps.services
            }))
        } if (newProps.bookings != this.state.bookings) {
            this.setState(() => ({ 
                bookings: newProps.bookings
            }))
        } if (newProps.categoryList != this.state.categoryList) {
            this.setState(() => ({ 
                categoryList: newProps.categoryList
            }))
        }
    }

    /**
     * sets the state for the selected tab view
     */
    onTabChange = (changeTo) => {
        this.setState(() => ({ showTab: changeTo }))
    }

    /**
     * changes to a tab view upon clicking a specific analytic
     * the analytic clicked will also determine whether there are any other viewing filters attached to the tab view
     */
    toTab = (tab) => {
        if (tab === 'buyers-all') { 
            this.setState(() => ({ 
                showTab: 'buyers',
                sortby: 'name'
            }))
        } if (tab === 'sellers-all') { 
            this.setState(() => ({ 
                showTab: 'sellers',
                sortby: 'name'
            }))
        } if (tab === 'buyers-new') { 
            this.setState(() => ({ 
                showTab: 'buyers',
                sortby: 'date'
            }))
        } if (tab === 'sellers-new') { 
            this.setState(() => ({ 
                showTab: 'sellers',
                sortby: 'date'
            }))
        } if (tab === 'sellers-services') { 
            this.setState(() => ({ 
                showTab: 'sellers',
                sortby: 'service'
            }))
        } if (tab === 'sellers-services-active') { 
            this.setState(() => ({ 
                showTab: 'sellers',
                sortby: 'service'
            }))
        } 
    }

    /**
     * sets the bookings for selected seller by calling the action in order to obtain transaction data
     */
    getBookings = (sellerID) => {
        this.props.startSetBookings(sellerID)
    }

    /**
     * the following methods all call associated action methods
     * they are used to update the database and redux store
     * when user edits, disables, deletes or creates profiles, service profiles or categories
     */
    onDeleteProfile = (id) => {
        this.props.startRemoveUser(id);

        // FOR ROGERS
        // delete user in firebase authentication here - id: id
    } 
    onDeleteServiceProfile = (id) => {
        this.props.startRemoveService(id);
    } 
    onSubmitProfile = (profile, id) => {
        this.props.startEditProfile(profile, id);
    }
    onSubmitServiceProfile = (id, service, add) => {
        Object.keys(service).forEach(key => service[key] === undefined ? delete service[key] : {});
        if (add) {
            this.props.startAddService(service);
        } else {
            this.props.startEditService(id, service);
        }
    }
    onSubmitNewProfile = (profile) => {
        this.props.startAddProfile(profile, 'super');
    }
    disableProfile = (profile) => {
        profile = {
            ...profile,
            disabled: true
        }
        this.props.startEditProfile(profile, profile.id)

        // FOR ROGERS
        // disable user in firebase authentication here - id: profile.id, email: this.state.buyer.email
    }
    enableProfile = (profile) => {
        profile = {
            ...profile,
            disabled: false
        }
        this.props.startEditProfile(profile, profile.id)

        // FOR ROGERS
        // enable disabled user in firebase authentication here - id: profile.id, email: this.state.buyer.email

    }
    disableServiceProfile = (profile) => {
        let service = {
            ...profile.service,
            disabled: true
        }
        this.props.startEditService(profile.id, service)
    }
    enableServiceProfile = (profile) => {
        let service = {
            ...profile.service,
            disabled: false
        }
        this.props.startEditService(profile.id, service)
    }
    onSaveCategory = (category) => {
        this.props.startAddCategory(category)
    }
    onDeleteCategory = (category) => {
        this.props.startRemoveCategory(category)
    }

    /**
     * method to handle showing inbox and setting user in state
     */
    onMessage = (user) => {
        this.setState(() => ({ 
            showInbox: true,
            user 
        }))
    }

    /**
     * method to hide inbox and reset user in state to null
     */
    onCancel = () => {
        this.setState(() => ({ 
            showInbox: false,
            user: {}
        }))
    }

    render() {
        return (
            <div>
            <div>
            <TopNav loggedIn={true} type={this.props.type} admin={true}/>
            <div>

                <div>
                    <Inbox
                        admin={true}
                        seller={this.state.user.type === 'seller' ? true : false}
                        userName={this.state.user ? this.state.user.name : ''}
                        toUserID={this.state.user ? this.state.user.id : ''}
                        userID={'admin'}
                        onServiceClick={false}
                        props={this.props}
                        onCancel={this.onCancel}
                        open={this.state.showInbox}/>
                </div>

                <div className="d-flex justify-content-center mt-5 mb-5 pt-5">
                    <div className="col-md-8 col-xs-12">

                        {/* tab views */}
                        <div className="row mb-4 mt-5 text-center">
                            <div className={this.state.showTab === 'analytics' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('analytics'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5">Analytics</h4>
                            </div>
                            <div className={this.state.showTab === 'categories' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('categories'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                                <h4 className="display-5">Categories</h4>
                            </div>
                            <div hidden={this.state.showTab != 'buyers'}>
                                <Buyers 
                                    buyers={this.state.buyers}
                                    sortby={this.state.sortby}
                                    onDeleteProfile={this.onDeleteProfile}
                                    onSubmitProfile={this.onSubmitProfile}
                                    onSubmitNewProfile={this.onSubmitNewProfile}
                                    disableProfile={this.disableProfile}
                                    enableProfile={this.enableProfile}
                                    onMessage={this.onMessage} />
                            </div>
                            <div hidden={this.state.showTab != 'sellers'}>
                                <Sellers 
                                    sellers={this.state.sellers}
                                    services={this.state.services}
                                    sortby={this.state.sortby}
                                    categories={this.props.categories}
                                    onDeleteProfile={this.onDeleteProfile}
                                    onDeleteServiceProfile={this.onDeleteServiceProfile}
                                    onSubmitProfile={this.onSubmitProfile}
                                    onSubmitServiceProfile={this.onSubmitServiceProfile}
                                    onSubmitNewProfile={this.onSubmitNewProfile}
                                    disableProfile={this.disableProfile}
                                    disableServiceProfile={this.disableServiceProfile}
                                    enableProfile={this.enableProfile}
                                    enableServiceProfile={this.enableServiceProfile}
                                    onMessage={this.onMessage}
                                    getBookings={this.getBookings}
                                    bookings={this.state.bookings} />
                            </div>
                        </div>

                        {/* content rendered dependent on active tab */}
                        <div hidden={this.state.showTab != 'analytics'}>
                            <Analytics 
                                services={this.props.services}
                                users={this.state.users}
                                toTab={this.toTab}
                            />
                        </div>
                        <div hidden={this.state.showTab != 'categories'}>
                            <Categories
                                categories={this.state.categoryList} 
                                onDeleteCategory={this.onDeleteCategory}
                                onSaveCategory={this.onSaveCategory}
                            />
                        </div>
                        <div hidden={this.state.showTab != 'buyers'}>
                            <Buyers 
                                buyers={this.state.buyers}
                                sortby={this.state.sortby}
                                onDeleteProfile={this.onDeleteProfile}
                                onSubmitProfile={this.onSubmitProfile}
                                onSubmitNewProfile={this.onSubmitNewProfile}
                                disableProfile={this.disableProfile}
                                enableProfile={this.enableProfile}
                                onMessage={this.onMessage} />
                        </div>
                        <div hidden={this.state.showTab != 'sellers'}>
                            <Sellers 
                                sellers={this.state.sellers}
                                services={this.state.services}
                                sortby={this.state.sortby}
                                categories={this.props.categories}
                                onDeleteProfile={this.onDeleteProfile}
                                onDeleteServiceProfile={this.onDeleteServiceProfile}
                                onSubmitProfile={this.onSubmitProfile}
                                onSubmitServiceProfile={this.onSubmitServiceProfile}
                                onSubmitNewProfile={this.onSubmitNewProfile}
                                disableProfile={this.disableProfile}
                                disableServiceProfile={this.disableServiceProfile}
                                enableProfile={this.enableProfile}
                                enableServiceProfile={this.enableServiceProfile}
                                onMessage={this.onMessage}
                                getBookings={this.getBookings}
                                bookings={this.state.bookings} />
                        </div>

                    </div>
                </div>
                <div className="mt-md-5 footer">
                    <Footer/>
                </div>
            </div>
            <div className="mt-md-5 footer">
                <Footer/>
            </div>
        </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    services: state.services,
    users: state.users,
    buyers: getBuyers(state.users, ''),
    sellers: getSellers(state.users, '', 'name', state.services),
    services: state.services,
    bookings: state.bookings,
    categoryList: state.categories,
    categories: state.categories.map((category) => {
        return {
            label: category.category,
            value: category.id
        }
    })
})

const mapDispatchToProps = (dispatch) => ({
    startSetUsers: () => dispatch(startSetUsers()),
    startEditProfile: (profile, id) => dispatch(startEditProfile(profile, id)),
    startAddProfile: (profile, user) => dispatch(startAddProfile(profile, user)),
    startRemoveUser: (id) => dispatch(startRemoveUser(id)),
    startRemoveService: (id) => dispatch(startRemoveService(id)),
    startEditService: (id, service) => dispatch(startEditService(id, service)),
    startEditService: (id, service) => dispatch(startEditService(id, service)),
    startAddService: (service) => dispatch(startAddService(service)),
    startSetBookings: (id) => dispatch(startSetBookings(id)),
    startAddCategory: (category) => dispatch(startAddCategory(category)),
    startRemoveCategory: (category) => dispatch(startRemoveCategory(category)),
    startSetMessages: (id) => dispatch(startSetMessages(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);