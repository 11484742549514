import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import filtersReducer from '../reducers/filters';
import messagesReducer from '../reducers/messages';
import profileReducer from '../reducers/profile';
import servicesReducer from '../reducers/services';
import categoriesReducer from '../reducers/categories';
import bookingsReducer from '../reducers/bookings';
import allBookingsReducer from '../reducers/allBookings';
import allEventsReducer from '../reducers/allEvents';
import eventsReducer from '../reducers/events';
import routeReducer from '../reducers/route';
import usersReducer from '../reducers/users';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
            filters: filtersReducer,
            messages: messagesReducer,
            profile: profileReducer,
            services: servicesReducer,
            categories: categoriesReducer,
            bookings: bookingsReducer,
            events: eventsReducer,
            route: routeReducer,
            users: usersReducer,
            allBookings: allBookingsReducer,
            allEvents: allEventsReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
}; 