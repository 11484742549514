const config = {
    apiKey: process.env.FIREBASE_API_KEY || "AIzaSyAvs8RlEthlswx_9hlNe4dWyKzxmZ1__oo",
    authDomain: process.env.FIREBASE_AUTH_DOMAIN || "itohro-ktr.firebaseapp.com",
    databaseURL: process.env.FIREBASE_DATABASE_URL || "https://itohro-ktr.firebaseio.com",
    projectId: process.env.FIREBASE_PROJECT_ID || "itohro-ktr",
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET || "itohro-ktr.appspot.com",
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "524824293455"
  };

firebase.initializeApp(config);

const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { firebase, googleAuthProvider, facebookAuthProvider, database as default };