import React from 'react';
import {connect} from 'react-redux';
import uuid from 'uuid';
import ServiceItemForm from './ServiceItemForm';
import TopNav from '../TopNav';
import {startEditService} from '../../actions/services';

/**
 * Page for adding a service item
 * 
 * www.itorho.com/add
 */
export class AddServiceItemPage extends React.Component {
    
    state = {
        id: this.props.service ? this.props.service.id : '',
        firstSignIn: false,
        complete: this.props.service ? this.props.service.serviceItems ? true : false : false
    }

    /**
     * /add in route indicates that this is a first sign in
     * if true, updates state to show welcome message
     */
    componentDidMount = () => {
        if (this.props.route[0] === '/add') { this.setState(() => ({ firstSignIn: true })) }
    }

    /**
     * updates state upon receiving new props from redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.service.id != this.state.id) { 
            this.setState(() => ({ id: newProps.service.id })) 
            if (newProps.route[0] === '/add') { this.setState(() => ({ firstSignIn: true })) }
        }
    }

    /**
     * calls action to edit service in database
     * checks if this is the first time adding service items to add parameters to route 
     * (this will inform whether to display welcome pop up on calendar page)
     * navigates user to calendar page
     */
    onSubmit = (item) => {
        const itemID = uuid();
        this.props.startEditService(this.state.id, {
            ...this.props.service,
            serviceItems: {
                ...this.props.service.serviceItems,
                [itemID]: item
            }
        });
        this.props.history.push(`/availability`);
    }

    /**
     * navigates back to the service view page
     */
    onCancel = () => {
        let s = this.props.service.name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true}  type={'seller'}/>
                <div className="container">
                    <div>
                        <ServiceItemForm
                            complete={this.state.complete}
                            firstSignIn={this.state.firstSignIn}
                            categories={this.props.categories}
                            edit={false}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}/>
                    </div>
                </div>
            </div>
            
        );
    };
}

const mapStateToProps = (state) => ({
    service: state.services[0],
    categories: state.categories.map((category) => ({
        value: category.id,
        label: category.category
    })),
    route: state.route
})

const mapDispatchToProps = (dispatch) => ({
    startEditService: (id, updates) => dispatch(startEditService(id, updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceItemPage);