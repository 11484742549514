import database from '../firebase/firebase';

// SET_MESSAGES

/** sets messages in redux store */
export const setMessages = (messages) => ({
    type: 'SET_MESSAGES',
    messages
  });
  
/** gets messages from database for a specific id */
export const startSetMessages = (id) => {
    return (dispatch) => {
        return database.ref(`messages/${id}/`)
            .once('value')
            .then((snapshot) => {
                const messages = [];
  
                snapshot.forEach((childSnapshot) => {
                    messages.push({
                        id: childSnapshot.key,
                        ...childSnapshot.val()
                    });
                });
            
            dispatch(setMessages(messages));
        });
    };
};

// ADD_MESSAGE

/** adds message to redux store */
export const addMessage = (message) => ({
    type: 'ADD_MESSAGE',
    message
});

/** adds a buyer's message to database */
export const startAddMessageBuyer = (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => {
    return (dispatch) => {
        return database.ref(`messages/${buyerID}/${mid}`)
            .set(buyerMessage)
            .then(() => {
                if (type === 'buyer') {
                    dispatch(addMessage({
                        id: mid,
                        ...buyerMessage
                    }))
                }
        });
    };
};

/** adds a seller's message to database */
export const startAddMessageSeller = (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => {
    return (dispatch) => {
        return database.ref(`messages/${sellerID}/${mid}`)
            .set(sellerMessage)
            .then(() => {
                if (type === 'seller') {
                    dispatch(addMessage({
                        id: mid,
                        ...sellerMessage
                    }))
                }
        });
    };
};

/** adds an admin message to database */
export const startAddMessageAdmin = (message, type, mid) => {
    return (dispatch) => {
        return database.ref(`messages/${'admin'}/${mid}`)
            .set(message)
            .then(() => {
                if (type == 'admin') {
                    dispatch(addMessage({
                        id: mid,
                        ...message
                    }))
                }
        });
    };
};

// EDIT_MESSAGE

/** edits a message in the redux store */
export const editMessage = (id, updates) => {
    return ({
    type: 'EDIT_MESSAGE',
    id,
    updates
})};

/** edits a message in the database */
export const startEditMessage = (id, mid, updates) => {
    return (dispatch) => {
        return database.ref(`messages/${id}/${mid}/`)
            .update(updates)
            .then(() => {
                dispatch(editMessage(mid, updates));
            });
    };
};

/** edits a seller's message in the database */
export const startEditMessageSeller = (id, mid, updates, type) => {
    return (dispatch) => {
        return database.ref(`messages/${id}/${mid}/`)
            .update(updates)
            .then(() => {
                if (type === "seller") {
                    dispatch(editMessage(mid, updates));
                }
            });
    };
};

/** edits a buyer's message in the database */
export const startEditMessageBuyer = (id, mid, updates, type) => {
    return (dispatch) => {
        return database.ref(`messages/${id}/${mid}/`)
            .update(updates)
            .then(() => {
                if (type === "buyer") {
                    dispatch(editMessage(mid, updates));
                }
            });
    };
};