import React from 'react'
import {connect} from 'react-redux';
import Rating from 'react-rating';
import SweetAlert from 'react-bootstrap-sweetalert';
import TopNav from '../TopNav';
import Footer from '../Footer';
import Booking from './Booking';
import {startEditBooking, startRemoveBooking, startRemoveDeposit} from '../../actions/bookings'
import {startEditService} from '../../actions/services';
import {sortBookings, sortDates, sortHistoryBookings, sortHistoryDates} from '../../selectors/bookings';
import {initGA, logPageView} from '../../utils/gAnalytics';

/**
 * Page for displaying bookings to buyers
 * 
 * www.itorho.com/bookings
 * 
 * displays upcoming and past bookings
 * allows for upcoming editing and removing bookings as well as rating and reviewing past bookings
 * bookings can also be paid for if still unpaid
 */
export class BookingsPage extends React.Component {

    state = {
        showBooking: false,
        showAlert: false,
        showRating: false,
        showReview: false,
        showReply: false,
        review: '',
        rating: 0,
        reply: '',
        bookings: this.props.bookings,
        dates: this.props.dates,
        historyBookings: this.props.historyBookings,
        historyDates: this.props.historyDates,
        services: this.props.services,
        booking: '',
        bookingID: '',
        tab: 'bookings'
    }

    /**
     * update bookings to reflect unpaid if only deposit has been paid upon mount
     */
    componentDidMount = () => {
        if (this.props.bookings) {
            this.props.bookings.map((booking) => {
                if (booking.deposit && booking.paid) {
                    this.props.startEditBooking(booking.id, { paid: false, paidDeposit: booking.deposit, cost: parseInt(booking.cost)-parseInt(booking.deposit)});
                    this.props.startRemoveDeposit(booking.id);
                }
            })
        }
        initGA();
        logPageView();
    }
    
    /**
     * updates state upon receiving new props from redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.bookings !== this.props.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }));
        }
        if (newProps.dates !== this.props.dates) {
            this.setState(() => ({ dates: newProps.dates }));
        }
        if (newProps.services !== this.props.services) {
            this.setState(() => ({ services: newProps.services }));
        }
    }

    /**
     * updates state to active tab view
     */
    onTabChange = (tab) => {
        this.setState(() => ({ tab }))
    }

    /**
     * updates state to open booking details pop up
     * sets selected booking in state
     */
    onEventClick = (booking) => {
        this.setState(() => ({ 
            booking,
            bookingID: booking.id,
            showBooking: true 
        }));
    }

    /**
     * updates state to close all pop ups
     */
    onCancel = () => {
        this.setState(() => ({ 
            showBooking: false,
            showAlert: false
        }));
    }

    /**
     * updates state to open delete confirmation pop up
     */
    onRemove = () => {
        this.setState(() => ({ 
            showBooking: false,
            showAlert: true 
        }));
    }

    /**
     * updates state to close delete confirmation pop up
     */
    onConfirm = () => {
        this.setState(() => ({ 
            showAlert: false,
            showBooking: false 
        }));
        this.props.startRemoveBooking(this.state.booking.id);
    }

    /**
     * updates state to close rating and open review pop up
     */
    onExitRating = () => {
        this.setState(() => ({ 
            showRating: false,
            showReview: true 
        }));
    }

    /**
     * updates state to close review pop up
     * updates booking by calling action to show it has been rated
     */
    onExitReview = () => {
        this.setState(() => ({ 
            showReview: false,
            showBooking: true 
        }));
        this.props.startEditBooking(this.props.booking.id, { rated: true });
    }

    /**
     * updates state to show rating pop up
     */
    onRate = () => {
        this.setState(() => ({ showRating: true }));
    }

    /**
     * method adds rating to database by calling action to edit service
     * closes rating and opens review pop up
     */
    onAddRating = () => {
        let noRatings = 0;
        let rating = 0;
        this.props.services.filter((service) => {
            if (service.id == this.state.booking.event.extendedProps.serviceID) {
                // Use noRatings
                noRatings = service.noRatings;
                rating = (service.rating*noRatings + this.state.rating)/(noRatings+1)
                noRatings = noRatings+1
                this.props.startEditService(this.state.booking.event.extendedProps.serviceID, {
                    noRatings,
                    rating,
                })
            }
        })
        this.setState(() => ({ 
            showRating: false,
            showReview: true 
        }));
    }

    /**
     * method adds review to database by calling action to edit service
     * close review pop up
     */
    onAddReview = () => {
        if (this.state.review != '') {
            this.props.services.filter((service) => {
                if (service.id == this.state.serviceID) {
                    let reviews = {}
                    if (service.reviews) {
                        const int = Object.keys(service.reviews).length+1;
                        const i = int.toString();
                        reviews = {
                            ...service.reviews,
                            [i]: moment.now() + ' ' + this.state.review + ' ' + this.state.rating
                        }
                    } else {
                        const i = 0;
                        reviews = {
                            [i]: moment.now() + ' ' + this.state.review + ' ' + this.state.rating
                        }
                    }
                    this.props.startEditService(this.state.serviceID, { reviews })
                }
            });
        }
        this.setState(() => ({ 
            showReview: false,
            onBookings: true 
        }));
    } 

    /**
     * updates state upon selecting rating on rating pop up
     */
    onRatingChange = (value) => {
        this.setState(() => ({ rating: value }));
    }

    /**
     * updates state upon text input on review pop up
     */
    onReviewChange = (e) => {
        const review = e.target.value;
        this.setState(() => ({ review }));
    }
    
    render() {
        return (

            <div>
                <TopNav loggedIn={true}  type="buyer" props={this.props}/>

                {/* tab view controls */}
                <div hidden={this.state.showBooking} className="container">
                    <div className="d-flex justify-content-center mt-5 mb-3 text-center ml-4 mr-4">
                        <div className={this.state.tab === 'bookings' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('bookings'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                            <h4 className="display-5">Bookings</h4>
                        </div>
                        <div className={this.state.tab === 'history' ? "col category-nav-active" : "col category-nav"} onClick={(() => this.onTabChange('history'))} style={{borderBottom: '0.2px solid gainsboro'}}>
                            <h4 className="display-5">History</h4>
                        </div>
                    </div>
                </div>

                {/* display if no bookings */}
                <div hidden={!(this.state.tab === 'bookings' && this.state.bookings.length === 0)}>
                    <div>
                        <h3 className="font-weight-light text-center pt-5 pb-1" style={{color: 'gray'}}>No Bookings to Display</h3>
                    </div>
                </div>

                {/* booking list view */}
                <div hidden={!(this.state.tab === 'bookings')}>
                    <div className="d-flex justify-content-center">
                        <div className="col-sm-8 col-12">
                            {this.state.dates ? (
                                this.state.dates.map((date) => (
                                    <div key={date}>
                                        <h3 className="font-weight-light pt-4 pb-1" style={{color: 'gray'}}>{date}</h3>
                                        {this.state.bookings.map((booking, index) => (
                                            (booking.date.toString().includes(date) ? (
                                                <div className="mt-3 mb-2 p-3 pl-1 link-base" onClick={() => this.onEventClick(booking)} style={{border: '1px solid gainsboro', borderLeft: '3px solid #142948'}} key={booking.id}>
                                                    {booking.paid ?<h4 className="font-weight-light float-right">PAID</h4> : <h4 className="font-weight-light float-right" style={{color: "#ec9422"}}>{booking.paidDeposit ? "Pay Remainder" : booking.deposit ? "Pay Deposit" : "Pay Now"}</h4>}
                                                    <h3>{booking.item}</h3>
                                                    {this.state.services.map((service) => {
                                                        if (service.id === booking.serviceID) {
                                                        return (
                                                            <div key={service.id}>
                                                                {service.address ? <h4 className="font-weight-light" style={{color: '#ec9422'}}>{service.address}, {service.city}</h4> : <h4 className="font-weight-light" style={{color: '#ec9422'}}>{service.city}</h4>}
                                                            </div>
                                                        )}
                                                    })}
                                                    <h4 className="font-weight-light" style={{color: 'gray'}}>{booking.time}</h4>
                                                    <h5 className="font-weight-light" style={{color: 'gray'}}>{booking.duration}</h5>
                                                </div>
                                            ) : (
                                                <div key={index}>
                                                </div>
                                            ))
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                </div>

                {/* display if no past bookings */}
                <div hidden={!(this.state.tab === 'history' && this.state.historyBookings.length === 0)}>
                    <div>
                        <h3 className="font-weight-light text-center pt-5 pb-1" style={{color: 'gray'}}>No History to Display</h3>
                    </div>
                </div>

                {/* past booking list view */}
                <div hidden={!(this.state.tab === 'history')}>
                    <div className="d-flex justify-content-center">
                        <div className="col-sm-8 col-12">
                            {this.state.historyDates ? (
                                this.state.historyDates.map((date) => (
                                    <div key={date}>
                                        <h3 className="font-weight-light pt-5 pb-1" style={{color: 'gray'}}>{date}</h3>
                                        {this.state.historyBookings.map((booking, index) => (
                                            (booking.date.toString().includes(date) ? (
                                                <div className="mt-3 mb-2 p-3 pl-1 link-base" onClick={() => this.onEventClick(booking)} style={{border: '1px solid gainsboro', borderLeft: '3px solid gainsboro'}} key={booking.id}>
                                                    <h4 className="font-weight-light float-right">{booking.paid ? "PAID" : ""}</h4>
                                                    <h3>{booking.item}</h3>
                                                    {this.state.services.map((service) => {
                                                        if (service.id == booking.serviceID) {
                                                        return (
                                                            <div key={service.id}>
                                                                {service.address ? <h4 className="font-weight-light" style={{color: '#ec9422'}}>{service.address}, {service.city}</h4> : <h4 className="font-weight-light" style={{color: '#ec9422'}}>{service.city}</h4>}
                                                            </div>
                                                        )}
                                                    })}
                                                    <h4 className="font-weight-light" style={{color: 'gray'}}>{booking.time}</h4>
                                                    <h5 className="font-weight-light" style={{color: 'gray'}}>{booking.duration}</h5>
                                                </div>
                                            ) : (
                                                <div key={index}>
                                                </div>
                                            ))
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                </div>

                {/* pop up for viewing booking details */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showBooking}
                        focusConfirmBtn={false}
                        overflow="scroll"
                        closeOnClickOutside
                        heightAuto={false}
                        confirmBtnText="Back"
                        confirmBtnBsStyle="link"
                        title="Booking Details"
                        onConfirm={this.onCancel}
                        onCancel={this.onCancel}>
                        <Booking
                            onCancel={this.onCancel}
                            onRemove={this.onRemove}
                            onRate={this.onRate}
                            booking={this.state.booking}
                            edit={true}/>
                    </SweetAlert>
                </div>
        
                {/* pop up to cancel booking */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showAlert}
                        showCancel
                        focusConfirmBtn={false}
                        confirmBtnText="Continue"
                        confirmBtnBsStyle="default"
                        cancelBtnBsStyle="link"
                        title="Are you sure you want to cancel this booking?"
                        onConfirm={this.onConfirm}
                        onCancel={this.onCancel}>
                        You will not be able to uncancel your cancellation.
                    </SweetAlert>
                </div>

                {/* pop up for rating a past service */}
                <div>
                    <SweetAlert
                        show={this.state.showRating}
                        focusConfirmBtn={false}
                        confirmBtnText="Submit"
                        confirmBtnBsStyle="default"
                        cancelBtnBsStyle="link"
                        title="Leave a rating"
                        onConfirm={this.onAddRating}
                        onCancel={this.onExitRating}>
                        <Rating
                            id="rating"
                            {...this.props}
                            onChange={((value) => this.onRatingChange(value))}
                            initialRating={this.state.rating}
                            emptySymbol={<img src="/images/star-empty.png" className="icon" style={{maxWidth: 20}} />}
                            fullSymbol={<img src="/images/star-full.png" className="icon" style={{maxWidth: 20}} />}
                        />
                    </SweetAlert>
                </div>

                {/* pop up for reviewing a past service */}
                <div className="mt-5">
                    <SweetAlert
                        show={this.state.showReview}
                        confirmBtnText="Done"
                        confirmBtnBsStyle="default"
                        cancelBtnBsStyle="default"
                        focusConfirmBtn={false}
                        title="Leave a review for"
                        onConfirm={this.onAddReview}
                        onCancel={this.onExitReview}>
                        <h3 className="pt-2 display-5" style={{color: '#ec9422'}}>{this.state.booking ? this.state.booking.item : ''} at {this.state.booking ? this.state.booking.service : ''}</h3>
                        <h5 className="font-weight-light p-3">{this.state.booking ? this.state.booking.date.substring(0,16) : ''}</h5>
                        <div className="d-flex justify-content-center">
                        <textarea
                            style={{maxWidth: 250}}
                            className="form-control font-weight-light rounded-0 form-control-lg m-3 mb-0 pb-0"
                            placeholder="Leave a review..."
                            type="text"
                            id="review"
                            value={this.state.review}
                            onChange={this.onReviewChange}/>
                        </div>
                    </SweetAlert>
                </div>
                <div className="mt-5 footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
  services: state.services,
  bookings: sortBookings(state.bookings.filter((booking) => (booking.buyerID == state.profile[0].id))),
  dates: sortDates(state.bookings.filter((booking) => (booking.buyerID == state.profile[0].id))),
  historyBookings: sortHistoryBookings(state.bookings.filter((booking) => (booking.buyerID == state.profile[0].id))),
  historyDates: sortHistoryDates(state.bookings.filter((booking) => (booking.buyerID == state.profile[0].id))),
  id: state.profile[0].id
});

const mapDispatchToProps = (dispatch) => ({
    startRemoveBooking: (bookingID) => dispatch(startRemoveBooking(bookingID)),
    startEditService: (id, updates) => dispatch(startEditService(id, updates)),
    startEditBooking: (id, updates) => dispatch(startEditBooking(id, updates)),
    startRemoveDeposit: (id) => dispatch(startRemoveDeposit(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsPage);