import React from 'react';
import {connect} from 'react-redux';
import TopNav from '../TopNav';
import ServiceProfile from './ServiceProfile';
import {setNearFilter} from '../../actions/filters';
import {removeRoute} from '../../actions/route';
import Footer from '../Footer';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * Page to display service profile
 * if logged in user is a seller, allows for editing of profile
 * 
 * www.itorho.com/service/:name
 * 
 */
export class ServicePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            service: this.props.service ? this.props.service : {},
            showBook: false,
            cost: undefined
        }
    }

    /**
     * calls action upon mounting
     */
    componentDidMount = () => {
        if (this.props.route && this.props.type === 'buyer') {
            if (this.props.route[0] === 'book') {
                this.setState(() => ({ showBook: true }))
            } else if (this.props.route[0] && parseInt(this.props.route[0])) {
                this.setState(() => ({ showBook: true }))
                this.setState(() => ({ cost: this.props.route[0] }))
            }
            this.props.removeRoute();
        }
        initGA();
        logPageView();
    }

    /**
     * updates state upon receiving new props from redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.service != this.state.service) {
            this.setState(() => ({ service: newProps.service }))
        }
    }

    /**
     * updates state to hide booking form popup
     */
    onBook = () => {
        this.setState(() => ({
            cost: undefined,
            showBook: false
        }))
    }

    /**
     * navigates back to the category page for the first associated category with the service profile
     * or to the dashboard page if no category
     * 
     * NOTE for future: there is a better way to do this - could save the category last visited to the 'route' parameter in redux store
     */
    onBack = () => {
        this.props.setNearFilter(false);
        const category = this.props.category ? this.props.category : this.props.service.categories ? this.props.service.categories[0].value : ''
        if (category) {
            this.props.history.push(`/category/${category}`);
        } else {
            this.props.history.push(`/dashboard`);
        }
    }

    render() {
        return (
    
            <div>
                <TopNav loggedIn={this.props.loggedIn} type={this.props.type} props={this.props}/>
                <div className="mb-5">
                    <ServiceProfile
                        onBook={this.onBook}
                        cost={this.state.cost} 
                        showForm={this.state.showBook}
                        service={this.state.service}
                        props={this.props}
                        history={this.props.history}
                        type={this.props.type} 
                        loggedIn={this.props.loggedIn}
                        onBack={this.onBack}
                        edit={this.props.type === "seller" ? true : false}/>
                </div>
                <div className="footer">
                    <Footer/>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state, props) => ({
    route: state.route,
    service: state.services,
    bookings: state.bookings,
    service: state.services.find((service) => !!service.name && service.name.toLowerCase().replace(/ /g,'') === props.match.params.service),
    category: state.filters.category,
    loggedIn: state.profile[0] ? true : false,
    type: state.profile[0] ? state.profile[0].type : '',
    id: state.profile[0] ? state.profile[0].id : '',
});

const mapDispatchToProps = (dispatch) => ({
    setNearFilter: (near) => dispatch(setNearFilter(near)),
    removeRoute: () => dispatch(removeRoute())
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);