import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import RegisterForm from './RegisterForm';
import TopNav from '../TopNav';
import {startAddProfile, setErrorMessage} from '../../actions/profile';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * Page for user registration
 * 
 * www.itorho.com/:type/register
 * 
 */
export class RegisterPage extends React.Component {
    
    state = {
        type: this.props.match.params.type,
        loading: false
    }

    /*
     * scroll to top of page on mount 
     */
    componentDidMount = () => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        initGA();
        logPageView();
    }

    /**
     * updates state upon receiving new props
     */
    componentWillReceiveProps = (newProps) => {
        if (newProps.profile != undefined) {
            this.setState(() => ({ loading: false }))
            this.props.history.push('/dashboard')
        }
    }

    /**
     * method calls action to submit profile to database
     * and redirects to dashboard page
     */
    onSubmit = (profile) => {
        this.props.startAddProfile(profile);
        this.setState(() => ({ loading: true }))
    }

    /**
     * calls action to set error message
     */
    setErrorMessage = (message) => {
        this.props.setErrorMessage(message)
    }

    render() {
        return (

            <div>
            
                {this.state.loading ?
                    <div>
                        <TopNav loggedIn={false} type={'buyer'} props={this.props}/>
                        <div className="mb-5 pb-5">
                            <div className="container h-80 mt-5 pt-5">
                                <div className="row h-80 justify-content-center align-items-center mt-5 pt-5">
                                    <img src='/images/loader.gif' />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className="bg-blue">
                        <div>
                            <div className="bg-white p-3" style={{maxWidth: 350}}>
                                
                                <div className="row d-flex justify-content-center mt-3">
                                    <img src="/images/itorho-logo-only.png" style={{maxWidth:60, maxHeight:60}}/>
                                </div>

                                <div hidden={!(this.state.type == "seller")}>
                                    <h3 className="text-center font-weight-light mt-3">Register as a Seller</h3>
                                </div>
                                
                                <div hidden={(this.state.type == "seller")}>
                                    <h3 className="text-center font-weight-light mt-3">Register</h3>
                                </div>

                                <div className="m-5 mb-0 pb-0">
                                    <RegisterForm
                                        onSubmit={this.onSubmit}
                                        type={this.props.match.params.type}
                                        setErrorMessage={this.props.setErrorMessage}
                                        errorMessage={this.props.error} />
                                </div>

                            </div>

                            <div className="mt-1 mb-4 p-0">
                                <Link className="link" to={`/${this.state.type}/login`}>
                                    <h5 className="terms-link text-center font-weight-light m-4 pt-2">Already have an account? Login.</h5>
                                </Link>
                            </div>

                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    startAddProfile: (profile) => dispatch(startAddProfile(profile)),
    setErrorMessage: (error) => dispatch(setErrorMessage(error))
});

const mapStateToProps = (state) => ({
    error: state.profile.error ? state.profile.error : '',
    profile: state.profile[0] ? state.profile[0] : undefined,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);