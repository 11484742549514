const categoriesReducerDefaultState = [];

export default (state = categoriesReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_CATEGORIES':
            return action.categories;
        case 'ADD_CATEGORY':
            return [
                ...state,
                action.category
            ];
        case 'EDIT_CATEGORY':   
            return state.map((category) => {
                if (category.id === category.id) {
                    return {
                        ...category,
                        ...action.updates
                    };
                } else {
                    return category;
                }
            });
        case 'REMOVE_CATEGORY':
            return state.filter(({ id }) => action.category !== id);
        default:
            return state;
    }
};