import React from 'react';
import {connect} from 'react-redux';
import TopNav from '../TopNav';
import Calendar from './Calendar';
import Footer from '../Footer';
import {initGA, logPageView} from '../../utils/gAnalytics';
import {startEditBooking} from '../../actions/bookings';
import {bookingsToCalendar} from '../../utils/bookings';

/**
 * Page for the calendar component
 * 
 * www.itorho.com/calendar
 * 
 * renders calendar and associated viewing actions for sellers
 */
export class CalendarPage extends React.Component {

    state = {
        bookings: this.props.bookings ? this.props.bookings : []
    };

    /**
     * initiates google analytics upon component mounting
     */
    componentDidMount = () => {
        initGA();
        logPageView();
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.bookings != this.state.bookings) {
            this.setState(() => ({ bookings: newProps.bookings }))
        }
    }

    /**
     * method to edit booking if seller updates to paid
     */
    onPaid = (id, updates) => {
        this.props.startEditBooking(id, updates)
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true} type={'seller'} props={this.props}/>                
                <div className="mb-5 pb-5">
                    <Calendar
                        onPaid={this.onPaid}
                        bookings={this.state.bookings} />
                </div>
                <div className="footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    firstSignIn: state.profile[0] ? state.profile[0].firstSignIn : null,
    route: state.route,
    bookings: bookingsToCalendar(state.bookings)
})

const mapDispatchToProps = (dispatch) => ({
    startEditBooking: (id, updates) => dispatch(startEditBooking(id, updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);