import React from 'react';
import Cropper from 'react-easy-crop'

/**
 * component to handle the cropping of images
 */
export default class Crop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 4 / 3,
            croppedArea: {},
            image: this.props.image ? this.props.image : '',
        }
    }

    /**
     * updates state upon receiving new props from  service form
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.image != this.state.image) {
            this.setState(() => ({ image: newProps.image }))
        }
    }

    /**
     * updates drawn image
     */
    componentWillUpdate = () => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const image = document.getElementById('source');
        var f = image.height / image.width;
        var newHeight = canvas.width * f;
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, newHeight);
    }

    /**
     * updates state to reflect crop change
     */
    onCropChange = (crop) => {
        this.setState({ crop })
    }

    /**
     * called when crop is complete
     * calls onCrop method in service form
     */
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState(() => ({ croppedArea: croppedAreaPixels }))
        this.props.onCrop(croppedAreaPixels)
    }

    /**
     * updates state to reflect selected zoom
     */
    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    render() {
        return (

            <div className="d-flex justify-content-center mb-5">
                <canvas width="0" height="0" id="canvas"></canvas>
                <div className="row">
                    <img 
                        style={{display: 'none'}}
                        id="source"
                        className="img"
                        src={this.state.image}
                    />
                </div>

                <div className="row">
                <Cropper
                    image={this.state.image}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                />
                </div>
            </div>

        )
    }
}