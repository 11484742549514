const bookingsReducerDefaultState = [];

export default (state = bookingsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_BOOKINGS':
            return action.bookings;
        case 'ADD_BOOKING':
            return [
                ...state,
                action.booking
            ];
        case 'EDIT_BOOKING':   
            return state.map((booking) => {
                if (booking.id === action.bookingID) {
                    return {
                        ...booking,
                        ...action.updates
                    };
                } else {
                    return booking;
                }
            });
        case 'REMOVE_BOOKING':
            return state.filter(({ id }) => action.bookingID !== id);
        case 'REMOVE_DEPOSIT':
            return state.map((booking) => {
                if (booking.id === action.bookingID) {
                    return {
                        deposit: undefined,
                        ...booking,
                    };
                } else {
                    return booking;
                }
            });
        default:
            return state;
    }
};