import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SweetAlert from 'react-bootstrap-sweetalert';

/**
 * image carousel displayer component
 * rendered by gallery component
 */
export class ImageCarousel extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            index: 0,
            direction: null,
            show: false
        }
    }

    /**
     * updates state upon receiving props from gallery component
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.show != this.state.show) {
            this.setState(() => ({ 
                show: newProps.show,
                index: newProps.index 
            }));
        }
    }

    /**
     * updates state to hide gallery pop up
     * calls closeGallery method in gallery
     */
    onCancel = () => {
        this.setState(() => ({ show: false }))
        this.props.closeGallery();
    }

    /**
     * updates state to next index to show next image
     */
    onClick = () => {
        let index = 0;
        if (this.state.index != this.props.gallery.length-1) { index = this.state.index+1 }
        this.setState({ index });
    }

    render() {
        return (

            <div>
                <SweetAlert
                    show={this.state.show}
                    focusConfirmBtn={false}
                    overflow="scroll"
                    closeOnClickOutside
                    heightAuto={false}
                    confirmBtnText="Exit"
                    confirmBtnBsStyle="link"
                    title={``}
                    onCancel={this.onCancel}
                    onConfirm={this.onCancel}>

                    <Carousel
                        indicators={false}
                        controls={false}
                        onSelect={this.handleSelect}>
                        <Carousel.Item
                            onClick={this.onClick}>
                            <img
                                className="d-block w-100"
                                src={this.props.gallery[this.state.index]}
                            />
                        </Carousel.Item>
                    </Carousel>
                </SweetAlert>
            </div>

        )
    }
}

export default ImageCarousel;