import React from 'react';
import {connect} from 'react-redux';
import {animateScroll as scroll} from 'react-scroll';
import TopNav from '../TopNav';
import CategoryList from '../Category/CategoryList';
import Tutorial from './Tutorial';
import Search from './Search';
import Feature from './Feature';
import Footer from '../Footer';
import {setTextFilter} from '../../actions/filters';
import {setCategoryFilter,setLocationFilter, setNearFilter} from '../../actions/filters';
import {selectServices} from '../../selectors/services';
import {initGA, logPageView} from '../../utils/gAnalytics';

/**
 * Page for first entry to site
 * 
 * www.itorho.com/
 */
export class EntryPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text ? this.props.text : '',
            onSell: false,
            onBuy: true,
            services: this.props.services ? this.props.services : []
        }
    }

    /**
     * sets filters for feature component (displaying 'catering vemues in cape town')
     */
    componentDidMount = () => {
        this.props.setCategoryFilter('catering')
        this.props.setLocationFilter('cape town')
        this.props.setNearFilter(false);
        initGA();
        logPageView();
    }

    /**
     * updates state upon receiving new props from redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.services != this.state.services) {
            this.setState(() => ({ services: newProps.services }))
        }
    }

    /**
     * updates state to selected tab view
     */
    onSellChange = () => {
        scroll.scrollTo(550, { duration: 750, delay: 10 });
        this.setState(() => ({ 
            onSell: true,
            onBuy: false 
        }))
    }

    /**
     * updates state to selected tab view
     */
    onBuyChange = () => {
        scroll.scrollTo(550, { duration: 750, delay: 10 });
        this.setState(() => ({ 
            onBuy: true,
            onSell: false 
        }))
    }

    /**
     * navigates to search page
     * calls action to set text filter
     */
    onSearch = (text) => {
        this.props.setTextFilter(text)
        this.props.history.push(`/search`)
    }

    /**
     * sets events for selected service
     * navigates to the selected service page
     */
    onService = (id, name) => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        let s = name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`)
    }

    render() {
        return (
    
            <div>
                <TopNav props={this.props} loggedIn={false}/>
                <div className="banner-bg-img">

                    {/* title */}
                    <div className="p-5 pb-0 col-md-8 col-10">
                        <div className="d-flex justify-content-center">
                            <div className="ml-1 pl-0 ml-md-5 pl-md-5" style={{color: 'black'}}>
                            <h1 className="pt-md-5 pl-md-4 pt-2 pl-1 welcome-title" style={{fontSize: '100px', fontWeight: 'bolder'}}>Welcome to iTorho.</h1>
                            <h1 className="font-weight-light pt-md-4 pl-md-5 pt-2 pl-2 welcome-subtitle" style={{fontSize: '30px'}}>Conveniently transact with digital customers through faster discoverability, simpler client access and efficient online booking management for a seamless payment experience.</h1>
                            </div>
                        </div>
                    </div>

                    {/* tab views */}
                    <div className="pt-md-4 ml-md-5 mr-md-5 p-3">
                        <div className="p-md-5 d-flex justify-content-center text-center">
                            <div className={this.state.onBuy ? "col category-nav-active pointer" : "col category-nav pointer"} onClick={this.onBuyChange} style={{borderBottom: '0.2px solid gainsboro', backgroundColor: 'white'}}>
                                <h3 className="display-5 p-md-5 pb-md-0 welcome-tab">Find a Service</h3>
                            </div>
                            <div className={this.state.onSell ? "col category-nav-active pointer" : "col category-nav pointer"} onClick={this.onSellChange} style={{borderBottom: '0.2px solid gainsboro', backgroundColor: 'white'}}>
                                <h3 className="display-5 p-md-5 pb-md-0 welcome-tab">List your Service</h3>
                            </div>
                        </div>
                    </div>

                </div>

                {/* buy tab view */}
                <div hidden={!this.state.onBuy}>
                    
                    <Search 
                        props={this.props} 
                        onSearch={this.onSearch} />
                    <Feature 
                        props={this.props} 
                        services={this.state.services} 
                        onService={this.onService} />
                    <hr />

                    <h3 className="mt-5 pt-md-5 pt-3 text-center font-weight-light display-4 welcome-subtitle" style={{ color: 'black' }}>Browse by Category</h3>
                    <div className="d-flex justify-content-center">
                        <div className="col-11 p-md-5">
                            <CategoryList />
                        </div>
                    </div>

                </div>
                
                {/* sell tab view */}
                <div hidden={!this.state.onSell}>
                    <Tutorial props={this.props} />
                </div>

                <div className="mt-md-5 pt-5 footer">
                    <Footer/>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    route: state.route,
    services: selectServices(state.services, state.filters)
})

const mapDispatchToProps = (dispatch) => ({
    setTextFilter: (text) => dispatch(setTextFilter(text)),
    setCategoryFilter: (category) => dispatch(setCategoryFilter(category)),
    setLocationFilter: (location) => dispatch(setLocationFilter(location)),
    setNearFilter: (near) => dispatch(setNearFilter(near))
});

export default connect(mapStateToProps, mapDispatchToProps)(EntryPage);