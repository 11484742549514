import database from '../firebase/firebase';
import {sendBookingEmail, sendBookingEmailToBuyer} from '../utils/emails';


// SET_BOOKINGS

/** set bookings in redux store */
export const setBookings = (bookings) => ({
    type: 'SET_BOOKINGS',
    bookings
  });

  /** set all bookings in redux store */
export const setAllBookings = (bookings) => ({
    type: 'SET_ALL_BOOKINGS',
    bookings
  });

/** get bookings relevant to logged in user from database */
export const startSetBookings = (uid) => {
    return (dispatch) => {
        return database.ref(`bookings/`)
            .once('value')
            .then((snapshot) => {
                const bookings = [];
  
                snapshot.forEach((childSnapshot) => {
                    if (childSnapshot.val().serviceID === uid || childSnapshot.val().buyerID === uid) {
                        const value = childSnapshot.val();
                        bookings.push({
                            id: childSnapshot.key,
                            ...value
                        });
                    }
                });
            
            dispatch(setBookings(bookings));
        });
    };
};

/** get all bookings from database */
export const startSetAllBookings = () => {
    return (dispatch) => {
        return database.ref(`bookings/`)
            .once('value')
            .then((snapshot) => {
                const bookings = [];
  
                snapshot.forEach((childSnapshot) => {
                        const value = childSnapshot.val();
                        bookings.push({
                            id: childSnapshot.key,
                            ...value
                        });
                });
            
            dispatch(setAllBookings(bookings));
        });
    };
};


// ADD_BOOKING

/** add booking to redux store */
export const addBooking = (booking) => ({
    type: 'ADD_BOOKING',
    booking
});

/** add new booking to database */
export const startAddBooking = (booking) => {
    return (dispatch, getState) => {
        const id = getState().profile[0].id;
        booking = {
            buyerID: id,
            ...booking
        }
        return database.ref(`bookings/${booking.bookingID}`)
            .set(booking)
            .then(() => {
            dispatch(addBooking({
                id: booking.bookingID,
                ...booking
            }));
            // Most probable location to send notification email
            sendBookingEmail(booking.serviceID, booking);
            sendBookingEmailToBuyer(id, booking);
        });
    };
};

// EDIT_BOOKING

/** edit booking in redux store */
export const editBooking = (bookingID, updates) => ({
    type: 'EDIT_BOOKING',
    bookingID,
    updates
});

/** edit booking in database */
export const startEditBooking = (bookingID, updates) => {
    return (dispatch) => {
        return database.ref(`bookings/${bookingID}`)
            .update(updates)
            .then(() => {
                dispatch(editBooking(bookingID, updates));
            });
    };
};


// REMOVE_DEPOSIT

/** remove booking from redux store */
export const removeDeposit = ({ bookingID }) => ({
    type: 'REMOVE_DEPOSIT',
    bookingID
});

/** remove booking from database */
export const startRemoveDeposit = (bookingID) => {
    return (dispatch) => {
        return database.ref(`bookings/${bookingID}/deposit`)
            .remove()
            .then(() => {
                dispatch(removeDeposit({ bookingID }));
        })
    }
}

// REMOVE_BOOKING

/** remove booking from redux store */
export const removeBooking = ({ bookingID }) => ({
    type: 'REMOVE_BOOKING',
    bookingID
});

/** remove booking from database */
export const startRemoveBooking = (bookingID) => {
    return (dispatch) => {
        return database.ref(`bookings/${bookingID}`)
            .remove()
            .then(() => {
                dispatch(removeBooking({ bookingID }));
        })
    }
}
