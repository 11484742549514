import React from 'react';
import {connect} from 'react-redux';
import {animateScroll as scroll} from 'react-scroll';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import TopNav from '../TopNav';
import ServiceItemForm from './ServiceItemForm';
import {startEditService} from '../../actions/services';

/**
 * Page for editing a service item
 * 
 * www.itorho.com/edit/:title
 * 
 */
export class EditServiceItemPage extends React.Component {
    
    state = {
        showRemove: false,
        showConfirm: false,
        item: Object.values(this.props.service.serviceItems).find((item) => (item.title === this.props.match.params.title || this.props.match.params.title.includes(item.title))),
        itemID: ''
    }
    
    /**
     * sets itemID upon mounting
     */
    componentDidMount = () => {
        let itemID = '';
        Object.values(this.props.service.serviceItems).find((item, index) => {
            if (item.title === this.props.match.params.title || this.props.match.params.title.includes(item.title)) {
                itemID = Object.keys(this.props.service.serviceItems)[index]
            }
        })
        this.setState(() => ({ itemID }))
    }

    /**
     * calls action method to edit service in database
     * navigates user to service profile view page
     */
    onSubmit = (item) => {
        this.props.startEditService(this.props.service.id, {
            ...this.props.service,
            serviceItems: {
                ...this.props.service.serviceItems,
                [this.state.itemID]: item 
            }
        })
        let s = this.props.service.name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
        scroll.scrollTo(0, { duration: 750, delay: 10 });
    }

    /**
     * checks if service item can be removed
     * by checking if bookings exist and if they are set in the future
     * updates state to show cofirm delete or cannot delete pop up
     */
    onRemoveRequest = () => {

        // else check if bookings exist and if bookings are in the future
        let remove = true;
        this.props.bookings.map((booking) => {
            if (booking.itemID === this.state.itemID && moment(booking.date).isSameOrAfter(moment())) {
                remove = false;
            }
        })
        if (remove === true) {
            this.setState(() => ({ showConfirm: true }));
        } else {
            this.setState(() => ({ showRemove: true }))
        }
    }

    /**
     * updates state to close cofirm delete
     * calls action to edit service profile in database
     * removes events for this service item by calling the action
     * navigates to the service profile view page
     */
    onRemove = () => {
        this.setState(() => ({ showConfirm: false }));
        
        let items = {}
        Object.keys(this.props.service.serviceItems).map((key, index) => {
            if (key != this.state.itemID) {
                items = {
                    ...items,
                    [key]: Object.values(this.props.service.serviceItems)[index]
                }
            }
        })

        // call action to edit service profile
        this.props.startEditService(this.props.service.id, {
            ...this.props.service,
            serviceItems: {
                ...items
            }
        })

        // navigate to service profile view page
        let s = this.props.service.name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
        scroll.scrollTo(0, { duration: 750, delay: 10 });
    }

    /**
     * updates state to close all pop ups
     */
    onCancel = () => {
        this.setState(() => ({ 
            showRemove: false,
            showConfirm: false 
        }))
    }

    /**
     * navigates user back to service profile view
     */
    onBack = () => {
        let s = this.props.service.name.toLowerCase().replace(/ /g,'');
        this.props.history.push(`/service/${s}`);
    }

    render() {
        return (

            <div>
                <TopNav loggedIn={true}  type={'seller'}/> 

                <div>
                    <ServiceItemForm
                        firstSignIn={false}
                        edit={true}
                        item={this.state.item}
                        onSubmit={this.onSubmit}
                        onCancel={this.onBack} 
                        onRemove={this.onRemoveRequest}
                        categories={this.props.categories} />
                </div>

                {/* pop up for item that cannot be deleted due to existing bookings */}
                <div>
                    <SweetAlert
                        show={this.state.showRemove}
                        focusConfirmBtn={false}
                        confirmBtnText="Back"
                        confirmBtnBsStyle="link"
                        title={'Error'}
                        heightAuto={false}
                        onConfirm={this.onCancel}>
                        <h3 className="font-weight-light mt-3">This item cannot be removed as there are bookings for it already.</h3>
                        <h5 className="font-weight-light mt-3">Please remove unbooked future events, and once all bookings have completed you will be able to remove it.</h5>
                    </SweetAlert>
                </div>

                {/* confirm deletion of item pop up */}
                <div>
                    <SweetAlert
                        show={this.state.showConfirm}
                        focusConfirmBtn={false}
                        confirmBtnText="Confirm"
                        confirmBtnBsStyle="default"
                        cancelBtnText="Cancel"
                        cancelBtnBsStyle="link"
                        showCancel
                        title="Are you sure you want to remove this service item?"
                        heightAuto={false}
                        onConfirm={this.onRemove}
                        onCancel={this.onCancel}>
                        <h3 className="font-weight-light mt-3">This service item will be permanently deleted.</h3>
                        <h5 className="font-weight-light mt-3">Once deleted, it cannot be recovered.</h5>
                    </SweetAlert>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    bookings: state.bookings,
    service: state.services[0],
    categories: state.categories.map((category) => ({
        value: category.id,
        label: category.category
    }))
});

const mapDispatchToProps = (dispatch) => ({
    startEditService: (id, service) => dispatch(startEditService(id, service))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceItemPage);