import React from 'react';

/**
 * Page display for incorrect urls
 * 
 * link to redirect users back
 */
class NotFoundPage extends React.Component {
    
    constructor(props){
        super(props)
    }

    onBack = () => {
        this.props.history.goBack();
    }
    
    render() {
        return (

            <div className="container m-5 p-5 pointer">
                404 - <div className="nav-back font-weight-light" onClick={this.onBack} to={`/`}>Go back</div>
            </div>

       )
    }
}

export default NotFoundPage;