import React from 'react';
import {getTotalBuyers, getTotalSellers, getTotalUsers, getTotalServices, getNewUsers, getNewBuyers, getNewSellers} from '../../utils/admin';
import {selectServices} from '../../selectors/services';

/**
 * analytics view for admin page
 */
export class Analytics extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            services: this.props.services ? this.props.services : [],
            users: this.props.users ? this.props.users : [],

            totalUsers: this.props.users ? getTotalUsers(this.props.users) : '',
            totalBuyers: this.props.users ? getTotalBuyers(this.props.users) : '',
            totalSellers: this.props.users ? getTotalSellers(this.props.users) : '',
            totalServices: this.props.services ? getTotalServices(this.props.services) : '',
            activeServices: this.props.services ? selectServices(this.props.services, { text:'', category:'', location:'', near:false, nearServices:[] }) : '',
            
            newUsers: this.props.users ? getNewUsers(this.props.users) : '',
            newBuyers: this.props.users ? getNewBuyers(this.props.users) : '',
            newSellers: this.props.users ? getNewSellers(this.props.users) : ''
        }
    }

    /**
     * updates the state when new props are received from AdminPage
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.users != newProps.users) {
            this.setState(() => ({
                users: newProps.users,
                totalUsers: getTotalUsers(newProps.users),
                totalBuyers: getTotalBuyers(newProps.users),
                totalSellers: getTotalSellers(newProps.users),
                totalServices: getTotalServices(newProps.services),
                activeServices: selectServices(newProps.services, { text:'', category:'', location:'', near:false, nearServices:[] }),
                newUsers: getNewUsers(newProps.users),
                newBuyers: getNewBuyers(newProps.users),
                newSellers: getNewSellers(newProps.users)
            }))
        }
    }

    /**
     * calls the method in AdminPage
     * determines the tab to change to depending on the analytic clicked
     */
    toTab = (tab) => {
        this.props.toTab(tab)
    }

    render() {
        return (

            <div>
                <div className="mt-5">

                    {/* data relating to total users */}
                    <div className="m-5 row">
                        <div className="col-4 d-flex align-items-center">
                            <h3 className="font-weight-light display-5">Users</h3>
                        </div>
                        <div className="col-4 item-link" onClick={(() => this.toTab('sellers-all'))}>
                            <h3 className="row font-weight-light text-left display-5">Sellers</h3>
                            <h3 className="row font-weight-bold text-left display-3" style={{color:"#142948"}}>{this.state.totalSellers}</h3>
                        </div>
                        <div className="col-4 item-link" onClick={(() => this.toTab('buyers-all'))}>
                            <h3 className="row font-weight-light text-left display-5">Buyers</h3>
                            <h3 className="row font-weight-bold text-left display-3" style={{color:"#142948"}}>{this.state.totalBuyers}</h3>
                        </div>
                    </div>
                    <hr/>

                    {/* data relating to services */}
                    <div className="m-5 row">
                        <div className="col-4 d-flex align-items-center pr-5">
                            <h3 className="font-weight-light display-5">Services</h3>
                        </div>
                        <div className="col-4 item-link" onClick={(() => this.toTab('sellers-services'))}>
                            <h3 className="row font-weight-light text-left display-5">Total Services</h3>
                            <h3 className="row font-weight-bold text-left display-3" style={{color:"#142948"}}>{this.state.totalServices}</h3>
                        </div>
                        <div className="col-4 item-link" onClick={(() => this.toTab('sellers-services-active'))}>
                            <h3 className="row font-weight-light text-left display-5">Active Services</h3>
                            <h3 className="row font-weight-bold text-left display-3" style={{color:"#142948"}}>{this.state.activeServices ? this.state.activeServices.length : 0}</h3>
                        </div>
                    </div>
                    <hr/>

                    {/* data relating to new registrations */}
                    <div className="m-5 row">
                        <div className="col-4 d-flex align-items-center text-wrap">
                            <h3 className="font-weight-light display-5 mr-5">New Registrations This Week</h3>
                        </div>
                        <div className="col-4 item-link" onClick={(() => this.toTab('sellers-new'))}>
                            <h3 className="row font-weight-light text-left display-5">New Sellers</h3>
                            <h3 className="row font-weight-bold text-left display-3" style={{color:"#142948"}}>{this.state.newSellers.length}</h3>
                        </div>
                        <div className="col-4 item-link" onClick={(() => this.toTab('buyers-new'))}>
                            <h3 className="row font-weight-light text-left display-5">New Buyers</h3>
                            <h3 className="row font-weight-bold text-left display-3" style={{color:"#142948"}}>{this.state.newBuyers.length}</h3>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Analytics;