import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import LoginPage from '../components/Login/LoginPage';
import SignUpPage from '../components/SignUpPage';
import RegisterPage from '../components/Register/RegisterPage';
import DashboardPage from '../components/Dashboard/DashboardPage';
import NotFoundPage from '../components/NotFoundPage';
import ServicePage from '../components/Service/ServicePage';
import AddServiceItemPage from '../components/Item/AddServiceItemPage';
import EditServicePage from '../components/Edit/EditServicePage';
import EditServiceItemPage from '../components/Item/EditServiceItemPage';
import CategoryPage from '../components/Services/CategoryPage';
import ProfilePage from '../components/Profile/ProfilePage';
import EntryPage from '../components/Entry/EntryPage';
import BookingsPage from '../components/Bookings/BookingsPage';
import SettingsPage from '../components/Settings/SettingsPage';
import CalendarPage from '../components/Calendar/CalendarPage';
import AdminPage from '../components/Admin/AdminPage';
import SearchPage from '../components/SearchPage';
import AvailabilityPage from '../components/Availability/AvailabilityPage';
import EventsPage from '../components/Events/EventsPage';

export const history = createHistory();

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
            
                {/* Disabled unil it can be secured behind a login */}
                
                <PublicRoute path="/admin" exact={true} component={AdminPage} /> 
                
                <PublicRoute path="/" component={EntryPage} exact={true}/> 
                <PublicRoute path="/search" component={SearchPage}/> 
                <PublicRoute path="/:type/login" component={LoginPage} />
                <PublicRoute path="/:type/signup" component={SignUpPage} />
                <PublicRoute path="/:type/register" component={RegisterPage} />
                <PrivateRoute path="/dashboard" component={DashboardPage}/>
                <PrivateRoute path="/calendar" component={CalendarPage}/>
                <PrivateRoute path="/availability" component={AvailabilityPage}/>
                <PrivateRoute path="/add" component={AddServiceItemPage}/>
                <PrivateRoute path="/edit/:title" component={EditServiceItemPage} />
                <PrivateRoute path="/edit" exact={true} component={EditServicePage} />
                <PrivateRoute path="/profile" component={ProfilePage} />
                <PrivateRoute path="/events" component={EventsPage} />
                <Route path="/category/:category" component={CategoryPage} />
                <Route path="/service/:service" exact={true} component={ServicePage} />
                <PrivateRoute path="/bookings" component={BookingsPage} />
                <PrivateRoute path="/settings" component={SettingsPage} />
                <Route component={NotFoundPage}/>
            </Switch>
        </div>
    </Router>
);

export default AppRouter;