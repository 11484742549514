import React from 'react';
import moment from 'moment';
import {SingleDatePicker} from 'react-dates';


/**
 * form component for events for buyers
 */
export class EventForm extends React.Component {

    state = {

        edit: this.props.edit,

        categories: this.props.categories,
        selectedCategories: this.props.event ? this.props.event.services : [],

        title: this.props.event ? this.props.event.title : '',
        description: this.props.event ? this.props.event.description : '',
        people: this.props.event ? this.props.event.people : '',
        date: this.props.event ? moment(this.props.event.date) : moment(),
        dateSelected: this.props.event ? true : false,
        time: this.props.event ? this.props.event.time : '',
        duration: this.props.event ? this.props.event.duration ? this.props.event.duration : undefined : undefined,
        
        showDurationPicker: false,
        customDuration: false,

        durations: ['N/A','15 minutes','30 minutes','45 minutes','60 minutes','90 minutes','120 minutes','360 minutes'],
        times: ['','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],

        titleError: '',
        formError: '',
        dateError: '',
        timeError: '',
        peopleError: '',
        catError: ''

    }

    /**
     * methods for updating state based on form inputs
     */
    onTitleChange = (e) => {
        const title = e.target.value;
        this.setState(() => ({ 
            title,
            titleError: '' 
        }))
    }
    onDescriptionChange = (e) => {
        const description = e.target.value;
        this.setState(() => ({ description }));
    }
    onPeopleChange = (e) => {
        const people = e.target.value;
        this.setState(() => ({ people }));
    }
    onDurationChange = (e) => {
        const duration = e.target.value;
        if (duration == "Custom") {
            this.setState(() => ({ 
                customDuration: true,
                duration 
            }));
        } else {
            this.setState(() => ({ 
                duration,
                customDuration: false
            }));
        }
        this.setState(() => ({ durationError: '' }))
    }
    onCustomDurationChange = (e) => {
        const duration = e.target.value;
        this.setState(() => ({ 
            duration: duration + ' minutes',
            durationError: ''
        }))
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }));
    }
    onDateChange = (date) => {
        if (date) { 
            this.setState(() => ({ 
                date,
                dateSelected: true,
                dateError: ''
            })) 
        }
    }
    onTimeChange = (e) => {
        let time = e.target.value;
        this.setState(() => ({ 
            time,
            timeError: ''
        }))
    }
    onChange = (value) => {
        let selectedCategories = this.state.selectedCategories

        if (selectedCategories != undefined && selectedCategories.length != 0 && (selectedCategories).includes(value)) {
            this.setState(() => ({ selectedCategories: selectedCategories.filter((c) => c != value) }))
        } else if (selectedCategories != undefined) {
            this.setState(() => ({ 
                selectedCategories: selectedCategories.concat([value]),
                catError: ''
            }))
        } else {
            this.setState(() => ({ 
                selectedCategories: [value],
                catError: ''
            }))
        }
    }

    /**
     * method for submitting a new event
     * performs error checks
     * calls onSubmit method in EventsPage
     */
    onSubmit = (e) => {
        e.preventDefault();

        let durationRegex = /^$|^([0-9]* (minutes))|(infinite)$/;

        // error checks
        if (!this.state.title) { this.setState(() => ({ titleError: 'Please provide a title.'})) }
        else { this.setState(() => ({ titleError: '' })) }
        if (!this.state.people) { this.setState(() => ({ peopleError: 'Please provide an estimate of the number of people.'})) }
        else { this.setState(() => ({ peopleError: '' })) }
        if (!this.state.time) { this.setState(() => ({ timeError: 'Please select a time.'})) }
        else { this.setState(() => ({ timeError: '' })) }
        if (!this.state.duration) { this.setState(() => ({ durationError: 'Please provide a valid duration.'})) }
        else { this.setState(() => ({ durationError: '' })) }
        if (this.state.customDuration) { if (!durationRegex.test(this.state.duration)) { this.setState(() => ({ durationError: 'Provide a valid duration in minutes.'})) }
        else { this.setState(() => ({ durationError: '' })) }}
        if (!this.state.dateSelected) { this.setState(() => ({ dateError: 'Please select a date.'})) }
        else { this.setState(() => ({ dateError: '' })) }
        if (this.state.selectedCategories.length === 0) { this.setState(() => ({ catError: 'Please select at least one service you will require.'})) }
        else { this.setState(() => ({ catError: '' })) }

        if (this.state.title && this.state.time && this.state.dateSelected && this.state.duration && (this.state.selectedCategories.length != 0)) {

            // submit
            let event = {
                date: this.state.date.toString().substring(4,16) + this.state.time.substring(0,6),
                time: this.state.time,
                duration: this.state.duration,
                title: this.state.title,
                description: this.state.description,
                people: this.state.people,
                services: this.state.selectedCategories
            }
            if (this.state.edit) {
                this.props.onSubmit({
                    ...event,
                    id: this.props.event.id
                }, 'edit')

            } else {
                this.props.onSubmit(event)
            }
        } else {
            this.setState(() => ({ formError: 'Please fill out all required fields.' }))
        }
    }

    render() {
        return (

            <div className="d-flex justify-content-center text-left mt-5">
                <div className="col-8">
                    <form onSubmit={this.onSubmit}>
                    
                    <div className="form-group pb-4">
                        <hr/>
                        <label htmlFor="name">Event Title</label>
                        <input
                            placeholder=""
                            className="form-control font-weight-light rounded-0 form-control-lg"
                            type="text"
                            value={this.state.title}
                            onChange={this.onTitleChange}/>
                        <small className="form-text" style={{color: '#ec9422'}}>{this.state.titleError}</small>                        
                    </div>

                    <div className="form-group pb-4">
                        <hr/>
                        <label htmlFor="notes">Description</label>
                        <textarea
                            className="form-control rounded-0 form-control-lg"
                            style={{height: 70}}
                            placeholder=""
                            type="text"
                            value={this.state.description}
                            onChange={this.onDescriptionChange}/>
                    </div>

                    <div className="form-group pb-4">
                        <hr/>
                        <label htmlFor="notes">Number of People</label>
                        <input
                            className="form-control font-weight-light rounded-0 form-control-lg"
                            placeholder=""
                            type="number"
                            value={this.state.people}
                            onChange={this.onPeopleChange}/>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.peopleError}</small>
                    </div>
                    
                    <div className="form-group pb-4">
                        <hr/>
                        <label htmlFor="date">Select Date</label>
                        <div style={{border: '1px solid gainsboro'}}>
                            <SingleDatePicker
                                date={this.state.date}
                                focused={this.state.calendarFocused}
                                onDateChange={this.onDateChange}
                                onFocusChange={this.onFocusChange}
                                numberOfMonths={1}
                            />
                        </div>
                        {this.state.dateError && <small className="form-text" style={{color: '#ec9422'}}>{this.state.dateError}</small>}
                    </div> 

                    <div>
                        <div className="form-group pb-4">
                            <hr/>
                            <label htmlFor="time">Select Time</label>
                            <select 
                                style={{color: 'black'}}
                                className="custom-select font-weight-light rounded-0 custom-select-lg"
                                value={this.state.time}
                                onChange={this.onTimeChange}>
                                <option></option>
                                {this.state.times.map((t, index) => (
                                    <option key={index}>{t}</option>
                                ))}
                            </select>
                            <small className="form-text" style={{color: '#ec9422'}}>{this.state.timeError}</small>
                        </div>
                    </div>

                    <div className="form-group pb-4">
                        <hr/>
                        <label>Duration</label>
                        <select 
                            className="custom-select font-weight-light rounded-0 custom-select-lg"
                            value={this.state.duration}
                            onChange={this.onDurationChange}>
                            <option>{this.state.customDuration ? 'Custom' : this.state.duration}</option>
                            <option>Custom</option>
                            {this.state.durations.map((duration) => {
                                return (<option key={duration}>{duration}</option>)
                            })}
                        </select>
                        
                        <div className="pt-2" hidden={!this.state.customDuration}>
                            <input
                                className="form-control font-weight-light rounded-0 form-control-lg"
                                placeholder="Minutes"
                                type="number"
                                value={this.state.customDurationVal}
                                onChange={this.onCustomDurationChange}/>
                            <small className="form-text text-muted">Please specify the number of minutes</small>     
                        </div>                           
                        <small className="form-text" style={{color: '#ec9422'}}>{this.state.durationError}</small>
                    </div>

                    <fieldset className="form-group pb-4">
                        <hr/>
                        <label className="col-form-label">Services Required</label>
                        {this.state.categories.map((c) => (
                            <div className="row" key={c.value}>
                                <div className="col-sm-10">
                                    <div className="form-check">
                                        <input className="form-check-input" defaultChecked={this.state.selectedCategories != undefined ? this.state.selectedCategories.includes(c.label) : false} type="checkbox" onChange={(() => this.onChange(c.label))} value={c.label}/>
                                        <label className="form-check-label ml-1">{c.label}</label>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <small className="form-text" style={{color: '#ec9422'}}>{this.state.catError}</small>
                    </fieldset>
                    <hr/>

                    <h4 className="mt-4 mb-3 font-weight-light" style={{color: '#ec9422'}}>{this.state.formError}</h4>
                    {!this.state.edit ?
                        <div hidden={this.state.edit} className="d-flex justify-content-center">
                            <button className="rounded-0 btn btn-main btn-block" style={{height: 30}}>
                                <h4 className="font-weight-light">Create Event</h4>
                            </button>
                        </div>
                    :
                        <div hidden={this.state.edit} className="d-flex justify-content-center">
                            <button className="rounded-0 btn btn-main btn-block" style={{height: 30}}>
                                <h4 className="font-weight-light">Save Changes</h4>
                            </button>
                        </div>
                    }

                </form>
                </div>
            </div>
        )
    }
}

export default EventForm;