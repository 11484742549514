import database, {firebase, googleAuthProvider, facebookAuthProvider} from '../firebase/firebase';
import moment from 'moment';
import {editUser, addUser} from '../actions/users';
import {startSetAllServices} from '../actions/services';
import {welcomeEmail, welcomeEmailGmail} from '../utils/emails';

// SET ERROR MESSAGE

/** sets error handling message in redux store */
export const setErrorMessage = (error) => {
  return ({
    type: 'SET_ERROR_MESSAGE',
    error
  })
};

// LOGIN

/** logs in using firebase authentication */
export const startLogin = (profile) => {
  
  if (profile.loginType == "Google") {

      // login with google
      return (dispatch) => {
        return firebase.auth().signInWithPopup(googleAuthProvider)
        .then((result) => {
          return database.ref('users/'+result.user.uid+'/')
            .once('value')
            .then((snapshot) => {
              if (snapshot.exists()) {

                // login will work

              } else {

                // need to register new profile
                const userProfile = {
                  name: result.user.displayName,
                  email: result.user.email,
                  number: '',
                  type: profile.type,
                  image: result.user.photoURL,
                  firstSignIn: true,
                  date: moment().toString()
              };
              dispatch(firebase.database().ref('users/'+result.user.uid+'/'+result.user.uid)
                  .update(userProfile)
                  .then((ref) => {
                      dispatch(addProfile({
                        ...userProfile,
                        id: result.user.uid
                      }));
              }));
              }
            })
        }).catch((error) => {
          const errorMessage = error.message;
          console.log("error message" + errorMessage);
        });
      }

  } else {

      // login with email 
      return (dispatch) => {
          return firebase.auth().signInWithEmailAndPassword(profile.email, profile.password)
          .catch((error) => {
            console.log("error message ", error.message)
            dispatch(setErrorMessage({ error: "Invalid email or password" }));
          });
      }
  }
};

// SET PROFILE

/** sets profile in redux state */
export const setProfile = (profile) => ({
  type: 'LOGIN',
  profile
});

/** gets profile from database */
export const startSetProfile = (uid) => {
  return (dispatch) => {
      return database.ref('users/'+uid+'/')
          .once('value')
          .then((snapshot) => {
              const profile = [];

              snapshot.forEach((childSnapshot) => {
                const value = childSnapshot.val();
                  profile.push({
                      id: childSnapshot.key,
                      ...value
                  });
              });
          
          dispatch(setProfile(profile));
          });
  };
};

// LOGOUT

/** clears profile from redux state */
export const logout = () => ({
  type: 'LOGOUT'
});

/** logs out user from firebase authentication */
export const startLogout = () => {
  return firebase.auth().signOut();
}

// ADD PROFILE | REGISTER

/** adds new profile to redux store */
export const addProfile = (profile) => ({
    type: 'ADD_PROFILE',
    profile
});

/** adds new profile to database */
export const startAddProfile = (profile, user) => {

  if (profile.loginType == "Google") {

    // register with google
    return (dispatch) => {
      return firebase.auth().signInWithPopup(googleAuthProvider)
      .then((result) => {
        return database.ref('users/'+result.user.uid+'/')
            .once('value')
            .then((snapshot) => {
              if (snapshot.exists()){
                console.log('user already registered')
              } else {

                // need to register new profile
                const userProfile = {
                  name: result.user.displayName,
                  email: result.user.email,
                  number: '',
                  type: profile.type,
                  image: result.user.photoURL,
                  firstSignIn: true,
                  date: moment().toString()
              };
              dispatch(firebase.database().ref('users/'+result.user.uid+'/'+result.user.uid)
                  .update(userProfile)
                  .then((ref) => {
                    dispatch(addProfile({
                      ...userProfile,
                      id: result.user.uid
                    }));
                    // Send welcome email
                    welcomeEmailGmail(profile, result);
              }));

              
              }
            })
        }).catch((error) => {
          const errorMessage = error.message;
          console.log("error message" + errorMessage);
        });
      }

  } else {

    // register with email 
    return (dispatch) => {
      return firebase.auth().createUserWithEmailAndPassword(profile.email, profile.password)
      .then((result) => {
        dispatch(firebase.database().ref(`users/${result.user.uid}/${result.user.uid}/`)
          .update({
              name: profile.name,
              email: profile.email,
              number: profile.number,
              type: profile.type,
              firstSignIn: true,
              date: profile.date
          })
          .then(() => {

            if (user === 'super') {
              dispatch(addUser({
                name: profile.name,
                email: profile.email,
                number: profile.number,
                type: profile.type,
                firstSignIn: true,
                id: result.user.uid,
                date: profile.date
              }));
              startLogout();
              startSetAllServices();
            } else {
              dispatch(addProfile({
                name: profile.name,
                email: profile.email,
                number: profile.number,
                type: profile.type,
                firstSignIn: true,
                id: result.user.uid,
                date: profile.date
              }));

              // Send welcome email
              welcomeEmail(profile);
            }
        }));
      }).catch((error) => {
        if (error.message === 'The email address is already in use by another account.') {
          dispatch(setErrorMessage({ error: "This email address has already been registered" }));
        }
    })};
  }
};

// EDIT PROFILE

/** edits profile in redux store */
export const editProfile = (id, updates) => ({
  type: 'EDIT_PROFILE',
  id,
  updates
});

/** edits profile in database */
export const startEditProfile = (updates, userID) => {

  // if editing from admin page (superuser)
  if (userID) {
    return (dispatch) => {
      const id = userID;
      return database.ref(`users/${id}/${id}/`)
        .update(updates)
        .then(() => {
          dispatch(editUser(id, {
            id,
            ...updates
          }));
        });
    };
  } 
  // if editing as a logged in user
  else {
    return (dispatch, getState) => {
      const id = getState().profile[0].id;
      return database.ref(`users/${id}/${id}/`)
        .update(updates)
        .then(() => {
          dispatch(editProfile(id, {
            id,
            ...updates
          }));
        });
    };
  }
};

/** deletes user from authentication in firebase */
export const deleteProfile = firebase.functions().httpsCallable('deleteUser');
