import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import Rating from 'react-rating';
import SweetAlert from 'react-bootstrap-sweetalert';

import TopNav from '../TopNav';
import Analytics from './Analytics';
import CategoryList from '../Category/CategoryList';
import CategoryListFilters from '../Category/CategoryListFilters';
import ServiceForm from '../Edit/ServiceForm';
import Nearby from './Nearby';
import AddImage from './AddImage';
import Progress from '../Progress';
import Footer from '../Footer';

import {startAddService, startEditService} from '../../actions/services';
import {startEditProfile} from '../../actions/profile';
import {startEditBooking} from '../../actions/bookings';
import {setNearServices} from '../../actions/filters';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * the dashboard page is the entry point for a logged in user (buyers and sellers)
 * 
 * www.itorho.com/dashboard
 */
export class DashboardPage extends React.Component {

    state = {
        type: this.props.type ? this.props.type : 'seller',
        showRating: !!this.props.booking,
        showReview: false,
        showImage: false,
        rating: 0,
        review: '',
        service: this.props.booking ? this.props.booking.service : '',
        item: this.props.booking ? this.props.booking.item : '',
        date: this.props.booking ? this.props.booking.date.substring(0,12) : '',
        id: this.props.booking ? this.props.booking.id : '',
        serviceID: this.props.booking ? this.props.booking.serviceID : '',
        firstSignIn: this.props.firstSignIn ? this.props.firstSignIn : false,
        route: this.props.route ? this.props.route : '',
        nearServices: [],
        showStep2: false,
        showStep3: false
    }

    /*
     * on first mount, method checks if user is a buyer and updates firstSignIn parameter to false
     *
     * NOTE: if user is a seller - firstSignIn parameter = true loads the ServiceForm component
     * this forces sellers to complete their service profile before giving them the functionality of the rest of the site
     * the firstSignIn parameter for sellers is only changed once they submit a valid service profile
     * 
     * checks route props to see if user was on a specific service page before signing in
     * and redirects them there if true
     */
    componentDidMount = () => {
        
        if (this.props.type === 'buyer') {
            this.props.startEditProfile({
                ...this.props.profile,
                firstSignIn: false
            })
            if (this.props.route.length != 0 && this.props.route && !this.props.route[0].offset) { 
                this.props.history.push(`service/${this.props.route}`)
            }
            this.setState(() => ({ firstSignIn: false }))
        }

        initGA();
        logPageView();
    }

    /*
     * updates state upon receiving new props from the redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        
        if (newProps.services != this.state.services) {
            this.setState(() => ({ services: newProps.services }))
        }
        if (newProps.firstSignIn != this.state.firstSignIn) {
            this.setState(() => ({ firstSignIn: newProps.firstSignIn }))
        }
        if (newProps.type != this.state.type) {
            this.setState(() => ({ type: newProps.type }))
        }
        
        // for updating progress component
        setTimeout(() => {
            if (newProps.services) {
                if (newProps.type == "seller") {
                    if (!newProps.services[0].serviceItems) {
                        this.setState(() => ({ 
                            showStep2: true,
                            showStep3: false 
                        }))
                    } else if (!newProps.services[0].availability) {
                        this.setState(() => ({ 
                            showStep3: true,
                            showStep2: false 
                        }))
                    } else {
                        this.setState(() => ({ 
                            showStep2: false,
                            showStep3: false 
                        }))
                    }
                }
            }
          }, 1000);
    }

    /*
     * method for adding a service profile on first sign in by seller
     *
     * calls the action to add service to database
     * updates the seller profile
     * redirects to seller to add service item page
     */
    onSubmit = (id, service) => {
        this.props.startAddService(service);
        this.props.startEditProfile({
            ...this.props.profile,
            firstSignIn: false
        });
        this.setState(() => ({ firstSignIn: false }))
        this.props.history.push(`/add`);
    }

    /**
     * updates state to close add image pop up
     */
    onCancel = () => {
        this.setState(() => ({ showImage: false })); 
        this.props.history.push(`/dashboard`);
    }

    /*
     * methods for ratings and reviews 
     */
    onRatingChange = (value) => {
        this.setState(() => ({ rating: value }));
    }
    onReviewChange = (e) => {
        const review = e.target.value;
        this.setState(() => ({ review }));
    }
    onAddRating = () => {
        this.props.services.filter((service) => {
            if (service.id == this.props.booking.serviceID) {
                let serviceNoRatings = service.noRatings || 0;
                let serviceRating = (service.rating*serviceNoRatings + this.state.rating)/(serviceNoRatings+1)
                serviceNoRatings = serviceNoRatings+1

                this.props.startEditService(this.state.serviceID, {
                    serviceNoRatings,
                    serviceRating,
                })
            }
        });
        this.props.startEditBooking(this.props.booking.id, { rated: true })
        this.setState(() => ({ 
            showRating: false,
            showReview: true 
        }))
    } 
    onAddReview = () => {
        if (this.state.review != '') {
            this.props.services.filter((service) => {
                if (service.id == this.state.serviceID) {
                    let reviews = {}
                    if (service.reviews) {
                        const int = Object.keys(service.reviews).length+1;
                        const i = int.toString();
                        reviews = {
                            ...service.reviews,
                            [i]: moment.now() + ' ' + this.state.review + ' ' + this.state.rating
                        }
                    } else {
                        const i = 0;
                        reviews = {
                            [i]: moment.now() + ' ' + this.state.review + ' ' + this.state.rating
                        }
                    }
                    this.props.startEditService(this.state.serviceID, { reviews })
                }
            });
        }
        this.setState(() => ({ 
            showReview: false,
            showImage: true 
        }))
    } 
    onExit = () => {
        this.props.startEditBooking(this.props.booking.id, { rated: true })
        this.setState(() => ({ 
            showRating: false,
            showReview: true 
        }))
    }
    onExitReview = () => {
        this.setState(() => ({ 
            showReview: false,
            showImage: true 
        }))
    }

    /*
     * method to set nearby services
     * calls the setNearServices action
     */
    setNearby = (nearServices) => {
        this.setState(() => ({ nearServices }));
        this.props.setNearServices(nearServices);
    }

    /**
     * method in place to encourage sellers to change their service profile image if still a placeholder image
     * redirects seller to edit service profile page
     * 
     * NOTE: this method will become redundant once older profiles have added own image 
     * as sellers are no longer allowed to complete service profile without adding own image
     */
    onImageChange = () => {
        this.props.history.push('/edit')
    }

    /**
     * called by analytics component
     * calls the action to edit booking to paid
     */
    onCash = (id) => {
        this.props.startEditBooking(id, { paid: true })
    }

    render() {
        return (
    
            <div>
                <TopNav loggedIn={true} type={this.state.type} props={this.props}/>
                <div className="mb-5 pb-5">

                    {this.state.type === 'seller' ?

                        /* content for sellers */
                        <div>

                            {/* force sellers to fill out service details if first sign in is true */}
                            <div hidden={!(this.state.firstSignIn)}>
                                <ServiceForm
                                    id={this.props.id}
                                    categories={this.props.categories}
                                    companyNames={this.props.companyNames}
                                    admin={false}
                                    edit={!this.state.firstSignIn}
                                    onSubmit={this.onSubmit}
                                    onCancel={this.onCancel}/>
                            </div>

                            {/* content for sellers who are not signing in for the first time (ie. have a valid service profile)*/}
                            <div hidden={this.state.firstSignIn}>

                                {/* for sellers still using placeholder image */}
                                <div hidden={!(this.props.services[0].image === "/images/service-placeholder.png")} className="mt-5 pt-5 ml-5 mr-5">
                                    <div className="mb-5 d-flex justify-content-center">
                                        <div className="col-8" style={{color: 'grey'}}>
                                            <h4 className="p-2 font-weight-light text-center"><span className="font-weight-bold">Note:</span> You are still using a placeholder image in your service profile. Please change this ASAP.</h4>
                                            <h4 className="p-2 text-center item-link" onClick={this.onImageChange}>CHANGE IMAGE NOW</h4>
                                        </div>
                                    </div>
                                </div>

                                {/* for incomplete seller profiles */}
                                <div hidden={!this.state.showStep3} className="mt-md-5 pt-md-5 pt-3 ml-md-5 mr-md-5">
                                    <div className="mb-5">
                                    <div className="d-flex justify-content-center">
                                    <div className="col-md-8 col-10" style={{color: 'grey'}}>
                                        <h4 className="p-md-2 font-weight-light text-center welcome-subtitle2"><span className="font-weight-bold">Note:</span> Please add your availabilty by going to the Availability tab. Your availability will have the default settings of Mon-Fri from 9-5 until you edit it.</h4>
                                    </div>
                                    </div>
                                    <Progress step={3} props={this.props}/>
                                    </div>
                                    <hr/>
                                </div> 
                                <div hidden={!this.state.showStep2} className="mt-5 pt-md-5 pt-3 ml-md-5 mr-md-5">
                                    <div className="mb-5">
                                    <div className="d-flex justify-content-center">
                                    <div className="col-md-8 col-10" style={{color: 'grey'}}>
                                        <h4 className="p-md-2 font-weight-light text-center welcome-subtitle2"><span className="font-weight-bold">Note:</span> Please add a service item to start generating bookings.</h4>
                                    </div>
                                    </div>
                                    <Progress step={2} props={this.props}/>
                                    </div>
                                    <hr/>
                                </div> 
                                    

                                {/* main dashboard content for sellers */}
                                <div className="mb-5 pb-5">
                                    <Analytics 
                                        onCash={this.onCash}
                                        bookings={this.props.bookings}
                                        service={this.props.service ? this.props.service : undefined}
                                        profile={this.props.profile} />
                                </div>

                            </div>
                            
                        </div>
                    :
                        /* content for buyers */
                        <div className="container">
                                
                            <Nearby
                                setNearby={this.setNearby}
                                services={this.props.services}/>
                                
                            {/* ratings popup for buyers */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showRating}
                                    confirmBtnText="Done"
                                    confirmBtnBsStyle="default"
                                    cancelBtnBsStyle="default"
                                    focusConfirmBtn={false}
                                    title="Leave a rating for"
                                    onConfirm={this.onAddRating}
                                    onCancel={this.onExit}>
                                    <h3 className="pt-2 display-5" style={{color: '#ec9422'}}>{this.props.booking ? this.props.booking.item : ''} at {this.props.booking ? this.props.booking.service : ''}</h3>
                                    <h5 className="font-weight-light p-2 pb-4">{this.props.booking ? this.props.booking.date.substring(0,12) : ''}</h5>
                                    <Rating
                                        id="rating"
                                        {...this.props}
                                        onChange={((value) => this.onRatingChange(value))}
                                        initialRating={this.state.rating}
                                        emptySymbol={<img src="/images/star-empty.png" className="icon" style={{maxWidth: 20}} />}
                                        fullSymbol={<img src="/images/star-full.png" className="icon" style={{maxWidth: 20}} />} 
                                    />
                                </SweetAlert>
                            </div>

                            {/* review popup for buyers */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showReview}
                                    confirmBtnText="Done"
                                    confirmBtnBsStyle="default"
                                    cancelBtnBsStyle="default"
                                    focusConfirmBtn={false}
                                    title="Leave a review for"
                                    onConfirm={this.onAddReview}
                                    onCancel={this.onExitReview}>
                                    <h3 className="pt-2 display-5" style={{color: '#ec9422'}}>{this.state.item} at {this.state.service}</h3>
                                    <h5 className="font-weight-light p-3">{this.state.date}</h5>
                                    <div className="d-flex justify-content-center">
                                    <textarea
                                        style={{maxWidth: 250}}
                                        className="form-control font-weight-light rounded-0 form-control-lg m-3 mb-0 pb-0"
                                        placeholder="Leave a review..."
                                        type="text"
                                        id="review"
                                        value={this.state.review}
                                        onChange={this.onReviewChange}/>
                                    </div>
                                </SweetAlert>
                            </div>

                            {/* add image popup for buyers */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showImage}
                                    confirmBtnText="Exit"
                                    confirmBtnBsStyle="link"
                                    focusConfirmBtn={false}
                                    title="Add an Image for this Service"
                                    onConfirm={this.onCancel}
                                    onCancel={this.onCancel}>
                                    <AddImage
                                        onAddImage={this.onCancel}
                                        serviceID={this.state.serviceID}
                                        serviceName={this.state.service}/>
                                </SweetAlert>
                            </div>

                            {/* main page dashboard content for buyers */}
                            <CategoryListFilters />
                            <CategoryList history={this.props.history}/>

                        </div>
                    }
                </div>
                <div className="mt-5 footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    id : state.profile[0] ? state.profile[0].id : '',
    bookings: state.bookings,
    service: state.services.find((service) => service.sellerID === state.profile[0].id),
    services: state.services,
    items: state.serviceItems,
    profile: state.profile[0] ? state.profile[0] : '',
    type: state.profile[0] ? state.profile[0].type : null,
    firstSignIn: state.profile[0] ? state.profile[0].firstSignIn : null,
    booking: state.bookings.find((booking) => (booking.buyerID === state.profile[0].id) && (booking.rated === false) && (moment().isAfter(booking.date, 'day'))),
    route: state.route,
    categories: state.categories.map((category) => {
        return {
            label: category.category,
            value: category.id
        }
    }),
    companyNames: state.services.map((service) => ( service.name ))
})

const mapDispatchToProps = (dispatch) => ({
    startAddService: (service) => dispatch(startAddService(service)),
    startEditProfile: (updates) => dispatch(startEditProfile(updates)),
    startEditService: (id, updates) => dispatch(startEditService(id, updates)),
    startEditBooking: (id, updates) => dispatch(startEditBooking(id, updates)),
    setNearServices: (services) => dispatch(setNearServices(services)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);