import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import uuid from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';
import {findServiceName, getAllUnread, getUnread, selectMessages, sortMessages} from '../../selectors/messages';
import {startAddMessageBuyer, startAddMessageSeller, startEditMessage} from '../../actions/messages';
import {sendNotificationEmail} from '../../utils/emails'

/**
 * form component for buyers to message sellers
 * 
 * rendered by the ServiceProfile component
 */
export class MessageForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            services: this.props.services ? this.props.services : [],
            onServiceClick: false,
            unreadCount: 0,
            toUserName: this.props.toUserName,
            toUserID: this.props.toUserID,
            showChat: false,
            message: '',
            allMessages: this.props.allMessages ? this.props.allMessages : [],
            messages: [],
            showInbox: false,
            showMessages: false,
            showName: false,
            inbox: [],
            buyerName: this.props.userName ? this.props.userName : ''
        }
    }

    /**
     * updates state upon component mounting
     */
    componentDidMount() {
        
        this.setState(() => ({ 
                toUserID: this.props.toUserID,
                toUserName: this.props.toUserName 
            }));

        const messages = selectMessages(this.props.toUserID, this.props.userID, this.state.allMessages);
        const unreadCount = getAllUnread(this.state.allMessages);
        this.setState(() => ({ 
                messages,
                unreadCount 
            }));
    }

    /**
     * updates state upon receiving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (newProps.onServiceClick !== this.state.onServiceClick) {
            this.setState(() => ({ 
                    onServiceClick: newProps.onServiceClick,
                    allMessages: newProps.allMessages,
                    toUserName: newProps.toUserName,
                    toUserID: newProps.toUserID,
                    services: newProps.services 
                }))
            if (newProps.onServiceClick === true) { 

                if (this.props.seller === false) {
                    let defaultMessage = true;
                    if (newProps.allMessages.length != 0) {
                        newProps.allMessages.map((message) => {
                            if (message.userID == newProps.toUserID) { defaultMessage = false; }
                        })
                    }
                    if (defaultMessage == true) {
                        let newMessage = {
                            userID: this.props.toUserID, 
                            toUserID: this.props.userID, 
                            message: 'Hello, how can we help you?', 
                            status: 'read',
                            time: moment().toString(),
                            buyerName: this.props.userName
                        }
                        const newAllMessages = newProps.allMessages.concat(newMessage)
                        this.setState(() => ({ 
                                allMessages: newAllMessages,
                                messages:  selectMessages(newProps.toUserID, this.props.userID, newAllMessages)
                            }))
        
                        const mid = uuid();
                        this.props.startAddMessageSeller(newMessage, newMessage, this.props.userID, newProps.toUserID, 'seller', mid);
                        this.props.startAddMessageBuyer(newMessage, newMessage, this.props.userID, newProps.toUserID, 'seller', mid);

                        this.setState(() => ({ inbox: sortMessages(this.props.userID, newAllMessages) }))
                    }
                }

                const unreadCount = getAllUnread(newProps.allMessages);
                this.setState(() => ({ unreadCount }));

                this.setState(() => ({ 
                        showChat: true,
                        showMessages: true,
                        showInbox: false 
                    }))
            }
        }
    }

    /**
     * updates state to hide chat
     * calls onCancel method in ServiceProfile
     */
    onCancel = () => {
        this.setState(() => ({ 
                showChat: false,
                onServiceClick: false 
            }))
        this.props.onCancel();
    }

    /**
     * updates state to close message view and show inbox
     */
    onBack = () => {
        this.setState(() => ({ 
                showInbox: true,
                showMessages: false,
                onServiceClick: false 
            }))
    }
    
    /**
     * method to handle opening messages
     * updates message to read and calls action to edit message
     */
    onOpen = (buyerName, toUserID) => {
        let messages = selectMessages(toUserID, this.state.userID, this.state.allMessages);
        this.setState(() => ({ 
                buyerName,
                messages,
                allMessages: this.state.allMessages,
                toUserName: this.props.seller ? buyerName : findServiceName(toUserID, this.state.services),
                toUserID,
                showInbox: false,
                showMessages: true,
                unreadCount: getAllUnread(this.state.allMessages) 
            }));

        // update message status in backend
        let id = this.props.userID;
        messages.map((message) => {
            if (message.status && message.status == 'unread') {
                let newMessage = {
                    ...message,
                    status: 'read',
                }
                this.props.startEditMessage(id, message.id, newMessage);
            }
        })
    }

    /**
     * updates state upon form input
     */
    onMessageChange = (e) => {
        const message = e.target.value;
        this.setState(() => ({ message }));
    }

    /**
     * method to handle sending a message
     * calls action to send message
     */
    onMessageSend = (e) => {
        e.preventDefault();

        let newMessage = {};
        let newOtherMessage = {};
        const time = moment().toString();
        if (this.state.message) {
            newMessage = { message: this.state.message, toUserID: this.state.toUserID, userID: this.props.userID, time: time, buyerName: this.state.buyerName };
            newOtherMessage = { message: this.state.message, toUserID: this.state.toUserID, userID: this.props.userID, status: 'unread', time: time, buyerName: this.state.buyerName };
            let allMessages = this.state.allMessages.concat(newMessage)
            let messages = this.state.messages.concat(newMessage)
            this.setState(() => ({ 
                    allMessages,
                    messages,
                    message: '' 
                }));
        }

        // add new message to backend
        let type = this.props.seller ? 'seller' : 'buyer';
        let buyerMessage = this.props.seller ? newOtherMessage : newMessage;
        let sellerMessage = this.props.seller ? newMessage : newOtherMessage;
        let buyerID = this.props.seller ? this.state.toUserID : this.props.userID;
        let sellerID = this.props.seller ? this.props.userID : this.state.toUserID;
        const mid = uuid();
        this.props.startAddMessageSeller(buyerMessage, sellerMessage, buyerID, sellerID, type, mid);
        this.props.startAddMessageBuyer(buyerMessage, sellerMessage, buyerID, sellerID, type, mid);

        if (!this.props.seller) {

            // FOR ROGERS
            // notification email of new message to service provider from a buyer
            sendNotificationEmail(sellerID);
        }
    }

    render() {
        return (

            <div>
                <div>
                    <div className="chat-div">
                        <SweetAlert
                            style={{borderRadius: 15}}
                            show={this.state.showChat}
                            focusConfirmBtn={false}
                            confirmBtnText="Exit"
                            closeOnClickOutside
                            confirmBtnBsStyle="link"
                            title={'Messenger'}
                            heightAuto={false}
                            onCancel={this.onCancel}
                            onConfirm={this.onCancel}>
                                
                            <div className="m-0 mt-2 p-0 bg-white">

                                <div hidden={!this.state.showInbox}>
                                    <h3 className="font-weight-light">Inbox</h3>
                                    {this.state.inbox.length != 0 ? (
                                        this.state.inbox.map((other, index) => (
                                                <div key={index} className="p-3 link-chat" style={{border: '1px solid gainsboro'}} onClick={() => this.onOpen(other.buyerName, other.userID)}>
                                                    <h3 className="font-weight-light ml-3 mt-1" style={{color: 'black'}}>{this.props.seller ? other.buyerName : findServiceName(other.userID, this.state.services)}</h3>
                                                    <h5 className="font-weight-light ml-3 mb-1">{getUnread(other.userID, this.state.allMessages)} unread</h5>
                                                </div>
                                        ))
                                    ) : (
                                        <h3>You have no messages</h3>
                                    )}
                                </div>

                                <div hidden={!this.state.showMessages}>
                                    <h3 className="ml-4 text-left font-weight-light">{this.state.toUserName}</h3>
                                    <hr/>
                                    {this.state.messages ? (
                                        this.state.messages.map((message, index) => {
                                            return (
                                            message.userID == this.props.userID ?
                                                <div key={index} className="message-user-div">
                                                    <h4 className="font-weight-light message-user">{message.message}</h4>
                                                </div>
                                            :
                                                <div key={index} className="message-other-div">
                                                    <h4 className="font-weight-light message-other">{message.message}</h4>
                                                </div>
                                        )})
                                    ) : (
                                        <div></div>
                                    )}
                                    <form onSubmit={this.onMessageSend}>
                                        <div className="input-group m-3 pt-4">
                                            <input
                                                placeholder="Type your message..."
                                                className="form-control font-weight-light form-control-lg"
                                                type="text"
                                                id="message"
                                                value={this.state.message}
                                                onChange={this.onMessageChange}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-chat" onClick={this.onMessageSend}>
                                                    <img src="/images/send.png" className="img img-fluid" style={{maxBlockSize: 20}}/>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    services: state.services,
    allMessages: state.messages
})

const mapDispatchToProps = (dispatch) => ({
    startAddMessageBuyer: (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => dispatch(startAddMessageBuyer(buyerMessage, sellerMessage, buyerID, sellerID, type, mid)),
    startAddMessageSeller: (buyerMessage, sellerMessage, buyerID, sellerID, type, mid) => dispatch(startAddMessageSeller(buyerMessage, sellerMessage, buyerID, sellerID, type, mid)),
    startEditMessage: (id, messageID, updates) => dispatch(startEditMessage(id, messageID, updates))
});


export default connect(mapStateToProps, mapDispatchToProps)(MessageForm);