import React from 'react';
//import Rating from 'react-rating';


/**
 * service list item display
 * rendered by the service list
 */
const ServiceListItem = (props) => (

    <div>

        <div className="row ml-sm-5 ml-2 pl-md-5 pl-0 margin-mobile" style={{borderTop: '1px solid gainsboro'}}>
            <div className="col-md-5 col-8 mt-4 float-left image-div">
                <img src={props.image ? props.image : `/images/service-placeholder.jpg`} className="image-image img img-fluid ml-3 p-0 col-12 img-center"/>
            </div>
            <div className="col-md-7 col-4 ml-0 pl-0 pl-sm-2 d-flex">
            {props.near === false ? (
                <div>
                    <h3 className="display-6 pb-1 pt-2 pt-sm-3 title mt-3" style={{color: '#ec9422'}}>
                        {props.categories.map((category, index) => (
                            (index === props.categories.length-1) ? category.label.toUpperCase() : category.label.toUpperCase() + ', ' 
                        ))}
                    </h3>
                    <h2 className="display-5 pb-sm-3 pb-1 pt-2 pt-sm-3 title">{props.name}</h2>
                    {/* <Rating
                        id="rating"
                        readonly
                        initialRating={props.rating}
                        emptySymbol={<img src="/images/star-empty.png" className="icon" style={{maxWidth: 15}} />}
                        fullSymbol={<img src="/images/star-full.png" className="icon" style={{maxWidth: 15}} />}
                    /> */}
                    <h4 className="font-weight-light mt-2 title-2" style={{color: 'gray'}}>{props.info.length > 180 ? props.info.substring(0,180) + '...' : props.info}</h4>
                </div>
            ):(
                <div>
                    <h3 className="category-mobile display-6 title mt-md-5 mt-3 pt-3" style={{color: '#ec9422'}}>
                        {props.categories.map((category, index) => (
                            (index === props.categories.length-1) ? category.label.toUpperCase() : category.label.toUpperCase() + ', ' 
                        ))}
                    </h3>
                    <h2 className="display-5 title">{props.name}</h2>
                    {props.distance ? props.distance < 1 ? <h4 className="title-2" style={{color: 'gray'}}>{(props.distance*1000).toFixed(0)}m away</h4> : <h4 className="title-2" style={{color: 'gray'}}>{(props.distance).toFixed(2)}km away</h4> : <h3></h3>}
                    <h4 className="font-weight-light mt-2 title-2" style={{color: 'gray'}}>{props.info.length > 180 ? props.info.substring(0,180) + '...' : props.info}</h4>
                </div>
            )}   
            </div>
        </div>
    </div>
    
);

export default ServiceListItem;