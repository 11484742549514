import moment from 'moment';

/** get the earliest possible date for a booking based on availability and current date */
export const getEarliestDate = (availability) => {

    let day
    if (moment(availability.fromDate).isAfter(moment())) {
        day = moment(availability.fromDate)
    } else {
        day = moment()
        if (parseInt(availability.notice.substring(0,2)) > 23) {
            day = day.add(1,'days')
        }
    }

    let dateMatch = false;
    while (!dateMatch) {
        availability.times.map((t) => {
            if (t.day.substring(0,3) === day._d.toString().substring(0,3)) { 
                dateMatch = true 
            }
        })
        if (dateMatch) { break; }
        else { day = day.add(1,'days') }
    }

    return day
}

/** checks if day is available based on service avilability */
export const isDayAvailable = (day, availability) => {

    let dateMatch = false;
    let from = moment(availability.fromDate)
    let to = moment(availability.toDate)
    let today = moment()

    if (parseInt(availability.notice.substring(0,2)) > 23 && today.isSameOrAfter(from)) {
        from = from.add(1,'days')
    }

    // check if day within date range
    if (day.isSameOrBefore(to) && day.isSameOrAfter(from)) { 
        
        // check if day included in available times
        availability.times.map((t) => {
            if (t.day.substring(0,3) === day._d.toString().substring(0,3)) { 
                dateMatch = true 
            }
        })
    }

    return !(dateMatch)
}

/** returns a list of possible times based on the service availability, item duration and other bookings */
export const getTimes = (day, availability, duration, bookings) => {

    let to = ''
    let from = ''
    let times = []

    // get time range for specified day
    availability.times.map((t) => {
        if (t.day.substring(0,3) === day._d.toString().substring(0,3)) {
            from = moment(t.from, 'hh:mm')
            to = moment(t.to, 'hh:mm')
        }
    })

    let timeNow = moment()
    let min;
    let current;
    
    // check the day selected for booking allows for sufficient notice when contructing times array
    if (day._d.toString().substring(0,16) === timeNow._d.toString().substring(0,16)) {
        min = timeNow.add(parseInt(availability.notice.substring(0,2).replace(/ /g,'')),availability.notice.substring(2).replace(/ /g,''))
        if (min.isAfter(from)) {
            current = moment(((parseInt(min._d.toString().substring(16,18))+1).toString() + "00"), 'hh:mm') // round off to nearest next hour
        } else {
            current = from
        }
    } else {
        current = from
    }

    // take duration into account
    if (duration != undefined && duration != 'N/A') {
        to = to.subtract(parseInt(duration.substring(0,2).replace(/ /g,'')), duration.substring(2).replace(/ /g,''))
    }

    let newDuration
    if (duration === 'N/A' || duration === undefined) { newDuration = '0 minutes'}
    else { newDuration = duration }

    // check for other bookings and take padding into consideration
    let sameDay
    let sameTimes = []
    if (bookings) {
        // filter bookings of same day
        sameDay = bookings.filter((b) => b.time && b.date.substring(0,12) === day._d.toString().substring(4,16))
        
        // get other bookings start and end times and add padding
        if (sameDay.length != 0) {
            sameDay.map((i) => {
                let dur = (i.duration != undefined && i.duration != 'N/A')  ? i.duration : '0 minutes'
                sameTimes.push({
                    s: moment(i.time, 'hh:mm').subtract(parseInt(availability.buffer.substring(0,2).replace(/ /g,'')),availability.buffer.substring(2).replace(/ /g,'')),
                    e: i.duration != 'N/A' ?
                            moment(i.time, 'hh:mm').add(parseInt(dur.substring(0,2).replace(/ /g,'')),dur.substring(2).replace(/ /g,'')).add(parseInt(availability.buffer.substring(0,2).replace(/ /g,'')),availability.buffer.substring(2).replace(/ /g,''))
                        :
                            moment(i.time, 'hh:mm').add(parseInt(availability.buffer.substring(0,2).replace(/ /g,'')),availability.buffer.substring(2).replace(/ /g,'')),
                })
            })
        }
        // sort sameTimes
        sameTimes = sameTimes.sort((a,b) => {
            return a.s.isSameOrBefore(b.s) ? -1 : 1;
        })
    }

    // create array of starting times using increments
    if (sameTimes.length != 0) {
        while (current.isSameOrBefore(to)) {
            
            // check that not encroaching on event time before push 
            if (sameTimes.length > 1) {
                if ((current.add(parseInt(newDuration.substring(0,2).replace(/ /g,'')), newDuration.substring(2).replace(/ /g,''))).isSameOrBefore(sameTimes[0].s)) {
                    times.push(current._d.toString().substring(16,21))
                    current = current.add(parseInt(availability.increments.substring(0,2).replace(/ /g,'')), availability.increments.substring(2).replace(/ /g,''))
                } else {
                    current = sameTimes[0].e
                }
                // remove index 0 from sameTimes
                sameTimes.shift()
            } else if (sameTimes.length > 0) {
                if ((current.add(parseInt(newDuration.substring(0,2).replace(/ /g,'')), newDuration.substring(2).replace(/ /g,''))).isSameOrBefore(sameTimes[0].s)) {
                    times.push(current._d.toString().substring(16,21))
                    current = current.add(parseInt(availability.increments.substring(0,2).replace(/ /g,'')), availability.increments.substring(2).replace(/ /g,''))
                } else {
                    current = sameTimes[0].e
                }
                // remove index 0 from sameTimes
                sameTimes.shift()
            } else {
                times.push(current._d.toString().substring(16,21))
                current = current.add(parseInt(availability.increments.substring(0,2).replace(/ /g,'')), availability.increments.substring(2).replace(/ /g,''))
            }
    
            if (current.isAfter(to)) { break; }
        }

    } else {
        while (current.isSameOrBefore(to)) {
            times.push(current._d.toString().substring(16,21))
            current = current.add(parseInt(availability.increments.substring(0,2).replace(/ /g,'')), availability.increments.substring(2).replace(/ /g,''))
    
            if (current.isAfter(to)) { break; }
        }
    }

    return times
}

/** edits bookings so that their parameters allow for full calendar display */
export const bookingsToCalendar = (bookings) => {

    let calendarBookings = [];
    bookings.map((booking) => {
        if (booking.time) {
            let endTime 
            if (booking.duration != 'N/A') {
                endTime = (moment(booking.time, 'hh:mm').add(parseInt(booking.duration.substring(0,2).replace(/ /g,'')), booking.duration.substring(2).replace(/ /g,'')))._d.toString().substring(16,21)
            } else {
                endTime = booking.time
            }
            let newBooking = {
                date: new Date(booking.date.toString().substring(0,16) + ' ' + booking.time + ' GMT+0200'),
                end: new Date(booking.date.toString().substring(0,16) + ' ' + endTime + ' GMT+0200'),
                title: booking.item + ' - ' + booking.title,
                item: booking.item,
                name: booking.title,
                duration: booking.duration,
                time: booking.time,
                paid: booking.paid,
                cost: booking.cost,
                deposit: booking.deposit,
                bookingID: booking.id,
                backgroundColor: '#e0e8f3',
                borderColor: '#e0e8f3'
            }
            calendarBookings.push(newBooking)
        }
    })

    return calendarBookings;
}