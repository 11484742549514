import React from 'react';

/**
 * form component for proposals for sellers
 * rendered by the EventsPage
 */
export class ProposalForm extends React.Component {

    state = {

        edit: this.props.edit,

        notes: this.props.proposal ? this.props.proposal.notes : '',
        cost: this.props.proposal ? this.props.proposal.cost : '',
        costType: this.props.proposal ? this.props.proposal.costType : 'flat',
        deposits: this.props.proposal ? this.props.proposal.percent ? true : false : false,
        percent: this.props.proposal ? this.props.proposal.percent ? this.props.proposal.percent : 0 : 0,

        costError: '',
        percentError: ''

    }

    /**
     * methods for updating state upon form input
     */
    onNotesChange = (e) => {
        const notes = e.target.value;
        this.setState(() => ({ notes }));
    }
    onCostChange = (e) => {
        const cost = e.target.value;
        this.setState(() => ({ cost }));
    }
    onCostTypeChange = (e) => {
        const costType = e.target.value;
        this.setState(() => ({ costType }));
        e.persist();
    }
    onDeposits = () => {
        this.setState(() => ({ deposits: !this.state.deposits }))
        if (!this.onDeposits) {
            this.setState(() => ({ percent: 0 }))
        }
    }
    onPercentChange = (e) => {
        const percent = e.target.value;
        this.setState(() => ({ percent }));
    }

    /**
     * method to handle removing a proposal
     * calls the onSubmitProposal method in EventsPage
     */
    onRemove = () => {
        this.props.onSubmitProposal(this.props.proposal,'remove')
    }

    /**
     * method to submit a proposal
     * performs form error checks
     * calls the onSubmitProposal method in EventsPage
     */
    onSubmit = (e) => {
        e.preventDefault();

        // error checks
        if (!this.state.cost) { this.setState(() => ({ costError: 'Please provide a quote.'})) }
        else { this.setState(() => ({ costError: '' })) }
        if (this.state.deposits && !this.state.percent) { this.setState(() => ({ percentError: 'Please provide a percent for the deposit.'})) }
        else { this.setState(() => ({ percentError: '' })) }

        if (this.state.cost && ( (this.state.deposits && this.state.percent) || (!this.state.deposits && !this.state.percent) ) ) {

            // submit
            let proposal = {
                cost: this.state.cost,
                costType: this.state.costType
            }
            if (this.state.deposits) {
                proposal = {
                    ...proposal,
                    percent: this.state.percent
                }
            }
            if (this.state.notes) {
                proposal = {
                    ...proposal,
                    notes: this.state.notes
                }
            }
            if (this.state.edit) {
                this.props.onSubmitProposal(proposal, 'edit')
            } else {
                this.props.onSubmitProposal(proposal, 'add')
            }
        }
    }

    render() {
        return (

            <div className="d-flex justify-content-center text-left mt-5">
                <div className="col-8">
                    <form onSubmit={this.onSubmit}>
                    
                    <div className="form-group pb-4">
                        <hr/>
                        <label htmlFor="notes">Optional Reply / Proposal Description</label>
                        <textarea
                            className="form-control rounded-0 form-control-lg"
                            style={{height: 70}}
                            placeholder=""
                            type="text"
                            value={this.state.notes}
                            onChange={this.onNotesChange}/>
                    </div>

                    <fieldset className="form-group pb-4">
                        <hr/>
                        <label className="col-form-label">Quote</label>
                        <div className="row">
                            <div className="col-sm-10">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flat" onChange={this.onCostTypeChange}  checked={this.state.costType === 'flat'} value='flat'/>
                                    <label className="form-check-label ml-1">Flat Rate</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="hourly" onChange={this.onCostTypeChange} checked={this.state.costType === 'hourly'} value='hourly'/>
                                    <label className="form-check-label ml-1">Hourly Rate</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div className="form-group pb-3">
                        <div className="input-group flex-nowrap">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="addon-wrapping">R</span>
                        </div>
                        <input
                            className="form-control font-weight-light rounded-0 form-control-lg"
                            placeholder="Cost"
                            type="text"
                            id="cost"
                            value={this.state.cost}
                            onChange={this.onCostChange}/>
                        </div>
                        <small className="form-text" style={{color: '#ec9422'}}>{this.state.costError}</small>
                    </div>

                    <div className="form-group form-check pb-3">
                        <input type="checkbox" defaultChecked={this.state.deposits} onClick={this.onDeposits} className="form-check-input"/>
                        <label className="pl-2 form-check-label">Deposit</label>
                    </div>

                    <div className="form-group pb-3" hidden={!this.state.deposits}>
                        <div className="input-group flex-nowrap">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="addon-wrapping">Deposit %</span>
                        </div>
                        <input
                            className="form-control font-weight-light rounded-0 form-control-lg"
                            placeholder="Cost"
                            type="number"
                            id="percent"
                            value={this.state.percent}
                            onChange={this.onPercentChange}/>
                        </div>
                        <small className="text-muted">{this.state.percentError ? <span style={{color: '#ec9422'}}>{this.state.percentError}</span> : `Input the percentage of total cost you'd like to charge as deposit.`}</small>
                    </div>

                    {this.state.edit ?
                        <div className="d-flex justify-content-center">
                            <button className="rounded-0 btn btn-main btn-block" style={{height: 30}}>
                                <h4 className="font-weight-light">Save Changes</h4>
                            </button>
                        </div>
                    :
                        <div className="d-flex justify-content-center">
                            <button className="rounded-0 btn btn-main btn-block" style={{height: 30}}>
                                <h4 className="font-weight-light">Send Proposal</h4>
                            </button>
                        </div>
                    }
                </form>

                {this.state.edit ?
                    <div className="row d-flex justify-content-center m-0 p-0 mt-5">
                        <div className="col-12 d-flex justify-content-center m-0 p-0">
                            <button onClick={this.onRemove} className="mr-2 rounded-0 mb-2 btn btn-block" style={{height: 30}}>
                                <h4 className="font-weight-light">Remove</h4>
                            </button>
                        </div>
                    </div>
                :
                    <div></div>
                }

                </div>
            </div>
        )
    }
}

export default ProposalForm;