
/** returns images in gallery that were uploaded by seller */
export const sortGallery1 = (gallery) => {

    let gallery1 = [];
    gallery.map((image) => {
        if (!image.i) { gallery1.push(image) }
    })

    return gallery1;
}

/** returns images in gallery that were uploaded by buyers */
export const sortGallery2 = (gallery) => {

    let gallery2 = [];
    gallery.map((image) => {
        if (image.i) { gallery2.push(image.i) }
    })

    return gallery2;
}