const filtersReducerDefaultState = {
    text: '',
    category: '',
    location: '',
    near:'',
    nearServices: []
};

export default (state = filtersReducerDefaultState, action) => {

    switch (action.type) {
        case 'SET_TEXT_FILTER':
            return {
                ...state, 
                text: action.text
            };
        case 'SET_CATEGORY_FILTER':
            return {
                ...state, 
                category: action.category
            };
        case 'SET_LOCATION_FILTER':
            return {
                ...state, 
                location: action.location
            };
        case 'SET_NEAR_FILTER':
            return {
                ...state, 
                near: action.near
            };
        case 'SET_NEAR_SERVICES':
            return {
                ...state, 
                nearServices: action.services
            };
        default:
            return state;
    }
};