import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {getCategories} from '../../utils/admin';

/**
 * categories view for admin page
 */
export class Categories extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            categories: this.props.categories ? this.props.categories : [],
            text: '',
            category: '',
            categoryVal: '',
            showAddCategory: false,
            showDeleteCategory: false,
            loading: false,
            image: '',
            formError: ''
        }
    }

    /**
     * updates state when new props received from AdminPage
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.categories != newProps.categories) {
            this.setState(() => ({ categories: newProps.categories }))
        }
    }

    /**
     * updates state upon search term input
     */
    onTextChange = (e) => {
        const text = e.target.value;
        this.setState(() => ({ 
            text,
            categories: this.state.categories
        }))
    }

    /**
     * updates state upon cancel click on all pop ups
     * closes all pop ups and clears state
     */
    onCancel = () => {
        this.setState(() => ({ 
            showAddCategory: false,
            showEditCategory: false,
            showDeleteCategory: false,
            category: '',
            categoryVal: '',
            image: ''
        }))
    }

    /**
     * updates state to show addCategory pop up
     */
    onAddCategory = () => {
        this.setState(() => ({ showAddCategory: true }))
    }

    /**
     * updates state upon entering category name in input
     */
    onNewCategoryChange = (e) => {
        const category = e.target.value;
        this.setState(() => ({ 
            formError: '',
            category 
        }))
    }

    /**
     * method for uploading and saving image to firebase storage
     */
    onImageChange(event) {
        const image = event.target.files[0]
        if (image.size < 2097152) {
            this.setState(() => ({ 
                loading: true,
                formError: ''
            }))
            const mainImage = firebase.storage().ref().child(image.name)
            mainImage.put(image).then((snapshot) => {
                mainImage.getDownloadURL().then((url) => {
                    this.setState(() => ({ 
                        image: url,
                        loading: false 
                    }))
                })
            })
        } else {
            this.setState(() => ({ 
                loading: false,
                formError: 'Please choose a smaller image.'
            }))
        }
    }

    /**
     * calls onSaveCategory method in AdminPage and passes in new category
     * updates state
     */
    onSaveCategory = () => {
        if (this.state.category && this.state.image) {
            this.props.onSaveCategory({
                category: this.state.category,
                id: this.state.category.toLowerCase(),
                image: this.state.image
            })
            this.setState(() => ({ 
                formError: '',
                showAddCategory: false,
                category: '',
                image: ''
            }))
        } else {
            this.setState(() => ({ formError: 'Please add a category name and image.'}))
        }
    }

    /**
     * updates state to show confirm delete category pop up and to selected category
     */
    onDeleteCategory = (category, categoryVal) => {
        this.setState(() => ({ 
            category,
            categoryVal,
            showDeleteCategory: true 
        }))
    }

    /**
     * calls onDeleteCategory method in AdminPage
     * updates state to hide confirm delete category pop up
     */
    onConfirmDeleteCategory = () => {
        this.props.onDeleteCategory(this.state.category)
        this.setState(() => ({
            showDeleteCategory: false,
            category: ''
        }))
    }

    render() {
        return (

            <div>
                <div className="mb-3 ml-5">

                    {/* pop up for adding category */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showAddCategory}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Save"
                            confirmBtnBsStyle="default"
                            showCancel
                            cancelBsBtnText="Cancel"
                            cancelBsBtnBsStyle="link"
                            title={`Add a Category`}
                            onConfirm={this.onSaveCategory}
                            onCancel={this.onCancel}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-sm-10 mt-5">
                                    
                                    <input
                                        className="form-control form-control-lg rounded-0 font-weight-light text-center"
                                        type="text"
                                        placeholder={`New Category`}
                                        value={this.state.newCategory}
                                        onChange={this.onNewCategoryChange}
                                    />

                                    {this.state.loading ? (
                                        <div className="container h-100">
                                            <div className="row h-100 justify-content-center align-items-center">
                                                <img src='/images/loader.gif' />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="mb-2 mt-5 preview">
                                                <img id="previewImg" src={this.state.image} className="rounded mx-auto d-block pb-4" style={{maxWidth:'50%'}}/>
                                            </div>
                                            <div className="custom-file rounded-0">
                                                <input 
                                                    type="file" 
                                                    className="image-input"
                                                    id="picture"
                                                    onChange={() => this.onImageChange(event)}
                                                    name="picture"
                                                    accept="image/*"/>
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="picture">{this.state.image ? 'Change image' : 'Add an image'}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <h5 className="font-weight-light m-5" style={{color: '#ec9422'}}>{this.state.formError}</h5>
                                
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* confirm pop up for deleting category */}
                    <div className="mt-5">
                        <SweetAlert
                            show={this.state.showDeleteCategory}
                            focusConfirmBtn={false}
                            overflow="scroll"
                            closeOnClickOutside
                            heightAuto={false}
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="default"
                            showCancel
                            cancelBsBtnText="Cancel"
                            cancelBsBtnBsStyle="link"
                            title={`Delete ${this.state.categoryVal}`}
                            onConfirm={this.onConfirmDeleteCategory}
                            onCancel={this.onCancel}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-sm-10 mt-5">
                                    <h3 className="row font-weight-light text-left display-5 mb-5">Are you sure you want to delete this category?</h3>
                                </div>
                            </div>
                        </SweetAlert>
                    </div>

                    {/* add category button */}
                    <div className="d-flex justify-content-center mt-3 mb-5" style={{height: 30}}>
                        <button className="btn btn-block col-12 col-sm-8" onClick={this.onAddCategory}><h4 className="font-weight-light">Add Category</h4></button>
                    </div>
                    <hr/>
                    
                    {/* category search bar */}
                    <div className="d-flex justify-content-center mb-5 mt-5">
                        <div className="col-12 col-sm-8 m-0 p-0">
                            <input
                                className="form-control form-control-lg rounded-0 font-weight-light text-center"
                                type="text"
                                placeholder={`Search Categories`}
                                value={this.state.text}
                                onChange={this.onTextChange}/>
                        </div>
                    </div>

                    {/* category list display */}
                    {getCategories(this.state.categories, this.state.text).map((category, index) => (
                        <div className="row mb-3 pt-2 hover-border" key={index}>
                            <h4 className="ml-4 col-9 font-weight-light display-5">{category.category}</h4>
                            <h5 className="font-weight-light m-0 p-0 text-right hover-orange col-2" onClick={(() => this.onDeleteCategory(category.id, category.category))}>Delete</h5>
                        </div>
                    ))}

                </div>
            </div>
        )
    }
}

export default Categories;