import axios from 'axios';
import database from '../firebase/firebase';

exports.welcomeEmailGmail = async (profile, result)=>{
    axios.post(`https://send-itorho-emails.herokuapp.com/new/${profile.type}`, { displayName: result.user.displayName, email: result.user.email })
        .then(res => {
            console.log(`Gmail registration: email sent to ${result.user.email}`);
        })
}

exports.welcomeEmail = async function(profile){
    axios.post(`https://send-itorho-emails.herokuapp.com/new/${profile.type}`, { displayName: profile.name, email: profile.email })
        .then(res => {
            console.log(`Email registration: email sent to ${profile.email}`);
        })
}


exports.sendNotificationEmail = async function(id){
    database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val())
                    if(value[0].id == id){
                        let buyer = value[0];
                        axios.post(`https://send-itorho-emails.herokuapp.com/message`, { displayName: buyer.name, email: buyer.email })
                            .then(res => {
                                console.log(`Message notification: email sent to ${buyer.email}`);
                            })
                    }
                });
    });
    
}

exports.sendQuoteNotificationEmail = async function(id){
    //TODO
    database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val())
                    if(value[0].id == id){
                        let buyer = value[0];
                        axios.post(`https://send-itorho-emails.herokuapp.com/quote`, { displayName: buyer.name, email: buyer.email })
                            .then(res => {
                                console.log(`New quote notification: email sent to ${buyer.email}`);
                            })
                    }
                });
    });
    
}

exports.sendQuoteAcceptedEmail = async (sellerID, sellerName)=>{
    //TODO
    const id = sellerID;
    database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val())
                    if(value[0].id == id){
                        let buyer = value[0];
                        axios.post(`https://send-itorho-emails.herokuapp.com/accept`, { displayName: buyer.name, email: buyer.email, sellerName: sellerName })
                            .then(res => {
                                console.log(`Quote accepted notification: email sent to ${buyer.email}`);
                            })
                    }
                });
    });
}

exports.sendInvoiceEmail = async (id, booking)=>{
    //TODO
    database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val())
                    if(value[0].id == id){
                        let buyer = value[0];
                        axios.post(`https://send-itorho-emails.herokuapp.com/invoice`, { displayName: buyer.name, email: buyer.email, booking: booking})
                            .then(res => {
                                console.log(`Invoice notification: email sent to ${buyer.email}`);
                            })
                    }
                });
    });
}

exports.sendBookingEmail = async (id, booking)=>{
    //TODO
    database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val());
                    if(value[0].id == id){
                        let seller = value[0];
                        axios.post(`https://send-itorho-emails.herokuapp.com/booking`, { displayName: seller.name, email: seller.email, booking: booking})
                            .then(res => {
                                console.log(`Booking notification: email sent to ${seller.email}`);
                            })
                    }
                });
    });

}

exports.sendBookingEmailToBuyer = async (id, booking)=>{
    //TODO
    database.ref(`users/`)
            .once('value')
            .then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    const value = Object.values(childSnapshot.val());
                    if(value[0].id == id){
                        let seller = value[0];
                        axios.post(`https://send-itorho-emails.herokuapp.com/booking`, { displayName: seller.name, email: seller.email, booking: booking})
                            .then(res => {
                                console.log(`Booking notification for buyer: email sent to ${seller.email}`);
                            })
                    }
                });
    });

}