import React from 'react';
import firebase from '../../firebase/firebase';

/**
 * form for editing profile used by edit profile component
 */
class ProfileForm extends React.Component {

    constructor(props) {
        super(props);

        this.onImageChange = this.onImageChange.bind(this);

        this.state = {
            id: props.profile ? props.profile.id : '',
            email: props.profile ? props.profile.email : '',
            name: props.profile ? props.profile.name : '',
            number: props.profile ? props.profile.number : '',
            type: props.profile ? props.profile.type : '',
            image: props.profile ? props.profile.image ? props.profile.image : '/images/profile-placeholder.png' : '/images/profile-placeholder.png',

            bank: props.profile ? props.profile.bank : '',
            accName: props.profile ? props.profile.accName : '',
            accNumber: props.profile ? props.profile.accNumber : '',
            branchCode: props.profile ? props.profile.branchCode : '',

            enableSave: true,
            loading: false
        }
    }

    /**
     * method calls onCancel method in EditProfile
     */
    onCancel = (e) => {
        e.preventDefault()
        this.props.onCancel()
    }

    /**
     * updates state on name input
     */
    onNameChange = (e) => {
        const name = e.target.value
        this.setState(() => ({ name }))
    }

    /**
     * updates state on number input
     */
    onNumberChange = (e) => {
        const number = e.target.value
        this.setState(() => ({ number }))
    }

    /**
     * updates state on email input
     */
    onEmailChange = (e) => {
        const email = e.target.value
        this.setState(() => ({ email }))
    }

    /**
     * method handles image upload to firebase storage
     */
    onImageChange(event) {
        this.setState(() => ({ loading: true }))
        const image = event.target.files[0];
        if(image.size < 2097152) {
            const storageRef = firebase.storage().ref();
            const mainImage = storageRef.child(image.name);
            mainImage.put(image)
                .then((snapshot) => {
                    mainImage.getDownloadURL().then((url) => {
                        this.setState({
                            image: url,
                            loading: false
                        })
                    })
                })
        } else {
            alert("File is too large. Please reduce the size and reupload.");
        }
    }

    /**
     * submits profile to onSubmit method in EditProfile
     */
    onSubmit = (e) => {
        e.preventDefault()
        if (!this.state.name) {
            this.setState(() => ({ error: 'Please provide a title.'}))
        } else {
            this.setState(() => ({ error: ''}))
            if (this.state.type === "seller") {
                if (!!this.state.bank && !!this.state.accName && !!this.state.accNumber && !!this.state.branchCode) {
                    this.props.onSubmit({
                        name: this.state.name,
                        number: this.state.number,
                        email: this.state.email,
                        firstSignIn: false,
                        type: this.state.type,
                        image: this.state.image,
                        bank: this.state.bank,
                        accName: this.state.accName,
                        accNumber: this.state.accNumber,
                        branchCode: this.state.branchCode
                    });
                } else {
                    this.props.onSubmit({
                        name: this.state.name,
                        number: this.state.number,
                        email: this.state.email,
                        firstSignIn: false,
                        type: this.state.type,
                        image: this.state.image
                    });
                }
            } else {
                this.props.onSubmit({
                    name: this.state.name,
                    number: this.state.number,
                    email: this.state.email,
                    firstSignIn: false,
                    type: this.state.type,
                    image: this.state.image
                });
            }
        }
    }

    render() {
        return (

            <div>
                <div className="d-flex justify-content-center text-left mt-4">
                    <div className="col-8">

                        <form onSubmit={this.onSubmit}>
                            <div className="form-group pb-4">
                                <hr/>

                                <div hidden={!this.state.loading} className="container h-100">
                                    <div className="row h-100 justify-content-center align-items-center">
                                        <img src='/images/loader.gif' />
                                    </div>
                                </div>

                                <div hidden={this.state.loading}>
                                    <div className="mb-2 preview">
                                        <img id="previewImg" src={this.state.image} className="rounded mx-auto d-block pb-4" style={{maxWidth:'50%'}}/>
                                    </div>
                                    <div className="custom-file rounded-0">
                                        <input 
                                            type="file" 
                                            className="image-input"
                                            id="picture"
                                            onChange={() => this.onImageChange(event)}
                                            name="picture"
                                            accept="image/*"/>
                                        <label
                                            className="custom-file-label"
                                            htmlFor="picture">Change Picture
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="form-group pb-4">
                                <hr/>
                                <label htmlFor="name">Name</label>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="text"
                                    id="name"
                                    value={this.state.name}
                                    onChange={this.onNameChange}/>
                            </div>

                            <div className="form-group pb-4">
                                <hr/>
                                <label htmlFor="number">Contact Number</label>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="text"
                                    id="number"
                                    value={this.state.number}
                                    onChange={this.onNumberChange}/>
                            </div>

                            <div className="form-group pb-4">
                                <hr/>
                                <label htmlFor="email">Email</label>
                                <input
                                    className="form-control font-weight-light rounded-0 form-control-lg"
                                    placeholder=""
                                    type="email"
                                    id="email"
                                    disabled={true}
                                    value={this.state.email}
                                    onChange={this.onEmailChange}/>
                            </div>

                            <hr/>
                            <div className="d-flex justify-content-center mb-4">
                                <button className="rounded-0 btn btn-main btn-block" disabled={!this.state.enableSave} style={{height: 35}}>
                                    <h4 className="font-weight-light">Save</h4>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileForm;