
/** returns categories matching search term (text) */
export const selectCategories = (categories, { text }) => {
    return categories.filter((category) => {

        const textMatch = category.id.includes(text.toLowerCase())
        
        return textMatch;
    })
};

/** return only categories that currently have an active service (avoid empty categories) */
export const selectActiveCategories = (categories, services) => {

    let active = []
    let match;
    if (services) {
        categories.map((cat) => {
            match = false
            services.map((s) => {
                if (s.name && s.categories) {
                    if (s.categories.length != 0) {
                        s.categories.map((c) => {
                            if (cat.id.includes(c.value.toLowerCase())) { 
                                if (!match && !(cat in active)) { 
                                    active.push(cat) 
                                    match = true
                                }
                            }
                        })
                    }
                }
            })
        })

    }
    return active;
};