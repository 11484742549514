import React from 'react';

/**
 * bank details display component used by admin page
 */
const Bank = (props) => (

    <div>
        <div className="d-flex justify-content-center m-5 mt-0">
            <div className="text-left col-sm-8 col-12">
                <h4 className="display-5 pt-5 ml-3">Bank Details</h4>
                <hr/>
                <h6 className="font-weight-light pt-2 ml-3">Bank</h6>
                <h4 className="font-weight-light ml-3">{props.profile.bank.label}</h4>
                <hr/>
                <h6 className="font-weight-light pt-2 ml-3">Account Name</h6>
                <h4 className="font-weight-light ml-3">{props.profile.accName}</h4>
                <hr/>
                <h6 className="font-weight-light pt-2 ml-3">Account Number</h6>
                <h4 className="font-weight-light ml-3">{props.profile.accNumber}</h4>
                <hr/>
                <h6 className="font-weight-light pt-2 ml-3">Account Name</h6>
                <h4 className="font-weight-light ml-3">{props.profile.branchCode}</h4>
                <hr/>
            </div>
        </div>
    </div>
)

export default Bank;