import React from 'react';
import {connect} from 'react-redux';
import {animateScroll as scroll} from 'react-scroll'
import ServiceListItem from './ServiceListItem';
import {selectServices} from '../../selectors/services';

/**
 * service list component
 * rendered by the category page
 */
export class ServiceList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            services: this.props.services
        }
    }

    /**
     * updates state upon receiving new props from the redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.services != this.state.services && newProps.services && newProps.services.length != 0) {
            this.setState(() => ({ services: newProps.services }))
        }
    }

    /**
     * method for onclick on service
     * navigates to the service page
     * and sets events from the database for that service 
     * (this is done only now to limit calls to the database as it costs money - this can be further optimised in other places)
     */
    onService = (id, name) => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        let s = name.toLowerCase().replace(/ /g,'');
        this.props.props.history.push(`/service/${s}`)
    }

    render() {
        return (

            <div className="row d-flex justify-content-center col-12 mb-5 pb-5">
                <div className="" style={{maxWidth: 900}}>
                    
                    {
                        (this.state.services === undefined || (this.props.near && this.state.services.length === 0)) ? (
                            <div className="container h-100">
                                <div className="row h-100 justify-content-center align-items-center">
                                    <img src='/images/loader.gif' />
                                </div>
                            </div>
                        ) : (
                            this.state.services.length === 0 ? (
                                
                                    <div className="d-flex justify-content-center mt-5">
                                        <h3 className="text-center display-5 pb-sm-3 pb-1 pt-5 pt-sm-3 title font-weight-light col-8" style={{color: 'grey'}}>There are currently no service providers for this category, but they will be listed soon.</h3>
                                    </div>
                            ) : (
                                
                                this.state.services.map((service, index) => (
                                    <div className="m-2 service-link pointer" key={index} onClick={() => this.onService(service.id, service.name)}>
                                        <ServiceListItem key={service.id} {...service} near={this.props.near}/>
                                    </div>
                                ))
                            )
                        )
                    }
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    services: selectServices(state.services, state.filters),
    near: state.filters.near,
    filters: state.filters
});

export default connect(mapStateToProps)(ServiceList);