import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll'
import CategoryListItem from './CategoryListItem';
import ServiceList from '../Services/ServiceList';
import {addRoute} from '../../actions/route';
import {selectActiveCategories} from '../../selectors/categories';
import {selectActiveServices} from '../../selectors/services';

/**
 * component to display category list items
 */
export class CategoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            near: this.props.near,
            categories: this.props.categories ? this.props.categories : []
        }
    }
    
    /**
     * set near filter off on mounting
     * page loads to show categories tab first
     */
    componentDidMount = () => {
        this.setState(() => ({ near: false }))
    }

    /**
     * updates state upon receiving new props from the redux store
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.near != newProps.near) {
            this.setState(() => ({ near: newProps.near }));
        }
        if (this.state.categories != newProps.categories) {
            this.setState(() => ({ categories: newProps.categories }));
        }
    }

    /**
     * when user presses on a category, this method saves the vertical location user was on this page
     * in the case that user presses back - they will therefore be automatically scrolled back to the same place on this page
     * method scrolls to top for service page view
     */
    onCategory = () => {
        this.props.addRoute({offset: window.pageYOffset})
        scroll.scrollTo(0, { duration: 750, delay: 10 })
    }

    render() {
        return (
    
            <div className="mt-2 pt-md-3 pt-1">
                <div hidden={this.state.near} className="row">
                    
                    {this.state.categories.length === 0 ? (
                        <div className="container h-80 mt-5 pt-5">
                            <div className="row h-80 justify-content-center align-items-center mt-5 pt-5">
                                <img src='/images/loader.gif' />
                            </div>
                        </div>
                    ) : (
                            this.state.categories.map((category) => {
                                return (
                                    <div className="col-lg-3 col-4 p-3 d-flex justify-content-center" key={category.id}>
                                        <NavLink style={{width: '100%'}} className="category-link" key={category.id} onClick={this.onCategory} to={`/category/${category.id}`}>
                                            <CategoryListItem  style={{border: '1px solid blue'}} key={category.id} {...category}/>
                                        </NavLink>
                                    </div>
                                );
                            })
                        )
                    }

                </div>

                <div hidden={!this.state.near}>
                    <ServiceList near={true} props={this.props}/>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categories: selectActiveCategories(state.categories, selectActiveServices(state.services)),
    near: state.filters.near,
    route: state.route
});
const mapDispatchToProps = (dispatch) => ({
    addRoute: (route) => dispatch(addRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);