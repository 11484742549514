import React from 'react';
import {getProposals, getAcceptedProposals} from '../../selectors/events';

/**
 * component for displaying event
 * 
 * rendered by EventsPage
 */
export class Event extends React.Component {

    state = {

        proposals: this.props.event.proposals ? getProposals(this.props.event.proposals) : [],
        accepted: this.props.event.proposals ? getAcceptedProposals(this.props.event.proposals) : []
    }

    /**
     * updates state upon receiving new props
     */
    componentWillReceiveProps = (newProps) => {
        if (getProposals(newProps.event.proposals) != this.state.proposals) {
            this.setState(() => ({ proposals: getProposals(newProps.event.proposals) }))
        }
        if (getAcceptedProposals(newProps.event.proposals) != this.state.accepted) {
            this.setState(() => ({ accepted: getAcceptedProposals(newProps.event.proposals) }))
        }
    }

    render() {
        return (

            <div className="mt-5 mb-3">
                <div className="d-flex justify-content-center text-left">
                    <div className="col-8">

                        {!this.props.seller && this.state.proposals && this.state.proposals.length != 0 ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                                <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>New Proposals</h5> 
                                {this.state.proposals.map((p, index) => (
                                    <div key={index}>
                                        <div className="pl-3 pr-3">
                                            <hr />
                                        </div>
                                        <h4 className="pl-3">From <span onClick={(() => this.props.onService(p.service))} className="item-link">{p.service ? p.service : ''}</span></h4>
                                        <h4 className="font-weight-light pl-3 pt-2">R{p.cost}.00 ({p.costType})</h4>
                                        <h4 className="font-weight-light pl-3">{p.percent ? `Deposit: ${p.percent}%` : ''}</h4>
                                        <h5 className="font-weight-light pl-3 pt-2 pb-2">{p.notes ? p.notes : ''}</h5>
                                        <div className="m-4 pb-2 d-flex justify-content-center">
                                            <div className="pointer">
                                                <h4 onClick={(() => this.props.onAccept(p))} style={{color: '#ec9422'}} className="mr-5">Accept</h4>
                                            </div>
                                            <div className="pointer">
                                                <h4 onClick={(() => this.props.onReject(p))} style={{color: '#ec9422'}} className="ml-5">Decline</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}                  
                            </div>
                        : ''} 

                        {!this.props.seller && this.state.accepted && this.state.accepted.length != 0 ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                                <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Accepted Proposals</h5> 
                                {this.state.accepted.map((p, index) => (
                                    <div key={index}>
                                        <div className="pl-3 pr-3">
                                            <hr />
                                        </div>
                                        <h4 className="pl-3">From {p.service ? p.service : ''}</h4>
                                        <h4 className="font-weight-light pl-3 pt-2">R{p.cost}.00 ({p.costType})</h4>
                                        <h4 className="font-weight-light pl-3">{p.percent ? `Deposit: ${p.percent}%` : ''}</h4>
                                        <h5 className="font-weight-light pl-3 pt-2 pb-2">{p.notes ? p.notes : ''}</h5>
                                    </div>
                                ))}                  
                            </div>
                        : ''} 

                        {this.props.seller && this.props.proposal && Object.entries(this.props.proposal).length != 0 ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                                <h5 className="text-left font-weight-light pt-2 pb-2 pl-2" style={{color: '#ec9422'}}>{this.props.proposal.status === undefined ? `Proposal Pending` : this.props.proposal.status === 'accepted' ? `Proposal Accepted` : this.props.proposal.status === 'rejected' ? `Proposal Declined` : ''}</h5>     
                                <h4 className="font-weight-light pb-2 pl-3">Notes: {this.props.proposal.notes ? this.props.proposal.notes : ''}</h4>
                                <h4 className="font-weight-light pb-2 pl-3">Quote: R{this.props.proposal.cost}.00 ({this.props.proposal.costType})</h4>
                                <h4 className="font-weight-light pb-2 pl-3">{this.props.proposal.percent ? `Deposit: ${this.props.proposal.percent}%` : ''}</h4>
                                <div className="pointer" hidden={this.props.proposal.status != 'rejected'}>
                                    <h4 onClick={(() => this.props.onClear(this.props.proposal))} style={{color: '#ec9422'}} className="mb-3 text-center">Clear</h4>
                                </div>
                            </div>
                        : ''}

                        {this.props.event.description ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                                <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Description</h5>                      
                                <h4 className="font-weight-light p-2 pl-3">{this.props.event.description}</h4>
                            </div>
                        : ''} 

                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Date</h5>                                                        
                            <h4 className="font-weight-light p-2 pl-3">{this.props.event.date}</h4>
                        </div>

                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Time</h5>                                                        
                            <h4 className="font-weight-light p-2 pl-3">{this.props.event.time}</h4>
                        </div>

                        {this.props.event.duration ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                                <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Duration</h5>                      
                                <h4 className="font-weight-light p-2 pl-3">{this.props.event.duration}</h4>
                            </div>
                        : ''} 

                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Number of People</h5>                                                        
                            <h4 className="font-weight-light p-2 pl-3">{this.props.event.people}</h4>
                        </div>

                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>  
                            <h5 className="text-left font-weight-light pt-2 pl-2" style={{color: '#ec9422'}}>Services Required</h5>
                            {Object.values(this.props.event.services).map((service, index) => (
                                <div key={index}>
                                    <h4 className="font-weight-light pl-2 pl-3">{service}</h4>
                                </div>
                            ))}
                        </div>
                                
                    </div>
                </div>
            </div>
        )
    }
}

export default Event;