import moment from 'moment';

/**
 * sort events by date
 */
export const sortEvents = (events) => {

    if (events && events.length != 0) {
        return events.filter((event) => {
            const date = moment(event.date);
            const now = moment();
            const future = date ? date.isSameOrAfter(now, 'day') : true;
            return future;
        }).sort((a, b) => {
            return moment(a.date).isSameOrAfter(moment(b.date), 'day') ? 1 : -1;
        })
    } else {
        return []
    }
}

/**
 * get all events for a specific buyer
 */
export const getEvents = (events, id) => {
    
    let eventList = []
    
    events.map((e) => {
        if (e.buyerID === id) {
            eventList.push(e)
        }
    })

    return eventList;
}

/**
 * check if service category is included in required services of event
 */
export const getSellerEvents = (events, categories, id) => {
    
    let eventList = []

    if (categories) {
        categories.map((c) => {
            events.map((e) => {
                e.services.map((s) => {
                    if (s === c.label && !eventList.includes(e)) {
                        eventList.push(e)
                    }
                })
                
            })
        })
    }

    // filter eventList checking for existing proposal and if it was already cleared
    let i
    eventList.map((e, index) => {
        i = index
        if (e.proposals) {
            Object.values(e.proposals).map((p) => {
                if (p.serviceID === id) {
                    if (p.clear != undefined) {
                        eventList = eventList.filter((e, index2) => index2 != index)
                    }
                }
            })
        }
    })

    return eventList;
}

/**
 * check if a seller has made a proposal
 */
export const proposalMatch = (event, id) => {

    let match = false
    if (event.proposals) {
        Object.values(event.proposals).map((p) => {
            if (p.serviceID === id) {
                match = true
            }
        })
    }
    return match;
}

/**
 * get the proposal status for a specific seller id
 */
export const proposalStatus = (event, id) => {

    let status = false
    if (event.proposals) {
        Object.values(event.proposals).map((p) => {
            if (p.serviceID === id) {
                status = p.status
            }
        })
    }
    return status;
}

/**
 * find proposal matching seller id
 */
export const selectProposal = (event, id) => {

    let proposal = {}
    if (event.proposals) {
        Object.values(event.proposals).map((p) => {
            if (p.serviceID === id) {
                proposal = p
            }
        })
    }
    return proposal;
}

/**
 * get all pending proposals
 */
export const getProposals = (proposals) => {
    let proposalList = []
    if (proposals) {
        proposals.map((p) => {
            if (p.status === undefined) {
                proposalList.push(p)
            }
        })
    }
    return proposalList;
}

/**
 * get all accepted proposals
 */
export const getAcceptedProposals = (proposals) => {

    let proposalList = []
    if (proposals) {
        proposals.map((p) => {
            if (p.status === 'accepted') {
                proposalList.push(p)
            }
        })
    }
    return proposalList;
}

/**
 * get all accepted and pending proposals
 */
export const getActiveProposals = (proposals) => {

    let proposalList = []
    if (proposals) {
        proposals.map((p) => {
            if (p.status === 'accepted' || p.status === undefined) {
                proposalList.push(p)
            }
        })
    }
    return proposalList;
}

/**
 * remap proposals so that object indices start from 0 and are consecutive
 */
export const remap = (proposals, proposal) => {

    let proposalList = []
    Object.values(proposals).map((p, index) => {
        proposalList.push(p)
    }) 

    if (proposal) {
        proposalList.push(proposal)
    }
    return proposalList;
}