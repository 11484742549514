import React from 'react';

/**
 * component for displaying a booking for the seller
 * 
 * rendered by the Calendar component
 */
export class BookingEvent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            booking: this.props.booking.event ? this.props.booking.event : {},
            paid: this.props.booking.event ? this.props.booking.event.extendedProps.deposit ? `R${this.props.booking.event.extendedProps.deposit} Deposit Outstanding` : this.props.booking.event.extendedProps.paid ? 'Paid' : 'Payment Outstanding' : '',
            deposit: this.props.booking.event ? this.props.booking.event.extendedProps.paidDeposit ? 'Paid' : '' : '',
            showPayButton: this.props.booking.event ? this.props.booking.event.extendedProps.paid ? false : true : true,
        }
    }

    /**
     * method to update state upone receving new props
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.state.booking != newProps.booking.event) {
            this.setState(() => ({ booking: newProps.booking.event }))
        }
    }

    /**
     * method to update state when user presses paid button
     * calls onPaid method in Calendar component
     */
    onPaid = () => {
        this.setState(() => ({ 
            paid: 'Paid',
            showPayButton: false 
        }))
        this.props.onPaid(this.state.booking.extendedProps.bookingID, { paid: true })
    }

    render() {
        return (

            <div className="d-flex justify-content-center">
                <div className="col-8">
                    <h3 className="font-weight-light mb-4 mt-5">{this.state.booking ? this.state.booking.start.toString().substring(0,15) : ''}</h3>
                    <div>
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>
                            <h4 className="text-left font-weight-light p-3">{this.state.booking ? this.state.booking.extendedProps.item : ''}</h4>
                        </div>
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>
                            <h4 className="text-left font-weight-light p-3">{this.state.booking ? this.state.booking.extendedProps.time : ''}</h4>
                        </div>
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>
                            <h4 className="text-left font-weight-light p-3">{this.state.booking ? this.state.booking.extendedProps.name : ''}</h4>
                        </div>
                        <div className="mb-3" style={{border: '1px gainsboro solid'}} hidden={this.state.booking ? this.state.booking.extendedProps.notes ? false : true : true}>
                            <h4 className="text-left font-weight-light p-3">{this.state.booking ? this.state.booking.extendedProps.notes : ''}</h4>
                        </div>
                        {this.state.booking ? this.state.booking.extendedProps.paidDeposit ?
                            <div className="mb-3" style={{border: '1px gainsboro solid'}}>
                                <h4 className="font-weight-light float-right p-3" style={{color: '#ec9422'}}>{this.state.deposit}</h4>                             
                                <h4 className="text-left font-weight-light p-3">{this.state.booking ? 'R' + this.state.booking.extendedProps.paidDeposit + ' (deposit)': ''}</h4>
                            </div> : <div></div> : <div></div>
                        }
                        <div className="mb-3" style={{border: '1px gainsboro solid'}}>
                            <h4 className="font-weight-light float-right p-3" style={{color: '#ec9422'}}>{this.state.paid}</h4> 
                            <h4 className="text-left font-weight-light p-3">{this.state.booking ? 'R' + this.state.booking.extendedProps.cost + ' ' : ''}</h4>
                        </div>

                        {this.state.showPayButton ? (
                            <div>
                                <div className="mt-4 p-0" onClick={this.onPaid}>
                                    <button className="btn btn-main btn-block m-0" style={{height: 30}}><h4 className="font-weight-light">Paid in Cash</h4></button>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                            
                    </div>
                </div>
            </div>
        )
    }
}

export default BookingEvent;