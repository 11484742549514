import ReactGA from 'react-ga';

export const initGA = ()=>{
    console.log('G analytics has started');
    ReactGA.initialize('UA-141897145-1');
};

export const logPageView =   ()=>{
    ReactGA.set({page: window.location.pathname});
    ReactGA.pageview(window.location.pathname);
    console.log('pageview recoreded: '+window.location.pathname);
};
