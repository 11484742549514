import React from 'react';
import {connect} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import firebase from '../../firebase/firebase';
import {animateScroll as scroll} from 'react-scroll';
import TopNav from '../TopNav';
import EditProfile from './EditProfile';
import EditBank from './EditBank';
import Footer from '../Footer';
import {startLogout, deleteProfile, startEditProfile} from '../../actions/profile';
import {checkFutureBookings} from '../../selectors/bookings';
import {startRemoveBooking} from '../../actions/bookings';
import {startEditService} from '../../actions/services';
import {initGA, logPageView} from '../../utils/gAnalytics';


/**
 * Settings page for site
 * Users can update their profiles, banking details and operating hours (sellers only)
 * Users can slo delete/disable their profiles and reset their password
 * 
 * www.itorho.com/settings
 */
export class SettingsPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showForm: false,
            showChange: false,
            showConfirm: false,
            showConfirm2: false,
            showDelete: false,
            showUnable: false,
            showBank: false,
            showHours: false,
    
            password: '',
            passwordError: '',
            newPassword: '',
            newPasswordConfirm: '',
            newPasswordError: '',
            newPasswordConfirmError: ''
        }
    }

    /**
     * initiates google analytics upon component mounting
     */
    componentDidMount = () => {
        initGA();
        logPageView();
    }

    /**
     * updates state to show edit profile form (in EditProfile component)
     */
    onEditProfile = () => {
        this.setState(() => ({ showForm: true }));
    }

    /**
     * updates state to show edit bank form (in EditBank component)
     */
    onEditBank = () => {
        this.setState(() => ({ showBank: true }));
    }

    /**
     * updates state to close all pop up editing forms
     * calls actions to update profile/service in database
     */
    onSubmit = (action, data) => {
        scroll.scrollTo(0, { duration: 750, delay: 10 });
        this.setState(() => ({ 
            showForm: false,
            showBank: false,
            //showHours: false
        }));
        if (action === 'profile') {
            this.props.startEditProfile(data)
        } else if (action === 'hours') {
            this.props.startEditService(this.props.id, data)
        }
    }

    /**
     * global state update method to close any pop ups
     */
    onCancel = () => {
        this.setState(() => ({ 
            showUnable: false,
            showDelete: false,
            showConfirm: false,
            showConfirm2: false,
            showChange: false 
        }));
    }

    /**
     * method for updating state to show change password pop up
     */
    onChange = () => {
        this.setState(() => ({ showChange: true }));
    }

    /**
     * method to update state as password is input
     */
    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({ 
            password,
            passwordError: '' 
        }))
    }

    /**
     * methods to update state as password is input
     */
    onNewPasswordChange = (e) => {
        const newPassword = e.target.value;
        this.setState(() => ({ 
            newPassword,
            newPasswordError: '' 
        }))
    }
    onNewPasswordConfirmChange = (e) => {
        const newPasswordConfirm = e.target.value;
        this.setState(() => ({ 
            newPasswordConfirm,
            newPasswordConfirmError: '' 
        }))
    }

    /**
     * method checks if current password is correct before allowing password reset
     * also checks new password is valid and matches confirmation
     * not essential, but good practise to include
     */
    onReset = () => {

        if (!this.state.password) { 
            this.setState(() => ({ passwordError: 'Please enter your current password.'})) 
        } else { 
            this.setState(() => ({ passwordError: '' })) 
        }

        if (this.state.newPassword.length < 8) { 
            this.setState(() => ({ newPasswordError: 'Your password needs to be at least 8 characters.'}))
        } else if (!this.state.newPassword) {
            this.setState(() => ({ newPasswordError: 'Please enter a new password.'}))
        } else {
            this.setState(() => ({ newPasswordError: ''}))            
        }
        
        if (!this.state.newPasswordConfirm) { 
            this.setState(() => ({ newPasswordConfirmError: 'Please confirm your new password.'})) 
        } else if (this.state.newPasswordConfirm != this.state.newPassword) { 
            this.setState(() => ({ newPasswordConfirmError: 'Passwords do not match.'})) 
        } else { 
            this.setState(() => ({ newPasswordConfirmError: '' })) 
        }

        if (this.state.password && this.state.newPassword && this.state.newPassword.length > 7 && this.state.newPasswordConfirm && this.state.newPassword === this.state.newPasswordConfirm) {
            this.setState(() => ({ 
                showChange: false,
                showConfirm: true,
                password: '',
                newPassword: '',
                newPasswordConfirm: ''
            }))
            this.onResetPassword();
        }
    }

    /**
     * method for resetting password
     */
    onResetPassword() {

        // retrieve the currently logged in user
        const user = firebase.auth().currentUser;

        if (user) {
            // user is signed in
            user.updatePassword(this.state.newPassword).then(function () {
                // update successful
                console.log("Password updated.");
            }).catch(function (error) {
                // an error occurred
                console.log("Password NOT Updated\nError:\n" + error);
            });
        } else {
                console.log("No user logged in");
        }
    }

    /**
     * method for updating state to show confirm delete pop up
     */
    onDelete = () => {
        this.setState(() => ({ showDelete: true }));
    }

    /**
     * method updates state to close delete confirmation pop up
     * 
     * checks if user is a buyer or seller
     * if buyer - will show pop up to confirm bookings will be deleted
     * if seller - will not allow deletion if bookings exist and will show the unable pop up
     * 
     * if no bookings exist - calls the onDisable method
     */
    onConfirmDelete = () => {
        this.setState(() => ({ showDelete: false }));

        if (this.props.type === 'seller') {
            if (this.props.bookings.length != 0) {
                this.setState(() => ({ showUnable: true }));
            } else {
                this.props.startEditService(this.props.service.id, {
                    ...this.props.service,
                    disabled: true
                })
                this.onDisable()
            }
        } else {
            if (this.props.bookings.length != 0) {
                this.setState(() => ({ showConfirm2: true }))
            } else {
                this.onDisable()
            }
        }
    }

    /**
     * method to disable user
     * 
     * profile is not permanently deleted but disabled
     * user will not be able to login and access this profile anymore
     */
    onDisable = () => {
        this.setState(() => ({ showConfirm2: false }))

        // remove existing bookings for the user before deleting profile
        if (this.props.bookings.length != 0) {
            let bookings = this.props.bookings
            bookings.map((booking) => {
                this.props.startRemoveBooking(booking.id)
            })
        }

        // removes the user from auth list
        deleteProfile().then(() => {
            this.props.startLogout()
        })
    };

    render() {
        return (

            <div>
                <TopNav loggedIn={true} type={this.props.type} props={this.props}/>
                <div className="mb-5 pb-5">
                    <div className="d-flex justify-content-center ml-3 mr-3 ml-sm-0 mr-sm-0">
                        <div className="col-12 col-sm-8">
                
                            {/* pop up components */}
                            <EditProfile 
                                profile={this.props.profile}
                                onSubmit={this.onSubmit}
                                showForm={this.state.showForm}/>
                            <EditBank
                                profile={this.props.profile}
                                onSubmit={this.onSubmit}
                                showForm={this.state.showBank}/>

                            {/* reset password popup */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showChange}
                                    confirmBtnText="Cancel"
                                    confirmBtnBsStyle="link"
                                    showCancelBtn={false}
                                    focusConfirmBtn={false}
                                    title="Password Reset"
                                    onCancel={this.onCancel}
                                    onConfirm={this.onCancel}>
                                    <div className="d-flex justify-content-center">
                                        <div className="col-8">
                                            <h4 className="font-weight-light mt-5">Confirm your current password.</h4>
                                            <input
                                                className="form-control form-control-lg mt-4"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.onPasswordChange}
                                                placeholder="Current Password"/>
                                                <small className="font-weight-light text-left" style={{color: '#ec9422'}}>{this.state.passwordError}</small>
                                            <h4 className="font-weight-light mt-5">Your new password should be at least 8 characters long.</h4>
                                            <input
                                                className="form-control form-control-lg mt-4"
                                                type="password"
                                                value={this.state.newPassword}
                                                onChange={this.onNewPasswordChange}
                                                placeholder="New Password"/>
                                                <small className="font-weight-light text-left" style={{color: '#ec9422'}}>{this.state.newPasswordError}</small>
                                            <input
                                                className="form-control form-control-lg mt-4"
                                                type="password"
                                                value={this.state.newPasswordConfirm}
                                                onChange={this.onNewPasswordConfirmChange}
                                                placeholder="Confirm New Password"/>
                                                <small className="font-weight-light text-left" style={{color: '#ec9422'}}>{this.state.newPasswordConfirmError}</small>
                                            <button className="btn btn-block mt-4" onClick={this.onReset}><h4 className="font-weight-light">Submit</h4></button>
                                        </div>
                                    </div>
                                </SweetAlert>
                            </div>

                            {/* confirm email sent popup */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showConfirm}
                                    confirmBtnText="Done"
                                    confirmBtnBsStyle="link"
                                    focusConfirmBtn={false}
                                    title="Password Reset"
                                    onCancel={this.onCancel}
                                    onConfirm={this.onCancel}>
                                    <div className="d-flex justify-content-center">
                                        <div className="col-6">
                                            <h4 className="font-weight-light mt-5">Your password has successfully been reset.</h4>
                                        </div>
                                    </div>
                                </SweetAlert>
                            </div>

                            {/* confirm delete account popup */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showDelete}
                                    showCancel
                                    confirmBtnText="Yes, Delete my Account"
                                    cancelBtnText="Cancel"
                                    confirmBtnBsStyle="default"
                                    cancelBtnBsStyle="link"
                                    showCancelBtn={false}
                                    focusConfirmBtn={false}
                                    title="Are You Sure You Want to Delete Your Account?"
                                    onCancel={this.onCancel}
                                    onConfirm={this.onConfirmDelete}>
                                    <div className="d-flex justify-content-center">
                                        <div className="col-6">
                                            <h4 className="font-weight-light mt-5">Once deleted, your account cannot be recovered.</h4>
                                        </div>
                                    </div>
                                </SweetAlert>
                            </div>

                            {/* confirm2 delete account popup */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showConfirm2}
                                    showCancel
                                    confirmBtnText="Yes, Delete my Account"
                                    cancelBtnText="Cancel"
                                    confirmBtnBsStyle="default"
                                    cancelBtnBsStyle="link"
                                    showCancelBtn={false}
                                    focusConfirmBtn={false}
                                    title="Are You Sure You Want to Delete Your Account?"
                                    onCancel={this.onCancel}
                                    onConfirm={this.onDisable}>
                                    <div className="d-flex justify-content-center">
                                        <div className="col-10">
                                            <h4 className="font-weight-light mt-5">You have upcoming bookings. These will be cancelled and you will not receive refunds for those that have been paid.</h4>
                                        </div>
                                    </div>
                                </SweetAlert>
                            </div>

                            {/* cannot delete account popup for sellers */}
                            <div className="mt-5">
                                <SweetAlert
                                    show={this.state.showUnable}
                                    confirmBtnText="Ok"
                                    confirmBtnBsStyle="default"
                                    showCancelBtn={false}
                                    focusConfirmBtn={false}
                                    title="You cannot disable your account right now."
                                    onCancel={this.onCancel}
                                    onConfirm={this.onCancel}>
                                    <div className="d-flex justify-content-center">
                                        <div className="col-10">
                                            <h4 className="font-weight-light mt-5">You have upcoming bookings, and therefore cannot delete your account. Once all your bookings have completed, you may try again.</h4>
                                        </div>
                                    </div>
                                </SweetAlert>
                            </div>

                            {/* page content */}
                            <h3 className="display-5 mt-5 mb-3 text-center">Settings</h3>
                            <hr/>
                            
                            <div className="mt-2 text-left terms-link hover-orange" onClick={this.onEditProfile}>
                                <h3 className="font-weight-light">Edit Profile</h3>
                            </div>
                            <hr/>

                            <div className="mt-2 text-left">
                                <h3 className="font-weight-light">Account Settings</h3>
                                <div onClick={this.onChange} className="terms-link hover-orange">
                                    <h3 className="font-weight-light ml-5 mt-3">Change Password</h3>
                                </div>
                                <div onClick={this.onDelete} className="terms-link hover-orange">
                                    <h3 className="font-weight-light ml-5 mt-3">Delete Account</h3>
                                </div>
                            </div>
                            <hr/> 

                            <div hidden={!(this.props.type === "seller")}>
                                <div className="mt-2 text-left">
                                    <h3 className="font-weight-light">Payment Settings</h3>
                                    <div onClick={this.onEditBank} className="terms-link hover-orange">
                                        <h3 className="font-weight-light ml-5 mt-3">Add / Edit Banking Details</h3>
                                    </div>
                                </div>
                                <hr/> 
                            </div> 

                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Footer/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    id: state.profile[0].id,
    profile: state.profile[0],
    type: state.profile[0].type,
    service: state.services[0],
    bookings: checkFutureBookings(state.bookings)
})

const mapDispatchToProps = (dispatch) => ({
    startLogout: () => dispatch(startLogout()),
    startEditService: (id, updates) => dispatch(startEditService(id, updates)),
    startRemoveBooking: (id) => dispatch(startRemoveBooking(id)),
    startEditProfile: (profile) => dispatch(startEditProfile(profile))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);