import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

export const PrivateRoute = ({ 
    isAuthenticated, 
    component: Component,
    ...rest
}) => (
    <Route {...rest} component={(props) => {
        return (
        isAuthenticated ? (
            <div><Component {...props}/></div>
        ) : (
            <Redirect to="/" />
        )
    )}}/>
);

export const mapStateToProps = (state) => ({
    isAuthenticated: !!state.profile[0]
});

export default connect(mapStateToProps)(PrivateRoute);