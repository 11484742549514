import React from 'react';
import {connect} from 'react-redux';
import {animateScroll as scroll} from 'react-scroll'
import ServiceList from './ServiceList';
import ServiceListFilters from './ServiceListFilters';
import TopNav from '../TopNav';
import {setNearFilter} from '../../actions/filters';
import {removeRoute} from '../../actions/route';
import {initGA, logPageView} from '../../utils/gAnalytics';

/**
 * Page to display the services associated with a specific category
 * upon clicking on a category item on the dashboard page
 * 
 * www.itorho.com/category/:category
 * 
 */
export class CategoryPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            category: this.props.category ? this.props.category.category : ''
        }
    }

    /**
     * set the near filter (to default = false) by calling the action upon mounting
     */
    componentDidMount = () => {
        this.props.setNearFilter();
        
        initGA();
        logPageView();
    }

    /**
     * method is called by onBack in ServiceListFilters
     * navigates back to the dashboard
     * implements a scroll down to the correct place on the dashboard page (location saved in route) and removes the route
     */
    onBack = () => {
        this.props.history.push('/dashboard');
        if (this.props.loggedIn && this.props.route[0]) {
            scroll.scrollTo(this.props.route[0].offset, { duration: 750, delay: 10 })
        }
        this.props.removeRoute()
    }

    render() {
        return (
    
            <div>
                <TopNav loggedIn={this.props.loggedIn} type='buyer' props={this.props}/>
                <div>
                    <div>
                        <div>
                            <ServiceListFilters 
                                title={this.state.category} 
                                props={this.props.match.params.category}
                                onBack={this.onBack}/>
                            <ServiceList 
                                props={this.props}
                                near={false} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    category: state.categories.find((category) => {
        if (category.id == props.match.params.category) {
            return category;
        }
    }),
    route: state.route,
    loggedIn: state.profile[0] ? true : false
});

const mapDispatchToProps = (dispatch) => ({
    setNearFilter: () => dispatch(setNearFilter()),
    removeRoute: () => dispatch(removeRoute())
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);