import React from 'react';

/**
 * horizontal service feature list display component for entry page
 */
const ServiceListItemHorizontal = (props) => (

    <div className="mb-5">
        <div className="item-link">
            <div className="col-12 mt-md-4 mb-3 welcome-image" style={{backgroundImage: `url(${props.image})`, backgroundSize: 'cover', height: `200px`}}></div>
            <div className="p-md-4 p-1 pt-0 category-link-border">
                <div>
                    <h2 className="display-5 pb-md-3 pt-md-3 title welcome-feature">{props.name}</h2>
                    <h4 className="font-weight-light mt-md-2 title-2 welcome-text">{props.info.length > 180 ? props.info.substring(0,180) + '...' : props.info}</h4>
                </div>
            </div>
        </div>
    </div>

);

export default ServiceListItemHorizontal;