import React from 'react';
import ServiceListItemHorizontal from './ServiceListItemHorizontal';

/**
 * feature component for entry page
 * currently set to show catering venues in cape town
 */
export class Feature extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            services: this.props.services ? this.props.services.length > 5 ? this.props.services : this.props.services.concat(this.props.services) : [],
            index: 0,
            prevShow: false
        }
    }

    /**
     * updates state upon receiving new props from entry page
     */
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.services != this.state.services) {
            this.setState(() => ({ services: newProps.services }))
        }
    }

    /**
     * calls onService method in EntryPage
     */
    onService = (id, name) => {
        this.props.onService(id, name)
    }

    /**
     * onNext and onPrev are methods for horizontal navigation of the featured services
     */
    onNext = () => {
        if (this.state.index+6 >= this.state.services.length) {
            this.setState(() => ({ services: this.state.services.concat(this.state.services)}))
        }
        this.setState(() => ({ index: this.state.index+3 }))
        if (this.state.index+3 >= 2) {
            this.setState(() => ({ prevShow: true }))
        }
    }
    onPrev = () => {
        this.setState(() => ({ index: this.state.index-3 }))
        if (this.state.index <= 3) {
            this.setState(() => ({ prevShow: false }))
        }
    }
    
    render() {
        return (

            <div className="m-md-5" style={{ backgroundColor: 'white' }}>
                <hr/>
                <h3 className="ml-5 mr-5 m-md-0 p-3 pb-5 pt-5 p-md-5 m-md-0 mt-md-5 pt-md-5 text-center font-weight-light display-4 welcome-subtitle" style={{ color: 'black' }}>Browse our list of Catering services in Cape Town</h3>
                <div className="mt-md-5 row d-flex justify-content-center">

                    {/* previous button */}
                    <div className="col-1">
                        <div className="mt-5 pt-3 mr-5 ml-md-0 ml-3 float-left" hidden={!this.state.prevShow} onClick={this.onPrev}>
                            <img src="/images/back.png" className="img search-link welcome-search-link" style={{maxWidth:20, maxHeight:20}}/>
                        </div>
                    </div> 

                    {/* list of services */}
                    <div className="col-10 d-flex justify-content-centre">
                        {this.state.services ? (
                            this.state.services.slice(this.state.index,this.state.index+3).map((service, index) => (
                                <div key={index} className="col-4">
                                    <div className="m-md-2 service-link" key={index} onClick={() => this.onService(service.id, service.name)}>
                                        <ServiceListItemHorizontal key={service.id} {...service} near={false}/>
                                    </div>
                                </div>
                            ))
                        ) : ( 
                            <div></div>
                        )}
                    </div>

                    {/* next button */}
                    <div className="col-1">
                        <div className="mt-5 pt-3 ml-5 mr-md-0 mr-3 float-right" onClick={this.onNext}>
                            <img src="/images/next.png" className="img search-link welcome-search-link" style={{maxWidth:20, maxHeight:20}}/>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Feature;