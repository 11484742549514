
/** set filters in redux store */

export const setTextFilter = (text = "") => ({
    type: 'SET_TEXT_FILTER',
    text
});

export const setCategoryFilter = (category = " ") => ({
    type: 'SET_CATEGORY_FILTER',
    category
});

export const setLocationFilter = (location = " ") => ({
    type: 'SET_LOCATION_FILTER',
    location
});

export const setNearFilter = (near = false) => ({
    type: 'SET_NEAR_FILTER',
    near
});

export const setNearServices = (services) => ({
    type: 'SET_NEAR_SERVICES',
    services
});